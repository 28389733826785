import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  Void: any;
};


export enum Action_Log_Origin {
  Owd = 'OWD',
  Routine = 'ROUTINE',
  FiveS = 'FIVE_S',
  ActionLog = 'ACTION_LOG'
}

export enum Action_Log_Pillar_Image {
  Safety = 'SAFETY',
  Management = 'MANAGEMENT',
  Planning = 'PLANNING',
  Distribution = 'DISTRIBUTION',
  Fleet = 'FLEET',
  Stock = 'STOCK',
  People = 'PEOPLE',
  Warehouse = 'WAREHOUSE'
}

export enum Action_Log_Status {
  Open = 'OPEN',
  InProgress = 'IN_PROGRESS',
  ForRevision = 'FOR_REVISION',
  Completed = 'COMPLETED'
}

export enum Attachment_Types {
  Image = 'IMAGE',
  Video = 'VIDEO',
  Pdf = 'PDF'
}

export type ActionLogFilters = {
  meeting?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<Action_Log_Status>>>;
  KPIs?: Maybe<Array<Maybe<Scalars['String']>>>;
  pillars?: Maybe<Array<Maybe<Action_Log_Pillar_Image>>>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  involvedUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  dueDate?: Maybe<Scalars['Date']>;
  delayed?: Maybe<DelayedActionLogsInDcByStatus>;
};

export type ActionLogsInDcByStatus = {
  __typename?: 'ActionLogsInDCByStatus';
  OPEN: Array<Maybe<CreateEditActionLog>>;
  IN_PROGRESS: Array<Maybe<CreateEditActionLog>>;
  FOR_REVISION: Array<Maybe<CreateEditActionLog>>;
  COMPLETED: Array<Maybe<CreateEditActionLog>>;
};

export type AnalyticsFileUpload = {
  __typename?: 'AnalyticsFileUpload';
  id: Scalars['ID'];
  fileType: File_Types;
  fileS3Url: Scalars['String'];
  uploadedAt?: Maybe<Scalars['Date']>;
};

export type AnsweredRoutinesSchema = {
  __typename?: 'AnsweredRoutinesSchema';
  routines?: Maybe<Array<Maybe<Routine>>>;
  pageInfo: PageInfo;
};

export type AssignedUsersWithRoles = {
  userId: Scalars['ID'];
  role: Scalars['String'];
  routineAvailableId?: Maybe<Scalars['ID']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  type?: Maybe<Attachment_Types>;
};

export type BaseLocation = {
  __typename?: 'BaseLocation';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  conutry: Scalars['String'];
};

export type BaseUser = {
  __typename?: 'BaseUser';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  shortSlug?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  blockedReason?: Maybe<Scalars['String']>;
  lastActiveAt?: Maybe<Scalars['Date']>;
  doctorStatus?: Maybe<Scalars['String']>;
  employeeNumber?: Maybe<Scalars['String']>;
};

export type BuData = {
  __typename?: 'BuData';
  usersToTrain: Scalars['Int'];
  usersTrained: Scalars['Int'];
  name: Scalars['String'];
  position: Scalars['String'];
  percentage: Scalars['String'];
};

export type BusinessUnit = {
  __typename?: 'BusinessUnit';
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  businessUnitId?: Maybe<Scalars['Int']>;
  zones?: Maybe<Array<Maybe<Zone>>>;
};

export type BusinessUnitWithActionLogCount = {
  __typename?: 'BusinessUnitWithActionLogCount';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  actionLogsCount?: Maybe<Scalars['Int']>;
  zones?: Maybe<Array<Maybe<ZoneWithActionLogCount>>>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type ChecklistAvailable = {
  __typename?: 'ChecklistAvailable';
  id: Scalars['ID'];
  name: Scalars['String'];
  content: Scalars['JSON'];
};

export type ChecklistInfo = {
  __typename?: 'ChecklistInfo';
  bu?: Maybe<BusinessUnit>;
  department?: Maybe<Department>;
  externalCompany?: Maybe<ExternalCompany>;
  workingDepartment?: Maybe<Department>;
  workingSubDepartment?: Maybe<SubDepartment>;
  areaManager?: Maybe<BaseUser>;
  performer?: Maybe<BaseUser>;
  territoryOwner?: Maybe<BaseUser>;
  inspector?: Maybe<BaseUser>;
  gasTests?: Maybe<Array<Maybe<GetTest>>>;
  status?: Maybe<Scalars['String']>;
  statusReasonContent?: Maybe<StatusReasonContent>;
  userUnderEvaluation?: Maybe<BaseUser>;
  progressPercentage: Scalars['Int'];
  isCompleted?: Maybe<Scalars['Boolean']>;
};

export enum ChecklistType {
  Pdt = 'PDT',
  Skap = 'SKAP',
  Owd = 'OWD',
  /** If you wana use it, convert fiveS to "5S" before */
  FiveS = 'fiveS'
}

export enum ClientType {
  App = 'APP',
  Web = 'WEB'
}

export type Cluster = {
  __typename?: 'Cluster';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  comment: Scalars['String'];
  user: BaseUser;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** SKAP web: count by autonomous sub-levels */
export type CountByAutonomousLevel = {
  __typename?: 'CountByAutonomousLevel';
  title: Scalars['String'];
  count: Scalars['Int'];
};

/** SKAP web: count by level i.e funcional, technical, autonomous */
export type CountByLevels = {
  __typename?: 'CountByLevels';
  slug: Scalars['String'];
  count: Scalars['Int'];
  percentage: Scalars['Int'];
  total: Scalars['Int'];
};

/** SKAP web: count for each level grouped by position */
export type CountByPosition = {
  __typename?: 'CountByPosition';
  slug: Scalars['String'];
  total: Scalars['Int'];
  impactedCount: Scalars['Int'];
  data: Array<Maybe<ModulePercentage>>;
};

export type CountryAndLocationList = {
  __typename?: 'CountryAndLocationList';
  country?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CovidTest = {
  __typename?: 'CovidTest';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  givenAt?: Maybe<Scalars['Date']>;
  testImage?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  resultAt?: Maybe<Scalars['Date']>;
  approvedBy?: Maybe<Scalars['Int']>;
  needsApproval?: Maybe<Scalars['Boolean']>;
};

export type CovidUser = {
  __typename?: 'CovidUser';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  isAlreadyDiagnosed?: Maybe<Scalars['Boolean']>;
  covidTest?: Maybe<CovidTest>;
};

export type CovidUserAndCount = {
  __typename?: 'CovidUserAndCount';
  count?: Maybe<Scalars['Int']>;
  covidUserList?: Maybe<Array<Maybe<CovidUser>>>;
};

export type CovidUsersForLocation = {
  __typename?: 'CovidUsersForLocation';
  name: Scalars['String'];
  slug: Scalars['String'];
  employeeNumber?: Maybe<Scalars['String']>;
  quarantineUntil?: Maybe<Scalars['Date']>;
  doctorStatus?: Maybe<Scalars['String']>;
  location?: Maybe<LocationName>;
  latestCovidTest?: Maybe<LatestCovidTest>;
};

export type CovidUsersForLocationCount = {
  __typename?: 'CovidUsersForLocationCount';
  count: Scalars['Int'];
  covidUsersForLocation?: Maybe<Array<Maybe<CovidUsersForLocation>>>;
};

export type CreateEditActionLog = {
  __typename?: 'CreateEditActionLog';
  id: Scalars['ID'];
  startDate: Scalars['Date'];
  description: Scalars['String'];
  successCriteria: Scalars['String'];
  KPIs: Scalars['String'];
  KPI: Kpi;
  endDate: Scalars['Date'];
  status: Action_Log_Status;
  pillar: Action_Log_Pillar_Image;
  owner: BaseUser;
  location: BaseLocation;
  responsibles: Array<BaseUser>;
  origin?: Maybe<Action_Log_Origin>;
  meeting: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  images: Array<Maybe<ImageInputItemType>>;
  statusUpdateDate?: Maybe<Scalars['Date']>;
  routinesAvailable?: Maybe<RoutinesAvailableNameAndSlug>;
  originText?: Maybe<Scalars['String']>;
  numberOfComments?: Maybe<Scalars['Int']>;
  ManagementTool?: Maybe<ManagementTool>;
  checklistsAvailableId?: Maybe<Scalars['ID']>;
  checklistId?: Maybe<Scalars['ID']>;
};

export type CreateEditActionLogPayload = {
  id?: Maybe<Scalars['ID']>;
  startDate: Scalars['Date'];
  description: Scalars['String'];
  successCriteria: Scalars['String'];
  KPIs: Scalars['String'];
  endDate: Scalars['Date'];
  status: Action_Log_Status;
  pillar: Action_Log_Pillar_Image;
  owner?: Maybe<Scalars['String']>;
  meeting: Scalars['String'];
  location: Scalars['String'];
  responsibles: Array<Scalars['String']>;
  origin?: Maybe<Action_Log_Origin>;
  note?: Maybe<Scalars['String']>;
  images: Array<Maybe<ImageInputItem>>;
  routinesAvailableSlug?: Maybe<Scalars['String']>;
  managementTool?: Maybe<Scalars['ID']>;
  checklistsAvailableId?: Maybe<Scalars['ID']>;
  checklistId?: Maybe<Scalars['ID']>;
  createdVia?: Maybe<Scalars['String']>;
};

export type CreateEditActionLogResponse = {
  __typename?: 'CreateEditActionLogResponse';
  isSuccess: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type DateRangeInput = {
  startDateTime: Scalars['Date'];
  endDateTime: Scalars['Date'];
};

export type DelayedActionLogsInDcByStatus = {
  isDelayed?: Maybe<Scalars['Boolean']>;
  currentDate: Scalars['Date'];
};

export type Department = {
  __typename?: 'Department';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameEN: Scalars['String'];
  acronymEN: Scalars['String'];
  acronymES: Scalars['String'];
  slug: Scalars['String'];
  subDepartments: Array<Maybe<SubDepartment>>;
};

export enum Espectro_User_Types {
  ReporterOnlyDpo = 'ReporterOnlyDPO',
  EspectroViewOnly = 'EspectroViewOnly',
  SubzoneGeneralManagerDpo = 'SubzoneGeneralManagerDPO'
}

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type ExternalCompany = {
  __typename?: 'ExternalCompany';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export enum File_Types {
  Headcount = 'HEADCOUNT',
  Accidentability = 'ACCIDENTABILITY',
  Cashless = 'CASHLESS',
  Capex = 'CAPEX',
  Telemetria = 'TELEMETRIA',
  ViolencePrevention = 'VIOLENCE_PREVENTION',
  RankingSafety = 'RANKING_SAFETY'
}

export type FailedCertificate = {
  __typename?: 'FailedCertificate';
  answers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type Forklift = {
  __typename?: 'Forklift';
  id?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  forkliftArea?: Maybe<ForkliftArea>;
  sapNumber?: Maybe<Scalars['String']>;
};

export type ForkliftArea = {
  __typename?: 'ForkliftArea';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  totalForklifts?: Maybe<Scalars['Int']>;
};

export type ForkliftRoutine = {
  __typename?: 'ForkliftRoutine';
  id: Scalars['ID'];
  approvedAt?: Maybe<Scalars['Date']>;
  approvedBy?: Maybe<User>;
  createdAt?: Maybe<Scalars['Date']>;
  forklift?: Maybe<Forklift>;
  routine?: Maybe<Routine>;
  isCompliant?: Maybe<Scalars['Boolean']>;
  isApproved?: Maybe<Scalars['Boolean']>;
};

export type GkpiDataResponse = {
  __typename?: 'GKPIDataResponse';
  GKPI: Scalars['String'];
  data: Scalars['JSON'];
};

export type GkpiExcelProcessingStatus = {
  __typename?: 'GKPIExcelProcessingStatus';
  status: GkpiFileProcessingStatus;
  message?: Maybe<Scalars['String']>;
};

export enum GkpiFileProcessingStatus {
  Finished = 'FINISHED',
  Failed = 'FAILED'
}

export type GkpiItemContent = {
  __typename?: 'GKPIItemContent';
  AC?: Maybe<Scalars['Float']>;
  BU?: Maybe<Scalars['Float']>;
  LY?: Maybe<Scalars['Float']>;
  AC_VS_LY?: Maybe<Scalars['Float']>;
};

export type GetAll5SChecklistCountByStatus = {
  __typename?: 'GetAll5SChecklistCountByStatus';
  count: Scalars['Int'];
};

export type GetAllOwdChecklists = {
  __typename?: 'GetAllOWDChecklists';
  id: Scalars['ID'];
  name: Scalars['String'];
  content: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type GetModulesAndSubmodulesCountResponse = {
  __typename?: 'GetModulesAndSubmodulesCountResponse';
  countByLevels: Array<Maybe<CountByLevels>>;
  countByAutonomousLevel: Array<Maybe<CountByAutonomousLevel>>;
  countByPosition: Array<Maybe<CountByPosition>>;
  countByMonth: Array<Maybe<MonthWiseScore>>;
};

export type GetPdtAccessResponse = {
  __typename?: 'GetPDTAccessResponse';
  canAccessPDT?: Maybe<Scalars['Boolean']>;
};

export enum GetPdtCreationType {
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  Any = 'ANY'
}

export type GetPostResponse = {
  __typename?: 'GetPostResponse';
  posts: Array<Maybe<Post>>;
  hasMore: Scalars['Boolean'];
};

export type GetRoutineAvailableByWorkOrderIdResponse = {
  __typename?: 'GetRoutineAvailableByWorkOrderIdResponse';
  checklists: Array<Maybe<Scalars['JSON']>>;
  partialChecklistId?: Maybe<Scalars['ID']>;
  checklistNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  workOrderId?: Maybe<Scalars['ID']>;
  routinesAvailableIds: Array<Maybe<Scalars['ID']>>;
  routineSlugs: Array<Maybe<Scalars['String']>>;
  versionNumbers: Array<Maybe<Scalars['Float']>>;
  executors?: Maybe<Array<Maybe<WorkOrderRoutineExecutor>>>;
};

export enum GetSifStatsBy {
  Month = 'month',
  Weekday = 'weekday',
  Location = 'location',
  Classification = 'classification',
  Cause = 'cause',
  Nature = 'nature'
}

export type GetTest = {
  __typename?: 'GetTest';
  id: Scalars['ID'];
  content: Scalars['JSON'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export enum GetTrainingsBy {
  Position = 'position',
  Bu = 'bu'
}

export type GetTrainingsPayload = {
  bu?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  zone?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type HealthStatus = {
  __typename?: 'HealthStatus';
  location?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
};

export type ImageInputItem = {
  id: Scalars['String'];
  url: Scalars['String'];
};

export type ImageInputItemType = {
  __typename?: 'ImageInputItemType';
  id: Scalars['String'];
  url: Scalars['String'];
};

export type IncidentBetweenRange = {
  __typename?: 'IncidentBetweenRange';
  bu: Scalars['String'];
  month: Scalars['String'];
  weeks: Array<Maybe<WeekContent>>;
};

export type IncidentByCategory = {
  __typename?: 'IncidentByCategory';
  categoryName: Scalars['String'];
  bus: Array<Maybe<BuContent>>;
};

export type IncidentByMonth = {
  __typename?: 'IncidentByMonth';
  bu: Scalars['String'];
  months: Array<Maybe<BuContent>>;
};

export type IncidentByType = {
  __typename?: 'IncidentByType';
  bu: Scalars['String'];
  name: Scalars['String'];
  totalIncidents: Scalars['Int'];
};


export type Kpi = {
  __typename?: 'KPI';
  id: Scalars['ID'];
  slug: Scalars['String'];
  isGlobal: Scalars['Boolean'];
  enTitle: Scalars['String'];
  esTitle: Scalars['String'];
};

export enum Location_Type {
  DistributionCenter = 'DISTRIBUTION_CENTER',
  Factory = 'FACTORY'
}

export type LatestCovidTest = {
  __typename?: 'LatestCovidTest';
  type: Scalars['String'];
};

export type List = {
  __typename?: 'List';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  shortSlug?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  company?: Maybe<Company>;
  items?: Maybe<Array<Maybe<ListItem>>>;
};

export type ListItem = {
  __typename?: 'ListItem';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  shortSlug?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSON']>;
  imageUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  list?: Maybe<List>;
};

export type ListSifStatsResponse = {
  __typename?: 'ListSifStatsResponse';
  reference?: Maybe<Scalars['String']>;
  classificationOfInjury?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  incidentCause?: Maybe<Scalars['String']>;
  nature?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  incidentDate?: Maybe<Scalars['Date']>;
  shiftType?: Maybe<Scalars['String']>;
  incidentTime?: Maybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  name?: Maybe<Scalars['String']>;
};

export type LocationDetails = {
  __typename?: 'LocationDetails';
  id: Scalars['ID'];
  name: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  subzone: Subzone;
  lat?: Maybe<Scalars['Int']>;
  long?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  bu?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  countryEs?: Maybe<Scalars['String']>;
  hasIncidentEnabled?: Maybe<Scalars['Boolean']>;
  emergencyContanct?: Maybe<Scalars['String']>;
  type?: Maybe<Location_Type>;
  riskLevel?: Maybe<Risk_Level>;
};

export type LocationName = {
  __typename?: 'LocationName';
  name: Scalars['String'];
};

export type LocationSchema = {
  __typename?: 'LocationSchema';
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type LocationWithActionLogCount = {
  __typename?: 'LocationWithActionLogCount';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  cluster?: Maybe<Cluster>;
  actionLogsCount?: Maybe<Scalars['Int']>;
};

export type ManagementTool = {
  __typename?: 'ManagementTool';
  id: Scalars['ID'];
  slug: Scalars['String'];
  enTitle: Scalars['String'];
  esTitle: Scalars['String'];
};

/** SKAP web: average % for each level */
export type ModulePercentage = {
  __typename?: 'ModulePercentage';
  title: Scalars['String'];
  percentage: Scalars['Int'];
};

/** SKAP web: ramp up score for each month */
export type MonthWiseScore = {
  __typename?: 'MonthWiseScore';
  month: Scalars['String'];
  score: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['String']>;
  updateUserDoctorStatus: MutationStatus;
  addUserHealthStatus: MutationStatus;
  addUserHealthStatusUnAuthenticated: MutationStatus;
  updateUserInfo: MutationStatus;
  updateUserType: MutationStatus;
  createEspectroUser: MutationStatus;
  reinviteEspectroUser: MutationStatus;
  updateUserDefaultLocation: MutationStatus;
  updateForkliftIsBlocked: MutationStatus;
  updateForkliftApproval: MutationStatus;
  saveRoutineAnswer: MutationStatus;
  saveRoutineAnswerAnonymously: MutationStatus;
  scheduleRoutineDeletion: MutationStatus;
  updateVersionOfRoutineAvailable: MutationStatus;
  assignUserRoutine: MutationStatus;
  userTestDetails: MutationStatus;
  createCovidTest: MutationStatus;
  approveTest: MutationStatus;
  giveTestApproval: MutationStatus;
  setAsEmergencyContact: MutationStatus;
  addVaccinationInformation: MutationStatus;
  updateVaccinationInformation: MutationStatus;
  setVaccineApproval: MutationStatus;
  removeVaccination: MutationStatus;
  createEditActionLog: CreateEditActionLogResponse;
  updateActionLogStatus: MutationStatus;
  deleteActionLog: MutationStatus;
  addOrEditComment: MutationStatus;
  deleteComment: MutationStatus;
  createKPI: Array<Maybe<Kpi>>;
  createSignedS3UrlForGKPIUpload: S3SignedUrl;
  startProcessingGKPIFile: GkpiExcelProcessingStatus;
  updateSeenAt: MutationStatus;
  sendNotification: MutationStatus;
  createNote?: Maybe<Scalars['Boolean']>;
  addOrEditCapexComment: MutationStatus;
  deleteCapexComment: MutationStatus;
  updateRoutineAvailable: MutationStatus;
  saveRoutinesAvailable: MutationStatus;
  duplicateChecklist: MutationStatus;
  generateShareableLinkForRoutineAvailable: ShareableLinkInfo;
  toggleShareableLinkActive: MutationStatus;
  updateUserAccessLocation: MutationStatus;
  saveSKAPChecklist: MutationStatus;
  savePDTChecklist: MutationStatus;
  createPDTChecklist: Scalars['ID'];
  addOrEditCommentOnChecklist: MutationStatus;
  addSignatureToChecklist: MutationStatus;
  deleteSignatureFromChecklist: MutationStatus;
  updateOperatorOfChecklist: MutationStatus;
  deleteOperatorsOfChecklist: MutationStatus;
  updateStatusOfChecklist: MutationStatus;
  updateSKAPChecklist: MutationStatus;
  save5SChecklist: MutationStatus;
  savePDTSupportCertificate: MutationStatus;
  saveOWDChecklist: MutationStatus;
  discardCreatedActionLogsOfUncreatedOWD: MutationStatus;
  addOrEditGasTest: MutationStatus;
  createNewList: MutationStatus;
  createListItem: MutationStatus;
  updateListItem: MutationStatus;
  deleteListItem: MutationStatus;
  updateListItemAvatar: MutationStatus;
  createWorkOrder?: Maybe<WorkOrder>;
  editWorkOrder?: Maybe<WorkOrder>;
  deleteWorkOrder?: Maybe<Scalars['Boolean']>;
  partiallySaveWorkOrder?: Maybe<Scalars['Boolean']>;
  finalizeSaveWorkOrder?: Maybe<Scalars['Boolean']>;
  cancelWorkOrder: MutationStatus;
  duplicateWorkOrder: MutationStatus;
};


export type MutationUpdateUserDoctorStatusArgs = {
  daysToQuarantine?: Maybe<Scalars['Date']>;
  slug: Scalars['String'];
  doctorStatus: Scalars['String'];
};


export type MutationAddUserHealthStatusArgs = {
  status: Scalars['String'];
  location: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};


export type MutationAddUserHealthStatusUnAuthenticatedArgs = {
  status: Scalars['String'];
  location: Scalars['String'];
  slug: Scalars['String'];
};


export type MutationUpdateUserInfoArgs = {
  id: Scalars['ID'];
  general?: Maybe<UsersGeneralInfoPayload>;
  permission?: Maybe<PermissionInfoPayload>;
  roleId?: Maybe<Scalars['ID']>;
  userInfo?: Maybe<UserInfoPayload>;
};


export type MutationUpdateUserTypeArgs = {
  id: Scalars['ID'];
  type?: Maybe<Espectro_User_Types>;
};


export type MutationCreateEspectroUserArgs = {
  email: Scalars['String'];
  type: Espectro_User_Types;
  userExists?: Maybe<Scalars['Boolean']>;
};


export type MutationReinviteEspectroUserArgs = {
  userId: Scalars['ID'];
};


export type MutationUpdateUserDefaultLocationArgs = {
  locationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUpdateForkliftIsBlockedArgs = {
  slug: Scalars['String'];
  isBlocked: Scalars['Boolean'];
};


export type MutationUpdateForkliftApprovalArgs = {
  forkliftRoutineId: Scalars['Int'];
};


export type MutationSaveRoutineAnswerArgs = {
  args?: Maybe<SaveRoutineAnswerInput>;
};


export type MutationSaveRoutineAnswerAnonymouslyArgs = {
  args?: Maybe<SaveRoutineAnswerAnonymouslyInput>;
};


export type MutationScheduleRoutineDeletionArgs = {
  routineAvailableId: Scalars['ID'];
  isNotDependent?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateVersionOfRoutineAvailableArgs = {
  versionId: Scalars['ID'];
  userRoutineId: Scalars['ID'];
};


export type MutationAssignUserRoutineArgs = {
  args?: Maybe<AssignRoutine>;
};


export type MutationUserTestDetailsArgs = {
  slug: Scalars['String'];
  id: Scalars['Int'];
  result?: Maybe<Scalars['Boolean']>;
  reportNumber?: Maybe<Scalars['String']>;
  sendAlert?: Maybe<Scalars['Boolean']>;
  sendReport?: Maybe<Scalars['Boolean']>;
  resultAt?: Maybe<Scalars['Date']>;
  type?: Maybe<Scalars['String']>;
};


export type MutationCreateCovidTestArgs = {
  slug: Scalars['String'];
  testName: Scalars['String'];
  resultAt?: Maybe<Scalars['Date']>;
  testImage?: Maybe<Scalars['String']>;
  needsApproval?: Maybe<Scalars['Boolean']>;
  result?: Maybe<Scalars['Boolean']>;
};


export type MutationApproveTestArgs = {
  id: Scalars['Int'];
  resultAt: Scalars['Date'];
};


export type MutationGiveTestApprovalArgs = {
  id: Scalars['ID'];
  rejectReason?: Maybe<Scalars['String']>;
};


export type MutationSetAsEmergencyContactArgs = {
  contactId: Scalars['ID'];
};


export type MutationAddVaccinationInformationArgs = {
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  givenAt?: Maybe<Scalars['Date']>;
  image?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['Date']>;
  isApproved?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateVaccinationInformationArgs = {
  id: Scalars['ID'];
  givenAt: Scalars['Date'];
  approvedAt: Scalars['Date'];
};


export type MutationSetVaccineApprovalArgs = {
  id: Scalars['ID'];
  givenAt: Scalars['Date'];
  approvedAt?: Maybe<Scalars['Date']>;
  isApproved?: Maybe<Scalars['Boolean']>;
};


export type MutationRemoveVaccinationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEditActionLogArgs = {
  payload?: Maybe<CreateEditActionLogPayload>;
};


export type MutationUpdateActionLogStatusArgs = {
  actionLogId: Scalars['Int'];
  status: Action_Log_Status;
  isOwnerCheckSurpassed?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteActionLogArgs = {
  actionLogId: Scalars['Int'];
};


export type MutationAddOrEditCommentArgs = {
  actionLogId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationCreateKpiArgs = {
  kpi: Scalars['String'];
};


export type MutationStartProcessingGkpiFileArgs = {
  fileName: Scalars['String'];
};


export type MutationUpdateSeenAtArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};


export type MutationSendNotificationArgs = {
  type: Scalars['String'];
};


export type MutationCreateNoteArgs = {
  input: NoteInput;
};


export type MutationAddOrEditCapexCommentArgs = {
  capexId: Scalars['ID'];
  comment: Scalars['String'];
  commentId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteCapexCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationUpdateRoutineAvailableArgs = {
  routineAvailableId: Scalars['ID'];
  content: Scalars['JSON'];
};


export type MutationSaveRoutinesAvailableArgs = {
  args?: Maybe<SaveRoutinesAvailableInput>;
};


export type MutationDuplicateChecklistArgs = {
  routineAvailableId: Scalars['ID'];
};


export type MutationGenerateShareableLinkForRoutineAvailableArgs = {
  routineAvailableId: Scalars['ID'];
  responseModeIndividualChecklist?: Maybe<Scalars['Boolean']>;
};


export type MutationToggleShareableLinkActiveArgs = {
  isShareableLinkActive: Scalars['Boolean'];
  routineAvailableId: Scalars['ID'];
};


export type MutationUpdateUserAccessLocationArgs = {
  userId?: Maybe<Scalars['String']>;
  locationIds: Array<Maybe<Scalars['Int']>>;
};


export type MutationSaveSkapChecklistArgs = {
  args: SaveSkapChecklistInput;
};


export type MutationSavePdtChecklistArgs = {
  args: SavePdtChecklistInput;
};


export type MutationCreatePdtChecklistArgs = {
  args?: Maybe<CreatePdtChecklistInput>;
};


export type MutationAddOrEditCommentOnChecklistArgs = {
  args: AddOrEditCommentOnChecklistInput;
};


export type MutationAddSignatureToChecklistArgs = {
  args: AddSignatureToChecklistInput;
};


export type MutationDeleteSignatureFromChecklistArgs = {
  args: DeleteSignatureFromChecklistInput;
};


export type MutationUpdateOperatorOfChecklistArgs = {
  args: UpdateOperatorOfChecklistInput;
};


export type MutationDeleteOperatorsOfChecklistArgs = {
  args: DeleteOperatorsOfChecklistInput;
};


export type MutationUpdateStatusOfChecklistArgs = {
  args: UpdateStatusOfChecklistInput;
};


export type MutationUpdateSkapChecklistArgs = {
  args: UpdateSkapChecklistInput;
};


export type MutationSave5SChecklistArgs = {
  args: Save5SChecklistInput;
};


export type MutationSavePdtSupportCertificateArgs = {
  args?: Maybe<SavePdtSupportCertificateInput>;
};


export type MutationSaveOwdChecklistArgs = {
  args?: Maybe<SaveOwdChecklistInput>;
};


export type MutationDiscardCreatedActionLogsOfUncreatedOwdArgs = {
  args?: Maybe<DiscardCreatedActionLogsOfUncreatedOwdInput>;
};


export type MutationAddOrEditGasTestArgs = {
  args: AddOrEditGasTestInput;
};


export type MutationCreateNewListArgs = {
  name: Scalars['String'];
  attributes: Scalars['JSON'];
};


export type MutationCreateListItemArgs = {
  listId: Scalars['ID'];
  name: Scalars['String'];
  uniqueSlug: Scalars['String'];
  additionalAttributes: Scalars['JSON'];
  imageUrl?: Maybe<Scalars['String']>;
};


export type MutationUpdateListItemArgs = {
  listId: Scalars['ID'];
  name: Scalars['String'];
  uniqueSlug: Scalars['String'];
  additionalAttributes: Scalars['JSON'];
  imageUrl?: Maybe<Scalars['String']>;
  listItemId: Scalars['ID'];
};


export type MutationDeleteListItemArgs = {
  listItemId: Scalars['ID'];
};


export type MutationUpdateListItemAvatarArgs = {
  listItemId: Scalars['ID'];
  imageUrl: Scalars['String'];
};


export type MutationCreateWorkOrderArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  routinesWithVersions: Array<Maybe<RefreshRoutinesWithVersion>>;
  receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignedUsersWithRoles: Array<Maybe<AssignedUsersWithRoles>>;
  validityStartDate: Scalars['Date'];
  validityEndDate: Scalars['Date'];
};


export type MutationEditWorkOrderArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  routinesWithVersions?: Maybe<Array<Maybe<RefreshRoutinesWithVersion>>>;
  assignedUsersWithRoles: Array<Maybe<AssignedUsersWithRoles>>;
  validityStartDate: Scalars['Date'];
  validityEndDate: Scalars['Date'];
};


export type MutationDeleteWorkOrderArgs = {
  id: Scalars['ID'];
};


export type MutationPartiallySaveWorkOrderArgs = {
  id: Scalars['ID'];
  answers: Array<Maybe<Scalars['JSON']>>;
  workOrderId: Scalars['ID'];
  partialAnswerId?: Maybe<Scalars['ID']>;
};


export type MutationFinalizeSaveWorkOrderArgs = {
  id: Scalars['ID'];
  answers: Array<Maybe<Scalars['JSON']>>;
  workOrderId: Scalars['ID'];
  partialAnswerId?: Maybe<Scalars['ID']>;
};


export type MutationCancelWorkOrderArgs = {
  cancellationReason: Scalars['String'];
  workOrderId: Scalars['ID'];
};


export type MutationDuplicateWorkOrderArgs = {
  workOrderId: Scalars['ID'];
};

export type MutationStatus = {
  __typename?: 'MutationStatus';
  isSuccess: Scalars['Boolean'];
};

export type Note = {
  __typename?: 'Note';
  id: Scalars['ID'];
  bu: Scalars['String'];
  content: Scalars['String'];
  updatedAt: Scalars['Date'];
  createdAt: Scalars['Date'];
  user?: Maybe<UserNote>;
};

export type NoteInput = {
  bu?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  type: Scalars['String'];
  userId: Scalars['Int'];
  time: Scalars['Date'];
  data: Scalars['JSON'];
  seenAt?: Maybe<Scalars['Date']>;
};

export type NumberOfContacts = {
  __typename?: 'NumberOfContacts';
  count: Scalars['Int'];
};

export type OperatorSignature = {
  __typename?: 'OperatorSignature';
  id: Scalars['ID'];
  signedUser: BaseUser;
  signature: Scalars['String'];
};

export enum OrderingFashion {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PdtChecklist = {
  __typename?: 'PDTChecklist';
  id: Scalars['ID'];
  checklistName: Scalars['String'];
  checklistCreationDate: Scalars['Date'];
  content: Scalars['JSON'];
  operators?: Maybe<Array<Maybe<BaseUser>>>;
  checklistSignatures?: Maybe<Array<Maybe<OperatorSignature>>>;
  checklistInfo: ChecklistInfo;
};

export type PdtSupportCertificate = {
  __typename?: 'PDTSupportCertificate';
  id: Scalars['ID'];
  questions?: Maybe<Scalars['JSON']>;
  answers?: Maybe<Scalars['JSON']>;
};

export enum Posts_Types {
  News = 'NEWS',
  BestPractices = 'BEST_PRACTICES',
  PreventionCampaigns = 'PREVENTION_CAMPAIGNS'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  totalPages: Scalars['Int'];
  currentPage: Scalars['Int'];
};

export type PerformingBody = {
  __typename?: 'PerformingBody';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
};

export type PermissionInfoPayload = {
  routines?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
};

export type Pillar = {
  __typename?: 'Pillar';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Post = {
  __typename?: 'Post';
  id: Scalars['ID'];
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  author?: Maybe<Scalars['String']>;
  postedAt: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<Posts_Types>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
};

export type QuarantineeInformation = {
  __typename?: 'QuarantineeInformation';
  emergencyContact?: Maybe<EmergencyContact>;
  numberOfContacts?: Maybe<NumberOfContacts>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['String']>;
  users?: Maybe<Array<User>>;
  getUserInfo?: Maybe<User>;
  userPositionsByBu?: Maybe<Array<Maybe<UserPositions>>>;
  getUsersList: GetUsersListPaginated;
  quarantineeInformation: QuarantineeInformation;
  getCovidUsersForLocations: CovidUsersForLocationCount;
  userHealthStatus: UserHealthStatus;
  userHealthStatusUnAuthenticated: UserHealthStatus;
  searchCovidUsers: Array<Maybe<CovidUsersForLocation>>;
  searchUsersWithAccessToLocation?: Maybe<Array<Maybe<BaseUser>>>;
  getUserPositionsByBu: Array<Maybe<UserPositionsByBu>>;
  getUserUsualWorkLocation?: Maybe<UsualWorkLocation>;
  getUserHasSpeechRecognitionEnabled: User;
  getUserAvailableLocations: Array<Maybe<LocationDetails>>;
  forklift?: Maybe<Forklift>;
  forkliftRoutine?: Maybe<Array<Maybe<ForkliftRoutine>>>;
  getAllForklifts: Array<Maybe<Forklift>>;
  forkliftAreasForChecklist?: Maybe<Array<Maybe<ForkliftArea>>>;
  getAnsweredRoutines: AnsweredRoutinesSchema;
  getAnsweredRoutinesForUser?: Maybe<Array<Maybe<Routine>>>;
  uploadImages: Array<Maybe<S3Urls>>;
  covidUsers: CovidUserAndCount;
  userCovidTestTimeline: TestTimeline;
  emergencyContact?: Maybe<EmergencyContact>;
  userVaccines: Array<Maybe<UserVaccine>>;
  vaccinesForUserLocation: UserVaccinesForLocation;
  vaccinesPendingApproval?: Maybe<UserVaccine>;
  percentageOfVaccinatedUsers?: Maybe<UsersVaccinatedCount>;
  getUsersVaccineForType?: Maybe<Array<Maybe<User>>>;
  actionLogs: Array<Maybe<CreateEditActionLog>>;
  actionLog?: Maybe<CreateEditActionLog>;
  actionLogsCountGroupByLocation: Array<Maybe<BusinessUnitWithActionLogCount>>;
  groupActionLogsInDCByStatus: ActionLogsInDcByStatus;
  comments: Array<Maybe<Comment>>;
  comment: Comment;
  location: LocationSchema;
  getLocations: Array<Maybe<LocationDetails>>;
  locationDetails: LocationDetails;
  userHasAccessToLocation: Scalars['Boolean'];
  getPost?: Maybe<Post>;
  getPosts?: Maybe<GetPostResponse>;
  getUploadedFiles?: Maybe<Array<Maybe<AnalyticsFileUpload>>>;
  getLastUploadedFiles: AnalyticsFileUpload;
  getUploadedFilesByFileType?: Maybe<Array<Maybe<AnalyticsFileUpload>>>;
  getLastUploadedFilesByFileType: AnalyticsFileUpload;
  getAllKPI: Array<Maybe<Kpi>>;
  getS3UrlForGKPIUpload: S3SignedUrl;
  getGKPIData: Array<Maybe<GkpiDataResponse>>;
  getCountryAndLocationList?: Maybe<Array<Maybe<CountryAndLocationList>>>;
  notifications: Array<Maybe<Notification>>;
  notificationCount: Scalars['Int'];
  getNotificationOfTypeByUserSlug?: Maybe<Notification>;
  getTrainings: Array<Maybe<BuData>>;
  getNote?: Maybe<Note>;
  capexComments: Array<Maybe<Comment>>;
  capexComment: Comment;
  getSifStatsByDuration: Array<Maybe<SifStatsDuration>>;
  getSifStats: Array<Maybe<SifStats>>;
  getSifStatsByType: Array<Maybe<SifStatsByTypeResponse>>;
  listAllSifStatsByMonth: Array<Maybe<ListSifStatsResponse>>;
  lastFileUploadDate: Scalars['Date'];
  getIncidentsByCategories: Array<Maybe<IncidentByCategory>>;
  getIncidentsByMonths: Array<Maybe<IncidentByMonth>>;
  getIncidentsByType: Array<Maybe<IncidentByType>>;
  getIncidentsBetweenRange: Array<Maybe<IncidentBetweenRange>>;
  getRoutinesAvailable: Array<Maybe<RoutineAvailable>>;
  getRoutineAvailable?: Maybe<RoutineAvailable>;
  getRoutineAvailableFromShareableToken?: Maybe<RoutineAvailable>;
  getRoutinesAvailableWithReportInfo: Array<Maybe<RoutineAvailable>>;
  getLatestVersionOfRoutineAvailable?: Maybe<RoutineVersion>;
  getAllPillar: Array<Maybe<Pillar>>;
  getAllManagementTool: Array<Maybe<ManagementTool>>;
  getAccessLocations: Array<Maybe<LocationDetails>>;
  getAllAccessLocations?: Maybe<Scalars['JSON']>;
  getWorkLocations: Array<Maybe<WorkLocation>>;
  getSubLocations: Array<Maybe<SubLocations>>;
  getSpecificSubLocations: Array<Maybe<SpecificSubLocations>>;
  getDepartments: Array<Maybe<Department>>;
  getExternalCompanies: Array<Maybe<ExternalCompany>>;
  searchExternalCompanies: Array<Maybe<ExternalCompany>>;
  getSKAPAvailableChecklistByName: ChecklistAvailable;
  getPDTDefaultAvailableChecklist: ChecklistAvailable;
  getSKAPChecklistById: SkapChecklist;
  getPDTChecklistById: PdtChecklist;
  getSKAPChecklistsOfUserUnderEvaluation: Array<UserUnderEvaluationResponse>;
  getUserChecklistsByMonth: Array<Maybe<UserChecklistsByMonth>>;
  getAll5SChecklistsOfMonthGroupedByStatus: Array<UserChecklistsByStatus>;
  getPDTSByCreationTypeAndPerformingBody: Array<Maybe<RegisteredPdt>>;
  getSKAPModulesAndSubmodulesCount: GetModulesAndSubmodulesCountResponse;
  getPDTSupportCertificate: PdtSupportCertificate;
  getAll5SChecklistCountByStatus: GetAll5SChecklistCountByStatus;
  getAllOWDChecklists: Array<Maybe<GetAllOwdChecklists>>;
  getPDTAccess: GetPdtAccessResponse;
  getDashboardTableData?: Maybe<Scalars['JSON']>;
  getGasTestsByChecklistId: Array<Maybe<GetTest>>;
  getAllTrucks: Array<Maybe<Truck>>;
  getUserWorkLocations: Array<Maybe<UserWorkLocations>>;
  getAllLocationsUserPositions?: Maybe<Scalars['JSON']>;
  getListsForCompany: Array<Maybe<List>>;
  isListUniqueNameAvailable: UniqueNameAvailableResponse;
  getListItemFromShortSlug?: Maybe<ListItem>;
  getListItemsFromSearchInput?: Maybe<Array<Maybe<ListItem>>>;
  getListItemsOfCompany?: Maybe<Array<Maybe<ListItem>>>;
  getListItemDetails: ListItem;
  isListItemUniqueNameAvailable: UniqueNameAvailableResponse;
  getListItemsFromSearchWithoutSlug?: Maybe<Array<Maybe<ListItem>>>;
  getListItemsLinkedToListShortSlug?: Maybe<Array<Maybe<ListItem>>>;
  getWorkOrders?: Maybe<Array<Maybe<WorkOrder>>>;
  getWorkOrderForCurrentUser?: Maybe<Array<Maybe<WorkOrder>>>;
  getWorkOrderByWorkOrderId?: Maybe<WorkOrderByIdResponse>;
  getRoutineAvailableByWorkOrderId: GetRoutineAvailableByWorkOrderIdResponse;
  getRoutineAvailableByWorkOrderIds: Array<Maybe<GetRoutineAvailableByWorkOrderIdResponse>>;
  getWorkOrdersByRoutineAvailable?: Maybe<Array<Maybe<WorkOrder>>>;
  listWorkOrderReports: Array<Maybe<WorkOrderAnswer>>;
  queueEmailPDFToRecipients: MutationStatus;
  getWorkOrderDetails?: Maybe<WorkOrder>;
  listUsersForWorkOrders: Array<Maybe<User>>;
};


export type QueryGetUserInfoArgs = {
  id: Scalars['ID'];
};


export type QueryUserPositionsByBuArgs = {
  bu: Scalars['String'];
};


export type QueryGetUsersListArgs = {
  locationID: Scalars['ID'];
  userType?: Maybe<User_Type>;
  search?: Maybe<Scalars['String']>;
  roleType?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetCovidUsersForLocationsArgs = {
  locationIds: Array<Scalars['ID']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryUserHealthStatusArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryUserHealthStatusUnAuthenticatedArgs = {
  slug: Scalars['String'];
};


export type QuerySearchCovidUsersArgs = {
  search: Scalars['String'];
  locationIds: Array<Scalars['ID']>;
};


export type QuerySearchUsersWithAccessToLocationArgs = {
  searchString: Scalars['String'];
  locationSlug: Scalars['String'];
};


export type QueryGetUserUsualWorkLocationArgs = {
  userId: Scalars['Int'];
};


export type QueryForkliftArgs = {
  slug: Scalars['String'];
};


export type QueryForkliftRoutineArgs = {
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  timezoneOffsetStart?: Maybe<Scalars['String']>;
  timezoneOffsetEnd?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  forkliftRoutineId?: Maybe<Scalars['Int']>;
  pageSize?: Scalars['Int'];
  forkliftArea?: Maybe<Scalars['String']>;
};


export type QueryGetAllForkliftsArgs = {
  onlyWithSapNumber?: Maybe<Scalars['Boolean']>;
};


export type QueryGetAnsweredRoutinesArgs = {
  routinesAvailableId: Scalars['ID'];
  pageNumber?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetAnsweredRoutinesForUserArgs = {
  routinesAvailableId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type QueryUploadImagesArgs = {
  imageUrl: Array<Maybe<Scalars['JSON']>>;
};


export type QueryCovidUsersArgs = {
  doctorStatus: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryUserCovidTestTimelineArgs = {
  slug: Scalars['String'];
};


export type QueryUserVaccinesArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryGetUsersVaccineForTypeArgs = {
  type: Scalars['String'];
};


export type QueryActionLogsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  isResponsible?: Maybe<Scalars['Boolean']>;
  locationSlug?: Maybe<Scalars['String']>;
  status?: Maybe<Action_Log_Status>;
  isAll?: Maybe<Scalars['Boolean']>;
  filters?: Maybe<ActionLogFilters>;
};


export type QueryActionLogArgs = {
  id: Scalars['ID'];
  isResponsible?: Maybe<Scalars['Boolean']>;
  isAll?: Maybe<Scalars['Boolean']>;
};


export type QueryActionLogsCountGroupByLocationArgs = {
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
};


export type QueryGroupActionLogsInDcByStatusArgs = {
  locationSlug?: Maybe<Scalars['String']>;
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
  filters?: Maybe<ActionLogFilters>;
};


export type QueryCommentsArgs = {
  actionLogId: Scalars['ID'];
  orderByCreatedAt?: Maybe<OrderingFashion>;
};


export type QueryCommentArgs = {
  actionLogId: Scalars['ID'];
  commentId: Scalars['ID'];
};


export type QueryLocationArgs = {
  slug: Scalars['String'];
};


export type QueryGetLocationsArgs = {
  type?: Maybe<Location_Type>;
};


export type QueryLocationDetailsArgs = {
  locationSlug?: Maybe<Scalars['String']>;
};


export type QueryUserHasAccessToLocationArgs = {
  locationSlug: Scalars['String'];
  userSlug: Scalars['String'];
};


export type QueryGetPostArgs = {
  slug: Scalars['String'];
};


export type QueryGetPostsArgs = {
  author?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  type?: Maybe<Posts_Types>;
  attachmentType?: Maybe<Array<Maybe<Attachment_Types>>>;
};


export type QueryGetUploadedFilesArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetUploadedFilesByFileTypeArgs = {
  fileType: File_Types;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetLastUploadedFilesByFileTypeArgs = {
  fileType: File_Types;
};


export type QueryGetAllKpiArgs = {
  isGlobal?: Maybe<Scalars['Boolean']>;
};


export type QueryGetGkpiDataArgs = {
  country?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};


export type QueryNotificationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetNotificationOfTypeByUserSlugArgs = {
  userShortSlug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


export type QueryGetTrainingsArgs = {
  data: GetTrainingsPayload;
  by: GetTrainingsBy;
  isFindOne?: Maybe<Scalars['Boolean']>;
};


export type QueryGetNoteArgs = {
  bu: Scalars['String'];
};


export type QueryCapexCommentsArgs = {
  capexId: Scalars['ID'];
  orderByCreatedAt?: Maybe<OrderingFashion>;
};


export type QueryCapexCommentArgs = {
  capexId: Scalars['ID'];
  commentId: Scalars['ID'];
};


export type QueryGetSifStatsByDurationArgs = {
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
  filter: FilterParams;
};


export type QueryGetSifStatsArgs = {
  filter: FilterParams;
  by: GetSifStatsBy;
  year: Scalars['Int'];
};


export type QueryGetSifStatsByTypeArgs = {
  filter?: Maybe<FilterParamsByType>;
  year: Scalars['Int'];
};


export type QueryListAllSifStatsByMonthArgs = {
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
  filter: FilterParams;
};


export type QueryGetIncidentsByCategoriesArgs = {
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryGetIncidentsByMonthsArgs = {
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryGetIncidentsByTypeArgs = {
  by: By;
  type: Type;
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
  filterOnCountry?: Maybe<Scalars['String']>;
};


export type QueryGetIncidentsBetweenRangeArgs = {
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryGetRoutinesAvailableArgs = {
  forFilter?: Maybe<Scalars['Boolean']>;
  excludeNestedChecklist?: Maybe<Scalars['Boolean']>;
  includeDeletedRoutines?: Maybe<Scalars['Boolean']>;
};


export type QueryGetRoutineAvailableArgs = {
  routineAvailableId: Scalars['ID'];
};


export type QueryGetRoutineAvailableFromShareableTokenArgs = {
  shareableToken: Scalars['String'];
};


export type QueryGetLatestVersionOfRoutineAvailableArgs = {
  routineAvailableId: Scalars['ID'];
};


export type QueryGetAllAccessLocationsArgs = {
  id: Scalars['ID'];
};


export type QueryGetWorkLocationsArgs = {
  location: Array<Maybe<Scalars['Int']>>;
};


export type QueryGetSubLocationsArgs = {
  workLocation: Array<Maybe<Scalars['Int']>>;
};


export type QueryGetSpecificSubLocationsArgs = {
  subLocation: Array<Maybe<Scalars['Int']>>;
};


export type QuerySearchExternalCompaniesArgs = {
  searchString: Scalars['String'];
};


export type QueryGetSkapAvailableChecklistByNameArgs = {
  userUnderEvaluationShortSlug: Scalars['String'];
  name: Scalars['String'];
};


export type QueryGetSkapChecklistByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetPdtChecklistByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetSkapChecklistsOfUserUnderEvaluationArgs = {
  userUnderEvaluationShortSlug: Scalars['String'];
};


export type QueryGetPdtsByCreationTypeAndPerformingBodyArgs = {
  args: GetPdtInput;
};


export type QueryGetSkapModulesAndSubmodulesCountArgs = {
  filter: GetModuleSubmoduleCountFilterParams;
};


export type QueryGetPdtSupportCertificateArgs = {
  args: GetPdtSupportCertificateInput;
};


export type QueryGetAll5SChecklistCountByStatusArgs = {
  status: Scalars['String'];
};


export type QueryGetAllOwdChecklistsArgs = {
  args: GetAllOwdFilterParams;
};


export type QueryGetDashboardTableDataArgs = {
  locationIds: Array<Maybe<Scalars['ID']>>;
  groupBy?: Maybe<Scalars['JSON']>;
  date?: Maybe<Scalars['JSON']>;
};


export type QueryGetGasTestsByChecklistIdArgs = {
  checklistId: Scalars['ID'];
};


export type QueryGetUserWorkLocationsArgs = {
  user: Array<Maybe<Scalars['Int']>>;
};


export type QueryIsListUniqueNameAvailableArgs = {
  uniqueName: Scalars['String'];
};


export type QueryGetListItemFromShortSlugArgs = {
  listItemShortSlug: Scalars['String'];
  listShortSlugs: Array<Maybe<Scalars['String']>>;
};


export type QueryGetListItemsFromSearchInputArgs = {
  searchInput: Scalars['String'];
  listShortSlugs: Array<Maybe<Scalars['String']>>;
};


export type QueryGetListItemDetailsArgs = {
  listItemId: Scalars['ID'];
};


export type QueryIsListItemUniqueNameAvailableArgs = {
  uniqueName: Scalars['String'];
};


export type QueryGetListItemsFromSearchWithoutSlugArgs = {
  searchString: Scalars['String'];
};


export type QueryGetListItemsLinkedToListShortSlugArgs = {
  listShortSlug: Scalars['String'];
};


export type QueryGetWorkOrdersArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  status?: Maybe<WorkOrderStatus>;
  searchText?: Maybe<Scalars['String']>;
  activeMonth?: Maybe<Scalars['String']>;
};


export type QueryGetWorkOrderByWorkOrderIdArgs = {
  workOrderId: Scalars['ID'];
};


export type QueryGetRoutineAvailableByWorkOrderIdArgs = {
  workOrderId: Scalars['ID'];
};


export type QueryGetRoutineAvailableByWorkOrderIdsArgs = {
  workOrderIds: Array<Scalars['ID']>;
};


export type QueryGetWorkOrdersByRoutineAvailableArgs = {
  routineAvailableId: Scalars['ID'];
};


export type QueryQueueEmailPdfToRecipientsArgs = {
  recipients: Array<Scalars['String']>;
  workOrderId: Scalars['ID'];
};


export type QueryGetWorkOrderDetailsArgs = {
  workOrderId: Scalars['ID'];
};


export type QueryListUsersForWorkOrdersArgs = {
  workOrderId?: Maybe<Scalars['ID']>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};

export enum Risk_Level {
  Low = 'LOW',
  Mid = 'MID',
  High = 'HIGH'
}

export type RefreshRoutinesWithVersion = {
  routineId: Scalars['ID'];
  versionId: Scalars['ID'];
};

export type RegisteredPdt = {
  __typename?: 'RegisteredPDT';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  checklistName: Scalars['String'];
  status: Scalars['String'];
  performingBody: PerformingBody;
  description: Scalars['String'];
  externalUsers?: Maybe<Scalars['Int']>;
  checklistDepartment?: Maybe<Scalars['String']>;
};

export type RegisteredPdts = {
  __typename?: 'RegisteredPDTS';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  checklistName: Scalars['String'];
  description: Scalars['String'];
  externalUsers?: Maybe<Scalars['Int']>;
  checklistInfo: Scalars['JSON'];
};

export type Routine = {
  __typename?: 'Routine';
  id: Scalars['ID'];
  answers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  completedAt?: Maybe<Scalars['Date']>;
  userRoutine: UserRoutine;
  user?: Maybe<User>;
  routineAvailable?: Maybe<RoutineAvailable>;
  routineVersion?: Maybe<RoutineVersion>;
};

export type RoutineAvailable = {
  __typename?: 'RoutineAvailable';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  frequency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  content: Scalars['JSON'];
  isActive?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  company?: Maybe<Company>;
  isShareableLinkActive?: Maybe<Scalars['Boolean']>;
  shareableToken?: Maybe<Scalars['String']>;
  totalReports?: Maybe<Scalars['ID']>;
  lastReportCompletedAt?: Maybe<Scalars['Date']>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionId?: Maybe<Scalars['Int']>;
  isScheduledForDeletion?: Maybe<Scalars['Boolean']>;
  routineVersions?: Maybe<RoutineVersion>;
};

export type RoutineVersion = {
  __typename?: 'RoutineVersion';
  id?: Maybe<Scalars['ID']>;
  versionNumber?: Maybe<Scalars['Float']>;
  content?: Maybe<Scalars['JSON']>;
};

export type RoutineWithVersionAdded = {
  __typename?: 'RoutineWithVersionAdded';
  routineInfo?: Maybe<RoutineAvailable>;
  versionAdded?: Maybe<RoutineVersion>;
};

export type S3SignedUrl = {
  __typename?: 'S3SignedURL';
  preSignedUrl: Scalars['String'];
  fileName: Scalars['String'];
};

export type S3Urls = {
  __typename?: 'S3Urls';
  url: Scalars['String'];
};

export type SkapChecklist = {
  __typename?: 'SKAPChecklist';
  id: Scalars['ID'];
  content: Scalars['JSON'];
  checklistOperators: Array<BaseUser>;
  checklistInfo?: Maybe<ChecklistInfo>;
};

export enum SkapUsersFilterType {
  AllUsers = 'ALL_USERS',
  LastThreeMonths = 'LAST_THREE_MONTHS',
  MoreThanThreeMonths = 'MORE_THAN_THREE_MONTHS'
}

export type ShareableLinkInfo = {
  __typename?: 'ShareableLinkInfo';
  routines: Array<Maybe<RoutineAvailable>>;
  isSuccess: Scalars['Boolean'];
};

export type SifStats = {
  __typename?: 'SifStats';
  name: Scalars['String'];
  totalSif: Scalars['Int'];
};

export type SifStatsByTypeResponse = {
  __typename?: 'SifStatsByTypeResponse';
  month: Scalars['String'];
  sifType: Array<Maybe<SifStats>>;
};

export type SifStatsDuration = {
  __typename?: 'SifStatsDuration';
  duration: Scalars['String'];
  totalSif: Scalars['Int'];
};

export type SubDepartment = {
  __typename?: 'SubDepartment';
  id: Scalars['ID'];
  departmentId: Scalars['ID'];
  name: Scalars['String'];
  nameEN: Scalars['String'];
  acronymEN: Scalars['String'];
  acronymES: Scalars['String'];
  slug: Scalars['String'];
};

export type SubLocationDetails = {
  __typename?: 'SubLocationDetails';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  nameEN: Scalars['String'];
};

export type SubModulesData = {
  __typename?: 'SubModulesData';
  content: Scalars['JSON'];
  isCompleted: Scalars['Boolean'];
  name: Scalars['String'];
  progressPercentage: Scalars['Int'];
  slug: Scalars['String'];
  checklistId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['Date']>;
  startedAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isAnswered: Scalars['Boolean'];
};

export type Subzone = {
  __typename?: 'Subzone';
  name: Scalars['String'];
  slug: Scalars['String'];
  id: Scalars['ID'];
  zone: Zone;
  distributionCenters?: Maybe<Array<Maybe<Location>>>;
};

export type SubzoneWithActionLogCount = {
  __typename?: 'SubzoneWithActionLogCount';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  actionLogsCount?: Maybe<Scalars['Int']>;
  locations?: Maybe<Array<Maybe<LocationWithActionLogCount>>>;
};

export enum SupportCertificateNames {
  BloqueoDeEnergia = 'BLOQUEO_DE_ENERGIA',
  TrabajoEnAlturas = 'TRABAJO_EN_ALTURAS',
  EspaciosConfinados = 'ESPACIOS_CONFINADOS',
  ObrasDeInfraestructura = 'OBRAS_DE_INFRAESTRUCTURA',
  IzajeDeCargasConGrua = 'IZAJE_DE_CARGAS_CON_GRUA',
  TrabajosElectricos = 'TRABAJOS_ELECTRICOS',
  TrabajosEnCaliente = 'TRABAJOS_EN_CALIENTE',
  DeshabilitarSistemasDispositivosDeSeguridad = 'DESHABILITAR_SISTEMAS_DISPOSITIVOS_DE_SEGURIDAD',
  ManejoSustanciasQuimicasPeligrosasAGranel = 'MANEJO_SUSTANCIAS_QUIMICAS_PELIGROSAS_A_GRANEL'
}

export type TestTimeline = {
  __typename?: 'TestTimeline';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  doctorStatus?: Maybe<Scalars['String']>;
  quarantineUntil?: Maybe<Scalars['Date']>;
  quarantineStart?: Maybe<Scalars['Date']>;
  location?: Maybe<Location>;
  covidTests?: Maybe<Array<Maybe<CovidTest>>>;
  failedCertificate?: Maybe<FailedCertificate>;
  userHealthStatus?: Maybe<HealthStatus>;
};

export type Truck = {
  __typename?: 'Truck';
  id?: Maybe<Scalars['ID']>;
  sapNumber?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
};

export enum User_Type {
  Contractor = 'CONTRACTOR',
  Internal = 'INTERNAL'
}

export type UniqueNameAvailableResponse = {
  __typename?: 'UniqueNameAvailableResponse';
  isAvailable: Scalars['Boolean'];
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  registeredCompany?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  shortSlug?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  blockedReason?: Maybe<Scalars['String']>;
  lastActiveAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  doctorStatus?: Maybe<Scalars['String']>;
  employeeNumber?: Maybe<Scalars['String']>;
  quarantineUntil?: Maybe<Scalars['Date']>;
  distributionCenter?: Maybe<LocationDetails>;
  covidTest?: Maybe<CovidTest>;
  vaccines?: Maybe<Array<Maybe<UserVaccine>>>;
  lastVaccine?: Maybe<UserVaccine>;
  info?: Maybe<UserInfo>;
  enableSpeechRecognition?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserAccessDetails = {
  __typename?: 'UserAccessDetails';
  id: Scalars['ID'];
  distributionCenter: LocationDetails;
};

export type UserChecklistsByMonth = {
  __typename?: 'UserChecklistsByMonth';
  name: Scalars['String'];
  slug: Scalars['String'];
  value: Array<Maybe<UserChecklistsResponseValue>>;
};

export type UserChecklistsByStatus = {
  __typename?: 'UserChecklistsByStatus';
  status: Scalars['String'];
  value: Array<Maybe<UserChecklistsResponseValue>>;
};

export type UserChecklistsResponseValue = {
  __typename?: 'UserChecklistsResponseValue';
  checklistId: Scalars['ID'];
  checklistsAvailableId: Scalars['ID'];
  submittedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  name: Scalars['String'];
  slug: Scalars['String'];
  answeredContent?: Maybe<Scalars['JSON']>;
  content: Scalars['JSON'];
  status: Scalars['String'];
  workLocation: Scalars['String'];
  subWorkLocation: Scalars['String'];
  appliedBy: BaseUser;
  assignedTo?: Maybe<BaseUser>;
  truck?: Maybe<Truck>;
  forklift?: Maybe<Forklift>;
  sapNumber?: Maybe<Scalars['String']>;
  numberOfActionLogs: Scalars['Int'];
};

export type UserHealthStatus = {
  __typename?: 'UserHealthStatus';
  quarantineUntil?: Maybe<Scalars['Date']>;
  quarantineStart?: Maybe<Scalars['Date']>;
  healthStatus?: Maybe<HealthStatus>;
  usersLocationMedicalFacility?: Maybe<Array<Maybe<UsersLocationMedicalFacility>>>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  id: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  isAuthorisedForOWD?: Maybe<Scalars['Boolean']>;
};

export type UserInfoPayload = {
  isAuthorisedForOWD?: Maybe<Scalars['Boolean']>;
};

export type UserNote = {
  __typename?: 'UserNote';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UserPositionsByBu = {
  __typename?: 'UserPositionsByBU';
  position: Scalars['String'];
  bu: BusinessUnit;
};

export type UserRoutine = {
  __typename?: 'UserRoutine';
  id: Scalars['ID'];
  completedAt?: Maybe<Scalars['Date']>;
  routineAvailable: RoutineAvailable;
  user: User;
};

export type UserUnderEvaluationResponse = {
  __typename?: 'UserUnderEvaluationResponse';
  title: Scalars['String'];
  slug: Scalars['String'];
  progressPercentage: Scalars['Int'];
  isCompleted: Scalars['Boolean'];
  startedAt?: Maybe<Scalars['Date']>;
  subModules: Array<SubModulesData>;
};

export type UserVaccine = {
  __typename?: 'UserVaccine';
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  vaccinatedUser?: Maybe<User>;
  vaccinationGivenBy?: Maybe<BaseUser>;
  givenAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  approvedAt?: Maybe<Scalars['Date']>;
  image?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
};

export type UserVaccinesForLocation = {
  __typename?: 'UserVaccinesForLocation';
  users?: Maybe<Array<Maybe<User>>>;
  count?: Maybe<Scalars['Int']>;
};

export type UserWorkOrder = {
  __typename?: 'UserWorkOrder';
  id?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
  roleType?: Maybe<Scalars['String']>;
};

export type UsersGeneralInfoPayload = {
  locationId: Scalars['ID'];
  position?: Maybe<Scalars['String']>;
};

export type UsersLocationMedicalFacility = {
  __typename?: 'UsersLocationMedicalFacility';
  emergencyContact?: Maybe<Scalars['String']>;
};

export type UsersVaccinatedCount = {
  __typename?: 'UsersVaccinatedCount';
  vaccinated?: Maybe<Scalars['Int']>;
  totalUsers?: Maybe<Scalars['Int']>;
};

export enum Vaccine_Type {
  Pfizer = 'PFIZER',
  Johsons = 'JOHSONS',
  Moderna = 'MODERNA',
  LaChina = 'LA_CHINA'
}


export type WorkLocationDetails = {
  __typename?: 'WorkLocationDetails';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  locationType: Scalars['String'];
  nameEN: Scalars['String'];
};

export type WorkOrder = {
  __typename?: 'WorkOrder';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  routines?: Maybe<Array<Maybe<RoutineWithVersionAdded>>>;
  routineIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  status?: Maybe<WorkOrderStatus>;
  userWorkOrderId?: Maybe<Scalars['ID']>;
  userIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  totalQuestions?: Maybe<Scalars['Int']>;
  areRecordsSearched?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  totalQuestionsAnswered?: Maybe<Scalars['Int']>;
  isSupervisor?: Maybe<Scalars['Boolean']>;
  isFinalized?: Maybe<Scalars['Boolean']>;
  receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  userWorkOrders?: Maybe<Scalars['JSON']>;
  workOrderRoutineExecutors?: Maybe<Scalars['JSON']>;
};

export type WorkOrderAnswer = {
  __typename?: 'WorkOrderAnswer';
  id: Scalars['ID'];
  workOrder?: Maybe<WorkOrder>;
  isFinalized?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type WorkOrderByIdResponse = {
  __typename?: 'WorkOrderByIDResponse';
  workOrdersWithRoutines?: Maybe<Array<Maybe<WorkOrder>>>;
  detailedOrderInfo?: Maybe<Array<Maybe<GetRoutineAvailableByWorkOrderIdResponse>>>;
};

export type WorkOrderRoutineExecutor = {
  __typename?: 'WorkOrderRoutineExecutor';
  id?: Maybe<Scalars['ID']>;
  routineAvailable?: Maybe<RoutineAvailable>;
  routineAvailableId?: Maybe<Scalars['ID']>;
  workOrderId?: Maybe<Scalars['ID']>;
  workOrder?: Maybe<WorkOrder>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum WorkOrderStatus {
  Active = 'ACTIVE',
  Finalized = 'FINALIZED',
  Cancelled = 'CANCELLED'
}

export type Zone = {
  __typename?: 'Zone';
  name: Scalars['String'];
  slug: Scalars['String'];
  id: Scalars['ID'];
  businessUnit?: Maybe<BusinessUnit>;
  subzones?: Maybe<Array<Maybe<Subzone>>>;
};

export type ZoneWithActionLogCount = {
  __typename?: 'ZoneWithActionLogCount';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  actionLogsCount?: Maybe<Scalars['Int']>;
  subzones?: Maybe<Array<Maybe<SubzoneWithActionLogCount>>>;
};

export type AddOrEditCommentOnChecklistInput = {
  checklistId: Scalars['ID'];
  comment: Scalars['String'];
  commentId?: Maybe<Scalars['ID']>;
  isZBSUser?: Maybe<Scalars['Boolean']>;
};

export type AddOrEditGasTestInput = {
  checklistId: Scalars['ID'];
  content: Scalars['JSON'];
  gasTestId?: Maybe<Scalars['ID']>;
};

export type AddSignatureToChecklistInput = {
  id?: Maybe<Scalars['String']>;
  checklistId: Scalars['ID'];
  signedByUserId: Scalars['ID'];
  signature: Scalars['String'];
};

export type Area = {
  __typename?: 'area';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type AssignRoutine = {
  userId: Scalars['ID'];
  routinesAvailableId: Scalars['ID'];
};

export type BuContent = {
  __typename?: 'buContent';
  name: Scalars['String'];
  totalIncidents: Scalars['Int'];
};

export enum By {
  Cd = 'cd',
  Vehical = 'vehical'
}

export type CreatePdtChecklistInput = {
  checklistAvailableSlug: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
  externalCompanyId?: Maybe<Scalars['ID']>;
  pdtGasTestDetails?: Maybe<Scalars['JSON']>;
  areaManagerUserId: Scalars['ID'];
  performerUserId: Scalars['ID'];
  territoryOwnerUserId: Scalars['ID'];
  inspectedByUserId: Scalars['ID'];
  pdtZBSDetails?: Maybe<Scalars['JSON']>;
};

export type DeleteOperatorsOfChecklistInput = {
  checklistId: Scalars['ID'];
  operatorUserIds: Array<Scalars['ID']>;
};

export type DeleteSignatureFromChecklistInput = {
  checklistId: Scalars['ID'];
  signatureId?: Maybe<Scalars['ID']>;
};

export type DiscardCreatedActionLogsOfUncreatedOwdInput = {
  actionLogIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type DistributionCenterWorkLocation = {
  __typename?: 'distributionCenterWorkLocation';
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FilterParams = {
  bu?: Maybe<Scalars['String']>;
  cause?: Maybe<Scalars['String']>;
  nature?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type FilterParamsByType = {
  bu?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type GetAllOwdFilterParams = {
  locationSlug: Scalars['String'];
  source: ClientType;
  pillar?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type GetModuleSubmoduleCountFilterParams = {
  users?: Maybe<SkapUsersFilterType>;
  locationIds: Array<Maybe<Scalars['ID']>>;
};

export type GetPdtInput = {
  dateRange?: Maybe<DateRangeInput>;
  performingBodySlug: Scalars['String'];
};

export type GetPdtSupportCertificateInput = {
  checklistId: Scalars['ID'];
  checklistName: SupportCertificateNames;
};

export type GetUsersListPaginated = {
  __typename?: 'getUsersListPaginated';
  users?: Maybe<Array<Maybe<User>>>;
  paginationInfo: PaginationInfo;
};

export type ImpactedSkapQuestionBit = {
  skapChecklistId: Scalars['ID'];
  sectionIndex: Scalars['Int'];
  questionIndex: Scalars['Int'];
  newValue: Scalars['Boolean'];
};

export type PaginationInfo = {
  __typename?: 'paginationInfo';
  totalCount?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type Reason = {
  __typename?: 'reason';
  title: Scalars['String'];
  description: Scalars['String'];
};

export type ReasonInput = {
  title: Scalars['String'];
  description: Scalars['String'];
};

export type RoutinesAvailableNameAndSlug = {
  __typename?: 'routinesAvailableNameAndSlug';
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Save5SChecklistInput = {
  checklistId: Scalars['ID'];
  content: Scalars['JSON'];
  sapNumber?: Maybe<Scalars['String']>;
};

export type SaveOwdChecklistInput = {
  checklistAvailableId: Scalars['ID'];
  content: Scalars['JSON'];
  progressPercentage: Scalars['Float'];
  userUnderEvaluationShortSlug: Scalars['String'];
  impactedSKAPQuestions?: Maybe<Array<Maybe<ImpactedSkapQuestionBit>>>;
  actionLogs?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SavePdtChecklistInput = {
  checklistId: Scalars['ID'];
  operatorUserIds?: Maybe<Array<Scalars['ID']>>;
  content: Scalars['JSON'];
  buId?: Maybe<Scalars['ID']>;
  departmentId?: Maybe<Scalars['ID']>;
  externalCompanyId?: Maybe<Scalars['ID']>;
  workingDepartmentId?: Maybe<Scalars['ID']>;
  workingSubDepartmentId?: Maybe<Scalars['ID']>;
  areaManagerUserId: Scalars['ID'];
  performerUserId: Scalars['ID'];
  territoryOwnerUserId: Scalars['ID'];
  inspectedByUserId: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  statusReasonContent?: Maybe<StatusReasonContentInput>;
};

export type SavePdtSupportCertificateInput = {
  checklistId: Scalars['ID'];
  questions: Scalars['JSON'];
  answers: Scalars['JSON'];
};

export type SaveRoutineAnswerAnonymouslyInput = {
  answers: Scalars['JSON'];
  completedAt: Scalars['Date'];
  shareableToken: Scalars['String'];
};

export type SaveRoutineAnswerInput = {
  routineId?: Maybe<Scalars['ID']>;
  userRoutineId: Scalars['ID'];
  answers: Scalars['JSON'];
  locationId?: Maybe<Scalars['Int']>;
  completedAt: Scalars['Date'];
  versionId: Scalars['ID'];
};

export type SaveRoutinesAvailableInput = {
  routineAvailableId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  frequency: Scalars['String'];
  content: Scalars['JSON'];
  isActive: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};

export type SaveSkapChecklistInput = {
  checklistAvailableId: Scalars['ID'];
  userUnderEvaluationShortSlug: Scalars['String'];
  content: Scalars['JSON'];
  delta: Scalars['JSON'];
  progressPercentage: Scalars['Int'];
};

export type SpecificSubLocations = {
  __typename?: 'specificSubLocations';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  SubArea?: Maybe<SubLocationSpecificSubLocation>;
};

export type StatusReasonContent = {
  __typename?: 'statusReasonContent';
  reason: Array<Maybe<Reason>>;
  comment?: Maybe<Scalars['String']>;
};

export type StatusReasonContentInput = {
  reason: Array<Maybe<ReasonInput>>;
  comment?: Maybe<Scalars['String']>;
};

export type SubArea = {
  __typename?: 'subArea';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type SubLocationSpecificSubLocation = {
  __typename?: 'subLocationSpecificSubLocation';
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  workLocation?: Maybe<WorkLocationSubLocation>;
};

export type SubLocations = {
  __typename?: 'subLocations';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  workLocation?: Maybe<WorkLocationSubLocation>;
};

export enum Type {
  Best = 'best',
  Offender = 'offender'
}

export type UpdateOperatorOfChecklistInput = {
  checklistId: Scalars['ID'];
  operatorUserId: Scalars['ID'];
  oldOperatorUserId?: Maybe<Scalars['ID']>;
};

export type UpdateSkapChecklistInput = {
  checklistId: Scalars['ID'];
  userUnderEvaluationShortSlug: Scalars['String'];
  content: Scalars['JSON'];
  delta: Scalars['JSON'];
  progressPercentage: Scalars['Int'];
};

export type UpdateStatusOfChecklistInput = {
  checklistId: Scalars['ID'];
  status: Scalars['String'];
  statusReasonContent?: Maybe<StatusReasonContentInput>;
  checklistType?: Maybe<ChecklistType>;
  content?: Maybe<Scalars['JSON']>;
  progressPercentage?: Maybe<Scalars['Int']>;
};

export type UserPositions = {
  __typename?: 'userPositions';
  position?: Maybe<Scalars['String']>;
};

export type UserWorkLocations = {
  __typename?: 'userWorkLocations';
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  workLocation?: Maybe<WorkLocation>;
  subLocation?: Maybe<Area>;
  subArea?: Maybe<SubArea>;
};

export type UsualWorkLocation = {
  __typename?: 'usualWorkLocation';
  country: Scalars['String'];
  businessUnit: Scalars['String'];
  location: Scalars['String'];
};

export type WeekContent = {
  __typename?: 'weekContent';
  week: Scalars['String'];
  totalIncidents: Scalars['Int'];
};

export type WorkLocation = {
  __typename?: 'workLocation';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  DEPRECATED_distributionCenter?: Maybe<DistributionCenterWorkLocation>;
};

export type WorkLocationSubLocation = {
  __typename?: 'workLocationSubLocation';
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  DEPRECATED_distributionCenter?: Maybe<DistributionCenterWorkLocation>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ACTION_LOG_ORIGIN: ResolverTypeWrapper<any>;
  ACTION_LOG_PILLAR_IMAGE: ResolverTypeWrapper<any>;
  ACTION_LOG_STATUS: ResolverTypeWrapper<any>;
  ATTACHMENT_TYPES: ResolverTypeWrapper<any>;
  ActionLogFilters: ResolverTypeWrapper<any>;
  String: ResolverTypeWrapper<any>;
  ActionLogsInDCByStatus: ResolverTypeWrapper<any>;
  AnalyticsFileUpload: ResolverTypeWrapper<any>;
  ID: ResolverTypeWrapper<any>;
  AnsweredRoutinesSchema: ResolverTypeWrapper<any>;
  AssignedUsersWithRoles: ResolverTypeWrapper<any>;
  Attachment: ResolverTypeWrapper<any>;
  BaseLocation: ResolverTypeWrapper<any>;
  BaseUser: ResolverTypeWrapper<any>;
  Boolean: ResolverTypeWrapper<any>;
  BuData: ResolverTypeWrapper<any>;
  Int: ResolverTypeWrapper<any>;
  BusinessUnit: ResolverTypeWrapper<any>;
  BusinessUnitWithActionLogCount: ResolverTypeWrapper<any>;
  CacheControlScope: ResolverTypeWrapper<any>;
  ChecklistAvailable: ResolverTypeWrapper<any>;
  ChecklistInfo: ResolverTypeWrapper<any>;
  ChecklistType: ResolverTypeWrapper<any>;
  ClientType: ResolverTypeWrapper<any>;
  Cluster: ResolverTypeWrapper<any>;
  Comment: ResolverTypeWrapper<any>;
  Company: ResolverTypeWrapper<any>;
  CountByAutonomousLevel: ResolverTypeWrapper<any>;
  CountByLevels: ResolverTypeWrapper<any>;
  CountByPosition: ResolverTypeWrapper<any>;
  CountryAndLocationList: ResolverTypeWrapper<any>;
  CovidTest: ResolverTypeWrapper<any>;
  CovidUser: ResolverTypeWrapper<any>;
  CovidUserAndCount: ResolverTypeWrapper<any>;
  CovidUsersForLocation: ResolverTypeWrapper<any>;
  CovidUsersForLocationCount: ResolverTypeWrapper<any>;
  CreateEditActionLog: ResolverTypeWrapper<any>;
  CreateEditActionLogPayload: ResolverTypeWrapper<any>;
  CreateEditActionLogResponse: ResolverTypeWrapper<any>;
  Date: ResolverTypeWrapper<any>;
  DateRangeInput: ResolverTypeWrapper<any>;
  DelayedActionLogsInDCByStatus: ResolverTypeWrapper<any>;
  Department: ResolverTypeWrapper<any>;
  ESPECTRO_USER_TYPES: ResolverTypeWrapper<any>;
  EmergencyContact: ResolverTypeWrapper<any>;
  ExternalCompany: ResolverTypeWrapper<any>;
  FILE_TYPES: ResolverTypeWrapper<any>;
  FailedCertificate: ResolverTypeWrapper<any>;
  Forklift: ResolverTypeWrapper<any>;
  ForkliftArea: ResolverTypeWrapper<any>;
  ForkliftRoutine: ResolverTypeWrapper<any>;
  GKPIDataResponse: ResolverTypeWrapper<any>;
  GKPIExcelProcessingStatus: ResolverTypeWrapper<any>;
  GKPIFileProcessingStatus: ResolverTypeWrapper<any>;
  GKPIItemContent: ResolverTypeWrapper<any>;
  Float: ResolverTypeWrapper<any>;
  GetAll5SChecklistCountByStatus: ResolverTypeWrapper<any>;
  GetAllOWDChecklists: ResolverTypeWrapper<any>;
  GetModulesAndSubmodulesCountResponse: ResolverTypeWrapper<any>;
  GetPDTAccessResponse: ResolverTypeWrapper<any>;
  GetPDTCreationType: ResolverTypeWrapper<any>;
  GetPostResponse: ResolverTypeWrapper<any>;
  GetRoutineAvailableByWorkOrderIdResponse: ResolverTypeWrapper<any>;
  GetSifStatsBy: ResolverTypeWrapper<any>;
  GetTest: ResolverTypeWrapper<any>;
  GetTrainingsBy: ResolverTypeWrapper<any>;
  GetTrainingsPayload: ResolverTypeWrapper<any>;
  HealthStatus: ResolverTypeWrapper<any>;
  ImageInputItem: ResolverTypeWrapper<any>;
  ImageInputItemType: ResolverTypeWrapper<any>;
  IncidentBetweenRange: ResolverTypeWrapper<any>;
  IncidentByCategory: ResolverTypeWrapper<any>;
  IncidentByMonth: ResolverTypeWrapper<any>;
  IncidentByType: ResolverTypeWrapper<any>;
  JSON: ResolverTypeWrapper<any>;
  KPI: ResolverTypeWrapper<any>;
  LOCATION_TYPE: ResolverTypeWrapper<any>;
  LatestCovidTest: ResolverTypeWrapper<any>;
  List: ResolverTypeWrapper<any>;
  ListItem: ResolverTypeWrapper<any>;
  ListSifStatsResponse: ResolverTypeWrapper<any>;
  Location: ResolverTypeWrapper<any>;
  LocationDetails: ResolverTypeWrapper<any>;
  LocationName: ResolverTypeWrapper<any>;
  LocationSchema: ResolverTypeWrapper<any>;
  LocationWithActionLogCount: ResolverTypeWrapper<any>;
  ManagementTool: ResolverTypeWrapper<any>;
  ModulePercentage: ResolverTypeWrapper<any>;
  MonthWiseScore: ResolverTypeWrapper<any>;
  Mutation: ResolverTypeWrapper<{}>;
  MutationStatus: ResolverTypeWrapper<any>;
  Note: ResolverTypeWrapper<any>;
  NoteInput: ResolverTypeWrapper<any>;
  Notification: ResolverTypeWrapper<any>;
  NumberOfContacts: ResolverTypeWrapper<any>;
  OperatorSignature: ResolverTypeWrapper<any>;
  OrderingFashion: ResolverTypeWrapper<any>;
  PDTChecklist: ResolverTypeWrapper<any>;
  PDTSupportCertificate: ResolverTypeWrapper<any>;
  POSTS_TYPES: ResolverTypeWrapper<any>;
  PageInfo: ResolverTypeWrapper<any>;
  PerformingBody: ResolverTypeWrapper<any>;
  PermissionInfoPayload: ResolverTypeWrapper<any>;
  Pillar: ResolverTypeWrapper<any>;
  Post: ResolverTypeWrapper<any>;
  QuarantineeInformation: ResolverTypeWrapper<any>;
  Query: ResolverTypeWrapper<{}>;
  RISK_LEVEL: ResolverTypeWrapper<any>;
  RefreshRoutinesWithVersion: ResolverTypeWrapper<any>;
  RegisteredPDT: ResolverTypeWrapper<any>;
  RegisteredPDTS: ResolverTypeWrapper<any>;
  Routine: ResolverTypeWrapper<any>;
  RoutineAvailable: ResolverTypeWrapper<any>;
  RoutineVersion: ResolverTypeWrapper<any>;
  RoutineWithVersionAdded: ResolverTypeWrapper<any>;
  S3SignedURL: ResolverTypeWrapper<any>;
  S3Urls: ResolverTypeWrapper<any>;
  SKAPChecklist: ResolverTypeWrapper<any>;
  SKAPUsersFilterType: ResolverTypeWrapper<any>;
  ShareableLinkInfo: ResolverTypeWrapper<any>;
  SifStats: ResolverTypeWrapper<any>;
  SifStatsByTypeResponse: ResolverTypeWrapper<any>;
  SifStatsDuration: ResolverTypeWrapper<any>;
  SubDepartment: ResolverTypeWrapper<any>;
  SubLocationDetails: ResolverTypeWrapper<any>;
  SubModulesData: ResolverTypeWrapper<any>;
  Subzone: ResolverTypeWrapper<any>;
  SubzoneWithActionLogCount: ResolverTypeWrapper<any>;
  SupportCertificateNames: ResolverTypeWrapper<any>;
  TestTimeline: ResolverTypeWrapper<any>;
  Truck: ResolverTypeWrapper<any>;
  USER_TYPE: ResolverTypeWrapper<any>;
  UniqueNameAvailableResponse: ResolverTypeWrapper<any>;
  Upload: ResolverTypeWrapper<any>;
  User: ResolverTypeWrapper<any>;
  UserAccessDetails: ResolverTypeWrapper<any>;
  UserChecklistsByMonth: ResolverTypeWrapper<any>;
  UserChecklistsByStatus: ResolverTypeWrapper<any>;
  UserChecklistsResponseValue: ResolverTypeWrapper<any>;
  UserHealthStatus: ResolverTypeWrapper<any>;
  UserInfo: ResolverTypeWrapper<any>;
  UserInfoPayload: ResolverTypeWrapper<any>;
  UserNote: ResolverTypeWrapper<any>;
  UserPositionsByBU: ResolverTypeWrapper<any>;
  UserRoutine: ResolverTypeWrapper<any>;
  UserUnderEvaluationResponse: ResolverTypeWrapper<any>;
  UserVaccine: ResolverTypeWrapper<any>;
  UserVaccinesForLocation: ResolverTypeWrapper<any>;
  UserWorkOrder: ResolverTypeWrapper<any>;
  UsersGeneralInfoPayload: ResolverTypeWrapper<any>;
  UsersLocationMedicalFacility: ResolverTypeWrapper<any>;
  UsersVaccinatedCount: ResolverTypeWrapper<any>;
  VACCINE_TYPE: ResolverTypeWrapper<any>;
  Void: ResolverTypeWrapper<any>;
  WorkLocationDetails: ResolverTypeWrapper<any>;
  WorkOrder: ResolverTypeWrapper<any>;
  WorkOrderAnswer: ResolverTypeWrapper<any>;
  WorkOrderByIDResponse: ResolverTypeWrapper<any>;
  WorkOrderRoutineExecutor: ResolverTypeWrapper<any>;
  WorkOrderStatus: ResolverTypeWrapper<any>;
  Zone: ResolverTypeWrapper<any>;
  ZoneWithActionLogCount: ResolverTypeWrapper<any>;
  addOrEditCommentOnChecklistInput: ResolverTypeWrapper<any>;
  addOrEditGasTestInput: ResolverTypeWrapper<any>;
  addSignatureToChecklistInput: ResolverTypeWrapper<any>;
  area: ResolverTypeWrapper<any>;
  assignRoutine: ResolverTypeWrapper<any>;
  buContent: ResolverTypeWrapper<any>;
  by: ResolverTypeWrapper<any>;
  createPDTChecklistInput: ResolverTypeWrapper<any>;
  deleteOperatorsOfChecklistInput: ResolverTypeWrapper<any>;
  deleteSignatureFromChecklistInput: ResolverTypeWrapper<any>;
  discardCreatedActionLogsOfUncreatedOWDInput: ResolverTypeWrapper<any>;
  distributionCenterWorkLocation: ResolverTypeWrapper<any>;
  filterParams: ResolverTypeWrapper<any>;
  filterParamsByType: ResolverTypeWrapper<any>;
  getAllOWDFilterParams: ResolverTypeWrapper<any>;
  getModuleSubmoduleCountFilterParams: ResolverTypeWrapper<any>;
  getPDTInput: ResolverTypeWrapper<any>;
  getPDTSupportCertificateInput: ResolverTypeWrapper<any>;
  getUsersListPaginated: ResolverTypeWrapper<any>;
  impactedSKAPQuestionBit: ResolverTypeWrapper<any>;
  paginationInfo: ResolverTypeWrapper<any>;
  reason: ResolverTypeWrapper<any>;
  reasonInput: ResolverTypeWrapper<any>;
  routinesAvailableNameAndSlug: ResolverTypeWrapper<any>;
  save5SChecklistInput: ResolverTypeWrapper<any>;
  saveOWDChecklistInput: ResolverTypeWrapper<any>;
  savePDTChecklistInput: ResolverTypeWrapper<any>;
  savePDTSupportCertificateInput: ResolverTypeWrapper<any>;
  saveRoutineAnswerAnonymouslyInput: ResolverTypeWrapper<any>;
  saveRoutineAnswerInput: ResolverTypeWrapper<any>;
  saveRoutinesAvailableInput: ResolverTypeWrapper<any>;
  saveSKAPChecklistInput: ResolverTypeWrapper<any>;
  specificSubLocations: ResolverTypeWrapper<any>;
  statusReasonContent: ResolverTypeWrapper<any>;
  statusReasonContentInput: ResolverTypeWrapper<any>;
  subArea: ResolverTypeWrapper<any>;
  subLocationSpecificSubLocation: ResolverTypeWrapper<any>;
  subLocations: ResolverTypeWrapper<any>;
  type: ResolverTypeWrapper<any>;
  updateOperatorOfChecklistInput: ResolverTypeWrapper<any>;
  updateSKAPChecklistInput: ResolverTypeWrapper<any>;
  updateStatusOfChecklistInput: ResolverTypeWrapper<any>;
  userPositions: ResolverTypeWrapper<any>;
  userWorkLocations: ResolverTypeWrapper<any>;
  usualWorkLocation: ResolverTypeWrapper<any>;
  weekContent: ResolverTypeWrapper<any>;
  workLocation: ResolverTypeWrapper<any>;
  workLocationSubLocation: ResolverTypeWrapper<any>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ActionLogFilters: any;
  String: any;
  ActionLogsInDCByStatus: any;
  AnalyticsFileUpload: any;
  ID: any;
  AnsweredRoutinesSchema: any;
  AssignedUsersWithRoles: any;
  Attachment: any;
  BaseLocation: any;
  BaseUser: any;
  Boolean: any;
  BuData: any;
  Int: any;
  BusinessUnit: any;
  BusinessUnitWithActionLogCount: any;
  ChecklistAvailable: any;
  ChecklistInfo: any;
  Cluster: any;
  Comment: any;
  Company: any;
  CountByAutonomousLevel: any;
  CountByLevels: any;
  CountByPosition: any;
  CountryAndLocationList: any;
  CovidTest: any;
  CovidUser: any;
  CovidUserAndCount: any;
  CovidUsersForLocation: any;
  CovidUsersForLocationCount: any;
  CreateEditActionLog: any;
  CreateEditActionLogPayload: any;
  CreateEditActionLogResponse: any;
  Date: any;
  DateRangeInput: any;
  DelayedActionLogsInDCByStatus: any;
  Department: any;
  EmergencyContact: any;
  ExternalCompany: any;
  FailedCertificate: any;
  Forklift: any;
  ForkliftArea: any;
  ForkliftRoutine: any;
  GKPIDataResponse: any;
  GKPIExcelProcessingStatus: any;
  GKPIItemContent: any;
  Float: any;
  GetAll5SChecklistCountByStatus: any;
  GetAllOWDChecklists: any;
  GetModulesAndSubmodulesCountResponse: any;
  GetPDTAccessResponse: any;
  GetPostResponse: any;
  GetRoutineAvailableByWorkOrderIdResponse: any;
  GetTest: any;
  GetTrainingsPayload: any;
  HealthStatus: any;
  ImageInputItem: any;
  ImageInputItemType: any;
  IncidentBetweenRange: any;
  IncidentByCategory: any;
  IncidentByMonth: any;
  IncidentByType: any;
  JSON: any;
  KPI: any;
  LatestCovidTest: any;
  List: any;
  ListItem: any;
  ListSifStatsResponse: any;
  Location: any;
  LocationDetails: any;
  LocationName: any;
  LocationSchema: any;
  LocationWithActionLogCount: any;
  ManagementTool: any;
  ModulePercentage: any;
  MonthWiseScore: any;
  Mutation: {};
  MutationStatus: any;
  Note: any;
  NoteInput: any;
  Notification: any;
  NumberOfContacts: any;
  OperatorSignature: any;
  PDTChecklist: any;
  PDTSupportCertificate: any;
  PageInfo: any;
  PerformingBody: any;
  PermissionInfoPayload: any;
  Pillar: any;
  Post: any;
  QuarantineeInformation: any;
  Query: {};
  RefreshRoutinesWithVersion: any;
  RegisteredPDT: any;
  RegisteredPDTS: any;
  Routine: any;
  RoutineAvailable: any;
  RoutineVersion: any;
  RoutineWithVersionAdded: any;
  S3SignedURL: any;
  S3Urls: any;
  SKAPChecklist: any;
  ShareableLinkInfo: any;
  SifStats: any;
  SifStatsByTypeResponse: any;
  SifStatsDuration: any;
  SubDepartment: any;
  SubLocationDetails: any;
  SubModulesData: any;
  Subzone: any;
  SubzoneWithActionLogCount: any;
  TestTimeline: any;
  Truck: any;
  UniqueNameAvailableResponse: any;
  Upload: any;
  User: any;
  UserAccessDetails: any;
  UserChecklistsByMonth: any;
  UserChecklistsByStatus: any;
  UserChecklistsResponseValue: any;
  UserHealthStatus: any;
  UserInfo: any;
  UserInfoPayload: any;
  UserNote: any;
  UserPositionsByBU: any;
  UserRoutine: any;
  UserUnderEvaluationResponse: any;
  UserVaccine: any;
  UserVaccinesForLocation: any;
  UserWorkOrder: any;
  UsersGeneralInfoPayload: any;
  UsersLocationMedicalFacility: any;
  UsersVaccinatedCount: any;
  Void: any;
  WorkLocationDetails: any;
  WorkOrder: any;
  WorkOrderAnswer: any;
  WorkOrderByIDResponse: any;
  WorkOrderRoutineExecutor: any;
  Zone: any;
  ZoneWithActionLogCount: any;
  addOrEditCommentOnChecklistInput: any;
  addOrEditGasTestInput: any;
  addSignatureToChecklistInput: any;
  area: any;
  assignRoutine: any;
  buContent: any;
  createPDTChecklistInput: any;
  deleteOperatorsOfChecklistInput: any;
  deleteSignatureFromChecklistInput: any;
  discardCreatedActionLogsOfUncreatedOWDInput: any;
  distributionCenterWorkLocation: any;
  filterParams: any;
  filterParamsByType: any;
  getAllOWDFilterParams: any;
  getModuleSubmoduleCountFilterParams: any;
  getPDTInput: any;
  getPDTSupportCertificateInput: any;
  getUsersListPaginated: any;
  impactedSKAPQuestionBit: any;
  paginationInfo: any;
  reason: any;
  reasonInput: any;
  routinesAvailableNameAndSlug: any;
  save5SChecklistInput: any;
  saveOWDChecklistInput: any;
  savePDTChecklistInput: any;
  savePDTSupportCertificateInput: any;
  saveRoutineAnswerAnonymouslyInput: any;
  saveRoutineAnswerInput: any;
  saveRoutinesAvailableInput: any;
  saveSKAPChecklistInput: any;
  specificSubLocations: any;
  statusReasonContent: any;
  statusReasonContentInput: any;
  subArea: any;
  subLocationSpecificSubLocation: any;
  subLocations: any;
  updateOperatorOfChecklistInput: any;
  updateSKAPChecklistInput: any;
  updateStatusOfChecklistInput: any;
  userPositions: any;
  userWorkLocations: any;
  usualWorkLocation: any;
  weekContent: any;
  workLocation: any;
  workLocationSubLocation: any;
};

export type CacheControlDirectiveArgs = {   maxAge?: Maybe<Scalars['Int']>;
  scope?: Maybe<CacheControlScope>; };

export type CacheControlDirectiveResolver<Result, Parent, ContextType = any, Args = CacheControlDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ActionLogsInDcByStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionLogsInDCByStatus'] = ResolversParentTypes['ActionLogsInDCByStatus']> = {
  OPEN?: Resolver<Array<Maybe<ResolversTypes['CreateEditActionLog']>>, ParentType, ContextType>;
  IN_PROGRESS?: Resolver<Array<Maybe<ResolversTypes['CreateEditActionLog']>>, ParentType, ContextType>;
  FOR_REVISION?: Resolver<Array<Maybe<ResolversTypes['CreateEditActionLog']>>, ParentType, ContextType>;
  COMPLETED?: Resolver<Array<Maybe<ResolversTypes['CreateEditActionLog']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsFileUploadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnalyticsFileUpload'] = ResolversParentTypes['AnalyticsFileUpload']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  fileType?: Resolver<ResolversTypes['FILE_TYPES'], ParentType, ContextType>;
  fileS3Url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnsweredRoutinesSchemaResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnsweredRoutinesSchema'] = ResolversParentTypes['AnsweredRoutinesSchema']> = {
  routines?: Resolver<Maybe<Array<Maybe<ResolversTypes['Routine']>>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attachment'] = ResolversParentTypes['Attachment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ATTACHMENT_TYPES']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseLocation'] = ResolversParentTypes['BaseLocation']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conutry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseUser'] = ResolversParentTypes['BaseUser']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  shortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastActiveAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  doctorStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employeeNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuData'] = ResolversParentTypes['BuData']> = {
  usersToTrain?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  usersTrained?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BusinessUnitResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessUnit'] = ResolversParentTypes['BusinessUnit']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessUnitId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zones?: Resolver<Maybe<Array<Maybe<ResolversTypes['Zone']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BusinessUnitWithActionLogCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessUnitWithActionLogCount'] = ResolversParentTypes['BusinessUnitWithActionLogCount']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  actionLogsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zones?: Resolver<Maybe<Array<Maybe<ResolversTypes['ZoneWithActionLogCount']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChecklistAvailableResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChecklistAvailable'] = ResolversParentTypes['ChecklistAvailable']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChecklistInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChecklistInfo'] = ResolversParentTypes['ChecklistInfo']> = {
  bu?: Resolver<Maybe<ResolversTypes['BusinessUnit']>, ParentType, ContextType>;
  department?: Resolver<Maybe<ResolversTypes['Department']>, ParentType, ContextType>;
  externalCompany?: Resolver<Maybe<ResolversTypes['ExternalCompany']>, ParentType, ContextType>;
  workingDepartment?: Resolver<Maybe<ResolversTypes['Department']>, ParentType, ContextType>;
  workingSubDepartment?: Resolver<Maybe<ResolversTypes['SubDepartment']>, ParentType, ContextType>;
  areaManager?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  performer?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  territoryOwner?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  inspector?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  gasTests?: Resolver<Maybe<Array<Maybe<ResolversTypes['GetTest']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statusReasonContent?: Resolver<Maybe<ResolversTypes['statusReasonContent']>, ParentType, ContextType>;
  userUnderEvaluation?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  progressPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isCompleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClusterResolvers<ContextType = any, ParentType extends ResolversParentTypes['Cluster'] = ResolversParentTypes['Cluster']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Comment'] = ResolversParentTypes['Comment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['BaseUser'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountByAutonomousLevelResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountByAutonomousLevel'] = ResolversParentTypes['CountByAutonomousLevel']> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountByLevelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountByLevels'] = ResolversParentTypes['CountByLevels']> = {
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountByPositionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountByPosition'] = ResolversParentTypes['CountByPosition']> = {
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impactedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  data?: Resolver<Array<Maybe<ResolversTypes['ModulePercentage']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryAndLocationListResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryAndLocationList'] = ResolversParentTypes['CountryAndLocationList']> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locations?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CovidTestResolvers<ContextType = any, ParentType extends ResolversParentTypes['CovidTest'] = ResolversParentTypes['CovidTest']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  givenAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  testImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  resultAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  needsApproval?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CovidUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['CovidUser'] = ResolversParentTypes['CovidUser']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isAlreadyDiagnosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  covidTest?: Resolver<Maybe<ResolversTypes['CovidTest']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CovidUserAndCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['CovidUserAndCount'] = ResolversParentTypes['CovidUserAndCount']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  covidUserList?: Resolver<Maybe<Array<Maybe<ResolversTypes['CovidUser']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CovidUsersForLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CovidUsersForLocation'] = ResolversParentTypes['CovidUsersForLocation']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  employeeNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quarantineUntil?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  doctorStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['LocationName']>, ParentType, ContextType>;
  latestCovidTest?: Resolver<Maybe<ResolversTypes['LatestCovidTest']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CovidUsersForLocationCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['CovidUsersForLocationCount'] = ResolversParentTypes['CovidUsersForLocationCount']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  covidUsersForLocation?: Resolver<Maybe<Array<Maybe<ResolversTypes['CovidUsersForLocation']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateEditActionLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateEditActionLog'] = ResolversParentTypes['CreateEditActionLog']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  successCriteria?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  KPIs?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  KPI?: Resolver<ResolversTypes['KPI'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ACTION_LOG_STATUS'], ParentType, ContextType>;
  pillar?: Resolver<ResolversTypes['ACTION_LOG_PILLAR_IMAGE'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['BaseUser'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['BaseLocation'], ParentType, ContextType>;
  responsibles?: Resolver<Array<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  origin?: Resolver<Maybe<ResolversTypes['ACTION_LOG_ORIGIN']>, ParentType, ContextType>;
  meeting?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  images?: Resolver<Array<Maybe<ResolversTypes['ImageInputItemType']>>, ParentType, ContextType>;
  statusUpdateDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  routinesAvailable?: Resolver<Maybe<ResolversTypes['routinesAvailableNameAndSlug']>, ParentType, ContextType>;
  originText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfComments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ManagementTool?: Resolver<Maybe<ResolversTypes['ManagementTool']>, ParentType, ContextType>;
  checklistsAvailableId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  checklistId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateEditActionLogResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateEditActionLogResponse'] = ResolversParentTypes['CreateEditActionLogResponse']> = {
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type DepartmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Department'] = ResolversParentTypes['Department']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  acronymEN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  acronymES?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subDepartments?: Resolver<Array<Maybe<ResolversTypes['SubDepartment']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmergencyContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmergencyContact'] = ResolversParentTypes['EmergencyContact']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalCompany'] = ResolversParentTypes['ExternalCompany']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FailedCertificateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FailedCertificate'] = ResolversParentTypes['FailedCertificate']> = {
  answers?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ForkliftResolvers<ContextType = any, ParentType extends ResolversParentTypes['Forklift'] = ResolversParentTypes['Forklift']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  forkliftArea?: Resolver<Maybe<ResolversTypes['ForkliftArea']>, ParentType, ContextType>;
  sapNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ForkliftAreaResolvers<ContextType = any, ParentType extends ResolversParentTypes['ForkliftArea'] = ResolversParentTypes['ForkliftArea']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalForklifts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ForkliftRoutineResolvers<ContextType = any, ParentType extends ResolversParentTypes['ForkliftRoutine'] = ResolversParentTypes['ForkliftRoutine']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  approvedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  forklift?: Resolver<Maybe<ResolversTypes['Forklift']>, ParentType, ContextType>;
  routine?: Resolver<Maybe<ResolversTypes['Routine']>, ParentType, ContextType>;
  isCompliant?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GkpiDataResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GKPIDataResponse'] = ResolversParentTypes['GKPIDataResponse']> = {
  GKPI?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GkpiExcelProcessingStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['GKPIExcelProcessingStatus'] = ResolversParentTypes['GKPIExcelProcessingStatus']> = {
  status?: Resolver<ResolversTypes['GKPIFileProcessingStatus'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GkpiItemContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['GKPIItemContent'] = ResolversParentTypes['GKPIItemContent']> = {
  AC?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BU?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AC_VS_LY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAll5SChecklistCountByStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetAll5SChecklistCountByStatus'] = ResolversParentTypes['GetAll5SChecklistCountByStatus']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllOwdChecklistsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetAllOWDChecklists'] = ResolversParentTypes['GetAllOWDChecklists']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetModulesAndSubmodulesCountResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetModulesAndSubmodulesCountResponse'] = ResolversParentTypes['GetModulesAndSubmodulesCountResponse']> = {
  countByLevels?: Resolver<Array<Maybe<ResolversTypes['CountByLevels']>>, ParentType, ContextType>;
  countByAutonomousLevel?: Resolver<Array<Maybe<ResolversTypes['CountByAutonomousLevel']>>, ParentType, ContextType>;
  countByPosition?: Resolver<Array<Maybe<ResolversTypes['CountByPosition']>>, ParentType, ContextType>;
  countByMonth?: Resolver<Array<Maybe<ResolversTypes['MonthWiseScore']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPdtAccessResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetPDTAccessResponse'] = ResolversParentTypes['GetPDTAccessResponse']> = {
  canAccessPDT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPostResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetPostResponse'] = ResolversParentTypes['GetPostResponse']> = {
  posts?: Resolver<Array<Maybe<ResolversTypes['Post']>>, ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetRoutineAvailableByWorkOrderIdResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetRoutineAvailableByWorkOrderIdResponse'] = ResolversParentTypes['GetRoutineAvailableByWorkOrderIdResponse']> = {
  checklists?: Resolver<Array<Maybe<ResolversTypes['JSON']>>, ParentType, ContextType>;
  partialChecklistId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  checklistNames?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  workOrderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  routinesAvailableIds?: Resolver<Array<Maybe<ResolversTypes['ID']>>, ParentType, ContextType>;
  routineSlugs?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  versionNumbers?: Resolver<Array<Maybe<ResolversTypes['Float']>>, ParentType, ContextType>;
  executors?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkOrderRoutineExecutor']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetTestResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetTest'] = ResolversParentTypes['GetTest']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HealthStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['HealthStatus'] = ResolversParentTypes['HealthStatus']> = {
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageInputItemTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImageInputItemType'] = ResolversParentTypes['ImageInputItemType']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncidentBetweenRangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncidentBetweenRange'] = ResolversParentTypes['IncidentBetweenRange']> = {
  bu?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  month?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  weeks?: Resolver<Array<Maybe<ResolversTypes['weekContent']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncidentByCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncidentByCategory'] = ResolversParentTypes['IncidentByCategory']> = {
  categoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bus?: Resolver<Array<Maybe<ResolversTypes['buContent']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncidentByMonthResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncidentByMonth'] = ResolversParentTypes['IncidentByMonth']> = {
  bu?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  months?: Resolver<Array<Maybe<ResolversTypes['buContent']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncidentByTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncidentByType'] = ResolversParentTypes['IncidentByType']> = {
  bu?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalIncidents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type KpiResolvers<ContextType = any, ParentType extends ResolversParentTypes['KPI'] = ResolversParentTypes['KPI']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isGlobal?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  esTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LatestCovidTestResolvers<ContextType = any, ParentType extends ResolversParentTypes['LatestCovidTest'] = ResolversParentTypes['LatestCovidTest']> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListResolvers<ContextType = any, ParentType extends ResolversParentTypes['List'] = ResolversParentTypes['List']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attributes?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['ListItem']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListItem'] = ResolversParentTypes['ListItem']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attributes?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  list?: Resolver<Maybe<ResolversTypes['List']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListSifStatsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListSifStatsResponse'] = ResolversParentTypes['ListSifStatsResponse']> = {
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classificationOfInjury?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incidentCause?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incidentDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  shiftType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incidentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationDetails'] = ResolversParentTypes['LocationDetails']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subzone?: Resolver<ResolversTypes['Subzone'], ParentType, ContextType>;
  lat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  long?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bu?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryEs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasIncidentEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  emergencyContanct?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['LOCATION_TYPE']>, ParentType, ContextType>;
  riskLevel?: Resolver<Maybe<ResolversTypes['RISK_LEVEL']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationNameResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationName'] = ResolversParentTypes['LocationName']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationSchemaResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationSchema'] = ResolversParentTypes['LocationSchema']> = {
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  long?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationWithActionLogCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationWithActionLogCount'] = ResolversParentTypes['LocationWithActionLogCount']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cluster?: Resolver<Maybe<ResolversTypes['Cluster']>, ParentType, ContextType>;
  actionLogsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagementToolResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagementTool'] = ResolversParentTypes['ManagementTool']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  esTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModulePercentageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ModulePercentage'] = ResolversParentTypes['ModulePercentage']> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonthWiseScoreResolvers<ContextType = any, ParentType extends ResolversParentTypes['MonthWiseScore'] = ResolversParentTypes['MonthWiseScore']> = {
  month?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  _?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updateUserDoctorStatus?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateUserDoctorStatusArgs, 'slug' | 'doctorStatus'>>;
  addUserHealthStatus?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddUserHealthStatusArgs, 'status' | 'location'>>;
  addUserHealthStatusUnAuthenticated?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddUserHealthStatusUnAuthenticatedArgs, 'status' | 'location' | 'slug'>>;
  updateUserInfo?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateUserInfoArgs, 'id'>>;
  updateUserType?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateUserTypeArgs, 'id'>>;
  createEspectroUser?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationCreateEspectroUserArgs, 'email' | 'type'>>;
  reinviteEspectroUser?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationReinviteEspectroUserArgs, 'userId'>>;
  updateUserDefaultLocation?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateUserDefaultLocationArgs, 'locationId' | 'userId'>>;
  updateForkliftIsBlocked?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateForkliftIsBlockedArgs, 'slug' | 'isBlocked'>>;
  updateForkliftApproval?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateForkliftApprovalArgs, 'forkliftRoutineId'>>;
  saveRoutineAnswer?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSaveRoutineAnswerArgs, never>>;
  saveRoutineAnswerAnonymously?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSaveRoutineAnswerAnonymouslyArgs, never>>;
  scheduleRoutineDeletion?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationScheduleRoutineDeletionArgs, 'routineAvailableId'>>;
  updateVersionOfRoutineAvailable?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateVersionOfRoutineAvailableArgs, 'versionId' | 'userRoutineId'>>;
  assignUserRoutine?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAssignUserRoutineArgs, never>>;
  userTestDetails?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUserTestDetailsArgs, 'slug' | 'id'>>;
  createCovidTest?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationCreateCovidTestArgs, 'slug' | 'testName'>>;
  approveTest?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationApproveTestArgs, 'id' | 'resultAt'>>;
  giveTestApproval?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationGiveTestApprovalArgs, 'id'>>;
  setAsEmergencyContact?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSetAsEmergencyContactArgs, 'contactId'>>;
  addVaccinationInformation?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddVaccinationInformationArgs, never>>;
  updateVaccinationInformation?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateVaccinationInformationArgs, 'id' | 'givenAt' | 'approvedAt'>>;
  setVaccineApproval?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSetVaccineApprovalArgs, 'id' | 'givenAt'>>;
  removeVaccination?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationRemoveVaccinationArgs, 'id'>>;
  createEditActionLog?: Resolver<ResolversTypes['CreateEditActionLogResponse'], ParentType, ContextType, RequireFields<MutationCreateEditActionLogArgs, never>>;
  updateActionLogStatus?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateActionLogStatusArgs, 'actionLogId' | 'status'>>;
  deleteActionLog?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDeleteActionLogArgs, 'actionLogId'>>;
  addOrEditComment?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddOrEditCommentArgs, 'actionLogId'>>;
  deleteComment?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDeleteCommentArgs, 'commentId'>>;
  createKPI?: Resolver<Array<Maybe<ResolversTypes['KPI']>>, ParentType, ContextType, RequireFields<MutationCreateKpiArgs, 'kpi'>>;
  createSignedS3UrlForGKPIUpload?: Resolver<ResolversTypes['S3SignedURL'], ParentType, ContextType>;
  startProcessingGKPIFile?: Resolver<ResolversTypes['GKPIExcelProcessingStatus'], ParentType, ContextType, RequireFields<MutationStartProcessingGkpiFileArgs, 'fileName'>>;
  updateSeenAt?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateSeenAtArgs, 'ids'>>;
  sendNotification?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSendNotificationArgs, 'type'>>;
  createNote?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateNoteArgs, 'input'>>;
  addOrEditCapexComment?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddOrEditCapexCommentArgs, 'capexId' | 'comment'>>;
  deleteCapexComment?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDeleteCapexCommentArgs, 'commentId'>>;
  updateRoutineAvailable?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateRoutineAvailableArgs, 'routineAvailableId' | 'content'>>;
  saveRoutinesAvailable?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSaveRoutinesAvailableArgs, never>>;
  duplicateChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDuplicateChecklistArgs, 'routineAvailableId'>>;
  generateShareableLinkForRoutineAvailable?: Resolver<ResolversTypes['ShareableLinkInfo'], ParentType, ContextType, RequireFields<MutationGenerateShareableLinkForRoutineAvailableArgs, 'routineAvailableId'>>;
  toggleShareableLinkActive?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationToggleShareableLinkActiveArgs, 'isShareableLinkActive' | 'routineAvailableId'>>;
  updateUserAccessLocation?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateUserAccessLocationArgs, 'locationIds'>>;
  saveSKAPChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSaveSkapChecklistArgs, 'args'>>;
  savePDTChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSavePdtChecklistArgs, 'args'>>;
  createPDTChecklist?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationCreatePdtChecklistArgs, never>>;
  addOrEditCommentOnChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddOrEditCommentOnChecklistArgs, 'args'>>;
  addSignatureToChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddSignatureToChecklistArgs, 'args'>>;
  deleteSignatureFromChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDeleteSignatureFromChecklistArgs, 'args'>>;
  updateOperatorOfChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateOperatorOfChecklistArgs, 'args'>>;
  deleteOperatorsOfChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDeleteOperatorsOfChecklistArgs, 'args'>>;
  updateStatusOfChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateStatusOfChecklistArgs, 'args'>>;
  updateSKAPChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateSkapChecklistArgs, 'args'>>;
  save5SChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSave5SChecklistArgs, 'args'>>;
  savePDTSupportCertificate?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSavePdtSupportCertificateArgs, never>>;
  saveOWDChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSaveOwdChecklistArgs, never>>;
  discardCreatedActionLogsOfUncreatedOWD?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDiscardCreatedActionLogsOfUncreatedOwdArgs, never>>;
  addOrEditGasTest?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddOrEditGasTestArgs, 'args'>>;
  createNewList?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationCreateNewListArgs, 'name' | 'attributes'>>;
  createListItem?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationCreateListItemArgs, 'listId' | 'name' | 'uniqueSlug' | 'additionalAttributes'>>;
  updateListItem?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateListItemArgs, 'listId' | 'name' | 'uniqueSlug' | 'additionalAttributes' | 'listItemId'>>;
  deleteListItem?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDeleteListItemArgs, 'listItemId'>>;
  updateListItemAvatar?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateListItemAvatarArgs, 'listItemId' | 'imageUrl'>>;
  createWorkOrder?: Resolver<Maybe<ResolversTypes['WorkOrder']>, ParentType, ContextType, RequireFields<MutationCreateWorkOrderArgs, 'name' | 'routinesWithVersions' | 'assignedUsersWithRoles' | 'validityStartDate' | 'validityEndDate'>>;
  editWorkOrder?: Resolver<Maybe<ResolversTypes['WorkOrder']>, ParentType, ContextType, RequireFields<MutationEditWorkOrderArgs, 'id' | 'name' | 'description' | 'assignedUsersWithRoles' | 'validityStartDate' | 'validityEndDate'>>;
  deleteWorkOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteWorkOrderArgs, 'id'>>;
  partiallySaveWorkOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationPartiallySaveWorkOrderArgs, 'id' | 'answers' | 'workOrderId'>>;
  finalizeSaveWorkOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationFinalizeSaveWorkOrderArgs, 'id' | 'answers' | 'workOrderId'>>;
  cancelWorkOrder?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationCancelWorkOrderArgs, 'cancellationReason' | 'workOrderId'>>;
  duplicateWorkOrder?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDuplicateWorkOrderArgs, 'workOrderId'>>;
};

export type MutationStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutationStatus'] = ResolversParentTypes['MutationStatus']> = {
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Note'] = ResolversParentTypes['Note']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  bu?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['UserNote']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  time?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  seenAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NumberOfContactsResolvers<ContextType = any, ParentType extends ResolversParentTypes['NumberOfContacts'] = ResolversParentTypes['NumberOfContacts']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OperatorSignatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['OperatorSignature'] = ResolversParentTypes['OperatorSignature']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  signedUser?: Resolver<ResolversTypes['BaseUser'], ParentType, ContextType>;
  signature?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdtChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['PDTChecklist'] = ResolversParentTypes['PDTChecklist']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  checklistName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  checklistCreationDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  operators?: Resolver<Maybe<Array<Maybe<ResolversTypes['BaseUser']>>>, ParentType, ContextType>;
  checklistSignatures?: Resolver<Maybe<Array<Maybe<ResolversTypes['OperatorSignature']>>>, ParentType, ContextType>;
  checklistInfo?: Resolver<ResolversTypes['ChecklistInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdtSupportCertificateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PDTSupportCertificate'] = ResolversParentTypes['PDTSupportCertificate']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  questions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  answers?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalPages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PerformingBodyResolvers<ContextType = any, ParentType extends ResolversParentTypes['PerformingBody'] = ResolversParentTypes['PerformingBody']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PillarResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pillar'] = ResolversParentTypes['Pillar']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostResolvers<ContextType = any, ParentType extends ResolversParentTypes['Post'] = ResolversParentTypes['Post']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bannerImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['POSTS_TYPES']>, ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Attachment']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuarantineeInformationResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuarantineeInformation'] = ResolversParentTypes['QuarantineeInformation']> = {
  emergencyContact?: Resolver<Maybe<ResolversTypes['EmergencyContact']>, ParentType, ContextType>;
  numberOfContacts?: Resolver<Maybe<ResolversTypes['NumberOfContacts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  _?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  getUserInfo?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryGetUserInfoArgs, 'id'>>;
  userPositionsByBu?: Resolver<Maybe<Array<Maybe<ResolversTypes['userPositions']>>>, ParentType, ContextType, RequireFields<QueryUserPositionsByBuArgs, 'bu'>>;
  getUsersList?: Resolver<ResolversTypes['getUsersListPaginated'], ParentType, ContextType, RequireFields<QueryGetUsersListArgs, 'locationID'>>;
  quarantineeInformation?: Resolver<ResolversTypes['QuarantineeInformation'], ParentType, ContextType>;
  getCovidUsersForLocations?: Resolver<ResolversTypes['CovidUsersForLocationCount'], ParentType, ContextType, RequireFields<QueryGetCovidUsersForLocationsArgs, 'locationIds' | 'offset' | 'limit'>>;
  userHealthStatus?: Resolver<ResolversTypes['UserHealthStatus'], ParentType, ContextType, RequireFields<QueryUserHealthStatusArgs, never>>;
  userHealthStatusUnAuthenticated?: Resolver<ResolversTypes['UserHealthStatus'], ParentType, ContextType, RequireFields<QueryUserHealthStatusUnAuthenticatedArgs, 'slug'>>;
  searchCovidUsers?: Resolver<Array<Maybe<ResolversTypes['CovidUsersForLocation']>>, ParentType, ContextType, RequireFields<QuerySearchCovidUsersArgs, 'search' | 'locationIds'>>;
  searchUsersWithAccessToLocation?: Resolver<Maybe<Array<Maybe<ResolversTypes['BaseUser']>>>, ParentType, ContextType, RequireFields<QuerySearchUsersWithAccessToLocationArgs, 'searchString' | 'locationSlug'>>;
  getUserPositionsByBu?: Resolver<Array<Maybe<ResolversTypes['UserPositionsByBU']>>, ParentType, ContextType>;
  getUserUsualWorkLocation?: Resolver<Maybe<ResolversTypes['usualWorkLocation']>, ParentType, ContextType, RequireFields<QueryGetUserUsualWorkLocationArgs, 'userId'>>;
  getUserHasSpeechRecognitionEnabled?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  getUserAvailableLocations?: Resolver<Array<Maybe<ResolversTypes['LocationDetails']>>, ParentType, ContextType>;
  forklift?: Resolver<Maybe<ResolversTypes['Forklift']>, ParentType, ContextType, RequireFields<QueryForkliftArgs, 'slug'>>;
  forkliftRoutine?: Resolver<Maybe<Array<Maybe<ResolversTypes['ForkliftRoutine']>>>, ParentType, ContextType, RequireFields<QueryForkliftRoutineArgs, 'pageSize'>>;
  getAllForklifts?: Resolver<Array<Maybe<ResolversTypes['Forklift']>>, ParentType, ContextType, RequireFields<QueryGetAllForkliftsArgs, never>>;
  forkliftAreasForChecklist?: Resolver<Maybe<Array<Maybe<ResolversTypes['ForkliftArea']>>>, ParentType, ContextType>;
  getAnsweredRoutines?: Resolver<ResolversTypes['AnsweredRoutinesSchema'], ParentType, ContextType, RequireFields<QueryGetAnsweredRoutinesArgs, 'routinesAvailableId'>>;
  getAnsweredRoutinesForUser?: Resolver<Maybe<Array<Maybe<ResolversTypes['Routine']>>>, ParentType, ContextType, RequireFields<QueryGetAnsweredRoutinesForUserArgs, 'routinesAvailableId' | 'userId'>>;
  uploadImages?: Resolver<Array<Maybe<ResolversTypes['S3Urls']>>, ParentType, ContextType, RequireFields<QueryUploadImagesArgs, 'imageUrl'>>;
  covidUsers?: Resolver<ResolversTypes['CovidUserAndCount'], ParentType, ContextType, RequireFields<QueryCovidUsersArgs, 'doctorStatus'>>;
  userCovidTestTimeline?: Resolver<ResolversTypes['TestTimeline'], ParentType, ContextType, RequireFields<QueryUserCovidTestTimelineArgs, 'slug'>>;
  emergencyContact?: Resolver<Maybe<ResolversTypes['EmergencyContact']>, ParentType, ContextType>;
  userVaccines?: Resolver<Array<Maybe<ResolversTypes['UserVaccine']>>, ParentType, ContextType, RequireFields<QueryUserVaccinesArgs, never>>;
  vaccinesForUserLocation?: Resolver<ResolversTypes['UserVaccinesForLocation'], ParentType, ContextType>;
  vaccinesPendingApproval?: Resolver<Maybe<ResolversTypes['UserVaccine']>, ParentType, ContextType>;
  percentageOfVaccinatedUsers?: Resolver<Maybe<ResolversTypes['UsersVaccinatedCount']>, ParentType, ContextType>;
  getUsersVaccineForType?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType, RequireFields<QueryGetUsersVaccineForTypeArgs, 'type'>>;
  actionLogs?: Resolver<Array<Maybe<ResolversTypes['CreateEditActionLog']>>, ParentType, ContextType, RequireFields<QueryActionLogsArgs, never>>;
  actionLog?: Resolver<Maybe<ResolversTypes['CreateEditActionLog']>, ParentType, ContextType, RequireFields<QueryActionLogArgs, 'id'>>;
  actionLogsCountGroupByLocation?: Resolver<Array<Maybe<ResolversTypes['BusinessUnitWithActionLogCount']>>, ParentType, ContextType, RequireFields<QueryActionLogsCountGroupByLocationArgs, 'startTime' | 'endTime'>>;
  groupActionLogsInDCByStatus?: Resolver<ResolversTypes['ActionLogsInDCByStatus'], ParentType, ContextType, RequireFields<QueryGroupActionLogsInDcByStatusArgs, 'startTime' | 'endTime'>>;
  comments?: Resolver<Array<Maybe<ResolversTypes['Comment']>>, ParentType, ContextType, RequireFields<QueryCommentsArgs, 'actionLogId'>>;
  comment?: Resolver<ResolversTypes['Comment'], ParentType, ContextType, RequireFields<QueryCommentArgs, 'actionLogId' | 'commentId'>>;
  location?: Resolver<ResolversTypes['LocationSchema'], ParentType, ContextType, RequireFields<QueryLocationArgs, 'slug'>>;
  getLocations?: Resolver<Array<Maybe<ResolversTypes['LocationDetails']>>, ParentType, ContextType, RequireFields<QueryGetLocationsArgs, never>>;
  locationDetails?: Resolver<ResolversTypes['LocationDetails'], ParentType, ContextType, RequireFields<QueryLocationDetailsArgs, never>>;
  userHasAccessToLocation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryUserHasAccessToLocationArgs, 'locationSlug' | 'userSlug'>>;
  getPost?: Resolver<Maybe<ResolversTypes['Post']>, ParentType, ContextType, RequireFields<QueryGetPostArgs, 'slug'>>;
  getPosts?: Resolver<Maybe<ResolversTypes['GetPostResponse']>, ParentType, ContextType, RequireFields<QueryGetPostsArgs, never>>;
  getUploadedFiles?: Resolver<Maybe<Array<Maybe<ResolversTypes['AnalyticsFileUpload']>>>, ParentType, ContextType, RequireFields<QueryGetUploadedFilesArgs, never>>;
  getLastUploadedFiles?: Resolver<ResolversTypes['AnalyticsFileUpload'], ParentType, ContextType>;
  getUploadedFilesByFileType?: Resolver<Maybe<Array<Maybe<ResolversTypes['AnalyticsFileUpload']>>>, ParentType, ContextType, RequireFields<QueryGetUploadedFilesByFileTypeArgs, 'fileType'>>;
  getLastUploadedFilesByFileType?: Resolver<ResolversTypes['AnalyticsFileUpload'], ParentType, ContextType, RequireFields<QueryGetLastUploadedFilesByFileTypeArgs, 'fileType'>>;
  getAllKPI?: Resolver<Array<Maybe<ResolversTypes['KPI']>>, ParentType, ContextType, RequireFields<QueryGetAllKpiArgs, never>>;
  getS3UrlForGKPIUpload?: Resolver<ResolversTypes['S3SignedURL'], ParentType, ContextType>;
  getGKPIData?: Resolver<Array<Maybe<ResolversTypes['GKPIDataResponse']>>, ParentType, ContextType, RequireFields<QueryGetGkpiDataArgs, never>>;
  getCountryAndLocationList?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryAndLocationList']>>>, ParentType, ContextType>;
  notifications?: Resolver<Array<Maybe<ResolversTypes['Notification']>>, ParentType, ContextType, RequireFields<QueryNotificationsArgs, never>>;
  notificationCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  getNotificationOfTypeByUserSlug?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<QueryGetNotificationOfTypeByUserSlugArgs, never>>;
  getTrainings?: Resolver<Array<Maybe<ResolversTypes['BuData']>>, ParentType, ContextType, RequireFields<QueryGetTrainingsArgs, 'data' | 'by'>>;
  getNote?: Resolver<Maybe<ResolversTypes['Note']>, ParentType, ContextType, RequireFields<QueryGetNoteArgs, 'bu'>>;
  capexComments?: Resolver<Array<Maybe<ResolversTypes['Comment']>>, ParentType, ContextType, RequireFields<QueryCapexCommentsArgs, 'capexId'>>;
  capexComment?: Resolver<ResolversTypes['Comment'], ParentType, ContextType, RequireFields<QueryCapexCommentArgs, 'capexId' | 'commentId'>>;
  getSifStatsByDuration?: Resolver<Array<Maybe<ResolversTypes['SifStatsDuration']>>, ParentType, ContextType, RequireFields<QueryGetSifStatsByDurationArgs, 'monthNumber' | 'year' | 'filter'>>;
  getSifStats?: Resolver<Array<Maybe<ResolversTypes['SifStats']>>, ParentType, ContextType, RequireFields<QueryGetSifStatsArgs, 'filter' | 'by' | 'year'>>;
  getSifStatsByType?: Resolver<Array<Maybe<ResolversTypes['SifStatsByTypeResponse']>>, ParentType, ContextType, RequireFields<QueryGetSifStatsByTypeArgs, 'year'>>;
  listAllSifStatsByMonth?: Resolver<Array<Maybe<ResolversTypes['ListSifStatsResponse']>>, ParentType, ContextType, RequireFields<QueryListAllSifStatsByMonthArgs, 'monthNumber' | 'year' | 'filter'>>;
  lastFileUploadDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  getIncidentsByCategories?: Resolver<Array<Maybe<ResolversTypes['IncidentByCategory']>>, ParentType, ContextType, RequireFields<QueryGetIncidentsByCategoriesArgs, 'monthNumber' | 'year'>>;
  getIncidentsByMonths?: Resolver<Array<Maybe<ResolversTypes['IncidentByMonth']>>, ParentType, ContextType, RequireFields<QueryGetIncidentsByMonthsArgs, 'monthNumber' | 'year'>>;
  getIncidentsByType?: Resolver<Array<Maybe<ResolversTypes['IncidentByType']>>, ParentType, ContextType, RequireFields<QueryGetIncidentsByTypeArgs, 'by' | 'type' | 'monthNumber' | 'year'>>;
  getIncidentsBetweenRange?: Resolver<Array<Maybe<ResolversTypes['IncidentBetweenRange']>>, ParentType, ContextType, RequireFields<QueryGetIncidentsBetweenRangeArgs, 'monthNumber' | 'year'>>;
  getRoutinesAvailable?: Resolver<Array<Maybe<ResolversTypes['RoutineAvailable']>>, ParentType, ContextType, RequireFields<QueryGetRoutinesAvailableArgs, never>>;
  getRoutineAvailable?: Resolver<Maybe<ResolversTypes['RoutineAvailable']>, ParentType, ContextType, RequireFields<QueryGetRoutineAvailableArgs, 'routineAvailableId'>>;
  getRoutineAvailableFromShareableToken?: Resolver<Maybe<ResolversTypes['RoutineAvailable']>, ParentType, ContextType, RequireFields<QueryGetRoutineAvailableFromShareableTokenArgs, 'shareableToken'>>;
  getRoutinesAvailableWithReportInfo?: Resolver<Array<Maybe<ResolversTypes['RoutineAvailable']>>, ParentType, ContextType>;
  getLatestVersionOfRoutineAvailable?: Resolver<Maybe<ResolversTypes['RoutineVersion']>, ParentType, ContextType, RequireFields<QueryGetLatestVersionOfRoutineAvailableArgs, 'routineAvailableId'>>;
  getAllPillar?: Resolver<Array<Maybe<ResolversTypes['Pillar']>>, ParentType, ContextType>;
  getAllManagementTool?: Resolver<Array<Maybe<ResolversTypes['ManagementTool']>>, ParentType, ContextType>;
  getAccessLocations?: Resolver<Array<Maybe<ResolversTypes['LocationDetails']>>, ParentType, ContextType>;
  getAllAccessLocations?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, RequireFields<QueryGetAllAccessLocationsArgs, 'id'>>;
  getWorkLocations?: Resolver<Array<Maybe<ResolversTypes['workLocation']>>, ParentType, ContextType, RequireFields<QueryGetWorkLocationsArgs, 'location'>>;
  getSubLocations?: Resolver<Array<Maybe<ResolversTypes['subLocations']>>, ParentType, ContextType, RequireFields<QueryGetSubLocationsArgs, 'workLocation'>>;
  getSpecificSubLocations?: Resolver<Array<Maybe<ResolversTypes['specificSubLocations']>>, ParentType, ContextType, RequireFields<QueryGetSpecificSubLocationsArgs, 'subLocation'>>;
  getDepartments?: Resolver<Array<Maybe<ResolversTypes['Department']>>, ParentType, ContextType>;
  getExternalCompanies?: Resolver<Array<Maybe<ResolversTypes['ExternalCompany']>>, ParentType, ContextType>;
  searchExternalCompanies?: Resolver<Array<Maybe<ResolversTypes['ExternalCompany']>>, ParentType, ContextType, RequireFields<QuerySearchExternalCompaniesArgs, 'searchString'>>;
  getSKAPAvailableChecklistByName?: Resolver<ResolversTypes['ChecklistAvailable'], ParentType, ContextType, RequireFields<QueryGetSkapAvailableChecklistByNameArgs, 'userUnderEvaluationShortSlug' | 'name'>>;
  getPDTDefaultAvailableChecklist?: Resolver<ResolversTypes['ChecklistAvailable'], ParentType, ContextType>;
  getSKAPChecklistById?: Resolver<ResolversTypes['SKAPChecklist'], ParentType, ContextType, RequireFields<QueryGetSkapChecklistByIdArgs, 'id'>>;
  getPDTChecklistById?: Resolver<ResolversTypes['PDTChecklist'], ParentType, ContextType, RequireFields<QueryGetPdtChecklistByIdArgs, 'id'>>;
  getSKAPChecklistsOfUserUnderEvaluation?: Resolver<Array<ResolversTypes['UserUnderEvaluationResponse']>, ParentType, ContextType, RequireFields<QueryGetSkapChecklistsOfUserUnderEvaluationArgs, 'userUnderEvaluationShortSlug'>>;
  getUserChecklistsByMonth?: Resolver<Array<Maybe<ResolversTypes['UserChecklistsByMonth']>>, ParentType, ContextType>;
  getAll5SChecklistsOfMonthGroupedByStatus?: Resolver<Array<ResolversTypes['UserChecklistsByStatus']>, ParentType, ContextType>;
  getPDTSByCreationTypeAndPerformingBody?: Resolver<Array<Maybe<ResolversTypes['RegisteredPDT']>>, ParentType, ContextType, RequireFields<QueryGetPdtsByCreationTypeAndPerformingBodyArgs, 'args'>>;
  getSKAPModulesAndSubmodulesCount?: Resolver<ResolversTypes['GetModulesAndSubmodulesCountResponse'], ParentType, ContextType, RequireFields<QueryGetSkapModulesAndSubmodulesCountArgs, 'filter'>>;
  getPDTSupportCertificate?: Resolver<ResolversTypes['PDTSupportCertificate'], ParentType, ContextType, RequireFields<QueryGetPdtSupportCertificateArgs, 'args'>>;
  getAll5SChecklistCountByStatus?: Resolver<ResolversTypes['GetAll5SChecklistCountByStatus'], ParentType, ContextType, RequireFields<QueryGetAll5SChecklistCountByStatusArgs, 'status'>>;
  getAllOWDChecklists?: Resolver<Array<Maybe<ResolversTypes['GetAllOWDChecklists']>>, ParentType, ContextType, RequireFields<QueryGetAllOwdChecklistsArgs, 'args'>>;
  getPDTAccess?: Resolver<ResolversTypes['GetPDTAccessResponse'], ParentType, ContextType>;
  getDashboardTableData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, RequireFields<QueryGetDashboardTableDataArgs, 'locationIds'>>;
  getGasTestsByChecklistId?: Resolver<Array<Maybe<ResolversTypes['GetTest']>>, ParentType, ContextType, RequireFields<QueryGetGasTestsByChecklistIdArgs, 'checklistId'>>;
  getAllTrucks?: Resolver<Array<Maybe<ResolversTypes['Truck']>>, ParentType, ContextType>;
  getUserWorkLocations?: Resolver<Array<Maybe<ResolversTypes['userWorkLocations']>>, ParentType, ContextType, RequireFields<QueryGetUserWorkLocationsArgs, 'user'>>;
  getAllLocationsUserPositions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  getListsForCompany?: Resolver<Array<Maybe<ResolversTypes['List']>>, ParentType, ContextType>;
  isListUniqueNameAvailable?: Resolver<ResolversTypes['UniqueNameAvailableResponse'], ParentType, ContextType, RequireFields<QueryIsListUniqueNameAvailableArgs, 'uniqueName'>>;
  getListItemFromShortSlug?: Resolver<Maybe<ResolversTypes['ListItem']>, ParentType, ContextType, RequireFields<QueryGetListItemFromShortSlugArgs, 'listItemShortSlug' | 'listShortSlugs'>>;
  getListItemsFromSearchInput?: Resolver<Maybe<Array<Maybe<ResolversTypes['ListItem']>>>, ParentType, ContextType, RequireFields<QueryGetListItemsFromSearchInputArgs, 'searchInput' | 'listShortSlugs'>>;
  getListItemsOfCompany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ListItem']>>>, ParentType, ContextType>;
  getListItemDetails?: Resolver<ResolversTypes['ListItem'], ParentType, ContextType, RequireFields<QueryGetListItemDetailsArgs, 'listItemId'>>;
  isListItemUniqueNameAvailable?: Resolver<ResolversTypes['UniqueNameAvailableResponse'], ParentType, ContextType, RequireFields<QueryIsListItemUniqueNameAvailableArgs, 'uniqueName'>>;
  getListItemsFromSearchWithoutSlug?: Resolver<Maybe<Array<Maybe<ResolversTypes['ListItem']>>>, ParentType, ContextType, RequireFields<QueryGetListItemsFromSearchWithoutSlugArgs, 'searchString'>>;
  getListItemsLinkedToListShortSlug?: Resolver<Maybe<Array<Maybe<ResolversTypes['ListItem']>>>, ParentType, ContextType, RequireFields<QueryGetListItemsLinkedToListShortSlugArgs, 'listShortSlug'>>;
  getWorkOrders?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkOrder']>>>, ParentType, ContextType, RequireFields<QueryGetWorkOrdersArgs, never>>;
  getWorkOrderForCurrentUser?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkOrder']>>>, ParentType, ContextType>;
  getWorkOrderByWorkOrderId?: Resolver<Maybe<ResolversTypes['WorkOrderByIDResponse']>, ParentType, ContextType, RequireFields<QueryGetWorkOrderByWorkOrderIdArgs, 'workOrderId'>>;
  getRoutineAvailableByWorkOrderId?: Resolver<ResolversTypes['GetRoutineAvailableByWorkOrderIdResponse'], ParentType, ContextType, RequireFields<QueryGetRoutineAvailableByWorkOrderIdArgs, 'workOrderId'>>;
  getRoutineAvailableByWorkOrderIds?: Resolver<Array<Maybe<ResolversTypes['GetRoutineAvailableByWorkOrderIdResponse']>>, ParentType, ContextType, RequireFields<QueryGetRoutineAvailableByWorkOrderIdsArgs, 'workOrderIds'>>;
  getWorkOrdersByRoutineAvailable?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkOrder']>>>, ParentType, ContextType, RequireFields<QueryGetWorkOrdersByRoutineAvailableArgs, 'routineAvailableId'>>;
  listWorkOrderReports?: Resolver<Array<Maybe<ResolversTypes['WorkOrderAnswer']>>, ParentType, ContextType>;
  queueEmailPDFToRecipients?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<QueryQueueEmailPdfToRecipientsArgs, 'recipients' | 'workOrderId'>>;
  getWorkOrderDetails?: Resolver<Maybe<ResolversTypes['WorkOrder']>, ParentType, ContextType, RequireFields<QueryGetWorkOrderDetailsArgs, 'workOrderId'>>;
  listUsersForWorkOrders?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<QueryListUsersForWorkOrdersArgs, 'startDate' | 'endDate'>>;
};

export type RegisteredPdtResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegisteredPDT'] = ResolversParentTypes['RegisteredPDT']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  checklistName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  performingBody?: Resolver<ResolversTypes['PerformingBody'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  checklistDepartment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegisteredPdtsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegisteredPDTS'] = ResolversParentTypes['RegisteredPDTS']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  checklistName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  checklistInfo?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoutineResolvers<ContextType = any, ParentType extends ResolversParentTypes['Routine'] = ResolversParentTypes['Routine']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  answers?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  userRoutine?: Resolver<ResolversTypes['UserRoutine'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  routineAvailable?: Resolver<Maybe<ResolversTypes['RoutineAvailable']>, ParentType, ContextType>;
  routineVersion?: Resolver<Maybe<ResolversTypes['RoutineVersion']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoutineAvailableResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoutineAvailable'] = ResolversParentTypes['RoutineAvailable']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  isShareableLinkActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  shareableToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalReports?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastReportCompletedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  versionNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  versionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isScheduledForDeletion?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  routineVersions?: Resolver<Maybe<ResolversTypes['RoutineVersion']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoutineVersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoutineVersion'] = ResolversParentTypes['RoutineVersion']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  versionNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoutineWithVersionAddedResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoutineWithVersionAdded'] = ResolversParentTypes['RoutineWithVersionAdded']> = {
  routineInfo?: Resolver<Maybe<ResolversTypes['RoutineAvailable']>, ParentType, ContextType>;
  versionAdded?: Resolver<Maybe<ResolversTypes['RoutineVersion']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type S3SignedUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['S3SignedURL'] = ResolversParentTypes['S3SignedURL']> = {
  preSignedUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type S3UrlsResolvers<ContextType = any, ParentType extends ResolversParentTypes['S3Urls'] = ResolversParentTypes['S3Urls']> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkapChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['SKAPChecklist'] = ResolversParentTypes['SKAPChecklist']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  checklistOperators?: Resolver<Array<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  checklistInfo?: Resolver<Maybe<ResolversTypes['ChecklistInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareableLinkInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShareableLinkInfo'] = ResolversParentTypes['ShareableLinkInfo']> = {
  routines?: Resolver<Array<Maybe<ResolversTypes['RoutineAvailable']>>, ParentType, ContextType>;
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SifStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SifStats'] = ResolversParentTypes['SifStats']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalSif?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SifStatsByTypeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SifStatsByTypeResponse'] = ResolversParentTypes['SifStatsByTypeResponse']> = {
  month?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sifType?: Resolver<Array<Maybe<ResolversTypes['SifStats']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SifStatsDurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SifStatsDuration'] = ResolversParentTypes['SifStatsDuration']> = {
  duration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalSif?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubDepartmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubDepartment'] = ResolversParentTypes['SubDepartment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  departmentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  acronymEN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  acronymES?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubLocationDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubLocationDetails'] = ResolversParentTypes['SubLocationDetails']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubModulesDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubModulesData'] = ResolversParentTypes['SubModulesData']> = {
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  isCompleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  progressPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  checklistId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  isAnswered?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubzoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subzone'] = ResolversParentTypes['Subzone']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  zone?: Resolver<ResolversTypes['Zone'], ParentType, ContextType>;
  distributionCenters?: Resolver<Maybe<Array<Maybe<ResolversTypes['Location']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubzoneWithActionLogCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubzoneWithActionLogCount'] = ResolversParentTypes['SubzoneWithActionLogCount']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  actionLogsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  locations?: Resolver<Maybe<Array<Maybe<ResolversTypes['LocationWithActionLogCount']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TestTimelineResolvers<ContextType = any, ParentType extends ResolversParentTypes['TestTimeline'] = ResolversParentTypes['TestTimeline']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doctorStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quarantineUntil?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  quarantineStart?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  covidTests?: Resolver<Maybe<Array<Maybe<ResolversTypes['CovidTest']>>>, ParentType, ContextType>;
  failedCertificate?: Resolver<Maybe<ResolversTypes['FailedCertificate']>, ParentType, ContextType>;
  userHealthStatus?: Resolver<Maybe<ResolversTypes['HealthStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TruckResolvers<ContextType = any, ParentType extends ResolversParentTypes['Truck'] = ResolversParentTypes['Truck']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  sapNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniqueNameAvailableResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniqueNameAvailableResponse'] = ResolversParentTypes['UniqueNameAvailableResponse']> = {
  isAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registeredCompany?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  shortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastActiveAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  doctorStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employeeNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quarantineUntil?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  distributionCenter?: Resolver<Maybe<ResolversTypes['LocationDetails']>, ParentType, ContextType>;
  covidTest?: Resolver<Maybe<ResolversTypes['CovidTest']>, ParentType, ContextType>;
  vaccines?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserVaccine']>>>, ParentType, ContextType>;
  lastVaccine?: Resolver<Maybe<ResolversTypes['UserVaccine']>, ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['UserInfo']>, ParentType, ContextType>;
  enableSpeechRecognition?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAccessDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAccessDetails'] = ResolversParentTypes['UserAccessDetails']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  distributionCenter?: Resolver<ResolversTypes['LocationDetails'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserChecklistsByMonthResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChecklistsByMonth'] = ResolversParentTypes['UserChecklistsByMonth']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Array<Maybe<ResolversTypes['UserChecklistsResponseValue']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserChecklistsByStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChecklistsByStatus'] = ResolversParentTypes['UserChecklistsByStatus']> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Array<Maybe<ResolversTypes['UserChecklistsResponseValue']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserChecklistsResponseValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChecklistsResponseValue'] = ResolversParentTypes['UserChecklistsResponseValue']> = {
  checklistId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  checklistsAvailableId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  submittedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  answeredContent?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workLocation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subWorkLocation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appliedBy?: Resolver<ResolversTypes['BaseUser'], ParentType, ContextType>;
  assignedTo?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  truck?: Resolver<Maybe<ResolversTypes['Truck']>, ParentType, ContextType>;
  forklift?: Resolver<Maybe<ResolversTypes['Forklift']>, ParentType, ContextType>;
  sapNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfActionLogs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserHealthStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHealthStatus'] = ResolversParentTypes['UserHealthStatus']> = {
  quarantineUntil?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  quarantineStart?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  healthStatus?: Resolver<Maybe<ResolversTypes['HealthStatus']>, ParentType, ContextType>;
  usersLocationMedicalFacility?: Resolver<Maybe<Array<Maybe<ResolversTypes['UsersLocationMedicalFacility']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserInfo'] = ResolversParentTypes['UserInfo']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isAuthorisedForOWD?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserNote'] = ResolversParentTypes['UserNote']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPositionsByBuResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPositionsByBU'] = ResolversParentTypes['UserPositionsByBU']> = {
  position?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bu?: Resolver<ResolversTypes['BusinessUnit'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserRoutineResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRoutine'] = ResolversParentTypes['UserRoutine']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  routineAvailable?: Resolver<ResolversTypes['RoutineAvailable'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserUnderEvaluationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUnderEvaluationResponse'] = ResolversParentTypes['UserUnderEvaluationResponse']> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  progressPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isCompleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  subModules?: Resolver<Array<ResolversTypes['SubModulesData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserVaccineResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserVaccine'] = ResolversParentTypes['UserVaccine']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vaccinatedUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  vaccinationGivenBy?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  givenAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  approvedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserVaccinesForLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserVaccinesForLocation'] = ResolversParentTypes['UserVaccinesForLocation']> = {
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserWorkOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkOrder'] = ResolversParentTypes['UserWorkOrder']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  roleType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersLocationMedicalFacilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersLocationMedicalFacility'] = ResolversParentTypes['UsersLocationMedicalFacility']> = {
  emergencyContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersVaccinatedCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersVaccinatedCount'] = ResolversParentTypes['UsersVaccinatedCount']> = {
  vaccinated?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface VoidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Void'], any> {
  name: 'Void';
}

export type WorkLocationDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkLocationDetails'] = ResolversParentTypes['WorkLocationDetails']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkOrder'] = ResolversParentTypes['WorkOrder']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  routines?: Resolver<Maybe<Array<Maybe<ResolversTypes['RoutineWithVersionAdded']>>>, ParentType, ContextType>;
  routineIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['WorkOrderStatus']>, ParentType, ContextType>;
  userWorkOrderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  userIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  totalQuestions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  areRecordsSearched?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalQuestionsAnswered?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isSupervisor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFinalized?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  receivers?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  isCancelled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cancellationReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userWorkOrders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  workOrderRoutineExecutors?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkOrderAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkOrderAnswer'] = ResolversParentTypes['WorkOrderAnswer']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  workOrder?: Resolver<Maybe<ResolversTypes['WorkOrder']>, ParentType, ContextType>;
  isFinalized?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkOrderByIdResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkOrderByIDResponse'] = ResolversParentTypes['WorkOrderByIDResponse']> = {
  workOrdersWithRoutines?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkOrder']>>>, ParentType, ContextType>;
  detailedOrderInfo?: Resolver<Maybe<Array<Maybe<ResolversTypes['GetRoutineAvailableByWorkOrderIdResponse']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkOrderRoutineExecutorResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkOrderRoutineExecutor'] = ResolversParentTypes['WorkOrderRoutineExecutor']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  routineAvailable?: Resolver<Maybe<ResolversTypes['RoutineAvailable']>, ParentType, ContextType>;
  routineAvailableId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  workOrderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  workOrder?: Resolver<Maybe<ResolversTypes['WorkOrder']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['Zone'] = ResolversParentTypes['Zone']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  businessUnit?: Resolver<Maybe<ResolversTypes['BusinessUnit']>, ParentType, ContextType>;
  subzones?: Resolver<Maybe<Array<Maybe<ResolversTypes['Subzone']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoneWithActionLogCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['ZoneWithActionLogCount'] = ResolversParentTypes['ZoneWithActionLogCount']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  actionLogsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subzones?: Resolver<Maybe<Array<Maybe<ResolversTypes['SubzoneWithActionLogCount']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AreaResolvers<ContextType = any, ParentType extends ResolversParentTypes['area'] = ResolversParentTypes['area']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['buContent'] = ResolversParentTypes['buContent']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalIncidents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistributionCenterWorkLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['distributionCenterWorkLocation'] = ResolversParentTypes['distributionCenterWorkLocation']> = {
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetUsersListPaginatedResolvers<ContextType = any, ParentType extends ResolversParentTypes['getUsersListPaginated'] = ResolversParentTypes['getUsersListPaginated']> = {
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  paginationInfo?: Resolver<ResolversTypes['paginationInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginationInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['paginationInfo'] = ResolversParentTypes['paginationInfo']> = {
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currentPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReasonResolvers<ContextType = any, ParentType extends ResolversParentTypes['reason'] = ResolversParentTypes['reason']> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoutinesAvailableNameAndSlugResolvers<ContextType = any, ParentType extends ResolversParentTypes['routinesAvailableNameAndSlug'] = ResolversParentTypes['routinesAvailableNameAndSlug']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecificSubLocationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['specificSubLocations'] = ResolversParentTypes['specificSubLocations']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubArea?: Resolver<Maybe<ResolversTypes['subLocationSpecificSubLocation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusReasonContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['statusReasonContent'] = ResolversParentTypes['statusReasonContent']> = {
  reason?: Resolver<Array<Maybe<ResolversTypes['reason']>>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubAreaResolvers<ContextType = any, ParentType extends ResolversParentTypes['subArea'] = ResolversParentTypes['subArea']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubLocationSpecificSubLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['subLocationSpecificSubLocation'] = ResolversParentTypes['subLocationSpecificSubLocation']> = {
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workLocation?: Resolver<Maybe<ResolversTypes['workLocationSubLocation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubLocationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['subLocations'] = ResolversParentTypes['subLocations']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workLocation?: Resolver<Maybe<ResolversTypes['workLocationSubLocation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPositionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPositions'] = ResolversParentTypes['userPositions']> = {
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserWorkLocationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userWorkLocations'] = ResolversParentTypes['userWorkLocations']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workLocation?: Resolver<Maybe<ResolversTypes['workLocation']>, ParentType, ContextType>;
  subLocation?: Resolver<Maybe<ResolversTypes['area']>, ParentType, ContextType>;
  subArea?: Resolver<Maybe<ResolversTypes['subArea']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsualWorkLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['usualWorkLocation'] = ResolversParentTypes['usualWorkLocation']> = {
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  businessUnit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WeekContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['weekContent'] = ResolversParentTypes['weekContent']> = {
  week?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalIncidents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['workLocation'] = ResolversParentTypes['workLocation']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DEPRECATED_distributionCenter?: Resolver<Maybe<ResolversTypes['distributionCenterWorkLocation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkLocationSubLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['workLocationSubLocation'] = ResolversParentTypes['workLocationSubLocation']> = {
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DEPRECATED_distributionCenter?: Resolver<Maybe<ResolversTypes['distributionCenterWorkLocation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  ActionLogsInDCByStatus?: ActionLogsInDcByStatusResolvers<ContextType>;
  AnalyticsFileUpload?: AnalyticsFileUploadResolvers<ContextType>;
  AnsweredRoutinesSchema?: AnsweredRoutinesSchemaResolvers<ContextType>;
  Attachment?: AttachmentResolvers<ContextType>;
  BaseLocation?: BaseLocationResolvers<ContextType>;
  BaseUser?: BaseUserResolvers<ContextType>;
  BuData?: BuDataResolvers<ContextType>;
  BusinessUnit?: BusinessUnitResolvers<ContextType>;
  BusinessUnitWithActionLogCount?: BusinessUnitWithActionLogCountResolvers<ContextType>;
  ChecklistAvailable?: ChecklistAvailableResolvers<ContextType>;
  ChecklistInfo?: ChecklistInfoResolvers<ContextType>;
  Cluster?: ClusterResolvers<ContextType>;
  Comment?: CommentResolvers<ContextType>;
  Company?: CompanyResolvers<ContextType>;
  CountByAutonomousLevel?: CountByAutonomousLevelResolvers<ContextType>;
  CountByLevels?: CountByLevelsResolvers<ContextType>;
  CountByPosition?: CountByPositionResolvers<ContextType>;
  CountryAndLocationList?: CountryAndLocationListResolvers<ContextType>;
  CovidTest?: CovidTestResolvers<ContextType>;
  CovidUser?: CovidUserResolvers<ContextType>;
  CovidUserAndCount?: CovidUserAndCountResolvers<ContextType>;
  CovidUsersForLocation?: CovidUsersForLocationResolvers<ContextType>;
  CovidUsersForLocationCount?: CovidUsersForLocationCountResolvers<ContextType>;
  CreateEditActionLog?: CreateEditActionLogResolvers<ContextType>;
  CreateEditActionLogResponse?: CreateEditActionLogResponseResolvers<ContextType>;
  Date?: GraphQLScalarType;
  Department?: DepartmentResolvers<ContextType>;
  EmergencyContact?: EmergencyContactResolvers<ContextType>;
  ExternalCompany?: ExternalCompanyResolvers<ContextType>;
  FailedCertificate?: FailedCertificateResolvers<ContextType>;
  Forklift?: ForkliftResolvers<ContextType>;
  ForkliftArea?: ForkliftAreaResolvers<ContextType>;
  ForkliftRoutine?: ForkliftRoutineResolvers<ContextType>;
  GKPIDataResponse?: GkpiDataResponseResolvers<ContextType>;
  GKPIExcelProcessingStatus?: GkpiExcelProcessingStatusResolvers<ContextType>;
  GKPIItemContent?: GkpiItemContentResolvers<ContextType>;
  GetAll5SChecklistCountByStatus?: GetAll5SChecklistCountByStatusResolvers<ContextType>;
  GetAllOWDChecklists?: GetAllOwdChecklistsResolvers<ContextType>;
  GetModulesAndSubmodulesCountResponse?: GetModulesAndSubmodulesCountResponseResolvers<ContextType>;
  GetPDTAccessResponse?: GetPdtAccessResponseResolvers<ContextType>;
  GetPostResponse?: GetPostResponseResolvers<ContextType>;
  GetRoutineAvailableByWorkOrderIdResponse?: GetRoutineAvailableByWorkOrderIdResponseResolvers<ContextType>;
  GetTest?: GetTestResolvers<ContextType>;
  HealthStatus?: HealthStatusResolvers<ContextType>;
  ImageInputItemType?: ImageInputItemTypeResolvers<ContextType>;
  IncidentBetweenRange?: IncidentBetweenRangeResolvers<ContextType>;
  IncidentByCategory?: IncidentByCategoryResolvers<ContextType>;
  IncidentByMonth?: IncidentByMonthResolvers<ContextType>;
  IncidentByType?: IncidentByTypeResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  KPI?: KpiResolvers<ContextType>;
  LatestCovidTest?: LatestCovidTestResolvers<ContextType>;
  List?: ListResolvers<ContextType>;
  ListItem?: ListItemResolvers<ContextType>;
  ListSifStatsResponse?: ListSifStatsResponseResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LocationDetails?: LocationDetailsResolvers<ContextType>;
  LocationName?: LocationNameResolvers<ContextType>;
  LocationSchema?: LocationSchemaResolvers<ContextType>;
  LocationWithActionLogCount?: LocationWithActionLogCountResolvers<ContextType>;
  ManagementTool?: ManagementToolResolvers<ContextType>;
  ModulePercentage?: ModulePercentageResolvers<ContextType>;
  MonthWiseScore?: MonthWiseScoreResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  MutationStatus?: MutationStatusResolvers<ContextType>;
  Note?: NoteResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NumberOfContacts?: NumberOfContactsResolvers<ContextType>;
  OperatorSignature?: OperatorSignatureResolvers<ContextType>;
  PDTChecklist?: PdtChecklistResolvers<ContextType>;
  PDTSupportCertificate?: PdtSupportCertificateResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PerformingBody?: PerformingBodyResolvers<ContextType>;
  Pillar?: PillarResolvers<ContextType>;
  Post?: PostResolvers<ContextType>;
  QuarantineeInformation?: QuarantineeInformationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RegisteredPDT?: RegisteredPdtResolvers<ContextType>;
  RegisteredPDTS?: RegisteredPdtsResolvers<ContextType>;
  Routine?: RoutineResolvers<ContextType>;
  RoutineAvailable?: RoutineAvailableResolvers<ContextType>;
  RoutineVersion?: RoutineVersionResolvers<ContextType>;
  RoutineWithVersionAdded?: RoutineWithVersionAddedResolvers<ContextType>;
  S3SignedURL?: S3SignedUrlResolvers<ContextType>;
  S3Urls?: S3UrlsResolvers<ContextType>;
  SKAPChecklist?: SkapChecklistResolvers<ContextType>;
  ShareableLinkInfo?: ShareableLinkInfoResolvers<ContextType>;
  SifStats?: SifStatsResolvers<ContextType>;
  SifStatsByTypeResponse?: SifStatsByTypeResponseResolvers<ContextType>;
  SifStatsDuration?: SifStatsDurationResolvers<ContextType>;
  SubDepartment?: SubDepartmentResolvers<ContextType>;
  SubLocationDetails?: SubLocationDetailsResolvers<ContextType>;
  SubModulesData?: SubModulesDataResolvers<ContextType>;
  Subzone?: SubzoneResolvers<ContextType>;
  SubzoneWithActionLogCount?: SubzoneWithActionLogCountResolvers<ContextType>;
  TestTimeline?: TestTimelineResolvers<ContextType>;
  Truck?: TruckResolvers<ContextType>;
  UniqueNameAvailableResponse?: UniqueNameAvailableResponseResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  UserAccessDetails?: UserAccessDetailsResolvers<ContextType>;
  UserChecklistsByMonth?: UserChecklistsByMonthResolvers<ContextType>;
  UserChecklistsByStatus?: UserChecklistsByStatusResolvers<ContextType>;
  UserChecklistsResponseValue?: UserChecklistsResponseValueResolvers<ContextType>;
  UserHealthStatus?: UserHealthStatusResolvers<ContextType>;
  UserInfo?: UserInfoResolvers<ContextType>;
  UserNote?: UserNoteResolvers<ContextType>;
  UserPositionsByBU?: UserPositionsByBuResolvers<ContextType>;
  UserRoutine?: UserRoutineResolvers<ContextType>;
  UserUnderEvaluationResponse?: UserUnderEvaluationResponseResolvers<ContextType>;
  UserVaccine?: UserVaccineResolvers<ContextType>;
  UserVaccinesForLocation?: UserVaccinesForLocationResolvers<ContextType>;
  UserWorkOrder?: UserWorkOrderResolvers<ContextType>;
  UsersLocationMedicalFacility?: UsersLocationMedicalFacilityResolvers<ContextType>;
  UsersVaccinatedCount?: UsersVaccinatedCountResolvers<ContextType>;
  Void?: GraphQLScalarType;
  WorkLocationDetails?: WorkLocationDetailsResolvers<ContextType>;
  WorkOrder?: WorkOrderResolvers<ContextType>;
  WorkOrderAnswer?: WorkOrderAnswerResolvers<ContextType>;
  WorkOrderByIDResponse?: WorkOrderByIdResponseResolvers<ContextType>;
  WorkOrderRoutineExecutor?: WorkOrderRoutineExecutorResolvers<ContextType>;
  Zone?: ZoneResolvers<ContextType>;
  ZoneWithActionLogCount?: ZoneWithActionLogCountResolvers<ContextType>;
  area?: AreaResolvers<ContextType>;
  buContent?: BuContentResolvers<ContextType>;
  distributionCenterWorkLocation?: DistributionCenterWorkLocationResolvers<ContextType>;
  getUsersListPaginated?: GetUsersListPaginatedResolvers<ContextType>;
  paginationInfo?: PaginationInfoResolvers<ContextType>;
  reason?: ReasonResolvers<ContextType>;
  routinesAvailableNameAndSlug?: RoutinesAvailableNameAndSlugResolvers<ContextType>;
  specificSubLocations?: SpecificSubLocationsResolvers<ContextType>;
  statusReasonContent?: StatusReasonContentResolvers<ContextType>;
  subArea?: SubAreaResolvers<ContextType>;
  subLocationSpecificSubLocation?: SubLocationSpecificSubLocationResolvers<ContextType>;
  subLocations?: SubLocationsResolvers<ContextType>;
  userPositions?: UserPositionsResolvers<ContextType>;
  userWorkLocations?: UserWorkLocationsResolvers<ContextType>;
  usualWorkLocation?: UsualWorkLocationResolvers<ContextType>;
  weekContent?: WeekContentResolvers<ContextType>;
  workLocation?: WorkLocationResolvers<ContextType>;
  workLocationSubLocation?: WorkLocationSubLocationResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
export type DirectiveResolvers<ContextType = any> = {
  cacheControl?: CacheControlDirectiveResolver<any, any, ContextType>;
};


/**
 * @deprecated
 * Use "DirectiveResolvers" root object instead. If you wish to get "IDirectiveResolvers", add "typesPrefix: I" to your config.
 */
export type IDirectiveResolvers<ContextType = any> = DirectiveResolvers<ContextType>;
export type CreateListItemMutationVariables = Exact<{
  listId: Scalars['ID'];
  name: Scalars['String'];
  uniqueSlug: Scalars['String'];
  additionalAttributes: Scalars['JSON'];
  imageUrl?: Maybe<Scalars['String']>;
}>;


export type CreateListItemMutation = (
  { __typename?: 'Mutation' }
  & { createListItem: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type IsListItemUniqueNameAvailableQueryVariables = Exact<{
  uniqueName: Scalars['String'];
}>;


export type IsListItemUniqueNameAvailableQuery = (
  { __typename?: 'Query' }
  & { isListItemUniqueNameAvailable: (
    { __typename?: 'UniqueNameAvailableResponse' }
    & Pick<UniqueNameAvailableResponse, 'isAvailable'>
  ) }
);

export type UpdateListItemMutationVariables = Exact<{
  listId: Scalars['ID'];
  name: Scalars['String'];
  uniqueSlug: Scalars['String'];
  additionalAttributes: Scalars['JSON'];
  imageUrl?: Maybe<Scalars['String']>;
  listItemId: Scalars['ID'];
}>;


export type UpdateListItemMutation = (
  { __typename?: 'Mutation' }
  & { updateListItem: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type GetListItemForCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetListItemForCompanyQuery = (
  { __typename?: 'Query' }
  & { getListItemsOfCompany?: Maybe<Array<Maybe<(
    { __typename?: 'ListItem' }
    & Pick<ListItem, 'id' | 'name' | 'shortSlug' | 'description' | 'slug'>
    & { list?: Maybe<(
      { __typename?: 'List' }
      & Pick<List, 'id' | 'name' | 'slug'>
    )> }
  )>>> }
);

export type GetListItemForCompanyByIdQueryVariables = Exact<{
  listItemId: Scalars['ID'];
}>;


export type GetListItemForCompanyByIdQuery = (
  { __typename?: 'Query' }
  & { getListItemDetails: (
    { __typename?: 'ListItem' }
    & Pick<ListItem, 'id' | 'name' | 'shortSlug' | 'imageUrl' | 'description' | 'attributes' | 'slug'>
    & { list?: Maybe<(
      { __typename?: 'List' }
      & Pick<List, 'id' | 'name' | 'slug'>
    )> }
  ) }
);

export type CreateNewListMutationVariables = Exact<{
  name: Scalars['String'];
  attributes: Scalars['JSON'];
}>;


export type CreateNewListMutation = (
  { __typename?: 'Mutation' }
  & { createNewList: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type DeleteListItemMutationVariables = Exact<{
  listItemId: Scalars['ID'];
}>;


export type DeleteListItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteListItem: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type GetListItemsFromSearchWithoutSlugQueryVariables = Exact<{
  searchString: Scalars['String'];
}>;


export type GetListItemsFromSearchWithoutSlugQuery = (
  { __typename?: 'Query' }
  & { getListItemsFromSearchWithoutSlug?: Maybe<Array<Maybe<(
    { __typename?: 'ListItem' }
    & Pick<ListItem, 'id' | 'name' | 'description'>
    & { list?: Maybe<(
      { __typename?: 'List' }
      & Pick<List, 'id' | 'name'>
    )> }
  )>>> }
);

export type UpdateListItemAvatarMutationVariables = Exact<{
  listItemId: Scalars['ID'];
  imageUrl: Scalars['String'];
}>;


export type UpdateListItemAvatarMutation = (
  { __typename?: 'Mutation' }
  & { updateListItemAvatar: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type CreateWorkOrderMutationVariables = Exact<{
  name: Scalars['String'];
  routinesWithVersions: Array<Maybe<RefreshRoutinesWithVersion>> | Maybe<RefreshRoutinesWithVersion>;
  description?: Maybe<Scalars['String']>;
  receivers?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  assignedUsersWithRoles: Array<Maybe<AssignedUsersWithRoles>> | Maybe<AssignedUsersWithRoles>;
  validityStartDate: Scalars['Date'];
  validityEndDate: Scalars['Date'];
}>;


export type CreateWorkOrderMutation = (
  { __typename?: 'Mutation' }
  & { createWorkOrder?: Maybe<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, 'createdAt' | 'description' | 'id' | 'name' | 'routineIds' | 'receivers'>
  )> }
);

export type EditWorkOrderMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  routinesWithVersions: Array<Maybe<RefreshRoutinesWithVersion>> | Maybe<RefreshRoutinesWithVersion>;
  description: Scalars['String'];
  receivers?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  assignedUsersWithRoles: Array<Maybe<AssignedUsersWithRoles>> | Maybe<AssignedUsersWithRoles>;
  validityStartDate: Scalars['Date'];
  validityEndDate: Scalars['Date'];
}>;


export type EditWorkOrderMutation = (
  { __typename?: 'Mutation' }
  & { editWorkOrder?: Maybe<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, 'id' | 'name' | 'description' | 'routineIds' | 'receivers'>
  )> }
);

export type GetRoutinesAvailableForWorkOrdersQueryVariables = Exact<{
  excludeNestedChecklist?: Maybe<Scalars['Boolean']>;
  includeDeletedRoutines?: Maybe<Scalars['Boolean']>;
}>;


export type GetRoutinesAvailableForWorkOrdersQuery = (
  { __typename?: 'Query' }
  & { getRoutinesAvailable: Array<Maybe<(
    { __typename?: 'RoutineAvailable' }
    & Pick<RoutineAvailable, 'id' | 'name' | 'slug' | 'versionNumber' | 'versionId'>
    & { routineVersions?: Maybe<(
      { __typename?: 'RoutineVersion' }
      & Pick<RoutineVersion, 'id' | 'versionNumber'>
    )> }
  )>> }
);

export type GetWorkOrderDetailsQueryVariables = Exact<{
  workOrderId: Scalars['ID'];
}>;


export type GetWorkOrderDetailsQuery = (
  { __typename?: 'Query' }
  & { getWorkOrderDetails?: Maybe<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, 'id' | 'name' | 'description' | 'receivers' | 'startDate' | 'endDate' | 'workOrderRoutineExecutors' | 'userWorkOrders'>
  )> }
);

export type ListUsersForWorkOrdersQueryVariables = Exact<{
  workOrderId?: Maybe<Scalars['ID']>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
}>;


export type ListUsersForWorkOrdersQuery = (
  { __typename?: 'Query' }
  & { listUsersForWorkOrders: Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email' | 'isEnabled' | 'registeredCompany'>
  )>> }
);

export type GetWorkOrdersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  status?: Maybe<WorkOrderStatus>;
  searchText?: Maybe<Scalars['String']>;
  activeMonth?: Maybe<Scalars['String']>;
}>;


export type GetWorkOrdersQuery = (
  { __typename?: 'Query' }
  & { getWorkOrders?: Maybe<Array<Maybe<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, 'id' | 'description' | 'name' | 'createdAt' | 'updatedAt' | 'startDate' | 'status' | 'endDate' | 'areRecordsSearched' | 'page' | 'start' | 'end' | 'title' | 'userWorkOrderId' | 'userIds' | 'totalQuestions' | 'totalQuestionsAnswered' | 'isFinalized' | 'receivers' | 'isCancelled' | 'cancellationReason'>
    & { routines?: Maybe<Array<Maybe<(
      { __typename?: 'RoutineWithVersionAdded' }
      & { routineInfo?: Maybe<(
        { __typename?: 'RoutineAvailable' }
        & Pick<RoutineAvailable, 'id' | 'name'>
        & { routineVersions?: Maybe<(
          { __typename?: 'RoutineVersion' }
          & Pick<RoutineVersion, 'id' | 'versionNumber'>
        )> }
      )>, versionAdded?: Maybe<(
        { __typename?: 'RoutineVersion' }
        & Pick<RoutineVersion, 'id' | 'versionNumber'>
      )> }
    )>>> }
  )>>> }
);

export type QueueEmailPdfToRecipientsQueryVariables = Exact<{
  workOrderId: Scalars['ID'];
  recipients: Array<Scalars['String']> | Scalars['String'];
}>;


export type QueueEmailPdfToRecipientsQuery = (
  { __typename?: 'Query' }
  & { queueEmailPDFToRecipients: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type CancelWorkOrderMutationVariables = Exact<{
  cancellationReason: Scalars['String'];
  workOrderId: Scalars['ID'];
}>;


export type CancelWorkOrderMutation = (
  { __typename?: 'Mutation' }
  & { cancelWorkOrder: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type DeleteWorkOrderMutationVariables = Exact<{
  deleteWorkOrderId: Scalars['ID'];
}>;


export type DeleteWorkOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteWorkOrder'>
);

export type DuplicateWorkOrderMutationVariables = Exact<{
  workOrderId: Scalars['ID'];
}>;


export type DuplicateWorkOrderMutation = (
  { __typename?: 'Mutation' }
  & { duplicateWorkOrder: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type GetRoutinesAvailableQueryVariables = Exact<{
  excludeNestedChecklist?: Maybe<Scalars['Boolean']>;
}>;


export type GetRoutinesAvailableQuery = (
  { __typename?: 'Query' }
  & { getRoutinesAvailable: Array<Maybe<(
    { __typename?: 'RoutineAvailable' }
    & Pick<RoutineAvailable, 'id' | 'frequency' | 'name' | 'slug' | 'content' | 'updatedAt' | 'createdAt' | 'shareableToken' | 'isShareableLinkActive' | 'versionNumber' | 'versionId'>
  )>> }
);

export type GetRoutineAvailableQueryVariables = Exact<{
  routineAvailableId: Scalars['ID'];
}>;


export type GetRoutineAvailableQuery = (
  { __typename?: 'Query' }
  & { getRoutineAvailable?: Maybe<(
    { __typename?: 'RoutineAvailable' }
    & Pick<RoutineAvailable, 'id' | 'name' | 'slug' | 'frequency' | 'content' | 'isActive' | 'description' | 'shareableToken' | 'isShareableLinkActive'>
    & { routineVersions?: Maybe<(
      { __typename?: 'RoutineVersion' }
      & Pick<RoutineVersion, 'versionNumber'>
    )> }
  )> }
);

export type GetListItemsFromSearchInputQueryVariables = Exact<{
  searchInput: Scalars['String'];
  listShortSlugs: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type GetListItemsFromSearchInputQuery = (
  { __typename?: 'Query' }
  & { getListItemsFromSearchInput?: Maybe<Array<Maybe<(
    { __typename?: 'ListItem' }
    & Pick<ListItem, 'id' | 'name' | 'shortSlug'>
  )>>> }
);

export type SaveRoutinesAvailableMutationVariables = Exact<{
  args?: Maybe<SaveRoutinesAvailableInput>;
}>;


export type SaveRoutinesAvailableMutation = (
  { __typename?: 'Mutation' }
  & { saveRoutinesAvailable: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type GetRoutinesAvailableWithReportInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRoutinesAvailableWithReportInfoQuery = (
  { __typename?: 'Query' }
  & { getRoutinesAvailableWithReportInfo: Array<Maybe<(
    { __typename?: 'RoutineAvailable' }
    & Pick<RoutineAvailable, 'id' | 'name' | 'lastReportCompletedAt' | 'totalReports' | 'isScheduledForDeletion'>
  )>> }
);

export type GenerateShareableLinkForRoutineAvailableMutationVariables = Exact<{
  routineAvailableId: Scalars['ID'];
  responseModeIndividualChecklist?: Maybe<Scalars['Boolean']>;
}>;


export type GenerateShareableLinkForRoutineAvailableMutation = (
  { __typename?: 'Mutation' }
  & { generateShareableLinkForRoutineAvailable: (
    { __typename?: 'ShareableLinkInfo' }
    & Pick<ShareableLinkInfo, 'isSuccess'>
    & { routines: Array<Maybe<(
      { __typename?: 'RoutineAvailable' }
      & Pick<RoutineAvailable, 'id' | 'frequency' | 'name' | 'slug' | 'content' | 'updatedAt' | 'createdAt' | 'shareableToken' | 'isShareableLinkActive'>
    )>> }
  ) }
);

export type GetRoutineAvailableFromShareableTokenQueryVariables = Exact<{
  shareableToken: Scalars['String'];
}>;


export type GetRoutineAvailableFromShareableTokenQuery = (
  { __typename?: 'Query' }
  & { getRoutineAvailableFromShareableToken?: Maybe<(
    { __typename?: 'RoutineAvailable' }
    & Pick<RoutineAvailable, 'id' | 'frequency' | 'name' | 'slug' | 'description' | 'content' | 'updatedAt' | 'createdAt' | 'shareableToken' | 'isShareableLinkActive'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'name'>
    )> }
  )> }
);

export type GetListsForCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetListsForCompanyQuery = (
  { __typename?: 'Query' }
  & { getListsForCompany: Array<Maybe<(
    { __typename?: 'List' }
    & Pick<List, 'id' | 'name' | 'attributes' | 'shortSlug'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'ListItem' }
      & Pick<ListItem, 'id' | 'name'>
    )>>> }
  )>> }
);

export type GetListsForChecklistQueryVariables = Exact<{ [key: string]: never; }>;


export type GetListsForChecklistQuery = (
  { __typename?: 'Query' }
  & { getListsForCompany: Array<Maybe<(
    { __typename?: 'List' }
    & Pick<List, 'id' | 'name' | 'shortSlug'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'ListItem' }
      & Pick<ListItem, 'id' | 'name' | 'description' | 'shortSlug'>
    )>>> }
  )>> }
);

export type GetWorkOrdersByRoutineAvailableQueryVariables = Exact<{
  routineAvailableId: Scalars['ID'];
}>;


export type GetWorkOrdersByRoutineAvailableQuery = (
  { __typename?: 'Query' }
  & { getWorkOrdersByRoutineAvailable?: Maybe<Array<Maybe<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, 'name'>
  )>>> }
);

export type SaveRoutinesAnonymouslyMutationVariables = Exact<{
  args?: Maybe<SaveRoutineAnswerAnonymouslyInput>;
}>;


export type SaveRoutinesAnonymouslyMutation = (
  { __typename?: 'Mutation' }
  & { saveRoutineAnswerAnonymously: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type DuplicateChecklistMutationVariables = Exact<{
  routineAvailableId: Scalars['ID'];
}>;


export type DuplicateChecklistMutation = (
  { __typename?: 'Mutation' }
  & { duplicateChecklist: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type ScheduleRoutineDeletionMutationVariables = Exact<{
  routineAvailableId: Scalars['ID'];
  isNotDependent?: Maybe<Scalars['Boolean']>;
}>;


export type ScheduleRoutineDeletionMutation = (
  { __typename?: 'Mutation' }
  & { scheduleRoutineDeletion: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type UpdateUserDefaultLocationMutationVariables = Exact<{
  locationId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type UpdateUserDefaultLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateUserDefaultLocation: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type GetUserAvailableLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserAvailableLocationsQuery = (
  { __typename?: 'Query' }
  & { getUserAvailableLocations: Array<Maybe<(
    { __typename?: 'LocationDetails' }
    & Pick<LocationDetails, 'id' | 'name' | 'slug'>
  )>> }
);

export type GetAnsweredRoutinesQueryVariables = Exact<{
  routinesAvailableId: Scalars['ID'];
  pageNumber?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAnsweredRoutinesQuery = (
  { __typename?: 'Query' }
  & { getAnsweredRoutines: (
    { __typename?: 'AnsweredRoutinesSchema' }
    & { routines?: Maybe<Array<Maybe<(
      { __typename?: 'Routine' }
      & Pick<Routine, 'answers' | 'id' | 'createdAt' | 'completedAt'>
      & { routineVersion?: Maybe<(
        { __typename?: 'RoutineVersion' }
        & Pick<RoutineVersion, 'versionNumber'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name' | 'email'>
      )>, routineAvailable?: Maybe<(
        { __typename?: 'RoutineAvailable' }
        & Pick<RoutineAvailable, 'name'>
        & { company?: Maybe<(
          { __typename?: 'Company' }
          & Pick<Company, 'name'>
        )> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'totalPages' | 'currentPage'>
    ) }
  ) }
);

export type ListWorkOrderReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListWorkOrderReportsQuery = (
  { __typename?: 'Query' }
  & { listWorkOrderReports: Array<Maybe<(
    { __typename?: 'WorkOrderAnswer' }
    & Pick<WorkOrderAnswer, 'id' | 'createdAt' | 'updatedAt'>
    & { workOrder?: Maybe<(
      { __typename?: 'WorkOrder' }
      & Pick<WorkOrder, 'name'>
    )> }
  )>> }
);

export type CreateSignedS3UrlForGkpiUploadMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateSignedS3UrlForGkpiUploadMutation = (
  { __typename?: 'Mutation' }
  & { createSignedS3UrlForGKPIUpload: (
    { __typename?: 'S3SignedURL' }
    & Pick<S3SignedUrl, 'preSignedUrl' | 'fileName'>
  ) }
);

export type StartProcessingGkpiFileMutationVariables = Exact<{
  fileName: Scalars['String'];
}>;


export type StartProcessingGkpiFileMutation = (
  { __typename?: 'Mutation' }
  & { startProcessingGKPIFile: (
    { __typename?: 'GKPIExcelProcessingStatus' }
    & Pick<GkpiExcelProcessingStatus, 'status' | 'message'>
  ) }
);

export type GetgkpiDataQueryVariables = Exact<{
  country?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
}>;


export type GetgkpiDataQuery = (
  { __typename?: 'Query' }
  & { getGKPIData: Array<Maybe<(
    { __typename?: 'GKPIDataResponse' }
    & Pick<GkpiDataResponse, 'GKPI' | 'data'>
  )>> }
);

export type GetCountryAndLocationListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountryAndLocationListQuery = (
  { __typename?: 'Query' }
  & { getCountryAndLocationList?: Maybe<Array<Maybe<(
    { __typename?: 'CountryAndLocationList' }
    & Pick<CountryAndLocationList, 'country' | 'locations'>
  )>>> }
);

export type UserHealthStatusUnAuthenticatedQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type UserHealthStatusUnAuthenticatedQuery = (
  { __typename?: 'Query' }
  & { userHealthStatusUnAuthenticated: (
    { __typename?: 'UserHealthStatus' }
    & Pick<UserHealthStatus, 'quarantineUntil' | 'quarantineStart'>
    & { healthStatus?: Maybe<(
      { __typename?: 'HealthStatus' }
      & Pick<HealthStatus, 'location' | 'status' | 'createdAt'>
    )>, usersLocationMedicalFacility?: Maybe<Array<Maybe<(
      { __typename?: 'UsersLocationMedicalFacility' }
      & Pick<UsersLocationMedicalFacility, 'emergencyContact'>
    )>>> }
  ) }
);

export type UpdateUserHealthStatusUnAuthenticatedMutationVariables = Exact<{
  status: Scalars['String'];
  location: Scalars['String'];
  slug: Scalars['String'];
}>;


export type UpdateUserHealthStatusUnAuthenticatedMutation = (
  { __typename?: 'Mutation' }
  & { addUserHealthStatusUnAuthenticated: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type GetSkapModulesAndSubmodulesCountQueryVariables = Exact<{
  filter: GetModuleSubmoduleCountFilterParams;
}>;


export type GetSkapModulesAndSubmodulesCountQuery = (
  { __typename?: 'Query' }
  & { getSKAPModulesAndSubmodulesCount: (
    { __typename?: 'GetModulesAndSubmodulesCountResponse' }
    & { countByLevels: Array<Maybe<(
      { __typename?: 'CountByLevels' }
      & Pick<CountByLevels, 'slug' | 'count' | 'percentage' | 'total'>
    )>>, countByAutonomousLevel: Array<Maybe<(
      { __typename?: 'CountByAutonomousLevel' }
      & Pick<CountByAutonomousLevel, 'title' | 'count'>
    )>>, countByPosition: Array<Maybe<(
      { __typename?: 'CountByPosition' }
      & Pick<CountByPosition, 'slug' | 'total' | 'impactedCount'>
      & { data: Array<Maybe<(
        { __typename?: 'ModulePercentage' }
        & Pick<ModulePercentage, 'title' | 'percentage'>
      )>> }
    )>>, countByMonth: Array<Maybe<(
      { __typename?: 'MonthWiseScore' }
      & Pick<MonthWiseScore, 'month' | 'score'>
    )>> }
  ) }
);

export type SendSkap_01NotificationMutationVariables = Exact<{ [key: string]: never; }>;


export type SendSkap_01NotificationMutation = (
  { __typename?: 'Mutation' }
  & { sendNotificationToLineManager: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ), sendNotificationToFrontLiners: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type KpIsListQueryVariables = Exact<{
  isGlobal?: Maybe<Scalars['Boolean']>;
}>;


export type KpIsListQuery = (
  { __typename?: 'Query' }
  & { getAllKPI: Array<Maybe<(
    { __typename?: 'KPI' }
    & Pick<Kpi, 'id' | 'slug' | 'enTitle' | 'esTitle'>
  )>> }
);

export type GetRoutineAvailableInfoQueryVariables = Exact<{
  forFilter?: Maybe<Scalars['Boolean']>;
}>;


export type GetRoutineAvailableInfoQuery = (
  { __typename?: 'Query' }
  & { getRoutinesAvailable: Array<Maybe<(
    { __typename?: 'RoutineAvailable' }
    & Pick<RoutineAvailable, 'name' | 'slug'>
  )>> }
);

export type GetAllManagementToolQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllManagementToolQuery = (
  { __typename?: 'Query' }
  & { getAllManagementTool: Array<Maybe<(
    { __typename?: 'ManagementTool' }
    & Pick<ManagementTool, 'id' | 'slug' | 'enTitle' | 'esTitle'>
  )>> }
);

export type SearchedUsersWithAccessToLocationQueryVariables = Exact<{
  searchString: Scalars['String'];
  locationSlug: Scalars['String'];
}>;


export type SearchedUsersWithAccessToLocationQuery = (
  { __typename?: 'Query' }
  & { searchUsersWithAccessToLocation?: Maybe<Array<Maybe<(
    { __typename?: 'BaseUser' }
    & Pick<BaseUser, 'name' | 'slug' | 'id' | 'picture'>
  )>>> }
);

export type ActionLogsCountGroupByLocationQueryQueryVariables = Exact<{
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
}>;


export type ActionLogsCountGroupByLocationQueryQuery = (
  { __typename?: 'Query' }
  & { actionLogsCountGroupByLocation: Array<Maybe<(
    { __typename?: 'BusinessUnitWithActionLogCount' }
    & Pick<BusinessUnitWithActionLogCount, 'name' | 'slug' | 'actionLogsCount'>
    & { zones?: Maybe<Array<Maybe<(
      { __typename?: 'ZoneWithActionLogCount' }
      & Pick<ZoneWithActionLogCount, 'name' | 'slug' | 'actionLogsCount'>
      & { subzones?: Maybe<Array<Maybe<(
        { __typename?: 'SubzoneWithActionLogCount' }
        & Pick<SubzoneWithActionLogCount, 'name' | 'slug' | 'actionLogsCount'>
        & { locations?: Maybe<Array<Maybe<(
          { __typename?: 'LocationWithActionLogCount' }
          & Pick<LocationWithActionLogCount, 'name' | 'slug' | 'actionLogsCount'>
          & { cluster?: Maybe<(
            { __typename?: 'Cluster' }
            & Pick<Cluster, 'name' | 'slug'>
          )> }
        )>>> }
      )>>> }
    )>>> }
  )>> }
);

export type ActionLogsInDcByStatusFragment = (
  { __typename?: 'CreateEditActionLog' }
  & Pick<CreateEditActionLog, 'id' | 'numberOfComments' | 'description' | 'endDate' | 'status' | 'pillar' | 'originText' | 'origin'>
  & { owner: (
    { __typename?: 'BaseUser' }
    & Pick<BaseUser, 'picture' | 'id' | 'name' | 'slug'>
  ), responsibles: Array<(
    { __typename?: 'BaseUser' }
    & Pick<BaseUser, 'id' | 'picture' | 'name' | 'slug'>
  )>, images: Array<Maybe<(
    { __typename?: 'ImageInputItemType' }
    & Pick<ImageInputItemType, 'id' | 'url'>
  )>>, routinesAvailable?: Maybe<(
    { __typename?: 'routinesAvailableNameAndSlug' }
    & Pick<RoutinesAvailableNameAndSlug, 'name' | 'slug'>
  )> }
);

export type AllActionLogForLocationQueryVariables = Exact<{
  actionLogsLocationSlug?: Maybe<Scalars['String']>;
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
  filters?: Maybe<ActionLogFilters>;
}>;


export type AllActionLogForLocationQuery = (
  { __typename?: 'Query' }
  & { groupActionLogsInDCByStatus: (
    { __typename?: 'ActionLogsInDCByStatus' }
    & { OPEN: Array<Maybe<(
      { __typename?: 'CreateEditActionLog' }
      & ActionLogsInDcByStatusFragment
    )>>, IN_PROGRESS: Array<Maybe<(
      { __typename?: 'CreateEditActionLog' }
      & ActionLogsInDcByStatusFragment
    )>>, FOR_REVISION: Array<Maybe<(
      { __typename?: 'CreateEditActionLog' }
      & ActionLogsInDcByStatusFragment
    )>>, COMPLETED: Array<Maybe<(
      { __typename?: 'CreateEditActionLog' }
      & ActionLogsInDcByStatusFragment
    )>> }
  ) }
);

export type AllCommentsForActionLogQueryVariables = Exact<{
  commentsActionLogId: Scalars['ID'];
}>;


export type AllCommentsForActionLogQuery = (
  { __typename?: 'Query' }
  & { comments: Array<Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'comment'>
  )>> }
);

export type AddOrEditActionLogCommentMutationVariables = Exact<{
  actionLogId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['ID']>;
}>;


export type AddOrEditActionLogCommentMutation = (
  { __typename?: 'Mutation' }
  & { addOrEditComment: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type DetailedCommentsForActionLogQueryVariables = Exact<{
  commentsActionLogId: Scalars['ID'];
}>;


export type DetailedCommentsForActionLogQuery = (
  { __typename?: 'Query' }
  & { comments: Array<Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'comment' | 'createdAt' | 'updatedAt'>
    & { user: (
      { __typename?: 'BaseUser' }
      & Pick<BaseUser, 'name' | 'slug' | 'picture'>
    ) }
  )>> }
);

export type SummaryForActionLogQueryVariables = Exact<{
  actionLogId: Scalars['ID'];
}>;


export type SummaryForActionLogQuery = (
  { __typename?: 'Query' }
  & { actionLog?: Maybe<(
    { __typename?: 'CreateEditActionLog' }
    & Pick<CreateEditActionLog, 'id' | 'startDate' | 'description' | 'successCriteria' | 'KPIs' | 'endDate' | 'status' | 'pillar' | 'origin' | 'originText' | 'meeting' | 'note' | 'statusUpdateDate'>
    & { KPI: (
      { __typename?: 'KPI' }
      & Pick<Kpi, 'esTitle' | 'enTitle' | 'slug'>
    ), ManagementTool?: Maybe<(
      { __typename?: 'ManagementTool' }
      & Pick<ManagementTool, 'esTitle' | 'enTitle' | 'slug' | 'id'>
    )>, owner: (
      { __typename?: 'BaseUser' }
      & Pick<BaseUser, 'id' | 'name' | 'slug' | 'picture'>
    ), responsibles: Array<(
      { __typename?: 'BaseUser' }
      & Pick<BaseUser, 'id' | 'name' | 'slug' | 'picture'>
    )>, location: (
      { __typename?: 'BaseLocation' }
      & Pick<BaseLocation, 'slug' | 'name'>
    ), images: Array<Maybe<(
      { __typename?: 'ImageInputItemType' }
      & Pick<ImageInputItemType, 'id' | 'url'>
    )>>, routinesAvailable?: Maybe<(
      { __typename?: 'routinesAvailableNameAndSlug' }
      & Pick<RoutinesAvailableNameAndSlug, 'name' | 'slug'>
    )> }
  )> }
);

export type DeleteActionLogCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
}>;


export type DeleteActionLogCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteComment: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type ActionLogStatusMutationVariables = Exact<{
  actionLogId: Scalars['Int'];
  status: Action_Log_Status;
  isOwnerCheckSurpassed?: Maybe<Scalars['Boolean']>;
}>;


export type ActionLogStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateActionLogStatus: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type CreateEditActionLogMutationVariables = Exact<{
  payload: CreateEditActionLogPayload;
}>;


export type CreateEditActionLogMutation = (
  { __typename?: 'Mutation' }
  & { createEditActionLog: (
    { __typename?: 'CreateEditActionLogResponse' }
    & Pick<CreateEditActionLogResponse, 'isSuccess'>
  ) }
);

export type DeleteActionLogMutationVariables = Exact<{
  actionLogId: Scalars['Int'];
}>;


export type DeleteActionLogMutation = (
  { __typename?: 'Mutation' }
  & { deleteActionLog: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type CreateKpiMutationVariables = Exact<{
  kpi: Scalars['String'];
}>;


export type CreateKpiMutation = (
  { __typename?: 'Mutation' }
  & { createKPI: Array<Maybe<(
    { __typename?: 'KPI' }
    & Pick<Kpi, 'id' | 'slug' | 'enTitle' | 'esTitle'>
  )>> }
);

export type GetDashboardTableDataQueryVariables = Exact<{
  locationIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  groupBy?: Maybe<Scalars['JSON']>;
  date?: Maybe<Scalars['JSON']>;
}>;


export type GetDashboardTableDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDashboardTableData'>
);

export type AddOrEditCapexCommentMutationVariables = Exact<{
  capexId: Scalars['ID'];
  comment: Scalars['String'];
  commentId?: Maybe<Scalars['ID']>;
}>;


export type AddOrEditCapexCommentMutation = (
  { __typename?: 'Mutation' }
  & { addOrEditCapexComment: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type DeleteCapexCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
}>;


export type DeleteCapexCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteCapexComment: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type CapexCommentsQueryVariables = Exact<{
  capexId: Scalars['ID'];
}>;


export type CapexCommentsQuery = (
  { __typename?: 'Query' }
  & { capexComments: Array<Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'comment' | 'createdAt' | 'updatedAt'>
    & { user: (
      { __typename?: 'BaseUser' }
      & Pick<BaseUser, 'name' | 'slug' | 'picture'>
    ) }
  )>> }
);

export type GetIncidentsByMonthsCategoriesAndTypeQueryVariables = Exact<{
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
}>;


export type GetIncidentsByMonthsCategoriesAndTypeQuery = (
  { __typename?: 'Query' }
  & { getIncidentsBetweenRange: Array<Maybe<(
    { __typename?: 'IncidentBetweenRange' }
    & Pick<IncidentBetweenRange, 'bu' | 'month'>
    & { weeks: Array<Maybe<(
      { __typename?: 'weekContent' }
      & Pick<WeekContent, 'week' | 'totalIncidents'>
    )>> }
  )>>, getIncidentsByCategories: Array<Maybe<(
    { __typename?: 'IncidentByCategory' }
    & Pick<IncidentByCategory, 'categoryName'>
    & { bus: Array<Maybe<(
      { __typename?: 'buContent' }
      & Pick<BuContent, 'name' | 'totalIncidents'>
    )>> }
  )>>, getIncidentsByMonths: Array<Maybe<(
    { __typename?: 'IncidentByMonth' }
    & Pick<IncidentByMonth, 'bu'>
    & { months: Array<Maybe<(
      { __typename?: 'buContent' }
      & Pick<BuContent, 'name' | 'totalIncidents'>
    )>> }
  )>>, topBest: Array<Maybe<(
    { __typename?: 'IncidentByType' }
    & Pick<IncidentByType, 'bu' | 'name' | 'totalIncidents'>
  )>>, topOffenders: Array<Maybe<(
    { __typename?: 'IncidentByType' }
    & Pick<IncidentByType, 'bu' | 'name' | 'totalIncidents'>
  )>>, topVehicleOffenders: Array<Maybe<(
    { __typename?: 'IncidentByType' }
    & Pick<IncidentByType, 'bu' | 'name' | 'totalIncidents'>
  )>> }
);

export type GetIncidentsByTypePerBuQueryVariables = Exact<{
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
  bu?: Maybe<Scalars['String']>;
}>;


export type GetIncidentsByTypePerBuQuery = (
  { __typename?: 'Query' }
  & { topBest: Array<Maybe<(
    { __typename?: 'IncidentByType' }
    & Pick<IncidentByType, 'bu' | 'name' | 'totalIncidents'>
  )>>, topOffenders: Array<Maybe<(
    { __typename?: 'IncidentByType' }
    & Pick<IncidentByType, 'bu' | 'name' | 'totalIncidents'>
  )>>, topVehicleOffenders: Array<Maybe<(
    { __typename?: 'IncidentByType' }
    & Pick<IncidentByType, 'bu' | 'name' | 'totalIncidents'>
  )>> }
);

export type GetTrainingsQueryVariables = Exact<{
  data: GetTrainingsPayload;
  by: GetTrainingsBy;
  isFindOne?: Maybe<Scalars['Boolean']>;
}>;


export type GetTrainingsQuery = (
  { __typename?: 'Query' }
  & { getTrainings: Array<Maybe<(
    { __typename?: 'BuData' }
    & Pick<BuData, 'usersToTrain' | 'usersTrained' | 'name' | 'percentage'>
  )>> }
);

export type GetTrainingsByPositionQueryVariables = Exact<{
  data: GetTrainingsPayload;
  by: GetTrainingsBy;
  isFindOne?: Maybe<Scalars['Boolean']>;
}>;


export type GetTrainingsByPositionQuery = (
  { __typename?: 'Query' }
  & { getTrainings: Array<Maybe<(
    { __typename?: 'BuData' }
    & Pick<BuData, 'usersToTrain' | 'usersTrained' | 'position' | 'percentage'>
  )>> }
);

export type GetSifStatsQueryVariables = Exact<{
  filter: FilterParams;
  by: GetSifStatsBy;
  year: Scalars['Int'];
}>;


export type GetSifStatsQuery = (
  { __typename?: 'Query' }
  & { getSifStats: Array<Maybe<(
    { __typename?: 'SifStats' }
    & Pick<SifStats, 'name' | 'totalSif'>
  )>> }
);

export type GetSifStatsByTypeQueryVariables = Exact<{
  filter?: Maybe<FilterParamsByType>;
  year: Scalars['Int'];
}>;


export type GetSifStatsByTypeQuery = (
  { __typename?: 'Query' }
  & { getSifStatsByType: Array<Maybe<(
    { __typename?: 'SifStatsByTypeResponse' }
    & Pick<SifStatsByTypeResponse, 'month'>
    & { sifType: Array<Maybe<(
      { __typename?: 'SifStats' }
      & Pick<SifStats, 'name' | 'totalSif'>
    )>> }
  )>> }
);

export type ListAllSifStatsByMonthQueryVariables = Exact<{
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
  filter: FilterParams;
}>;


export type ListAllSifStatsByMonthQuery = (
  { __typename?: 'Query' }
  & { listAllSifStatsByMonth: Array<Maybe<(
    { __typename?: 'ListSifStatsResponse' }
    & Pick<ListSifStatsResponse, 'reference' | 'classificationOfInjury' | 'location' | 'incidentCause' | 'nature' | 'department' | 'incidentDate' | 'shiftType' | 'incidentTime'>
  )>> }
);

export type GetSifStatsByDurationQueryVariables = Exact<{
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
  filter: FilterParams;
}>;


export type GetSifStatsByDurationQuery = (
  { __typename?: 'Query' }
  & { getSifStatsByDuration: Array<Maybe<(
    { __typename?: 'SifStatsDuration' }
    & Pick<SifStatsDuration, 'duration' | 'totalSif'>
  )>> }
);

export type GetNoteQueryVariables = Exact<{
  bu: Scalars['String'];
}>;


export type GetNoteQuery = (
  { __typename?: 'Query' }
  & { getNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'bu' | 'content' | 'updatedAt' | 'createdAt'>
    & { user?: Maybe<(
      { __typename?: 'UserNote' }
      & Pick<UserNote, 'id' | 'name' | 'email'>
    )> }
  )> }
);

export type CreateNoteMutationVariables = Exact<{
  input: NoteInput;
}>;


export type CreateNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createNote'>
);

export type GetCovidUsersForLocationsQueryVariables = Exact<{
  locationsToAccess: Array<Scalars['ID']> | Scalars['ID'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GetCovidUsersForLocationsQuery = (
  { __typename?: 'Query' }
  & { getCovidUsersForLocations: (
    { __typename?: 'CovidUsersForLocationCount' }
    & Pick<CovidUsersForLocationCount, 'count'>
    & { covidUsersForLocation?: Maybe<Array<Maybe<(
      { __typename?: 'CovidUsersForLocation' }
      & Pick<CovidUsersForLocation, 'name' | 'employeeNumber' | 'quarantineUntil' | 'doctorStatus'>
      & { location?: Maybe<(
        { __typename?: 'LocationName' }
        & Pick<LocationName, 'name'>
      )>, latestCovidTest?: Maybe<(
        { __typename?: 'LatestCovidTest' }
        & Pick<LatestCovidTest, 'type'>
      )> }
    )>>> }
  ) }
);

export type LastFileUploadDateQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type LastFileUploadDateQueryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'lastFileUploadDate'>
);

export type GetAllLocationsQueryVariables = Exact<{
  type?: Maybe<Location_Type>;
}>;


export type GetAllLocationsQuery = (
  { __typename?: 'Query' }
  & { getLocations: Array<Maybe<(
    { __typename?: 'LocationDetails' }
    & Pick<LocationDetails, 'id' | 'name' | 'slug'>
  )>> }
);

export type GetUserInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserInfoQuery = (
  { __typename?: 'Query' }
  & { getUserInfo?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'slug' | 'email' | 'type' | 'position' | 'language' | 'isBlocked' | 'shortSlug' | 'picture' | 'pin' | 'blockedReason' | 'lastActiveAt' | 'createdAt' | 'employeeNumber' | 'doctorStatus'>
    & { info?: Maybe<(
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'isAuthorisedForOWD' | 'id' | 'userId'>
    )>, distributionCenter?: Maybe<(
      { __typename?: 'LocationDetails' }
      & Pick<LocationDetails, 'id' | 'name' | 'bu' | 'type'>
    )> }
  )> }
);

export type GetUserWorkLocationsQueryVariables = Exact<{
  userId: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type GetUserWorkLocationsQuery = (
  { __typename?: 'Query' }
  & { getUserWorkLocations: Array<Maybe<(
    { __typename?: 'userWorkLocations' }
    & Pick<UserWorkLocations, 'id' | 'userId'>
    & { workLocation?: Maybe<(
      { __typename?: 'workLocation' }
      & Pick<WorkLocation, 'id' | 'name' | 'nameEN' | 'slug'>
    )>, subLocation?: Maybe<(
      { __typename?: 'area' }
      & Pick<Area, 'id' | 'name' | 'nameEN' | 'slug'>
    )>, subArea?: Maybe<(
      { __typename?: 'subArea' }
      & Pick<SubArea, 'id' | 'name' | 'nameEn' | 'slug'>
    )> }
  )>> }
);

export type GetAccessLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccessLocationsQuery = (
  { __typename?: 'Query' }
  & { getAccessLocations: Array<Maybe<(
    { __typename?: 'LocationDetails' }
    & Pick<LocationDetails, 'id' | 'name'>
  )>> }
);

export type UpdateUserInfoMutationVariables = Exact<{
  id: Scalars['ID'];
  general?: Maybe<UsersGeneralInfoPayload>;
  permission?: Maybe<PermissionInfoPayload>;
  roleId?: Maybe<Scalars['ID']>;
  userInfo?: Maybe<UserInfoPayload>;
}>;


export type UpdateUserInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateUserInfo: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type UpdateUserAccessLocationMutationVariables = Exact<{
  locationIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
}>;


export type UpdateUserAccessLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateUserAccessLocation: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type GetAllAccessLocationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAllAccessLocationsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllAccessLocations'>
);

export type GetAllLocationsUserPositionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllLocationsUserPositionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllLocationsUserPositions'>
);

export type GetUserPositionsByBuQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserPositionsByBuQuery = (
  { __typename?: 'Query' }
  & { getUserPositionsByBu: Array<Maybe<(
    { __typename?: 'UserPositionsByBU' }
    & Pick<UserPositionsByBu, 'position'>
    & { bu: (
      { __typename?: 'BusinessUnit' }
      & Pick<BusinessUnit, 'id' | 'name'>
    ) }
  )>> }
);

export type UpdateUserTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  type: Espectro_User_Types;
}>;


export type UpdateUserTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateUserType: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type CreateEspectroUserMutationVariables = Exact<{
  email: Scalars['String'];
  type: Espectro_User_Types;
  userExists?: Maybe<Scalars['Boolean']>;
}>;


export type CreateEspectroUserMutation = (
  { __typename?: 'Mutation' }
  & { createEspectroUser: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type ReinviteEspectroUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type ReinviteEspectroUserMutation = (
  { __typename?: 'Mutation' }
  & { reinviteEspectroUser: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type GetAllOwdChecklistsQueryVariables = Exact<{
  locationSlug: Scalars['String'];
  source: ClientType;
  pillar?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetAllOwdChecklistsQuery = (
  { __typename?: 'Query' }
  & { getAllOWDChecklists: Array<Maybe<(
    { __typename?: 'GetAllOWDChecklists' }
    & Pick<GetAllOwdChecklists, 'id' | 'content' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type GetPostsQueryVariables = Exact<{
  limit: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  type?: Maybe<Posts_Types>;
  attachmentType?: Maybe<Array<Maybe<Attachment_Types>> | Maybe<Attachment_Types>>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type GetPostsQuery = (
  { __typename?: 'Query' }
  & { getPosts?: Maybe<(
    { __typename?: 'GetPostResponse' }
    & Pick<GetPostResponse, 'hasMore'>
    & { posts: Array<Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'title' | 'content' | 'type' | 'bannerImage' | 'slug' | 'postedAt' | 'author'>
      & { attachments?: Maybe<Array<Maybe<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'url' | 'type'>
      )>>> }
    )>> }
  )> }
);

export type LocationDetailsQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type LocationDetailsQuery = (
  { __typename?: 'Query' }
  & { locationDetails: (
    { __typename?: 'LocationDetails' }
    & Pick<LocationDetails, 'type'>
  ) }
);

export const ActionLogsInDcByStatusFragmentDoc = gql`
    fragment actionLogsInDCByStatus on CreateEditActionLog {
  id
  numberOfComments
  description
  endDate
  status
  pillar
  owner {
    picture
    id
    name
    slug
  }
  originText
  origin
  responsibles {
    id
    picture
    name
    slug
  }
  images {
    id
    url
  }
  routinesAvailable {
    name
    slug
  }
}
    `;
export const CreateListItemDocument = gql`
    mutation CreateListItem($listId: ID!, $name: String!, $uniqueSlug: String!, $additionalAttributes: JSON!, $imageUrl: String) {
  createListItem(
    listId: $listId
    name: $name
    uniqueSlug: $uniqueSlug
    additionalAttributes: $additionalAttributes
    imageUrl: $imageUrl
  ) {
    isSuccess
  }
}
    `;
export type CreateListItemMutationFn = Apollo.MutationFunction<CreateListItemMutation, CreateListItemMutationVariables>;

/**
 * __useCreateListItemMutation__
 *
 * To run a mutation, you first call `useCreateListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListItemMutation, { data, loading, error }] = useCreateListItemMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      name: // value for 'name'
 *      uniqueSlug: // value for 'uniqueSlug'
 *      additionalAttributes: // value for 'additionalAttributes'
 *      imageUrl: // value for 'imageUrl'
 *   },
 * });
 */
export function useCreateListItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateListItemMutation, CreateListItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateListItemMutation, CreateListItemMutationVariables>(CreateListItemDocument, options);
      }
export type CreateListItemMutationHookResult = ReturnType<typeof useCreateListItemMutation>;
export type CreateListItemMutationResult = Apollo.MutationResult<CreateListItemMutation>;
export type CreateListItemMutationOptions = Apollo.BaseMutationOptions<CreateListItemMutation, CreateListItemMutationVariables>;
export const IsListItemUniqueNameAvailableDocument = gql`
    query IsListItemUniqueNameAvailable($uniqueName: String!) {
  isListItemUniqueNameAvailable(uniqueName: $uniqueName) {
    isAvailable
  }
}
    `;

/**
 * __useIsListItemUniqueNameAvailableQuery__
 *
 * To run a query within a React component, call `useIsListItemUniqueNameAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsListItemUniqueNameAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsListItemUniqueNameAvailableQuery({
 *   variables: {
 *      uniqueName: // value for 'uniqueName'
 *   },
 * });
 */
export function useIsListItemUniqueNameAvailableQuery(baseOptions: Apollo.QueryHookOptions<IsListItemUniqueNameAvailableQuery, IsListItemUniqueNameAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsListItemUniqueNameAvailableQuery, IsListItemUniqueNameAvailableQueryVariables>(IsListItemUniqueNameAvailableDocument, options);
      }
export function useIsListItemUniqueNameAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsListItemUniqueNameAvailableQuery, IsListItemUniqueNameAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsListItemUniqueNameAvailableQuery, IsListItemUniqueNameAvailableQueryVariables>(IsListItemUniqueNameAvailableDocument, options);
        }
export type IsListItemUniqueNameAvailableQueryHookResult = ReturnType<typeof useIsListItemUniqueNameAvailableQuery>;
export type IsListItemUniqueNameAvailableLazyQueryHookResult = ReturnType<typeof useIsListItemUniqueNameAvailableLazyQuery>;
export type IsListItemUniqueNameAvailableQueryResult = Apollo.QueryResult<IsListItemUniqueNameAvailableQuery, IsListItemUniqueNameAvailableQueryVariables>;
export const UpdateListItemDocument = gql`
    mutation updateListItem($listId: ID!, $name: String!, $uniqueSlug: String!, $additionalAttributes: JSON!, $imageUrl: String, $listItemId: ID!) {
  updateListItem(
    listId: $listId
    name: $name
    uniqueSlug: $uniqueSlug
    additionalAttributes: $additionalAttributes
    imageUrl: $imageUrl
    listItemId: $listItemId
  ) {
    isSuccess
  }
}
    `;
export type UpdateListItemMutationFn = Apollo.MutationFunction<UpdateListItemMutation, UpdateListItemMutationVariables>;

/**
 * __useUpdateListItemMutation__
 *
 * To run a mutation, you first call `useUpdateListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListItemMutation, { data, loading, error }] = useUpdateListItemMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      name: // value for 'name'
 *      uniqueSlug: // value for 'uniqueSlug'
 *      additionalAttributes: // value for 'additionalAttributes'
 *      imageUrl: // value for 'imageUrl'
 *      listItemId: // value for 'listItemId'
 *   },
 * });
 */
export function useUpdateListItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateListItemMutation, UpdateListItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateListItemMutation, UpdateListItemMutationVariables>(UpdateListItemDocument, options);
      }
export type UpdateListItemMutationHookResult = ReturnType<typeof useUpdateListItemMutation>;
export type UpdateListItemMutationResult = Apollo.MutationResult<UpdateListItemMutation>;
export type UpdateListItemMutationOptions = Apollo.BaseMutationOptions<UpdateListItemMutation, UpdateListItemMutationVariables>;
export const GetListItemForCompanyDocument = gql`
    query GetListItemForCompany {
  getListItemsOfCompany {
    id
    name
    shortSlug
    description
    slug
    list {
      id
      name
      slug
    }
  }
}
    `;

/**
 * __useGetListItemForCompanyQuery__
 *
 * To run a query within a React component, call `useGetListItemForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListItemForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListItemForCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetListItemForCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetListItemForCompanyQuery, GetListItemForCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListItemForCompanyQuery, GetListItemForCompanyQueryVariables>(GetListItemForCompanyDocument, options);
      }
export function useGetListItemForCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListItemForCompanyQuery, GetListItemForCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListItemForCompanyQuery, GetListItemForCompanyQueryVariables>(GetListItemForCompanyDocument, options);
        }
export type GetListItemForCompanyQueryHookResult = ReturnType<typeof useGetListItemForCompanyQuery>;
export type GetListItemForCompanyLazyQueryHookResult = ReturnType<typeof useGetListItemForCompanyLazyQuery>;
export type GetListItemForCompanyQueryResult = Apollo.QueryResult<GetListItemForCompanyQuery, GetListItemForCompanyQueryVariables>;
export const GetListItemForCompanyByIdDocument = gql`
    query GetListItemForCompanyById($listItemId: ID!) {
  getListItemDetails(listItemId: $listItemId) {
    id
    name
    shortSlug
    imageUrl
    description
    attributes
    slug
    list {
      id
      name
      slug
    }
  }
}
    `;

/**
 * __useGetListItemForCompanyByIdQuery__
 *
 * To run a query within a React component, call `useGetListItemForCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListItemForCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListItemForCompanyByIdQuery({
 *   variables: {
 *      listItemId: // value for 'listItemId'
 *   },
 * });
 */
export function useGetListItemForCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<GetListItemForCompanyByIdQuery, GetListItemForCompanyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListItemForCompanyByIdQuery, GetListItemForCompanyByIdQueryVariables>(GetListItemForCompanyByIdDocument, options);
      }
export function useGetListItemForCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListItemForCompanyByIdQuery, GetListItemForCompanyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListItemForCompanyByIdQuery, GetListItemForCompanyByIdQueryVariables>(GetListItemForCompanyByIdDocument, options);
        }
export type GetListItemForCompanyByIdQueryHookResult = ReturnType<typeof useGetListItemForCompanyByIdQuery>;
export type GetListItemForCompanyByIdLazyQueryHookResult = ReturnType<typeof useGetListItemForCompanyByIdLazyQuery>;
export type GetListItemForCompanyByIdQueryResult = Apollo.QueryResult<GetListItemForCompanyByIdQuery, GetListItemForCompanyByIdQueryVariables>;
export const CreateNewListDocument = gql`
    mutation CreateNewList($name: String!, $attributes: JSON!) {
  createNewList(name: $name, attributes: $attributes) {
    isSuccess
  }
}
    `;
export type CreateNewListMutationFn = Apollo.MutationFunction<CreateNewListMutation, CreateNewListMutationVariables>;

/**
 * __useCreateNewListMutation__
 *
 * To run a mutation, you first call `useCreateNewListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewListMutation, { data, loading, error }] = useCreateNewListMutation({
 *   variables: {
 *      name: // value for 'name'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateNewListMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewListMutation, CreateNewListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewListMutation, CreateNewListMutationVariables>(CreateNewListDocument, options);
      }
export type CreateNewListMutationHookResult = ReturnType<typeof useCreateNewListMutation>;
export type CreateNewListMutationResult = Apollo.MutationResult<CreateNewListMutation>;
export type CreateNewListMutationOptions = Apollo.BaseMutationOptions<CreateNewListMutation, CreateNewListMutationVariables>;
export const DeleteListItemDocument = gql`
    mutation DeleteListItem($listItemId: ID!) {
  deleteListItem(listItemId: $listItemId) {
    isSuccess
  }
}
    `;
export type DeleteListItemMutationFn = Apollo.MutationFunction<DeleteListItemMutation, DeleteListItemMutationVariables>;

/**
 * __useDeleteListItemMutation__
 *
 * To run a mutation, you first call `useDeleteListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteListItemMutation, { data, loading, error }] = useDeleteListItemMutation({
 *   variables: {
 *      listItemId: // value for 'listItemId'
 *   },
 * });
 */
export function useDeleteListItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteListItemMutation, DeleteListItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteListItemMutation, DeleteListItemMutationVariables>(DeleteListItemDocument, options);
      }
export type DeleteListItemMutationHookResult = ReturnType<typeof useDeleteListItemMutation>;
export type DeleteListItemMutationResult = Apollo.MutationResult<DeleteListItemMutation>;
export type DeleteListItemMutationOptions = Apollo.BaseMutationOptions<DeleteListItemMutation, DeleteListItemMutationVariables>;
export const GetListItemsFromSearchWithoutSlugDocument = gql`
    query GetListItemsFromSearchWithoutSlug($searchString: String!) {
  getListItemsFromSearchWithoutSlug(searchString: $searchString) {
    id
    name
    description
    list {
      id
      name
    }
  }
}
    `;

/**
 * __useGetListItemsFromSearchWithoutSlugQuery__
 *
 * To run a query within a React component, call `useGetListItemsFromSearchWithoutSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListItemsFromSearchWithoutSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListItemsFromSearchWithoutSlugQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useGetListItemsFromSearchWithoutSlugQuery(baseOptions: Apollo.QueryHookOptions<GetListItemsFromSearchWithoutSlugQuery, GetListItemsFromSearchWithoutSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListItemsFromSearchWithoutSlugQuery, GetListItemsFromSearchWithoutSlugQueryVariables>(GetListItemsFromSearchWithoutSlugDocument, options);
      }
export function useGetListItemsFromSearchWithoutSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListItemsFromSearchWithoutSlugQuery, GetListItemsFromSearchWithoutSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListItemsFromSearchWithoutSlugQuery, GetListItemsFromSearchWithoutSlugQueryVariables>(GetListItemsFromSearchWithoutSlugDocument, options);
        }
export type GetListItemsFromSearchWithoutSlugQueryHookResult = ReturnType<typeof useGetListItemsFromSearchWithoutSlugQuery>;
export type GetListItemsFromSearchWithoutSlugLazyQueryHookResult = ReturnType<typeof useGetListItemsFromSearchWithoutSlugLazyQuery>;
export type GetListItemsFromSearchWithoutSlugQueryResult = Apollo.QueryResult<GetListItemsFromSearchWithoutSlugQuery, GetListItemsFromSearchWithoutSlugQueryVariables>;
export const UpdateListItemAvatarDocument = gql`
    mutation UpdateListItemAvatar($listItemId: ID!, $imageUrl: String!) {
  updateListItemAvatar(listItemId: $listItemId, imageUrl: $imageUrl) {
    isSuccess
  }
}
    `;
export type UpdateListItemAvatarMutationFn = Apollo.MutationFunction<UpdateListItemAvatarMutation, UpdateListItemAvatarMutationVariables>;

/**
 * __useUpdateListItemAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateListItemAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListItemAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListItemAvatarMutation, { data, loading, error }] = useUpdateListItemAvatarMutation({
 *   variables: {
 *      listItemId: // value for 'listItemId'
 *      imageUrl: // value for 'imageUrl'
 *   },
 * });
 */
export function useUpdateListItemAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateListItemAvatarMutation, UpdateListItemAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateListItemAvatarMutation, UpdateListItemAvatarMutationVariables>(UpdateListItemAvatarDocument, options);
      }
export type UpdateListItemAvatarMutationHookResult = ReturnType<typeof useUpdateListItemAvatarMutation>;
export type UpdateListItemAvatarMutationResult = Apollo.MutationResult<UpdateListItemAvatarMutation>;
export type UpdateListItemAvatarMutationOptions = Apollo.BaseMutationOptions<UpdateListItemAvatarMutation, UpdateListItemAvatarMutationVariables>;
export const CreateWorkOrderDocument = gql`
    mutation CreateWorkOrder($name: String!, $routinesWithVersions: [RefreshRoutinesWithVersion]!, $description: String, $receivers: [String], $assignedUsersWithRoles: [AssignedUsersWithRoles]!, $validityStartDate: Date!, $validityEndDate: Date!) {
  createWorkOrder(
    name: $name
    routinesWithVersions: $routinesWithVersions
    description: $description
    receivers: $receivers
    assignedUsersWithRoles: $assignedUsersWithRoles
    validityStartDate: $validityStartDate
    validityEndDate: $validityEndDate
  ) {
    createdAt
    description
    id
    name
    routineIds
    receivers
  }
}
    `;
export type CreateWorkOrderMutationFn = Apollo.MutationFunction<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>;

/**
 * __useCreateWorkOrderMutation__
 *
 * To run a mutation, you first call `useCreateWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkOrderMutation, { data, loading, error }] = useCreateWorkOrderMutation({
 *   variables: {
 *      name: // value for 'name'
 *      routinesWithVersions: // value for 'routinesWithVersions'
 *      description: // value for 'description'
 *      receivers: // value for 'receivers'
 *      assignedUsersWithRoles: // value for 'assignedUsersWithRoles'
 *      validityStartDate: // value for 'validityStartDate'
 *      validityEndDate: // value for 'validityEndDate'
 *   },
 * });
 */
export function useCreateWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>(CreateWorkOrderDocument, options);
      }
export type CreateWorkOrderMutationHookResult = ReturnType<typeof useCreateWorkOrderMutation>;
export type CreateWorkOrderMutationResult = Apollo.MutationResult<CreateWorkOrderMutation>;
export type CreateWorkOrderMutationOptions = Apollo.BaseMutationOptions<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>;
export const EditWorkOrderDocument = gql`
    mutation EditWorkOrder($id: ID!, $name: String!, $routinesWithVersions: [RefreshRoutinesWithVersion]!, $description: String!, $receivers: [String], $assignedUsersWithRoles: [AssignedUsersWithRoles]!, $validityStartDate: Date!, $validityEndDate: Date!) {
  editWorkOrder(
    id: $id
    name: $name
    routinesWithVersions: $routinesWithVersions
    description: $description
    receivers: $receivers
    assignedUsersWithRoles: $assignedUsersWithRoles
    validityStartDate: $validityStartDate
    validityEndDate: $validityEndDate
  ) {
    id
    name
    description
    routineIds
    receivers
  }
}
    `;
export type EditWorkOrderMutationFn = Apollo.MutationFunction<EditWorkOrderMutation, EditWorkOrderMutationVariables>;

/**
 * __useEditWorkOrderMutation__
 *
 * To run a mutation, you first call `useEditWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWorkOrderMutation, { data, loading, error }] = useEditWorkOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      routinesWithVersions: // value for 'routinesWithVersions'
 *      description: // value for 'description'
 *      receivers: // value for 'receivers'
 *      assignedUsersWithRoles: // value for 'assignedUsersWithRoles'
 *      validityStartDate: // value for 'validityStartDate'
 *      validityEndDate: // value for 'validityEndDate'
 *   },
 * });
 */
export function useEditWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<EditWorkOrderMutation, EditWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditWorkOrderMutation, EditWorkOrderMutationVariables>(EditWorkOrderDocument, options);
      }
export type EditWorkOrderMutationHookResult = ReturnType<typeof useEditWorkOrderMutation>;
export type EditWorkOrderMutationResult = Apollo.MutationResult<EditWorkOrderMutation>;
export type EditWorkOrderMutationOptions = Apollo.BaseMutationOptions<EditWorkOrderMutation, EditWorkOrderMutationVariables>;
export const GetRoutinesAvailableForWorkOrdersDocument = gql`
    query GetRoutinesAvailableForWorkOrders($excludeNestedChecklist: Boolean, $includeDeletedRoutines: Boolean) {
  getRoutinesAvailable(
    excludeNestedChecklist: $excludeNestedChecklist
    includeDeletedRoutines: $includeDeletedRoutines
  ) {
    id
    name
    slug
    versionNumber
    versionId
    routineVersions {
      id
      versionNumber
    }
  }
}
    `;

/**
 * __useGetRoutinesAvailableForWorkOrdersQuery__
 *
 * To run a query within a React component, call `useGetRoutinesAvailableForWorkOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutinesAvailableForWorkOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutinesAvailableForWorkOrdersQuery({
 *   variables: {
 *      excludeNestedChecklist: // value for 'excludeNestedChecklist'
 *      includeDeletedRoutines: // value for 'includeDeletedRoutines'
 *   },
 * });
 */
export function useGetRoutinesAvailableForWorkOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetRoutinesAvailableForWorkOrdersQuery, GetRoutinesAvailableForWorkOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoutinesAvailableForWorkOrdersQuery, GetRoutinesAvailableForWorkOrdersQueryVariables>(GetRoutinesAvailableForWorkOrdersDocument, options);
      }
export function useGetRoutinesAvailableForWorkOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoutinesAvailableForWorkOrdersQuery, GetRoutinesAvailableForWorkOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoutinesAvailableForWorkOrdersQuery, GetRoutinesAvailableForWorkOrdersQueryVariables>(GetRoutinesAvailableForWorkOrdersDocument, options);
        }
export type GetRoutinesAvailableForWorkOrdersQueryHookResult = ReturnType<typeof useGetRoutinesAvailableForWorkOrdersQuery>;
export type GetRoutinesAvailableForWorkOrdersLazyQueryHookResult = ReturnType<typeof useGetRoutinesAvailableForWorkOrdersLazyQuery>;
export type GetRoutinesAvailableForWorkOrdersQueryResult = Apollo.QueryResult<GetRoutinesAvailableForWorkOrdersQuery, GetRoutinesAvailableForWorkOrdersQueryVariables>;
export const GetWorkOrderDetailsDocument = gql`
    query GetWorkOrderDetails($workOrderId: ID!) {
  getWorkOrderDetails(workOrderId: $workOrderId) {
    id
    name
    description
    receivers
    startDate
    endDate
    workOrderRoutineExecutors
    userWorkOrders
  }
}
    `;

/**
 * __useGetWorkOrderDetailsQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderDetailsQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useGetWorkOrderDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderDetailsQuery, GetWorkOrderDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderDetailsQuery, GetWorkOrderDetailsQueryVariables>(GetWorkOrderDetailsDocument, options);
      }
export function useGetWorkOrderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderDetailsQuery, GetWorkOrderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderDetailsQuery, GetWorkOrderDetailsQueryVariables>(GetWorkOrderDetailsDocument, options);
        }
export type GetWorkOrderDetailsQueryHookResult = ReturnType<typeof useGetWorkOrderDetailsQuery>;
export type GetWorkOrderDetailsLazyQueryHookResult = ReturnType<typeof useGetWorkOrderDetailsLazyQuery>;
export type GetWorkOrderDetailsQueryResult = Apollo.QueryResult<GetWorkOrderDetailsQuery, GetWorkOrderDetailsQueryVariables>;
export const ListUsersForWorkOrdersDocument = gql`
    query ListUsersForWorkOrders($workOrderId: ID, $startDate: Date!, $endDate: Date!) {
  listUsersForWorkOrders(
    workOrderId: $workOrderId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    name
    email
    isEnabled
    registeredCompany
  }
}
    `;

/**
 * __useListUsersForWorkOrdersQuery__
 *
 * To run a query within a React component, call `useListUsersForWorkOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersForWorkOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersForWorkOrdersQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useListUsersForWorkOrdersQuery(baseOptions: Apollo.QueryHookOptions<ListUsersForWorkOrdersQuery, ListUsersForWorkOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersForWorkOrdersQuery, ListUsersForWorkOrdersQueryVariables>(ListUsersForWorkOrdersDocument, options);
      }
export function useListUsersForWorkOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersForWorkOrdersQuery, ListUsersForWorkOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersForWorkOrdersQuery, ListUsersForWorkOrdersQueryVariables>(ListUsersForWorkOrdersDocument, options);
        }
export type ListUsersForWorkOrdersQueryHookResult = ReturnType<typeof useListUsersForWorkOrdersQuery>;
export type ListUsersForWorkOrdersLazyQueryHookResult = ReturnType<typeof useListUsersForWorkOrdersLazyQuery>;
export type ListUsersForWorkOrdersQueryResult = Apollo.QueryResult<ListUsersForWorkOrdersQuery, ListUsersForWorkOrdersQueryVariables>;
export const GetWorkOrdersDocument = gql`
    query GetWorkOrders($page: Int, $limit: Int, $status: WorkOrderStatus, $searchText: String, $activeMonth: String) {
  getWorkOrders(
    page: $page
    limit: $limit
    status: $status
    searchText: $searchText
    activeMonth: $activeMonth
  ) {
    id
    description
    name
    createdAt
    updatedAt
    startDate
    status
    endDate
    areRecordsSearched
    page
    start
    end
    title
    routines {
      routineInfo {
        id
        name
        routineVersions {
          id
          versionNumber
        }
      }
      versionAdded {
        id
        versionNumber
      }
    }
    userWorkOrderId
    userIds
    totalQuestions
    totalQuestionsAnswered
    isFinalized
    receivers
    isCancelled
    cancellationReason
  }
}
    `;

/**
 * __useGetWorkOrdersQuery__
 *
 * To run a query within a React component, call `useGetWorkOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrdersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      status: // value for 'status'
 *      searchText: // value for 'searchText'
 *      activeMonth: // value for 'activeMonth'
 *   },
 * });
 */
export function useGetWorkOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkOrdersQuery, GetWorkOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrdersQuery, GetWorkOrdersQueryVariables>(GetWorkOrdersDocument, options);
      }
export function useGetWorkOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrdersQuery, GetWorkOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrdersQuery, GetWorkOrdersQueryVariables>(GetWorkOrdersDocument, options);
        }
export type GetWorkOrdersQueryHookResult = ReturnType<typeof useGetWorkOrdersQuery>;
export type GetWorkOrdersLazyQueryHookResult = ReturnType<typeof useGetWorkOrdersLazyQuery>;
export type GetWorkOrdersQueryResult = Apollo.QueryResult<GetWorkOrdersQuery, GetWorkOrdersQueryVariables>;
export const QueueEmailPdfToRecipientsDocument = gql`
    query QueueEmailPDFToRecipients($workOrderId: ID!, $recipients: [String!]!) {
  queueEmailPDFToRecipients(workOrderId: $workOrderId, recipients: $recipients) {
    isSuccess
  }
}
    `;

/**
 * __useQueueEmailPdfToRecipientsQuery__
 *
 * To run a query within a React component, call `useQueueEmailPdfToRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueueEmailPdfToRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueueEmailPdfToRecipientsQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *      recipients: // value for 'recipients'
 *   },
 * });
 */
export function useQueueEmailPdfToRecipientsQuery(baseOptions: Apollo.QueryHookOptions<QueueEmailPdfToRecipientsQuery, QueueEmailPdfToRecipientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueueEmailPdfToRecipientsQuery, QueueEmailPdfToRecipientsQueryVariables>(QueueEmailPdfToRecipientsDocument, options);
      }
export function useQueueEmailPdfToRecipientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueueEmailPdfToRecipientsQuery, QueueEmailPdfToRecipientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueueEmailPdfToRecipientsQuery, QueueEmailPdfToRecipientsQueryVariables>(QueueEmailPdfToRecipientsDocument, options);
        }
export type QueueEmailPdfToRecipientsQueryHookResult = ReturnType<typeof useQueueEmailPdfToRecipientsQuery>;
export type QueueEmailPdfToRecipientsLazyQueryHookResult = ReturnType<typeof useQueueEmailPdfToRecipientsLazyQuery>;
export type QueueEmailPdfToRecipientsQueryResult = Apollo.QueryResult<QueueEmailPdfToRecipientsQuery, QueueEmailPdfToRecipientsQueryVariables>;
export const CancelWorkOrderDocument = gql`
    mutation CancelWorkOrder($cancellationReason: String!, $workOrderId: ID!) {
  cancelWorkOrder(
    cancellationReason: $cancellationReason
    workOrderId: $workOrderId
  ) {
    isSuccess
  }
}
    `;
export type CancelWorkOrderMutationFn = Apollo.MutationFunction<CancelWorkOrderMutation, CancelWorkOrderMutationVariables>;

/**
 * __useCancelWorkOrderMutation__
 *
 * To run a mutation, you first call `useCancelWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelWorkOrderMutation, { data, loading, error }] = useCancelWorkOrderMutation({
 *   variables: {
 *      cancellationReason: // value for 'cancellationReason'
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useCancelWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelWorkOrderMutation, CancelWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelWorkOrderMutation, CancelWorkOrderMutationVariables>(CancelWorkOrderDocument, options);
      }
export type CancelWorkOrderMutationHookResult = ReturnType<typeof useCancelWorkOrderMutation>;
export type CancelWorkOrderMutationResult = Apollo.MutationResult<CancelWorkOrderMutation>;
export type CancelWorkOrderMutationOptions = Apollo.BaseMutationOptions<CancelWorkOrderMutation, CancelWorkOrderMutationVariables>;
export const DeleteWorkOrderDocument = gql`
    mutation DeleteWorkOrder($deleteWorkOrderId: ID!) {
  deleteWorkOrder(id: $deleteWorkOrderId)
}
    `;
export type DeleteWorkOrderMutationFn = Apollo.MutationFunction<DeleteWorkOrderMutation, DeleteWorkOrderMutationVariables>;

/**
 * __useDeleteWorkOrderMutation__
 *
 * To run a mutation, you first call `useDeleteWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkOrderMutation, { data, loading, error }] = useDeleteWorkOrderMutation({
 *   variables: {
 *      deleteWorkOrderId: // value for 'deleteWorkOrderId'
 *   },
 * });
 */
export function useDeleteWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkOrderMutation, DeleteWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkOrderMutation, DeleteWorkOrderMutationVariables>(DeleteWorkOrderDocument, options);
      }
export type DeleteWorkOrderMutationHookResult = ReturnType<typeof useDeleteWorkOrderMutation>;
export type DeleteWorkOrderMutationResult = Apollo.MutationResult<DeleteWorkOrderMutation>;
export type DeleteWorkOrderMutationOptions = Apollo.BaseMutationOptions<DeleteWorkOrderMutation, DeleteWorkOrderMutationVariables>;
export const DuplicateWorkOrderDocument = gql`
    mutation DuplicateWorkOrder($workOrderId: ID!) {
  duplicateWorkOrder(workOrderId: $workOrderId) {
    isSuccess
  }
}
    `;
export type DuplicateWorkOrderMutationFn = Apollo.MutationFunction<DuplicateWorkOrderMutation, DuplicateWorkOrderMutationVariables>;

/**
 * __useDuplicateWorkOrderMutation__
 *
 * To run a mutation, you first call `useDuplicateWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateWorkOrderMutation, { data, loading, error }] = useDuplicateWorkOrderMutation({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useDuplicateWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateWorkOrderMutation, DuplicateWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateWorkOrderMutation, DuplicateWorkOrderMutationVariables>(DuplicateWorkOrderDocument, options);
      }
export type DuplicateWorkOrderMutationHookResult = ReturnType<typeof useDuplicateWorkOrderMutation>;
export type DuplicateWorkOrderMutationResult = Apollo.MutationResult<DuplicateWorkOrderMutation>;
export type DuplicateWorkOrderMutationOptions = Apollo.BaseMutationOptions<DuplicateWorkOrderMutation, DuplicateWorkOrderMutationVariables>;
export const GetRoutinesAvailableDocument = gql`
    query GetRoutinesAvailable($excludeNestedChecklist: Boolean) {
  getRoutinesAvailable(excludeNestedChecklist: $excludeNestedChecklist) {
    id
    frequency
    name
    slug
    content
    updatedAt
    createdAt
    shareableToken
    isShareableLinkActive
    versionNumber
    versionId
  }
}
    `;

/**
 * __useGetRoutinesAvailableQuery__
 *
 * To run a query within a React component, call `useGetRoutinesAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutinesAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutinesAvailableQuery({
 *   variables: {
 *      excludeNestedChecklist: // value for 'excludeNestedChecklist'
 *   },
 * });
 */
export function useGetRoutinesAvailableQuery(baseOptions?: Apollo.QueryHookOptions<GetRoutinesAvailableQuery, GetRoutinesAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoutinesAvailableQuery, GetRoutinesAvailableQueryVariables>(GetRoutinesAvailableDocument, options);
      }
export function useGetRoutinesAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoutinesAvailableQuery, GetRoutinesAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoutinesAvailableQuery, GetRoutinesAvailableQueryVariables>(GetRoutinesAvailableDocument, options);
        }
export type GetRoutinesAvailableQueryHookResult = ReturnType<typeof useGetRoutinesAvailableQuery>;
export type GetRoutinesAvailableLazyQueryHookResult = ReturnType<typeof useGetRoutinesAvailableLazyQuery>;
export type GetRoutinesAvailableQueryResult = Apollo.QueryResult<GetRoutinesAvailableQuery, GetRoutinesAvailableQueryVariables>;
export const GetRoutineAvailableDocument = gql`
    query GetRoutineAvailable($routineAvailableId: ID!) {
  getRoutineAvailable(routineAvailableId: $routineAvailableId) {
    id
    name
    slug
    frequency
    content
    isActive
    description
    shareableToken
    isShareableLinkActive
    routineVersions {
      versionNumber
    }
  }
}
    `;

/**
 * __useGetRoutineAvailableQuery__
 *
 * To run a query within a React component, call `useGetRoutineAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutineAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutineAvailableQuery({
 *   variables: {
 *      routineAvailableId: // value for 'routineAvailableId'
 *   },
 * });
 */
export function useGetRoutineAvailableQuery(baseOptions: Apollo.QueryHookOptions<GetRoutineAvailableQuery, GetRoutineAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoutineAvailableQuery, GetRoutineAvailableQueryVariables>(GetRoutineAvailableDocument, options);
      }
export function useGetRoutineAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoutineAvailableQuery, GetRoutineAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoutineAvailableQuery, GetRoutineAvailableQueryVariables>(GetRoutineAvailableDocument, options);
        }
export type GetRoutineAvailableQueryHookResult = ReturnType<typeof useGetRoutineAvailableQuery>;
export type GetRoutineAvailableLazyQueryHookResult = ReturnType<typeof useGetRoutineAvailableLazyQuery>;
export type GetRoutineAvailableQueryResult = Apollo.QueryResult<GetRoutineAvailableQuery, GetRoutineAvailableQueryVariables>;
export const GetListItemsFromSearchInputDocument = gql`
    query GetListItemsFromSearchInput($searchInput: String!, $listShortSlugs: [String]!) {
  getListItemsFromSearchInput(
    searchInput: $searchInput
    listShortSlugs: $listShortSlugs
  ) {
    id
    name
    shortSlug
  }
}
    `;

/**
 * __useGetListItemsFromSearchInputQuery__
 *
 * To run a query within a React component, call `useGetListItemsFromSearchInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListItemsFromSearchInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListItemsFromSearchInputQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      listShortSlugs: // value for 'listShortSlugs'
 *   },
 * });
 */
export function useGetListItemsFromSearchInputQuery(baseOptions: Apollo.QueryHookOptions<GetListItemsFromSearchInputQuery, GetListItemsFromSearchInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListItemsFromSearchInputQuery, GetListItemsFromSearchInputQueryVariables>(GetListItemsFromSearchInputDocument, options);
      }
export function useGetListItemsFromSearchInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListItemsFromSearchInputQuery, GetListItemsFromSearchInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListItemsFromSearchInputQuery, GetListItemsFromSearchInputQueryVariables>(GetListItemsFromSearchInputDocument, options);
        }
export type GetListItemsFromSearchInputQueryHookResult = ReturnType<typeof useGetListItemsFromSearchInputQuery>;
export type GetListItemsFromSearchInputLazyQueryHookResult = ReturnType<typeof useGetListItemsFromSearchInputLazyQuery>;
export type GetListItemsFromSearchInputQueryResult = Apollo.QueryResult<GetListItemsFromSearchInputQuery, GetListItemsFromSearchInputQueryVariables>;
export const SaveRoutinesAvailableDocument = gql`
    mutation SaveRoutinesAvailable($args: saveRoutinesAvailableInput) {
  saveRoutinesAvailable(args: $args) {
    isSuccess
  }
}
    `;
export type SaveRoutinesAvailableMutationFn = Apollo.MutationFunction<SaveRoutinesAvailableMutation, SaveRoutinesAvailableMutationVariables>;

/**
 * __useSaveRoutinesAvailableMutation__
 *
 * To run a mutation, you first call `useSaveRoutinesAvailableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRoutinesAvailableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRoutinesAvailableMutation, { data, loading, error }] = useSaveRoutinesAvailableMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useSaveRoutinesAvailableMutation(baseOptions?: Apollo.MutationHookOptions<SaveRoutinesAvailableMutation, SaveRoutinesAvailableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveRoutinesAvailableMutation, SaveRoutinesAvailableMutationVariables>(SaveRoutinesAvailableDocument, options);
      }
export type SaveRoutinesAvailableMutationHookResult = ReturnType<typeof useSaveRoutinesAvailableMutation>;
export type SaveRoutinesAvailableMutationResult = Apollo.MutationResult<SaveRoutinesAvailableMutation>;
export type SaveRoutinesAvailableMutationOptions = Apollo.BaseMutationOptions<SaveRoutinesAvailableMutation, SaveRoutinesAvailableMutationVariables>;
export const GetRoutinesAvailableWithReportInfoDocument = gql`
    query GetRoutinesAvailableWithReportInfo {
  getRoutinesAvailableWithReportInfo {
    id
    name
    lastReportCompletedAt
    totalReports
    isScheduledForDeletion
  }
}
    `;

/**
 * __useGetRoutinesAvailableWithReportInfoQuery__
 *
 * To run a query within a React component, call `useGetRoutinesAvailableWithReportInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutinesAvailableWithReportInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutinesAvailableWithReportInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoutinesAvailableWithReportInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetRoutinesAvailableWithReportInfoQuery, GetRoutinesAvailableWithReportInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoutinesAvailableWithReportInfoQuery, GetRoutinesAvailableWithReportInfoQueryVariables>(GetRoutinesAvailableWithReportInfoDocument, options);
      }
export function useGetRoutinesAvailableWithReportInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoutinesAvailableWithReportInfoQuery, GetRoutinesAvailableWithReportInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoutinesAvailableWithReportInfoQuery, GetRoutinesAvailableWithReportInfoQueryVariables>(GetRoutinesAvailableWithReportInfoDocument, options);
        }
export type GetRoutinesAvailableWithReportInfoQueryHookResult = ReturnType<typeof useGetRoutinesAvailableWithReportInfoQuery>;
export type GetRoutinesAvailableWithReportInfoLazyQueryHookResult = ReturnType<typeof useGetRoutinesAvailableWithReportInfoLazyQuery>;
export type GetRoutinesAvailableWithReportInfoQueryResult = Apollo.QueryResult<GetRoutinesAvailableWithReportInfoQuery, GetRoutinesAvailableWithReportInfoQueryVariables>;
export const GenerateShareableLinkForRoutineAvailableDocument = gql`
    mutation GenerateShareableLinkForRoutineAvailable($routineAvailableId: ID!, $responseModeIndividualChecklist: Boolean) {
  generateShareableLinkForRoutineAvailable(
    routineAvailableId: $routineAvailableId
    responseModeIndividualChecklist: $responseModeIndividualChecklist
  ) {
    routines {
      id
      frequency
      name
      slug
      content
      updatedAt
      createdAt
      shareableToken
      isShareableLinkActive
    }
    isSuccess
  }
}
    `;
export type GenerateShareableLinkForRoutineAvailableMutationFn = Apollo.MutationFunction<GenerateShareableLinkForRoutineAvailableMutation, GenerateShareableLinkForRoutineAvailableMutationVariables>;

/**
 * __useGenerateShareableLinkForRoutineAvailableMutation__
 *
 * To run a mutation, you first call `useGenerateShareableLinkForRoutineAvailableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateShareableLinkForRoutineAvailableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateShareableLinkForRoutineAvailableMutation, { data, loading, error }] = useGenerateShareableLinkForRoutineAvailableMutation({
 *   variables: {
 *      routineAvailableId: // value for 'routineAvailableId'
 *      responseModeIndividualChecklist: // value for 'responseModeIndividualChecklist'
 *   },
 * });
 */
export function useGenerateShareableLinkForRoutineAvailableMutation(baseOptions?: Apollo.MutationHookOptions<GenerateShareableLinkForRoutineAvailableMutation, GenerateShareableLinkForRoutineAvailableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateShareableLinkForRoutineAvailableMutation, GenerateShareableLinkForRoutineAvailableMutationVariables>(GenerateShareableLinkForRoutineAvailableDocument, options);
      }
export type GenerateShareableLinkForRoutineAvailableMutationHookResult = ReturnType<typeof useGenerateShareableLinkForRoutineAvailableMutation>;
export type GenerateShareableLinkForRoutineAvailableMutationResult = Apollo.MutationResult<GenerateShareableLinkForRoutineAvailableMutation>;
export type GenerateShareableLinkForRoutineAvailableMutationOptions = Apollo.BaseMutationOptions<GenerateShareableLinkForRoutineAvailableMutation, GenerateShareableLinkForRoutineAvailableMutationVariables>;
export const GetRoutineAvailableFromShareableTokenDocument = gql`
    query GetRoutineAvailableFromShareableToken($shareableToken: String!) {
  getRoutineAvailableFromShareableToken(shareableToken: $shareableToken) {
    id
    frequency
    name
    slug
    description
    content
    updatedAt
    createdAt
    shareableToken
    isShareableLinkActive
    company {
      name
    }
  }
}
    `;

/**
 * __useGetRoutineAvailableFromShareableTokenQuery__
 *
 * To run a query within a React component, call `useGetRoutineAvailableFromShareableTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutineAvailableFromShareableTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutineAvailableFromShareableTokenQuery({
 *   variables: {
 *      shareableToken: // value for 'shareableToken'
 *   },
 * });
 */
export function useGetRoutineAvailableFromShareableTokenQuery(baseOptions: Apollo.QueryHookOptions<GetRoutineAvailableFromShareableTokenQuery, GetRoutineAvailableFromShareableTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoutineAvailableFromShareableTokenQuery, GetRoutineAvailableFromShareableTokenQueryVariables>(GetRoutineAvailableFromShareableTokenDocument, options);
      }
export function useGetRoutineAvailableFromShareableTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoutineAvailableFromShareableTokenQuery, GetRoutineAvailableFromShareableTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoutineAvailableFromShareableTokenQuery, GetRoutineAvailableFromShareableTokenQueryVariables>(GetRoutineAvailableFromShareableTokenDocument, options);
        }
export type GetRoutineAvailableFromShareableTokenQueryHookResult = ReturnType<typeof useGetRoutineAvailableFromShareableTokenQuery>;
export type GetRoutineAvailableFromShareableTokenLazyQueryHookResult = ReturnType<typeof useGetRoutineAvailableFromShareableTokenLazyQuery>;
export type GetRoutineAvailableFromShareableTokenQueryResult = Apollo.QueryResult<GetRoutineAvailableFromShareableTokenQuery, GetRoutineAvailableFromShareableTokenQueryVariables>;
export const GetListsForCompanyDocument = gql`
    query GetListsForCompany {
  getListsForCompany {
    id
    name
    attributes
    shortSlug
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useGetListsForCompanyQuery__
 *
 * To run a query within a React component, call `useGetListsForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListsForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListsForCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetListsForCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetListsForCompanyQuery, GetListsForCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListsForCompanyQuery, GetListsForCompanyQueryVariables>(GetListsForCompanyDocument, options);
      }
export function useGetListsForCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListsForCompanyQuery, GetListsForCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListsForCompanyQuery, GetListsForCompanyQueryVariables>(GetListsForCompanyDocument, options);
        }
export type GetListsForCompanyQueryHookResult = ReturnType<typeof useGetListsForCompanyQuery>;
export type GetListsForCompanyLazyQueryHookResult = ReturnType<typeof useGetListsForCompanyLazyQuery>;
export type GetListsForCompanyQueryResult = Apollo.QueryResult<GetListsForCompanyQuery, GetListsForCompanyQueryVariables>;
export const GetListsForChecklistDocument = gql`
    query GetListsForChecklist {
  getListsForCompany {
    id
    name
    shortSlug
    items {
      id
      name
      description
      shortSlug
    }
  }
}
    `;

/**
 * __useGetListsForChecklistQuery__
 *
 * To run a query within a React component, call `useGetListsForChecklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListsForChecklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListsForChecklistQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetListsForChecklistQuery(baseOptions?: Apollo.QueryHookOptions<GetListsForChecklistQuery, GetListsForChecklistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListsForChecklistQuery, GetListsForChecklistQueryVariables>(GetListsForChecklistDocument, options);
      }
export function useGetListsForChecklistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListsForChecklistQuery, GetListsForChecklistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListsForChecklistQuery, GetListsForChecklistQueryVariables>(GetListsForChecklistDocument, options);
        }
export type GetListsForChecklistQueryHookResult = ReturnType<typeof useGetListsForChecklistQuery>;
export type GetListsForChecklistLazyQueryHookResult = ReturnType<typeof useGetListsForChecklistLazyQuery>;
export type GetListsForChecklistQueryResult = Apollo.QueryResult<GetListsForChecklistQuery, GetListsForChecklistQueryVariables>;
export const GetWorkOrdersByRoutineAvailableDocument = gql`
    query GetWorkOrdersByRoutineAvailable($routineAvailableId: ID!) {
  getWorkOrdersByRoutineAvailable(routineAvailableId: $routineAvailableId) {
    name
  }
}
    `;

/**
 * __useGetWorkOrdersByRoutineAvailableQuery__
 *
 * To run a query within a React component, call `useGetWorkOrdersByRoutineAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrdersByRoutineAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrdersByRoutineAvailableQuery({
 *   variables: {
 *      routineAvailableId: // value for 'routineAvailableId'
 *   },
 * });
 */
export function useGetWorkOrdersByRoutineAvailableQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrdersByRoutineAvailableQuery, GetWorkOrdersByRoutineAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrdersByRoutineAvailableQuery, GetWorkOrdersByRoutineAvailableQueryVariables>(GetWorkOrdersByRoutineAvailableDocument, options);
      }
export function useGetWorkOrdersByRoutineAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrdersByRoutineAvailableQuery, GetWorkOrdersByRoutineAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrdersByRoutineAvailableQuery, GetWorkOrdersByRoutineAvailableQueryVariables>(GetWorkOrdersByRoutineAvailableDocument, options);
        }
export type GetWorkOrdersByRoutineAvailableQueryHookResult = ReturnType<typeof useGetWorkOrdersByRoutineAvailableQuery>;
export type GetWorkOrdersByRoutineAvailableLazyQueryHookResult = ReturnType<typeof useGetWorkOrdersByRoutineAvailableLazyQuery>;
export type GetWorkOrdersByRoutineAvailableQueryResult = Apollo.QueryResult<GetWorkOrdersByRoutineAvailableQuery, GetWorkOrdersByRoutineAvailableQueryVariables>;
export const SaveRoutinesAnonymouslyDocument = gql`
    mutation SaveRoutinesAnonymously($args: saveRoutineAnswerAnonymouslyInput) {
  saveRoutineAnswerAnonymously(args: $args) {
    isSuccess
  }
}
    `;
export type SaveRoutinesAnonymouslyMutationFn = Apollo.MutationFunction<SaveRoutinesAnonymouslyMutation, SaveRoutinesAnonymouslyMutationVariables>;

/**
 * __useSaveRoutinesAnonymouslyMutation__
 *
 * To run a mutation, you first call `useSaveRoutinesAnonymouslyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRoutinesAnonymouslyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRoutinesAnonymouslyMutation, { data, loading, error }] = useSaveRoutinesAnonymouslyMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useSaveRoutinesAnonymouslyMutation(baseOptions?: Apollo.MutationHookOptions<SaveRoutinesAnonymouslyMutation, SaveRoutinesAnonymouslyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveRoutinesAnonymouslyMutation, SaveRoutinesAnonymouslyMutationVariables>(SaveRoutinesAnonymouslyDocument, options);
      }
export type SaveRoutinesAnonymouslyMutationHookResult = ReturnType<typeof useSaveRoutinesAnonymouslyMutation>;
export type SaveRoutinesAnonymouslyMutationResult = Apollo.MutationResult<SaveRoutinesAnonymouslyMutation>;
export type SaveRoutinesAnonymouslyMutationOptions = Apollo.BaseMutationOptions<SaveRoutinesAnonymouslyMutation, SaveRoutinesAnonymouslyMutationVariables>;
export const DuplicateChecklistDocument = gql`
    mutation DuplicateChecklist($routineAvailableId: ID!) {
  duplicateChecklist(routineAvailableId: $routineAvailableId) {
    isSuccess
  }
}
    `;
export type DuplicateChecklistMutationFn = Apollo.MutationFunction<DuplicateChecklistMutation, DuplicateChecklistMutationVariables>;

/**
 * __useDuplicateChecklistMutation__
 *
 * To run a mutation, you first call `useDuplicateChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateChecklistMutation, { data, loading, error }] = useDuplicateChecklistMutation({
 *   variables: {
 *      routineAvailableId: // value for 'routineAvailableId'
 *   },
 * });
 */
export function useDuplicateChecklistMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateChecklistMutation, DuplicateChecklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateChecklistMutation, DuplicateChecklistMutationVariables>(DuplicateChecklistDocument, options);
      }
export type DuplicateChecklistMutationHookResult = ReturnType<typeof useDuplicateChecklistMutation>;
export type DuplicateChecklistMutationResult = Apollo.MutationResult<DuplicateChecklistMutation>;
export type DuplicateChecklistMutationOptions = Apollo.BaseMutationOptions<DuplicateChecklistMutation, DuplicateChecklistMutationVariables>;
export const ScheduleRoutineDeletionDocument = gql`
    mutation ScheduleRoutineDeletion($routineAvailableId: ID!, $isNotDependent: Boolean) {
  scheduleRoutineDeletion(
    routineAvailableId: $routineAvailableId
    isNotDependent: $isNotDependent
  ) {
    isSuccess
  }
}
    `;
export type ScheduleRoutineDeletionMutationFn = Apollo.MutationFunction<ScheduleRoutineDeletionMutation, ScheduleRoutineDeletionMutationVariables>;

/**
 * __useScheduleRoutineDeletionMutation__
 *
 * To run a mutation, you first call `useScheduleRoutineDeletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleRoutineDeletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleRoutineDeletionMutation, { data, loading, error }] = useScheduleRoutineDeletionMutation({
 *   variables: {
 *      routineAvailableId: // value for 'routineAvailableId'
 *      isNotDependent: // value for 'isNotDependent'
 *   },
 * });
 */
export function useScheduleRoutineDeletionMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleRoutineDeletionMutation, ScheduleRoutineDeletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleRoutineDeletionMutation, ScheduleRoutineDeletionMutationVariables>(ScheduleRoutineDeletionDocument, options);
      }
export type ScheduleRoutineDeletionMutationHookResult = ReturnType<typeof useScheduleRoutineDeletionMutation>;
export type ScheduleRoutineDeletionMutationResult = Apollo.MutationResult<ScheduleRoutineDeletionMutation>;
export type ScheduleRoutineDeletionMutationOptions = Apollo.BaseMutationOptions<ScheduleRoutineDeletionMutation, ScheduleRoutineDeletionMutationVariables>;
export const UpdateUserDefaultLocationDocument = gql`
    mutation UpdateUserDefaultLocation($locationId: ID!, $userId: ID!) {
  updateUserDefaultLocation(locationId: $locationId, userId: $userId) {
    isSuccess
  }
}
    `;
export type UpdateUserDefaultLocationMutationFn = Apollo.MutationFunction<UpdateUserDefaultLocationMutation, UpdateUserDefaultLocationMutationVariables>;

/**
 * __useUpdateUserDefaultLocationMutation__
 *
 * To run a mutation, you first call `useUpdateUserDefaultLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDefaultLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDefaultLocationMutation, { data, loading, error }] = useUpdateUserDefaultLocationMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateUserDefaultLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserDefaultLocationMutation, UpdateUserDefaultLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserDefaultLocationMutation, UpdateUserDefaultLocationMutationVariables>(UpdateUserDefaultLocationDocument, options);
      }
export type UpdateUserDefaultLocationMutationHookResult = ReturnType<typeof useUpdateUserDefaultLocationMutation>;
export type UpdateUserDefaultLocationMutationResult = Apollo.MutationResult<UpdateUserDefaultLocationMutation>;
export type UpdateUserDefaultLocationMutationOptions = Apollo.BaseMutationOptions<UpdateUserDefaultLocationMutation, UpdateUserDefaultLocationMutationVariables>;
export const GetUserAvailableLocationsDocument = gql`
    query GetUserAvailableLocations {
  getUserAvailableLocations {
    id
    name
    slug
  }
}
    `;

/**
 * __useGetUserAvailableLocationsQuery__
 *
 * To run a query within a React component, call `useGetUserAvailableLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAvailableLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAvailableLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAvailableLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserAvailableLocationsQuery, GetUserAvailableLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAvailableLocationsQuery, GetUserAvailableLocationsQueryVariables>(GetUserAvailableLocationsDocument, options);
      }
export function useGetUserAvailableLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAvailableLocationsQuery, GetUserAvailableLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAvailableLocationsQuery, GetUserAvailableLocationsQueryVariables>(GetUserAvailableLocationsDocument, options);
        }
export type GetUserAvailableLocationsQueryHookResult = ReturnType<typeof useGetUserAvailableLocationsQuery>;
export type GetUserAvailableLocationsLazyQueryHookResult = ReturnType<typeof useGetUserAvailableLocationsLazyQuery>;
export type GetUserAvailableLocationsQueryResult = Apollo.QueryResult<GetUserAvailableLocationsQuery, GetUserAvailableLocationsQueryVariables>;
export const GetAnsweredRoutinesDocument = gql`
    query GetAnsweredRoutines($routinesAvailableId: ID!, $pageNumber: Int, $limit: Int) {
  getAnsweredRoutines(
    routinesAvailableId: $routinesAvailableId
    pageNumber: $pageNumber
    limit: $limit
  ) {
    routines {
      answers
      id
      createdAt
      completedAt
      routineVersion {
        versionNumber
      }
      user {
        name
        email
      }
      routineAvailable {
        name
        company {
          name
        }
      }
    }
    pageInfo {
      totalPages
      currentPage
    }
  }
}
    `;

/**
 * __useGetAnsweredRoutinesQuery__
 *
 * To run a query within a React component, call `useGetAnsweredRoutinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnsweredRoutinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnsweredRoutinesQuery({
 *   variables: {
 *      routinesAvailableId: // value for 'routinesAvailableId'
 *      pageNumber: // value for 'pageNumber'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAnsweredRoutinesQuery(baseOptions: Apollo.QueryHookOptions<GetAnsweredRoutinesQuery, GetAnsweredRoutinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnsweredRoutinesQuery, GetAnsweredRoutinesQueryVariables>(GetAnsweredRoutinesDocument, options);
      }
export function useGetAnsweredRoutinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnsweredRoutinesQuery, GetAnsweredRoutinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnsweredRoutinesQuery, GetAnsweredRoutinesQueryVariables>(GetAnsweredRoutinesDocument, options);
        }
export type GetAnsweredRoutinesQueryHookResult = ReturnType<typeof useGetAnsweredRoutinesQuery>;
export type GetAnsweredRoutinesLazyQueryHookResult = ReturnType<typeof useGetAnsweredRoutinesLazyQuery>;
export type GetAnsweredRoutinesQueryResult = Apollo.QueryResult<GetAnsweredRoutinesQuery, GetAnsweredRoutinesQueryVariables>;
export const ListWorkOrderReportsDocument = gql`
    query ListWorkOrderReports {
  listWorkOrderReports {
    id
    createdAt
    updatedAt
    workOrder {
      name
    }
  }
}
    `;

/**
 * __useListWorkOrderReportsQuery__
 *
 * To run a query within a React component, call `useListWorkOrderReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkOrderReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkOrderReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListWorkOrderReportsQuery(baseOptions?: Apollo.QueryHookOptions<ListWorkOrderReportsQuery, ListWorkOrderReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWorkOrderReportsQuery, ListWorkOrderReportsQueryVariables>(ListWorkOrderReportsDocument, options);
      }
export function useListWorkOrderReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWorkOrderReportsQuery, ListWorkOrderReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWorkOrderReportsQuery, ListWorkOrderReportsQueryVariables>(ListWorkOrderReportsDocument, options);
        }
export type ListWorkOrderReportsQueryHookResult = ReturnType<typeof useListWorkOrderReportsQuery>;
export type ListWorkOrderReportsLazyQueryHookResult = ReturnType<typeof useListWorkOrderReportsLazyQuery>;
export type ListWorkOrderReportsQueryResult = Apollo.QueryResult<ListWorkOrderReportsQuery, ListWorkOrderReportsQueryVariables>;
export const CreateSignedS3UrlForGkpiUploadDocument = gql`
    mutation CreateSignedS3UrlForGKPIUpload {
  createSignedS3UrlForGKPIUpload {
    preSignedUrl
    fileName
  }
}
    `;
export type CreateSignedS3UrlForGkpiUploadMutationFn = Apollo.MutationFunction<CreateSignedS3UrlForGkpiUploadMutation, CreateSignedS3UrlForGkpiUploadMutationVariables>;

/**
 * __useCreateSignedS3UrlForGkpiUploadMutation__
 *
 * To run a mutation, you first call `useCreateSignedS3UrlForGkpiUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignedS3UrlForGkpiUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignedS3UrlForGkpiUploadMutation, { data, loading, error }] = useCreateSignedS3UrlForGkpiUploadMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSignedS3UrlForGkpiUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreateSignedS3UrlForGkpiUploadMutation, CreateSignedS3UrlForGkpiUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSignedS3UrlForGkpiUploadMutation, CreateSignedS3UrlForGkpiUploadMutationVariables>(CreateSignedS3UrlForGkpiUploadDocument, options);
      }
export type CreateSignedS3UrlForGkpiUploadMutationHookResult = ReturnType<typeof useCreateSignedS3UrlForGkpiUploadMutation>;
export type CreateSignedS3UrlForGkpiUploadMutationResult = Apollo.MutationResult<CreateSignedS3UrlForGkpiUploadMutation>;
export type CreateSignedS3UrlForGkpiUploadMutationOptions = Apollo.BaseMutationOptions<CreateSignedS3UrlForGkpiUploadMutation, CreateSignedS3UrlForGkpiUploadMutationVariables>;
export const StartProcessingGkpiFileDocument = gql`
    mutation StartProcessingGKPIFile($fileName: String!) {
  startProcessingGKPIFile(fileName: $fileName) {
    status
    message
  }
}
    `;
export type StartProcessingGkpiFileMutationFn = Apollo.MutationFunction<StartProcessingGkpiFileMutation, StartProcessingGkpiFileMutationVariables>;

/**
 * __useStartProcessingGkpiFileMutation__
 *
 * To run a mutation, you first call `useStartProcessingGkpiFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartProcessingGkpiFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startProcessingGkpiFileMutation, { data, loading, error }] = useStartProcessingGkpiFileMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useStartProcessingGkpiFileMutation(baseOptions?: Apollo.MutationHookOptions<StartProcessingGkpiFileMutation, StartProcessingGkpiFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartProcessingGkpiFileMutation, StartProcessingGkpiFileMutationVariables>(StartProcessingGkpiFileDocument, options);
      }
export type StartProcessingGkpiFileMutationHookResult = ReturnType<typeof useStartProcessingGkpiFileMutation>;
export type StartProcessingGkpiFileMutationResult = Apollo.MutationResult<StartProcessingGkpiFileMutation>;
export type StartProcessingGkpiFileMutationOptions = Apollo.BaseMutationOptions<StartProcessingGkpiFileMutation, StartProcessingGkpiFileMutationVariables>;
export const GetgkpiDataDocument = gql`
    query GETGKPIData($country: String, $location: String, $from: Date, $to: Date) {
  getGKPIData(country: $country, location: $location, from: $from, to: $to) {
    GKPI
    data
  }
}
    `;

/**
 * __useGetgkpiDataQuery__
 *
 * To run a query within a React component, call `useGetgkpiDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetgkpiDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetgkpiDataQuery({
 *   variables: {
 *      country: // value for 'country'
 *      location: // value for 'location'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetgkpiDataQuery(baseOptions?: Apollo.QueryHookOptions<GetgkpiDataQuery, GetgkpiDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetgkpiDataQuery, GetgkpiDataQueryVariables>(GetgkpiDataDocument, options);
      }
export function useGetgkpiDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetgkpiDataQuery, GetgkpiDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetgkpiDataQuery, GetgkpiDataQueryVariables>(GetgkpiDataDocument, options);
        }
export type GetgkpiDataQueryHookResult = ReturnType<typeof useGetgkpiDataQuery>;
export type GetgkpiDataLazyQueryHookResult = ReturnType<typeof useGetgkpiDataLazyQuery>;
export type GetgkpiDataQueryResult = Apollo.QueryResult<GetgkpiDataQuery, GetgkpiDataQueryVariables>;
export const GetCountryAndLocationListDocument = gql`
    query GetCountryAndLocationList {
  getCountryAndLocationList {
    country
    locations
  }
}
    `;

/**
 * __useGetCountryAndLocationListQuery__
 *
 * To run a query within a React component, call `useGetCountryAndLocationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryAndLocationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryAndLocationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountryAndLocationListQuery(baseOptions?: Apollo.QueryHookOptions<GetCountryAndLocationListQuery, GetCountryAndLocationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountryAndLocationListQuery, GetCountryAndLocationListQueryVariables>(GetCountryAndLocationListDocument, options);
      }
export function useGetCountryAndLocationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountryAndLocationListQuery, GetCountryAndLocationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountryAndLocationListQuery, GetCountryAndLocationListQueryVariables>(GetCountryAndLocationListDocument, options);
        }
export type GetCountryAndLocationListQueryHookResult = ReturnType<typeof useGetCountryAndLocationListQuery>;
export type GetCountryAndLocationListLazyQueryHookResult = ReturnType<typeof useGetCountryAndLocationListLazyQuery>;
export type GetCountryAndLocationListQueryResult = Apollo.QueryResult<GetCountryAndLocationListQuery, GetCountryAndLocationListQueryVariables>;
export const UserHealthStatusUnAuthenticatedDocument = gql`
    query userHealthStatusUnAuthenticated($slug: String!) {
  userHealthStatusUnAuthenticated(slug: $slug) {
    quarantineUntil
    quarantineStart
    healthStatus {
      location
      status
      createdAt
    }
    usersLocationMedicalFacility {
      emergencyContact
    }
  }
}
    `;

/**
 * __useUserHealthStatusUnAuthenticatedQuery__
 *
 * To run a query within a React component, call `useUserHealthStatusUnAuthenticatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHealthStatusUnAuthenticatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHealthStatusUnAuthenticatedQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUserHealthStatusUnAuthenticatedQuery(baseOptions: Apollo.QueryHookOptions<UserHealthStatusUnAuthenticatedQuery, UserHealthStatusUnAuthenticatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserHealthStatusUnAuthenticatedQuery, UserHealthStatusUnAuthenticatedQueryVariables>(UserHealthStatusUnAuthenticatedDocument, options);
      }
export function useUserHealthStatusUnAuthenticatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserHealthStatusUnAuthenticatedQuery, UserHealthStatusUnAuthenticatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserHealthStatusUnAuthenticatedQuery, UserHealthStatusUnAuthenticatedQueryVariables>(UserHealthStatusUnAuthenticatedDocument, options);
        }
export type UserHealthStatusUnAuthenticatedQueryHookResult = ReturnType<typeof useUserHealthStatusUnAuthenticatedQuery>;
export type UserHealthStatusUnAuthenticatedLazyQueryHookResult = ReturnType<typeof useUserHealthStatusUnAuthenticatedLazyQuery>;
export type UserHealthStatusUnAuthenticatedQueryResult = Apollo.QueryResult<UserHealthStatusUnAuthenticatedQuery, UserHealthStatusUnAuthenticatedQueryVariables>;
export const UpdateUserHealthStatusUnAuthenticatedDocument = gql`
    mutation updateUserHealthStatusUnAuthenticated($status: String!, $location: String!, $slug: String!) {
  addUserHealthStatusUnAuthenticated(
    status: $status
    location: $location
    slug: $slug
  ) {
    isSuccess
  }
}
    `;
export type UpdateUserHealthStatusUnAuthenticatedMutationFn = Apollo.MutationFunction<UpdateUserHealthStatusUnAuthenticatedMutation, UpdateUserHealthStatusUnAuthenticatedMutationVariables>;

/**
 * __useUpdateUserHealthStatusUnAuthenticatedMutation__
 *
 * To run a mutation, you first call `useUpdateUserHealthStatusUnAuthenticatedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserHealthStatusUnAuthenticatedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserHealthStatusUnAuthenticatedMutation, { data, loading, error }] = useUpdateUserHealthStatusUnAuthenticatedMutation({
 *   variables: {
 *      status: // value for 'status'
 *      location: // value for 'location'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUpdateUserHealthStatusUnAuthenticatedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserHealthStatusUnAuthenticatedMutation, UpdateUserHealthStatusUnAuthenticatedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserHealthStatusUnAuthenticatedMutation, UpdateUserHealthStatusUnAuthenticatedMutationVariables>(UpdateUserHealthStatusUnAuthenticatedDocument, options);
      }
export type UpdateUserHealthStatusUnAuthenticatedMutationHookResult = ReturnType<typeof useUpdateUserHealthStatusUnAuthenticatedMutation>;
export type UpdateUserHealthStatusUnAuthenticatedMutationResult = Apollo.MutationResult<UpdateUserHealthStatusUnAuthenticatedMutation>;
export type UpdateUserHealthStatusUnAuthenticatedMutationOptions = Apollo.BaseMutationOptions<UpdateUserHealthStatusUnAuthenticatedMutation, UpdateUserHealthStatusUnAuthenticatedMutationVariables>;
export const GetSkapModulesAndSubmodulesCountDocument = gql`
    query getSKAPModulesAndSubmodulesCount($filter: getModuleSubmoduleCountFilterParams!) {
  getSKAPModulesAndSubmodulesCount(filter: $filter) {
    countByLevels {
      slug
      count
      percentage
      total
    }
    countByAutonomousLevel {
      title
      count
    }
    countByPosition {
      slug
      total
      impactedCount
      data {
        title
        percentage
      }
    }
    countByMonth {
      month
      score
    }
  }
}
    `;

/**
 * __useGetSkapModulesAndSubmodulesCountQuery__
 *
 * To run a query within a React component, call `useGetSkapModulesAndSubmodulesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkapModulesAndSubmodulesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkapModulesAndSubmodulesCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSkapModulesAndSubmodulesCountQuery(baseOptions: Apollo.QueryHookOptions<GetSkapModulesAndSubmodulesCountQuery, GetSkapModulesAndSubmodulesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSkapModulesAndSubmodulesCountQuery, GetSkapModulesAndSubmodulesCountQueryVariables>(GetSkapModulesAndSubmodulesCountDocument, options);
      }
export function useGetSkapModulesAndSubmodulesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSkapModulesAndSubmodulesCountQuery, GetSkapModulesAndSubmodulesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSkapModulesAndSubmodulesCountQuery, GetSkapModulesAndSubmodulesCountQueryVariables>(GetSkapModulesAndSubmodulesCountDocument, options);
        }
export type GetSkapModulesAndSubmodulesCountQueryHookResult = ReturnType<typeof useGetSkapModulesAndSubmodulesCountQuery>;
export type GetSkapModulesAndSubmodulesCountLazyQueryHookResult = ReturnType<typeof useGetSkapModulesAndSubmodulesCountLazyQuery>;
export type GetSkapModulesAndSubmodulesCountQueryResult = Apollo.QueryResult<GetSkapModulesAndSubmodulesCountQuery, GetSkapModulesAndSubmodulesCountQueryVariables>;
export const SendSkap_01NotificationDocument = gql`
    mutation SendSKAP_01Notification {
  sendNotificationToLineManager: sendNotification(type: "SKAP_LINE_MANAGERS_01") {
    isSuccess
  }
  sendNotificationToFrontLiners: sendNotification(type: "SKAP_FRONT_LINERS_01") {
    isSuccess
  }
}
    `;
export type SendSkap_01NotificationMutationFn = Apollo.MutationFunction<SendSkap_01NotificationMutation, SendSkap_01NotificationMutationVariables>;

/**
 * __useSendSkap_01NotificationMutation__
 *
 * To run a mutation, you first call `useSendSkap_01NotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSkap_01NotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSkap_01NotificationMutation, { data, loading, error }] = useSendSkap_01NotificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendSkap_01NotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendSkap_01NotificationMutation, SendSkap_01NotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSkap_01NotificationMutation, SendSkap_01NotificationMutationVariables>(SendSkap_01NotificationDocument, options);
      }
export type SendSkap_01NotificationMutationHookResult = ReturnType<typeof useSendSkap_01NotificationMutation>;
export type SendSkap_01NotificationMutationResult = Apollo.MutationResult<SendSkap_01NotificationMutation>;
export type SendSkap_01NotificationMutationOptions = Apollo.BaseMutationOptions<SendSkap_01NotificationMutation, SendSkap_01NotificationMutationVariables>;
export const KpIsListDocument = gql`
    query KPIsList($isGlobal: Boolean) {
  getAllKPI(isGlobal: $isGlobal) {
    id
    slug
    enTitle
    esTitle
  }
}
    `;

/**
 * __useKpIsListQuery__
 *
 * To run a query within a React component, call `useKpIsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpIsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpIsListQuery({
 *   variables: {
 *      isGlobal: // value for 'isGlobal'
 *   },
 * });
 */
export function useKpIsListQuery(baseOptions?: Apollo.QueryHookOptions<KpIsListQuery, KpIsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KpIsListQuery, KpIsListQueryVariables>(KpIsListDocument, options);
      }
export function useKpIsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KpIsListQuery, KpIsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KpIsListQuery, KpIsListQueryVariables>(KpIsListDocument, options);
        }
export type KpIsListQueryHookResult = ReturnType<typeof useKpIsListQuery>;
export type KpIsListLazyQueryHookResult = ReturnType<typeof useKpIsListLazyQuery>;
export type KpIsListQueryResult = Apollo.QueryResult<KpIsListQuery, KpIsListQueryVariables>;
export const GetRoutineAvailableInfoDocument = gql`
    query GetRoutineAvailableInfo($forFilter: Boolean) {
  getRoutinesAvailable(forFilter: $forFilter) {
    name
    slug
  }
}
    `;

/**
 * __useGetRoutineAvailableInfoQuery__
 *
 * To run a query within a React component, call `useGetRoutineAvailableInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutineAvailableInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutineAvailableInfoQuery({
 *   variables: {
 *      forFilter: // value for 'forFilter'
 *   },
 * });
 */
export function useGetRoutineAvailableInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetRoutineAvailableInfoQuery, GetRoutineAvailableInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoutineAvailableInfoQuery, GetRoutineAvailableInfoQueryVariables>(GetRoutineAvailableInfoDocument, options);
      }
export function useGetRoutineAvailableInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoutineAvailableInfoQuery, GetRoutineAvailableInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoutineAvailableInfoQuery, GetRoutineAvailableInfoQueryVariables>(GetRoutineAvailableInfoDocument, options);
        }
export type GetRoutineAvailableInfoQueryHookResult = ReturnType<typeof useGetRoutineAvailableInfoQuery>;
export type GetRoutineAvailableInfoLazyQueryHookResult = ReturnType<typeof useGetRoutineAvailableInfoLazyQuery>;
export type GetRoutineAvailableInfoQueryResult = Apollo.QueryResult<GetRoutineAvailableInfoQuery, GetRoutineAvailableInfoQueryVariables>;
export const GetAllManagementToolDocument = gql`
    query getAllManagementTool {
  getAllManagementTool {
    id
    slug
    enTitle
    esTitle
  }
}
    `;

/**
 * __useGetAllManagementToolQuery__
 *
 * To run a query within a React component, call `useGetAllManagementToolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllManagementToolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllManagementToolQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllManagementToolQuery(baseOptions?: Apollo.QueryHookOptions<GetAllManagementToolQuery, GetAllManagementToolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllManagementToolQuery, GetAllManagementToolQueryVariables>(GetAllManagementToolDocument, options);
      }
export function useGetAllManagementToolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllManagementToolQuery, GetAllManagementToolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllManagementToolQuery, GetAllManagementToolQueryVariables>(GetAllManagementToolDocument, options);
        }
export type GetAllManagementToolQueryHookResult = ReturnType<typeof useGetAllManagementToolQuery>;
export type GetAllManagementToolLazyQueryHookResult = ReturnType<typeof useGetAllManagementToolLazyQuery>;
export type GetAllManagementToolQueryResult = Apollo.QueryResult<GetAllManagementToolQuery, GetAllManagementToolQueryVariables>;
export const SearchedUsersWithAccessToLocationDocument = gql`
    query SearchedUsersWithAccessToLocation($searchString: String!, $locationSlug: String!) {
  searchUsersWithAccessToLocation(
    searchString: $searchString
    locationSlug: $locationSlug
  ) {
    name
    slug
    id
    picture
  }
}
    `;

/**
 * __useSearchedUsersWithAccessToLocationQuery__
 *
 * To run a query within a React component, call `useSearchedUsersWithAccessToLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchedUsersWithAccessToLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchedUsersWithAccessToLocationQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useSearchedUsersWithAccessToLocationQuery(baseOptions: Apollo.QueryHookOptions<SearchedUsersWithAccessToLocationQuery, SearchedUsersWithAccessToLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchedUsersWithAccessToLocationQuery, SearchedUsersWithAccessToLocationQueryVariables>(SearchedUsersWithAccessToLocationDocument, options);
      }
export function useSearchedUsersWithAccessToLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchedUsersWithAccessToLocationQuery, SearchedUsersWithAccessToLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchedUsersWithAccessToLocationQuery, SearchedUsersWithAccessToLocationQueryVariables>(SearchedUsersWithAccessToLocationDocument, options);
        }
export type SearchedUsersWithAccessToLocationQueryHookResult = ReturnType<typeof useSearchedUsersWithAccessToLocationQuery>;
export type SearchedUsersWithAccessToLocationLazyQueryHookResult = ReturnType<typeof useSearchedUsersWithAccessToLocationLazyQuery>;
export type SearchedUsersWithAccessToLocationQueryResult = Apollo.QueryResult<SearchedUsersWithAccessToLocationQuery, SearchedUsersWithAccessToLocationQueryVariables>;
export const ActionLogsCountGroupByLocationQueryDocument = gql`
    query ActionLogsCountGroupByLocationQuery($startTime: Date!, $endTime: Date!) {
  actionLogsCountGroupByLocation(startTime: $startTime, endTime: $endTime) {
    name
    slug
    actionLogsCount
    zones {
      name
      slug
      actionLogsCount
      subzones {
        name
        slug
        actionLogsCount
        locations {
          name
          slug
          cluster {
            name
            slug
          }
          actionLogsCount
        }
      }
    }
  }
}
    `;

/**
 * __useActionLogsCountGroupByLocationQueryQuery__
 *
 * To run a query within a React component, call `useActionLogsCountGroupByLocationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionLogsCountGroupByLocationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionLogsCountGroupByLocationQueryQuery({
 *   variables: {
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useActionLogsCountGroupByLocationQueryQuery(baseOptions: Apollo.QueryHookOptions<ActionLogsCountGroupByLocationQueryQuery, ActionLogsCountGroupByLocationQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActionLogsCountGroupByLocationQueryQuery, ActionLogsCountGroupByLocationQueryQueryVariables>(ActionLogsCountGroupByLocationQueryDocument, options);
      }
export function useActionLogsCountGroupByLocationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionLogsCountGroupByLocationQueryQuery, ActionLogsCountGroupByLocationQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActionLogsCountGroupByLocationQueryQuery, ActionLogsCountGroupByLocationQueryQueryVariables>(ActionLogsCountGroupByLocationQueryDocument, options);
        }
export type ActionLogsCountGroupByLocationQueryQueryHookResult = ReturnType<typeof useActionLogsCountGroupByLocationQueryQuery>;
export type ActionLogsCountGroupByLocationQueryLazyQueryHookResult = ReturnType<typeof useActionLogsCountGroupByLocationQueryLazyQuery>;
export type ActionLogsCountGroupByLocationQueryQueryResult = Apollo.QueryResult<ActionLogsCountGroupByLocationQueryQuery, ActionLogsCountGroupByLocationQueryQueryVariables>;
export const AllActionLogForLocationDocument = gql`
    query AllActionLogForLocation($actionLogsLocationSlug: String, $startTime: Date!, $endTime: Date!, $filters: ActionLogFilters) {
  groupActionLogsInDCByStatus(
    locationSlug: $actionLogsLocationSlug
    startTime: $startTime
    endTime: $endTime
    filters: $filters
  ) {
    OPEN {
      ...actionLogsInDCByStatus
    }
    IN_PROGRESS {
      ...actionLogsInDCByStatus
    }
    FOR_REVISION {
      ...actionLogsInDCByStatus
    }
    COMPLETED {
      ...actionLogsInDCByStatus
    }
  }
}
    ${ActionLogsInDcByStatusFragmentDoc}`;

/**
 * __useAllActionLogForLocationQuery__
 *
 * To run a query within a React component, call `useAllActionLogForLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActionLogForLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActionLogForLocationQuery({
 *   variables: {
 *      actionLogsLocationSlug: // value for 'actionLogsLocationSlug'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAllActionLogForLocationQuery(baseOptions: Apollo.QueryHookOptions<AllActionLogForLocationQuery, AllActionLogForLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllActionLogForLocationQuery, AllActionLogForLocationQueryVariables>(AllActionLogForLocationDocument, options);
      }
export function useAllActionLogForLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllActionLogForLocationQuery, AllActionLogForLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllActionLogForLocationQuery, AllActionLogForLocationQueryVariables>(AllActionLogForLocationDocument, options);
        }
export type AllActionLogForLocationQueryHookResult = ReturnType<typeof useAllActionLogForLocationQuery>;
export type AllActionLogForLocationLazyQueryHookResult = ReturnType<typeof useAllActionLogForLocationLazyQuery>;
export type AllActionLogForLocationQueryResult = Apollo.QueryResult<AllActionLogForLocationQuery, AllActionLogForLocationQueryVariables>;
export const AllCommentsForActionLogDocument = gql`
    query AllCommentsForActionLog($commentsActionLogId: ID!) {
  comments(actionLogId: $commentsActionLogId) {
    id
    comment
  }
}
    `;

/**
 * __useAllCommentsForActionLogQuery__
 *
 * To run a query within a React component, call `useAllCommentsForActionLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommentsForActionLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommentsForActionLogQuery({
 *   variables: {
 *      commentsActionLogId: // value for 'commentsActionLogId'
 *   },
 * });
 */
export function useAllCommentsForActionLogQuery(baseOptions: Apollo.QueryHookOptions<AllCommentsForActionLogQuery, AllCommentsForActionLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCommentsForActionLogQuery, AllCommentsForActionLogQueryVariables>(AllCommentsForActionLogDocument, options);
      }
export function useAllCommentsForActionLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCommentsForActionLogQuery, AllCommentsForActionLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCommentsForActionLogQuery, AllCommentsForActionLogQueryVariables>(AllCommentsForActionLogDocument, options);
        }
export type AllCommentsForActionLogQueryHookResult = ReturnType<typeof useAllCommentsForActionLogQuery>;
export type AllCommentsForActionLogLazyQueryHookResult = ReturnType<typeof useAllCommentsForActionLogLazyQuery>;
export type AllCommentsForActionLogQueryResult = Apollo.QueryResult<AllCommentsForActionLogQuery, AllCommentsForActionLogQueryVariables>;
export const AddOrEditActionLogCommentDocument = gql`
    mutation AddOrEditActionLogComment($actionLogId: ID!, $comment: String, $commentId: ID) {
  addOrEditComment(
    actionLogId: $actionLogId
    comment: $comment
    commentId: $commentId
  ) {
    isSuccess
  }
}
    `;
export type AddOrEditActionLogCommentMutationFn = Apollo.MutationFunction<AddOrEditActionLogCommentMutation, AddOrEditActionLogCommentMutationVariables>;

/**
 * __useAddOrEditActionLogCommentMutation__
 *
 * To run a mutation, you first call `useAddOrEditActionLogCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrEditActionLogCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrEditActionLogCommentMutation, { data, loading, error }] = useAddOrEditActionLogCommentMutation({
 *   variables: {
 *      actionLogId: // value for 'actionLogId'
 *      comment: // value for 'comment'
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useAddOrEditActionLogCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddOrEditActionLogCommentMutation, AddOrEditActionLogCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrEditActionLogCommentMutation, AddOrEditActionLogCommentMutationVariables>(AddOrEditActionLogCommentDocument, options);
      }
export type AddOrEditActionLogCommentMutationHookResult = ReturnType<typeof useAddOrEditActionLogCommentMutation>;
export type AddOrEditActionLogCommentMutationResult = Apollo.MutationResult<AddOrEditActionLogCommentMutation>;
export type AddOrEditActionLogCommentMutationOptions = Apollo.BaseMutationOptions<AddOrEditActionLogCommentMutation, AddOrEditActionLogCommentMutationVariables>;
export const DetailedCommentsForActionLogDocument = gql`
    query DetailedCommentsForActionLog($commentsActionLogId: ID!) {
  comments(actionLogId: $commentsActionLogId, orderByCreatedAt: DESC) {
    id
    comment
    createdAt
    updatedAt
    user {
      name
      slug
      picture
    }
  }
}
    `;

/**
 * __useDetailedCommentsForActionLogQuery__
 *
 * To run a query within a React component, call `useDetailedCommentsForActionLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailedCommentsForActionLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailedCommentsForActionLogQuery({
 *   variables: {
 *      commentsActionLogId: // value for 'commentsActionLogId'
 *   },
 * });
 */
export function useDetailedCommentsForActionLogQuery(baseOptions: Apollo.QueryHookOptions<DetailedCommentsForActionLogQuery, DetailedCommentsForActionLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailedCommentsForActionLogQuery, DetailedCommentsForActionLogQueryVariables>(DetailedCommentsForActionLogDocument, options);
      }
export function useDetailedCommentsForActionLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailedCommentsForActionLogQuery, DetailedCommentsForActionLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailedCommentsForActionLogQuery, DetailedCommentsForActionLogQueryVariables>(DetailedCommentsForActionLogDocument, options);
        }
export type DetailedCommentsForActionLogQueryHookResult = ReturnType<typeof useDetailedCommentsForActionLogQuery>;
export type DetailedCommentsForActionLogLazyQueryHookResult = ReturnType<typeof useDetailedCommentsForActionLogLazyQuery>;
export type DetailedCommentsForActionLogQueryResult = Apollo.QueryResult<DetailedCommentsForActionLogQuery, DetailedCommentsForActionLogQueryVariables>;
export const SummaryForActionLogDocument = gql`
    query SummaryForActionLog($actionLogId: ID!) {
  actionLog(id: $actionLogId) {
    id
    startDate
    description
    successCriteria
    KPIs
    KPI {
      esTitle
      enTitle
      slug
    }
    ManagementTool {
      esTitle
      enTitle
      slug
      id
    }
    endDate
    status
    pillar
    owner {
      id
      name
      slug
      picture
    }
    responsibles {
      id
      name
      slug
      picture
    }
    location {
      slug
      name
    }
    origin
    originText
    meeting
    note
    images {
      id
      url
    }
    statusUpdateDate
    routinesAvailable {
      name
      slug
    }
  }
}
    `;

/**
 * __useSummaryForActionLogQuery__
 *
 * To run a query within a React component, call `useSummaryForActionLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummaryForActionLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummaryForActionLogQuery({
 *   variables: {
 *      actionLogId: // value for 'actionLogId'
 *   },
 * });
 */
export function useSummaryForActionLogQuery(baseOptions: Apollo.QueryHookOptions<SummaryForActionLogQuery, SummaryForActionLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummaryForActionLogQuery, SummaryForActionLogQueryVariables>(SummaryForActionLogDocument, options);
      }
export function useSummaryForActionLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummaryForActionLogQuery, SummaryForActionLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummaryForActionLogQuery, SummaryForActionLogQueryVariables>(SummaryForActionLogDocument, options);
        }
export type SummaryForActionLogQueryHookResult = ReturnType<typeof useSummaryForActionLogQuery>;
export type SummaryForActionLogLazyQueryHookResult = ReturnType<typeof useSummaryForActionLogLazyQuery>;
export type SummaryForActionLogQueryResult = Apollo.QueryResult<SummaryForActionLogQuery, SummaryForActionLogQueryVariables>;
export const DeleteActionLogCommentDocument = gql`
    mutation deleteActionLogComment($commentId: ID!) {
  deleteComment(commentId: $commentId) {
    isSuccess
  }
}
    `;
export type DeleteActionLogCommentMutationFn = Apollo.MutationFunction<DeleteActionLogCommentMutation, DeleteActionLogCommentMutationVariables>;

/**
 * __useDeleteActionLogCommentMutation__
 *
 * To run a mutation, you first call `useDeleteActionLogCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionLogCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionLogCommentMutation, { data, loading, error }] = useDeleteActionLogCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteActionLogCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActionLogCommentMutation, DeleteActionLogCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActionLogCommentMutation, DeleteActionLogCommentMutationVariables>(DeleteActionLogCommentDocument, options);
      }
export type DeleteActionLogCommentMutationHookResult = ReturnType<typeof useDeleteActionLogCommentMutation>;
export type DeleteActionLogCommentMutationResult = Apollo.MutationResult<DeleteActionLogCommentMutation>;
export type DeleteActionLogCommentMutationOptions = Apollo.BaseMutationOptions<DeleteActionLogCommentMutation, DeleteActionLogCommentMutationVariables>;
export const ActionLogStatusDocument = gql`
    mutation ActionLogStatus($actionLogId: Int!, $status: ACTION_LOG_STATUS!, $isOwnerCheckSurpassed: Boolean) {
  updateActionLogStatus(
    actionLogId: $actionLogId
    status: $status
    isOwnerCheckSurpassed: $isOwnerCheckSurpassed
  ) {
    isSuccess
  }
}
    `;
export type ActionLogStatusMutationFn = Apollo.MutationFunction<ActionLogStatusMutation, ActionLogStatusMutationVariables>;

/**
 * __useActionLogStatusMutation__
 *
 * To run a mutation, you first call `useActionLogStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionLogStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionLogStatusMutation, { data, loading, error }] = useActionLogStatusMutation({
 *   variables: {
 *      actionLogId: // value for 'actionLogId'
 *      status: // value for 'status'
 *      isOwnerCheckSurpassed: // value for 'isOwnerCheckSurpassed'
 *   },
 * });
 */
export function useActionLogStatusMutation(baseOptions?: Apollo.MutationHookOptions<ActionLogStatusMutation, ActionLogStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActionLogStatusMutation, ActionLogStatusMutationVariables>(ActionLogStatusDocument, options);
      }
export type ActionLogStatusMutationHookResult = ReturnType<typeof useActionLogStatusMutation>;
export type ActionLogStatusMutationResult = Apollo.MutationResult<ActionLogStatusMutation>;
export type ActionLogStatusMutationOptions = Apollo.BaseMutationOptions<ActionLogStatusMutation, ActionLogStatusMutationVariables>;
export const CreateEditActionLogDocument = gql`
    mutation CreateEditActionLog($payload: CreateEditActionLogPayload!) {
  createEditActionLog(payload: $payload) {
    isSuccess
  }
}
    `;
export type CreateEditActionLogMutationFn = Apollo.MutationFunction<CreateEditActionLogMutation, CreateEditActionLogMutationVariables>;

/**
 * __useCreateEditActionLogMutation__
 *
 * To run a mutation, you first call `useCreateEditActionLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditActionLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditActionLogMutation, { data, loading, error }] = useCreateEditActionLogMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateEditActionLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateEditActionLogMutation, CreateEditActionLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEditActionLogMutation, CreateEditActionLogMutationVariables>(CreateEditActionLogDocument, options);
      }
export type CreateEditActionLogMutationHookResult = ReturnType<typeof useCreateEditActionLogMutation>;
export type CreateEditActionLogMutationResult = Apollo.MutationResult<CreateEditActionLogMutation>;
export type CreateEditActionLogMutationOptions = Apollo.BaseMutationOptions<CreateEditActionLogMutation, CreateEditActionLogMutationVariables>;
export const DeleteActionLogDocument = gql`
    mutation DeleteActionLog($actionLogId: Int!) {
  deleteActionLog(actionLogId: $actionLogId) {
    isSuccess
  }
}
    `;
export type DeleteActionLogMutationFn = Apollo.MutationFunction<DeleteActionLogMutation, DeleteActionLogMutationVariables>;

/**
 * __useDeleteActionLogMutation__
 *
 * To run a mutation, you first call `useDeleteActionLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionLogMutation, { data, loading, error }] = useDeleteActionLogMutation({
 *   variables: {
 *      actionLogId: // value for 'actionLogId'
 *   },
 * });
 */
export function useDeleteActionLogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActionLogMutation, DeleteActionLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActionLogMutation, DeleteActionLogMutationVariables>(DeleteActionLogDocument, options);
      }
export type DeleteActionLogMutationHookResult = ReturnType<typeof useDeleteActionLogMutation>;
export type DeleteActionLogMutationResult = Apollo.MutationResult<DeleteActionLogMutation>;
export type DeleteActionLogMutationOptions = Apollo.BaseMutationOptions<DeleteActionLogMutation, DeleteActionLogMutationVariables>;
export const CreateKpiDocument = gql`
    mutation CreateKpi($kpi: String!) {
  createKPI(kpi: $kpi) {
    id
    slug
    enTitle
    esTitle
  }
}
    `;
export type CreateKpiMutationFn = Apollo.MutationFunction<CreateKpiMutation, CreateKpiMutationVariables>;

/**
 * __useCreateKpiMutation__
 *
 * To run a mutation, you first call `useCreateKpiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKpiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKpiMutation, { data, loading, error }] = useCreateKpiMutation({
 *   variables: {
 *      kpi: // value for 'kpi'
 *   },
 * });
 */
export function useCreateKpiMutation(baseOptions?: Apollo.MutationHookOptions<CreateKpiMutation, CreateKpiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKpiMutation, CreateKpiMutationVariables>(CreateKpiDocument, options);
      }
export type CreateKpiMutationHookResult = ReturnType<typeof useCreateKpiMutation>;
export type CreateKpiMutationResult = Apollo.MutationResult<CreateKpiMutation>;
export type CreateKpiMutationOptions = Apollo.BaseMutationOptions<CreateKpiMutation, CreateKpiMutationVariables>;
export const GetDashboardTableDataDocument = gql`
    query getDashboardTableData($locationIds: [ID]!, $groupBy: JSON, $date: JSON) {
  getDashboardTableData(locationIds: $locationIds, groupBy: $groupBy, date: $date)
}
    `;

/**
 * __useGetDashboardTableDataQuery__
 *
 * To run a query within a React component, call `useGetDashboardTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardTableDataQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      groupBy: // value for 'groupBy'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetDashboardTableDataQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardTableDataQuery, GetDashboardTableDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardTableDataQuery, GetDashboardTableDataQueryVariables>(GetDashboardTableDataDocument, options);
      }
export function useGetDashboardTableDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardTableDataQuery, GetDashboardTableDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardTableDataQuery, GetDashboardTableDataQueryVariables>(GetDashboardTableDataDocument, options);
        }
export type GetDashboardTableDataQueryHookResult = ReturnType<typeof useGetDashboardTableDataQuery>;
export type GetDashboardTableDataLazyQueryHookResult = ReturnType<typeof useGetDashboardTableDataLazyQuery>;
export type GetDashboardTableDataQueryResult = Apollo.QueryResult<GetDashboardTableDataQuery, GetDashboardTableDataQueryVariables>;
export const AddOrEditCapexCommentDocument = gql`
    mutation AddOrEditCapexComment($capexId: ID!, $comment: String!, $commentId: ID) {
  addOrEditCapexComment(
    capexId: $capexId
    comment: $comment
    commentId: $commentId
  ) {
    isSuccess
  }
}
    `;
export type AddOrEditCapexCommentMutationFn = Apollo.MutationFunction<AddOrEditCapexCommentMutation, AddOrEditCapexCommentMutationVariables>;

/**
 * __useAddOrEditCapexCommentMutation__
 *
 * To run a mutation, you first call `useAddOrEditCapexCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrEditCapexCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrEditCapexCommentMutation, { data, loading, error }] = useAddOrEditCapexCommentMutation({
 *   variables: {
 *      capexId: // value for 'capexId'
 *      comment: // value for 'comment'
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useAddOrEditCapexCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddOrEditCapexCommentMutation, AddOrEditCapexCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrEditCapexCommentMutation, AddOrEditCapexCommentMutationVariables>(AddOrEditCapexCommentDocument, options);
      }
export type AddOrEditCapexCommentMutationHookResult = ReturnType<typeof useAddOrEditCapexCommentMutation>;
export type AddOrEditCapexCommentMutationResult = Apollo.MutationResult<AddOrEditCapexCommentMutation>;
export type AddOrEditCapexCommentMutationOptions = Apollo.BaseMutationOptions<AddOrEditCapexCommentMutation, AddOrEditCapexCommentMutationVariables>;
export const DeleteCapexCommentDocument = gql`
    mutation DeleteCapexComment($commentId: ID!) {
  deleteCapexComment(commentId: $commentId) {
    isSuccess
  }
}
    `;
export type DeleteCapexCommentMutationFn = Apollo.MutationFunction<DeleteCapexCommentMutation, DeleteCapexCommentMutationVariables>;

/**
 * __useDeleteCapexCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCapexCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCapexCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCapexCommentMutation, { data, loading, error }] = useDeleteCapexCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCapexCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCapexCommentMutation, DeleteCapexCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCapexCommentMutation, DeleteCapexCommentMutationVariables>(DeleteCapexCommentDocument, options);
      }
export type DeleteCapexCommentMutationHookResult = ReturnType<typeof useDeleteCapexCommentMutation>;
export type DeleteCapexCommentMutationResult = Apollo.MutationResult<DeleteCapexCommentMutation>;
export type DeleteCapexCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCapexCommentMutation, DeleteCapexCommentMutationVariables>;
export const CapexCommentsDocument = gql`
    query CapexComments($capexId: ID!) {
  capexComments(capexId: $capexId, orderByCreatedAt: DESC) {
    id
    comment
    createdAt
    updatedAt
    user {
      name
      slug
      picture
    }
  }
}
    `;

/**
 * __useCapexCommentsQuery__
 *
 * To run a query within a React component, call `useCapexCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapexCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapexCommentsQuery({
 *   variables: {
 *      capexId: // value for 'capexId'
 *   },
 * });
 */
export function useCapexCommentsQuery(baseOptions: Apollo.QueryHookOptions<CapexCommentsQuery, CapexCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CapexCommentsQuery, CapexCommentsQueryVariables>(CapexCommentsDocument, options);
      }
export function useCapexCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CapexCommentsQuery, CapexCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CapexCommentsQuery, CapexCommentsQueryVariables>(CapexCommentsDocument, options);
        }
export type CapexCommentsQueryHookResult = ReturnType<typeof useCapexCommentsQuery>;
export type CapexCommentsLazyQueryHookResult = ReturnType<typeof useCapexCommentsLazyQuery>;
export type CapexCommentsQueryResult = Apollo.QueryResult<CapexCommentsQuery, CapexCommentsQueryVariables>;
export const GetIncidentsByMonthsCategoriesAndTypeDocument = gql`
    query getIncidentsByMonthsCategoriesAndType($monthNumber: Int!, $year: Int!) {
  getIncidentsBetweenRange(monthNumber: $monthNumber, year: $year) {
    bu
    month
    weeks {
      week
      totalIncidents
    }
  }
  getIncidentsByCategories(monthNumber: $monthNumber, year: $year) {
    categoryName
    bus {
      name
      totalIncidents
    }
  }
  getIncidentsByMonths(monthNumber: $monthNumber, year: $year) {
    bu
    months {
      name
      totalIncidents
    }
  }
  topBest: getIncidentsByType(
    by: cd
    type: best
    monthNumber: $monthNumber
    year: $year
  ) {
    bu
    name
    totalIncidents
  }
  topOffenders: getIncidentsByType(
    by: cd
    type: offender
    monthNumber: $monthNumber
    year: $year
  ) {
    bu
    name
    totalIncidents
  }
  topVehicleOffenders: getIncidentsByType(
    by: vehical
    type: offender
    monthNumber: $monthNumber
    year: $year
  ) {
    bu
    name
    totalIncidents
  }
}
    `;

/**
 * __useGetIncidentsByMonthsCategoriesAndTypeQuery__
 *
 * To run a query within a React component, call `useGetIncidentsByMonthsCategoriesAndTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentsByMonthsCategoriesAndTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentsByMonthsCategoriesAndTypeQuery({
 *   variables: {
 *      monthNumber: // value for 'monthNumber'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetIncidentsByMonthsCategoriesAndTypeQuery(baseOptions: Apollo.QueryHookOptions<GetIncidentsByMonthsCategoriesAndTypeQuery, GetIncidentsByMonthsCategoriesAndTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncidentsByMonthsCategoriesAndTypeQuery, GetIncidentsByMonthsCategoriesAndTypeQueryVariables>(GetIncidentsByMonthsCategoriesAndTypeDocument, options);
      }
export function useGetIncidentsByMonthsCategoriesAndTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncidentsByMonthsCategoriesAndTypeQuery, GetIncidentsByMonthsCategoriesAndTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncidentsByMonthsCategoriesAndTypeQuery, GetIncidentsByMonthsCategoriesAndTypeQueryVariables>(GetIncidentsByMonthsCategoriesAndTypeDocument, options);
        }
export type GetIncidentsByMonthsCategoriesAndTypeQueryHookResult = ReturnType<typeof useGetIncidentsByMonthsCategoriesAndTypeQuery>;
export type GetIncidentsByMonthsCategoriesAndTypeLazyQueryHookResult = ReturnType<typeof useGetIncidentsByMonthsCategoriesAndTypeLazyQuery>;
export type GetIncidentsByMonthsCategoriesAndTypeQueryResult = Apollo.QueryResult<GetIncidentsByMonthsCategoriesAndTypeQuery, GetIncidentsByMonthsCategoriesAndTypeQueryVariables>;
export const GetIncidentsByTypePerBuDocument = gql`
    query getIncidentsByTypePerBu($monthNumber: Int!, $year: Int!, $bu: String) {
  topBest: getIncidentsByType(
    by: cd
    type: best
    monthNumber: $monthNumber
    year: $year
    filterOnCountry: $bu
  ) {
    bu
    name
    totalIncidents
  }
  topOffenders: getIncidentsByType(
    by: cd
    type: offender
    monthNumber: $monthNumber
    year: $year
    filterOnCountry: $bu
  ) {
    bu
    name
    totalIncidents
  }
  topVehicleOffenders: getIncidentsByType(
    by: vehical
    type: offender
    monthNumber: $monthNumber
    year: $year
    filterOnCountry: $bu
  ) {
    bu
    name
    totalIncidents
  }
}
    `;

/**
 * __useGetIncidentsByTypePerBuQuery__
 *
 * To run a query within a React component, call `useGetIncidentsByTypePerBuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentsByTypePerBuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentsByTypePerBuQuery({
 *   variables: {
 *      monthNumber: // value for 'monthNumber'
 *      year: // value for 'year'
 *      bu: // value for 'bu'
 *   },
 * });
 */
export function useGetIncidentsByTypePerBuQuery(baseOptions: Apollo.QueryHookOptions<GetIncidentsByTypePerBuQuery, GetIncidentsByTypePerBuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncidentsByTypePerBuQuery, GetIncidentsByTypePerBuQueryVariables>(GetIncidentsByTypePerBuDocument, options);
      }
export function useGetIncidentsByTypePerBuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncidentsByTypePerBuQuery, GetIncidentsByTypePerBuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncidentsByTypePerBuQuery, GetIncidentsByTypePerBuQueryVariables>(GetIncidentsByTypePerBuDocument, options);
        }
export type GetIncidentsByTypePerBuQueryHookResult = ReturnType<typeof useGetIncidentsByTypePerBuQuery>;
export type GetIncidentsByTypePerBuLazyQueryHookResult = ReturnType<typeof useGetIncidentsByTypePerBuLazyQuery>;
export type GetIncidentsByTypePerBuQueryResult = Apollo.QueryResult<GetIncidentsByTypePerBuQuery, GetIncidentsByTypePerBuQueryVariables>;
export const GetTrainingsDocument = gql`
    query getTrainings($data: GetTrainingsPayload!, $by: GetTrainingsBy!, $isFindOne: Boolean) {
  getTrainings(data: $data, by: $by, isFindOne: $isFindOne) {
    usersToTrain
    usersTrained
    name
    percentage
  }
}
    `;

/**
 * __useGetTrainingsQuery__
 *
 * To run a query within a React component, call `useGetTrainingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrainingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrainingsQuery({
 *   variables: {
 *      data: // value for 'data'
 *      by: // value for 'by'
 *      isFindOne: // value for 'isFindOne'
 *   },
 * });
 */
export function useGetTrainingsQuery(baseOptions: Apollo.QueryHookOptions<GetTrainingsQuery, GetTrainingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrainingsQuery, GetTrainingsQueryVariables>(GetTrainingsDocument, options);
      }
export function useGetTrainingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrainingsQuery, GetTrainingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrainingsQuery, GetTrainingsQueryVariables>(GetTrainingsDocument, options);
        }
export type GetTrainingsQueryHookResult = ReturnType<typeof useGetTrainingsQuery>;
export type GetTrainingsLazyQueryHookResult = ReturnType<typeof useGetTrainingsLazyQuery>;
export type GetTrainingsQueryResult = Apollo.QueryResult<GetTrainingsQuery, GetTrainingsQueryVariables>;
export const GetTrainingsByPositionDocument = gql`
    query getTrainingsByPosition($data: GetTrainingsPayload!, $by: GetTrainingsBy!, $isFindOne: Boolean) {
  getTrainings(data: $data, by: $by, isFindOne: $isFindOne) {
    usersToTrain
    usersTrained
    position
    percentage
  }
}
    `;

/**
 * __useGetTrainingsByPositionQuery__
 *
 * To run a query within a React component, call `useGetTrainingsByPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrainingsByPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrainingsByPositionQuery({
 *   variables: {
 *      data: // value for 'data'
 *      by: // value for 'by'
 *      isFindOne: // value for 'isFindOne'
 *   },
 * });
 */
export function useGetTrainingsByPositionQuery(baseOptions: Apollo.QueryHookOptions<GetTrainingsByPositionQuery, GetTrainingsByPositionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrainingsByPositionQuery, GetTrainingsByPositionQueryVariables>(GetTrainingsByPositionDocument, options);
      }
export function useGetTrainingsByPositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrainingsByPositionQuery, GetTrainingsByPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrainingsByPositionQuery, GetTrainingsByPositionQueryVariables>(GetTrainingsByPositionDocument, options);
        }
export type GetTrainingsByPositionQueryHookResult = ReturnType<typeof useGetTrainingsByPositionQuery>;
export type GetTrainingsByPositionLazyQueryHookResult = ReturnType<typeof useGetTrainingsByPositionLazyQuery>;
export type GetTrainingsByPositionQueryResult = Apollo.QueryResult<GetTrainingsByPositionQuery, GetTrainingsByPositionQueryVariables>;
export const GetSifStatsDocument = gql`
    query getSifStats($filter: filterParams!, $by: GetSifStatsBy!, $year: Int!) {
  getSifStats(filter: $filter, by: $by, year: $year) {
    name
    totalSif
  }
}
    `;

/**
 * __useGetSifStatsQuery__
 *
 * To run a query within a React component, call `useGetSifStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSifStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSifStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      by: // value for 'by'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetSifStatsQuery(baseOptions: Apollo.QueryHookOptions<GetSifStatsQuery, GetSifStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSifStatsQuery, GetSifStatsQueryVariables>(GetSifStatsDocument, options);
      }
export function useGetSifStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSifStatsQuery, GetSifStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSifStatsQuery, GetSifStatsQueryVariables>(GetSifStatsDocument, options);
        }
export type GetSifStatsQueryHookResult = ReturnType<typeof useGetSifStatsQuery>;
export type GetSifStatsLazyQueryHookResult = ReturnType<typeof useGetSifStatsLazyQuery>;
export type GetSifStatsQueryResult = Apollo.QueryResult<GetSifStatsQuery, GetSifStatsQueryVariables>;
export const GetSifStatsByTypeDocument = gql`
    query getSifStatsByType($filter: filterParamsByType, $year: Int!) {
  getSifStatsByType(filter: $filter, year: $year) {
    month
    sifType {
      name
      totalSif
    }
  }
}
    `;

/**
 * __useGetSifStatsByTypeQuery__
 *
 * To run a query within a React component, call `useGetSifStatsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSifStatsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSifStatsByTypeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetSifStatsByTypeQuery(baseOptions: Apollo.QueryHookOptions<GetSifStatsByTypeQuery, GetSifStatsByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSifStatsByTypeQuery, GetSifStatsByTypeQueryVariables>(GetSifStatsByTypeDocument, options);
      }
export function useGetSifStatsByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSifStatsByTypeQuery, GetSifStatsByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSifStatsByTypeQuery, GetSifStatsByTypeQueryVariables>(GetSifStatsByTypeDocument, options);
        }
export type GetSifStatsByTypeQueryHookResult = ReturnType<typeof useGetSifStatsByTypeQuery>;
export type GetSifStatsByTypeLazyQueryHookResult = ReturnType<typeof useGetSifStatsByTypeLazyQuery>;
export type GetSifStatsByTypeQueryResult = Apollo.QueryResult<GetSifStatsByTypeQuery, GetSifStatsByTypeQueryVariables>;
export const ListAllSifStatsByMonthDocument = gql`
    query listAllSifStatsByMonth($monthNumber: Int!, $year: Int!, $filter: filterParams!) {
  listAllSifStatsByMonth(monthNumber: $monthNumber, year: $year, filter: $filter) {
    reference
    classificationOfInjury
    location
    incidentCause
    nature
    department
    incidentDate
    shiftType
    incidentTime
  }
}
    `;

/**
 * __useListAllSifStatsByMonthQuery__
 *
 * To run a query within a React component, call `useListAllSifStatsByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllSifStatsByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllSifStatsByMonthQuery({
 *   variables: {
 *      monthNumber: // value for 'monthNumber'
 *      year: // value for 'year'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListAllSifStatsByMonthQuery(baseOptions: Apollo.QueryHookOptions<ListAllSifStatsByMonthQuery, ListAllSifStatsByMonthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllSifStatsByMonthQuery, ListAllSifStatsByMonthQueryVariables>(ListAllSifStatsByMonthDocument, options);
      }
export function useListAllSifStatsByMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllSifStatsByMonthQuery, ListAllSifStatsByMonthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllSifStatsByMonthQuery, ListAllSifStatsByMonthQueryVariables>(ListAllSifStatsByMonthDocument, options);
        }
export type ListAllSifStatsByMonthQueryHookResult = ReturnType<typeof useListAllSifStatsByMonthQuery>;
export type ListAllSifStatsByMonthLazyQueryHookResult = ReturnType<typeof useListAllSifStatsByMonthLazyQuery>;
export type ListAllSifStatsByMonthQueryResult = Apollo.QueryResult<ListAllSifStatsByMonthQuery, ListAllSifStatsByMonthQueryVariables>;
export const GetSifStatsByDurationDocument = gql`
    query getSifStatsByDuration($monthNumber: Int!, $year: Int!, $filter: filterParams!) {
  getSifStatsByDuration(monthNumber: $monthNumber, year: $year, filter: $filter) {
    duration
    totalSif
  }
}
    `;

/**
 * __useGetSifStatsByDurationQuery__
 *
 * To run a query within a React component, call `useGetSifStatsByDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSifStatsByDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSifStatsByDurationQuery({
 *   variables: {
 *      monthNumber: // value for 'monthNumber'
 *      year: // value for 'year'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSifStatsByDurationQuery(baseOptions: Apollo.QueryHookOptions<GetSifStatsByDurationQuery, GetSifStatsByDurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSifStatsByDurationQuery, GetSifStatsByDurationQueryVariables>(GetSifStatsByDurationDocument, options);
      }
export function useGetSifStatsByDurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSifStatsByDurationQuery, GetSifStatsByDurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSifStatsByDurationQuery, GetSifStatsByDurationQueryVariables>(GetSifStatsByDurationDocument, options);
        }
export type GetSifStatsByDurationQueryHookResult = ReturnType<typeof useGetSifStatsByDurationQuery>;
export type GetSifStatsByDurationLazyQueryHookResult = ReturnType<typeof useGetSifStatsByDurationLazyQuery>;
export type GetSifStatsByDurationQueryResult = Apollo.QueryResult<GetSifStatsByDurationQuery, GetSifStatsByDurationQueryVariables>;
export const GetNoteDocument = gql`
    query GetNote($bu: String!) {
  getNote(bu: $bu) {
    id
    bu
    content
    updatedAt
    createdAt
    user {
      id
      name
      email
    }
  }
}
    `;

/**
 * __useGetNoteQuery__
 *
 * To run a query within a React component, call `useGetNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoteQuery({
 *   variables: {
 *      bu: // value for 'bu'
 *   },
 * });
 */
export function useGetNoteQuery(baseOptions: Apollo.QueryHookOptions<GetNoteQuery, GetNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNoteQuery, GetNoteQueryVariables>(GetNoteDocument, options);
      }
export function useGetNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNoteQuery, GetNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNoteQuery, GetNoteQueryVariables>(GetNoteDocument, options);
        }
export type GetNoteQueryHookResult = ReturnType<typeof useGetNoteQuery>;
export type GetNoteLazyQueryHookResult = ReturnType<typeof useGetNoteLazyQuery>;
export type GetNoteQueryResult = Apollo.QueryResult<GetNoteQuery, GetNoteQueryVariables>;
export const CreateNoteDocument = gql`
    mutation createNote($input: NoteInput!) {
  createNote(input: $input)
}
    `;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const GetCovidUsersForLocationsDocument = gql`
    query GetCovidUsersForLocations($locationsToAccess: [ID!]!, $offset: Int!, $limit: Int!) {
  getCovidUsersForLocations(
    locationIds: $locationsToAccess
    offset: $offset
    limit: $limit
  ) {
    count
    covidUsersForLocation {
      name
      employeeNumber
      quarantineUntil
      doctorStatus
      location {
        name
      }
      latestCovidTest {
        type
      }
    }
  }
}
    `;

/**
 * __useGetCovidUsersForLocationsQuery__
 *
 * To run a query within a React component, call `useGetCovidUsersForLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCovidUsersForLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCovidUsersForLocationsQuery({
 *   variables: {
 *      locationsToAccess: // value for 'locationsToAccess'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCovidUsersForLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetCovidUsersForLocationsQuery, GetCovidUsersForLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCovidUsersForLocationsQuery, GetCovidUsersForLocationsQueryVariables>(GetCovidUsersForLocationsDocument, options);
      }
export function useGetCovidUsersForLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCovidUsersForLocationsQuery, GetCovidUsersForLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCovidUsersForLocationsQuery, GetCovidUsersForLocationsQueryVariables>(GetCovidUsersForLocationsDocument, options);
        }
export type GetCovidUsersForLocationsQueryHookResult = ReturnType<typeof useGetCovidUsersForLocationsQuery>;
export type GetCovidUsersForLocationsLazyQueryHookResult = ReturnType<typeof useGetCovidUsersForLocationsLazyQuery>;
export type GetCovidUsersForLocationsQueryResult = Apollo.QueryResult<GetCovidUsersForLocationsQuery, GetCovidUsersForLocationsQueryVariables>;
export const LastFileUploadDateQueryDocument = gql`
    query LastFileUploadDateQuery {
  lastFileUploadDate
}
    `;

/**
 * __useLastFileUploadDateQueryQuery__
 *
 * To run a query within a React component, call `useLastFileUploadDateQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastFileUploadDateQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastFileUploadDateQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastFileUploadDateQueryQuery(baseOptions?: Apollo.QueryHookOptions<LastFileUploadDateQueryQuery, LastFileUploadDateQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastFileUploadDateQueryQuery, LastFileUploadDateQueryQueryVariables>(LastFileUploadDateQueryDocument, options);
      }
export function useLastFileUploadDateQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastFileUploadDateQueryQuery, LastFileUploadDateQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastFileUploadDateQueryQuery, LastFileUploadDateQueryQueryVariables>(LastFileUploadDateQueryDocument, options);
        }
export type LastFileUploadDateQueryQueryHookResult = ReturnType<typeof useLastFileUploadDateQueryQuery>;
export type LastFileUploadDateQueryLazyQueryHookResult = ReturnType<typeof useLastFileUploadDateQueryLazyQuery>;
export type LastFileUploadDateQueryQueryResult = Apollo.QueryResult<LastFileUploadDateQueryQuery, LastFileUploadDateQueryQueryVariables>;
export const GetAllLocationsDocument = gql`
    query getAllLocations($type: LOCATION_TYPE) {
  getLocations(type: $type) {
    id
    name
    slug
  }
}
    `;

/**
 * __useGetAllLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLocationsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetAllLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllLocationsQuery, GetAllLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllLocationsQuery, GetAllLocationsQueryVariables>(GetAllLocationsDocument, options);
      }
export function useGetAllLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllLocationsQuery, GetAllLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllLocationsQuery, GetAllLocationsQueryVariables>(GetAllLocationsDocument, options);
        }
export type GetAllLocationsQueryHookResult = ReturnType<typeof useGetAllLocationsQuery>;
export type GetAllLocationsLazyQueryHookResult = ReturnType<typeof useGetAllLocationsLazyQuery>;
export type GetAllLocationsQueryResult = Apollo.QueryResult<GetAllLocationsQuery, GetAllLocationsQueryVariables>;
export const GetUserInfoDocument = gql`
    query GetUserInfo($id: ID!) {
  getUserInfo(id: $id) {
    id
    name
    slug
    email
    type
    position
    language
    isBlocked
    shortSlug
    picture
    pin
    blockedReason
    lastActiveAt
    createdAt
    employeeNumber
    doctorStatus
    info {
      isAuthorisedForOWD
      id
      userId
    }
    distributionCenter {
      id
      name
      bu
      type
    }
  }
}
    `;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
      }
export function useGetUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
        }
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
export const GetUserWorkLocationsDocument = gql`
    query getUserWorkLocations($userId: [Int]!) {
  getUserWorkLocations(user: $userId) {
    id
    userId
    workLocation {
      id
      name
      nameEN
      slug
    }
    subLocation {
      id
      name
      nameEN
      slug
    }
    subArea {
      id
      name
      nameEn
      slug
    }
  }
}
    `;

/**
 * __useGetUserWorkLocationsQuery__
 *
 * To run a query within a React component, call `useGetUserWorkLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWorkLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWorkLocationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserWorkLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetUserWorkLocationsQuery, GetUserWorkLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWorkLocationsQuery, GetUserWorkLocationsQueryVariables>(GetUserWorkLocationsDocument, options);
      }
export function useGetUserWorkLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWorkLocationsQuery, GetUserWorkLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWorkLocationsQuery, GetUserWorkLocationsQueryVariables>(GetUserWorkLocationsDocument, options);
        }
export type GetUserWorkLocationsQueryHookResult = ReturnType<typeof useGetUserWorkLocationsQuery>;
export type GetUserWorkLocationsLazyQueryHookResult = ReturnType<typeof useGetUserWorkLocationsLazyQuery>;
export type GetUserWorkLocationsQueryResult = Apollo.QueryResult<GetUserWorkLocationsQuery, GetUserWorkLocationsQueryVariables>;
export const GetAccessLocationsDocument = gql`
    query GetAccessLocations {
  getAccessLocations {
    id
    name
  }
}
    `;

/**
 * __useGetAccessLocationsQuery__
 *
 * To run a query within a React component, call `useGetAccessLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccessLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccessLocationsQuery, GetAccessLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessLocationsQuery, GetAccessLocationsQueryVariables>(GetAccessLocationsDocument, options);
      }
export function useGetAccessLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessLocationsQuery, GetAccessLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessLocationsQuery, GetAccessLocationsQueryVariables>(GetAccessLocationsDocument, options);
        }
export type GetAccessLocationsQueryHookResult = ReturnType<typeof useGetAccessLocationsQuery>;
export type GetAccessLocationsLazyQueryHookResult = ReturnType<typeof useGetAccessLocationsLazyQuery>;
export type GetAccessLocationsQueryResult = Apollo.QueryResult<GetAccessLocationsQuery, GetAccessLocationsQueryVariables>;
export const UpdateUserInfoDocument = gql`
    mutation UpdateUserInfo($id: ID!, $general: UsersGeneralInfoPayload, $permission: PermissionInfoPayload, $roleId: ID, $userInfo: UserInfoPayload) {
  updateUserInfo(
    id: $id
    general: $general
    permission: $permission
    roleId: $roleId
    userInfo: $userInfo
  ) {
    isSuccess
  }
}
    `;
export type UpdateUserInfoMutationFn = Apollo.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      general: // value for 'general'
 *      permission: // value for 'permission'
 *      roleId: // value for 'roleId'
 *      userInfo: // value for 'userInfo'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(UpdateUserInfoDocument, options);
      }
export type UpdateUserInfoMutationHookResult = ReturnType<typeof useUpdateUserInfoMutation>;
export type UpdateUserInfoMutationResult = Apollo.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = Apollo.BaseMutationOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export const UpdateUserAccessLocationDocument = gql`
    mutation UpdateUserAccessLocation($locationIds: [Int]!, $userId: String) {
  updateUserAccessLocation(locationIds: $locationIds, userId: $userId) {
    isSuccess
  }
}
    `;
export type UpdateUserAccessLocationMutationFn = Apollo.MutationFunction<UpdateUserAccessLocationMutation, UpdateUserAccessLocationMutationVariables>;

/**
 * __useUpdateUserAccessLocationMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccessLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccessLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccessLocationMutation, { data, loading, error }] = useUpdateUserAccessLocationMutation({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateUserAccessLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAccessLocationMutation, UpdateUserAccessLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAccessLocationMutation, UpdateUserAccessLocationMutationVariables>(UpdateUserAccessLocationDocument, options);
      }
export type UpdateUserAccessLocationMutationHookResult = ReturnType<typeof useUpdateUserAccessLocationMutation>;
export type UpdateUserAccessLocationMutationResult = Apollo.MutationResult<UpdateUserAccessLocationMutation>;
export type UpdateUserAccessLocationMutationOptions = Apollo.BaseMutationOptions<UpdateUserAccessLocationMutation, UpdateUserAccessLocationMutationVariables>;
export const GetAllAccessLocationsDocument = gql`
    query GetAllAccessLocations($id: ID!) {
  getAllAccessLocations(id: $id)
}
    `;

/**
 * __useGetAllAccessLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllAccessLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccessLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccessLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllAccessLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetAllAccessLocationsQuery, GetAllAccessLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAccessLocationsQuery, GetAllAccessLocationsQueryVariables>(GetAllAccessLocationsDocument, options);
      }
export function useGetAllAccessLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAccessLocationsQuery, GetAllAccessLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAccessLocationsQuery, GetAllAccessLocationsQueryVariables>(GetAllAccessLocationsDocument, options);
        }
export type GetAllAccessLocationsQueryHookResult = ReturnType<typeof useGetAllAccessLocationsQuery>;
export type GetAllAccessLocationsLazyQueryHookResult = ReturnType<typeof useGetAllAccessLocationsLazyQuery>;
export type GetAllAccessLocationsQueryResult = Apollo.QueryResult<GetAllAccessLocationsQuery, GetAllAccessLocationsQueryVariables>;
export const GetAllLocationsUserPositionsDocument = gql`
    query GetAllLocationsUserPositions {
  getAllLocationsUserPositions
}
    `;

/**
 * __useGetAllLocationsUserPositionsQuery__
 *
 * To run a query within a React component, call `useGetAllLocationsUserPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocationsUserPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLocationsUserPositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLocationsUserPositionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllLocationsUserPositionsQuery, GetAllLocationsUserPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllLocationsUserPositionsQuery, GetAllLocationsUserPositionsQueryVariables>(GetAllLocationsUserPositionsDocument, options);
      }
export function useGetAllLocationsUserPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllLocationsUserPositionsQuery, GetAllLocationsUserPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllLocationsUserPositionsQuery, GetAllLocationsUserPositionsQueryVariables>(GetAllLocationsUserPositionsDocument, options);
        }
export type GetAllLocationsUserPositionsQueryHookResult = ReturnType<typeof useGetAllLocationsUserPositionsQuery>;
export type GetAllLocationsUserPositionsLazyQueryHookResult = ReturnType<typeof useGetAllLocationsUserPositionsLazyQuery>;
export type GetAllLocationsUserPositionsQueryResult = Apollo.QueryResult<GetAllLocationsUserPositionsQuery, GetAllLocationsUserPositionsQueryVariables>;
export const GetUserPositionsByBuDocument = gql`
    query getUserPositionsByBu {
  getUserPositionsByBu {
    position
    bu {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUserPositionsByBuQuery__
 *
 * To run a query within a React component, call `useGetUserPositionsByBuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPositionsByBuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPositionsByBuQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPositionsByBuQuery(baseOptions?: Apollo.QueryHookOptions<GetUserPositionsByBuQuery, GetUserPositionsByBuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPositionsByBuQuery, GetUserPositionsByBuQueryVariables>(GetUserPositionsByBuDocument, options);
      }
export function useGetUserPositionsByBuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPositionsByBuQuery, GetUserPositionsByBuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPositionsByBuQuery, GetUserPositionsByBuQueryVariables>(GetUserPositionsByBuDocument, options);
        }
export type GetUserPositionsByBuQueryHookResult = ReturnType<typeof useGetUserPositionsByBuQuery>;
export type GetUserPositionsByBuLazyQueryHookResult = ReturnType<typeof useGetUserPositionsByBuLazyQuery>;
export type GetUserPositionsByBuQueryResult = Apollo.QueryResult<GetUserPositionsByBuQuery, GetUserPositionsByBuQueryVariables>;
export const UpdateUserTypeDocument = gql`
    mutation UpdateUserType($id: ID!, $type: ESPECTRO_USER_TYPES!) {
  updateUserType(id: $id, type: $type) {
    isSuccess
  }
}
    `;
export type UpdateUserTypeMutationFn = Apollo.MutationFunction<UpdateUserTypeMutation, UpdateUserTypeMutationVariables>;

/**
 * __useUpdateUserTypeMutation__
 *
 * To run a mutation, you first call `useUpdateUserTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTypeMutation, { data, loading, error }] = useUpdateUserTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateUserTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserTypeMutation, UpdateUserTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserTypeMutation, UpdateUserTypeMutationVariables>(UpdateUserTypeDocument, options);
      }
export type UpdateUserTypeMutationHookResult = ReturnType<typeof useUpdateUserTypeMutation>;
export type UpdateUserTypeMutationResult = Apollo.MutationResult<UpdateUserTypeMutation>;
export type UpdateUserTypeMutationOptions = Apollo.BaseMutationOptions<UpdateUserTypeMutation, UpdateUserTypeMutationVariables>;
export const CreateEspectroUserDocument = gql`
    mutation CreateEspectroUser($email: String!, $type: ESPECTRO_USER_TYPES!, $userExists: Boolean) {
  createEspectroUser(email: $email, type: $type, userExists: $userExists) {
    isSuccess
  }
}
    `;
export type CreateEspectroUserMutationFn = Apollo.MutationFunction<CreateEspectroUserMutation, CreateEspectroUserMutationVariables>;

/**
 * __useCreateEspectroUserMutation__
 *
 * To run a mutation, you first call `useCreateEspectroUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEspectroUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEspectroUserMutation, { data, loading, error }] = useCreateEspectroUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      type: // value for 'type'
 *      userExists: // value for 'userExists'
 *   },
 * });
 */
export function useCreateEspectroUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateEspectroUserMutation, CreateEspectroUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEspectroUserMutation, CreateEspectroUserMutationVariables>(CreateEspectroUserDocument, options);
      }
export type CreateEspectroUserMutationHookResult = ReturnType<typeof useCreateEspectroUserMutation>;
export type CreateEspectroUserMutationResult = Apollo.MutationResult<CreateEspectroUserMutation>;
export type CreateEspectroUserMutationOptions = Apollo.BaseMutationOptions<CreateEspectroUserMutation, CreateEspectroUserMutationVariables>;
export const ReinviteEspectroUserDocument = gql`
    mutation ReinviteEspectroUser($userId: ID!) {
  reinviteEspectroUser(userId: $userId) {
    isSuccess
  }
}
    `;
export type ReinviteEspectroUserMutationFn = Apollo.MutationFunction<ReinviteEspectroUserMutation, ReinviteEspectroUserMutationVariables>;

/**
 * __useReinviteEspectroUserMutation__
 *
 * To run a mutation, you first call `useReinviteEspectroUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReinviteEspectroUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reinviteEspectroUserMutation, { data, loading, error }] = useReinviteEspectroUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReinviteEspectroUserMutation(baseOptions?: Apollo.MutationHookOptions<ReinviteEspectroUserMutation, ReinviteEspectroUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReinviteEspectroUserMutation, ReinviteEspectroUserMutationVariables>(ReinviteEspectroUserDocument, options);
      }
export type ReinviteEspectroUserMutationHookResult = ReturnType<typeof useReinviteEspectroUserMutation>;
export type ReinviteEspectroUserMutationResult = Apollo.MutationResult<ReinviteEspectroUserMutation>;
export type ReinviteEspectroUserMutationOptions = Apollo.BaseMutationOptions<ReinviteEspectroUserMutation, ReinviteEspectroUserMutationVariables>;
export const GetAllOwdChecklistsDocument = gql`
    query GetAllOWDChecklists($locationSlug: String!, $source: ClientType!, $pillar: String, $startDate: Date, $endDate: Date, $limit: Int, $offset: Int) {
  getAllOWDChecklists(
    args: {locationSlug: $locationSlug, source: $source, pillar: $pillar, startDate: $startDate, endDate: $endDate, limit: $limit, offset: $offset}
  ) {
    id
    content
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetAllOwdChecklistsQuery__
 *
 * To run a query within a React component, call `useGetAllOwdChecklistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOwdChecklistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOwdChecklistsQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *      source: // value for 'source'
 *      pillar: // value for 'pillar'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllOwdChecklistsQuery(baseOptions: Apollo.QueryHookOptions<GetAllOwdChecklistsQuery, GetAllOwdChecklistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOwdChecklistsQuery, GetAllOwdChecklistsQueryVariables>(GetAllOwdChecklistsDocument, options);
      }
export function useGetAllOwdChecklistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOwdChecklistsQuery, GetAllOwdChecklistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOwdChecklistsQuery, GetAllOwdChecklistsQueryVariables>(GetAllOwdChecklistsDocument, options);
        }
export type GetAllOwdChecklistsQueryHookResult = ReturnType<typeof useGetAllOwdChecklistsQuery>;
export type GetAllOwdChecklistsLazyQueryHookResult = ReturnType<typeof useGetAllOwdChecklistsLazyQuery>;
export type GetAllOwdChecklistsQueryResult = Apollo.QueryResult<GetAllOwdChecklistsQuery, GetAllOwdChecklistsQueryVariables>;
export const GetPostsDocument = gql`
    query getPosts($limit: Int!, $search: String, $type: POSTS_TYPES, $attachmentType: [ATTACHMENT_TYPES], $startDate: Date, $endDate: Date) {
  getPosts(
    limit: $limit
    search: $search
    type: $type
    startDate: $startDate
    endDate: $endDate
    attachmentType: $attachmentType
  ) {
    posts {
      title
      content
      type
      bannerImage
      slug
      postedAt
      author
      attachments {
        url
        type
      }
    }
    hasMore
  }
}
    `;

/**
 * __useGetPostsQuery__
 *
 * To run a query within a React component, call `useGetPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      type: // value for 'type'
 *      attachmentType: // value for 'attachmentType'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetPostsQuery(baseOptions: Apollo.QueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
      }
export function useGetPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
        }
export type GetPostsQueryHookResult = ReturnType<typeof useGetPostsQuery>;
export type GetPostsLazyQueryHookResult = ReturnType<typeof useGetPostsLazyQuery>;
export type GetPostsQueryResult = Apollo.QueryResult<GetPostsQuery, GetPostsQueryVariables>;
export const LocationDetailsDocument = gql`
    query locationDetails($locationSlug: String!) {
  locationDetails(locationSlug: $locationSlug) {
    type
  }
}
    `;

/**
 * __useLocationDetailsQuery__
 *
 * To run a query within a React component, call `useLocationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationDetailsQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useLocationDetailsQuery(baseOptions: Apollo.QueryHookOptions<LocationDetailsQuery, LocationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationDetailsQuery, LocationDetailsQueryVariables>(LocationDetailsDocument, options);
      }
export function useLocationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationDetailsQuery, LocationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationDetailsQuery, LocationDetailsQueryVariables>(LocationDetailsDocument, options);
        }
export type LocationDetailsQueryHookResult = ReturnType<typeof useLocationDetailsQuery>;
export type LocationDetailsLazyQueryHookResult = ReturnType<typeof useLocationDetailsLazyQuery>;
export type LocationDetailsQueryResult = Apollo.QueryResult<LocationDetailsQuery, LocationDetailsQueryVariables>;