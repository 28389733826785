export enum FETCH_STATUS {
  NO_ACTIVE_REQUEST,
  PENDING,
  SUCCESS,
  FAIL,
}

export enum LOCATION_TYPES {
  INSIDE_DC = 'INSIDE_DC',
  ROUTE = 'ROUTE',
  POINT_OF_SALE = 'POINT_OF_SALE',
  SALES_COMMISSION = 'SALES_COMMISSION',
  TRUCK = 'TRUCK',
  DISTRIBUTION_CENTER = 'DISTRIBUTION_CENTER',
  FACTORY = 'FACTORY'
}

export enum IMAGE_SAVING_STATUS {
  DONE = 'Done',
  IN_PROGRESS = 'In Progress',
  NOT_CONNECTED = 'Not Connected',
}

export enum HEALTH_STATUS {
  STABLE = 'STABLE',
  STABLE_WITH_OXYGEN = 'STABLE_WITH_OXYGEN',
  SERIOUS_WITH_OXYGEN = 'SERIOUS_WITH_OXYGEN',
  SERIOUS_WITHOUT_OXYGEN = 'SERIOUS_WITHOUT_OXYGEN',
}

export enum DOCTOR_STATUS {
  IN_TEST = 'IN_TEST',
  QUARANTINE = 'QUARANTINE',
  DISCHARGED = 'DISCHARGED',
}

export enum COLOR_CODE_NEW {
  BLACK = '#000',
  WHITE = '#FFF',
  PRIMARY = '#C00000',
  PRIMARY_LIGHT = '#FFE6E6',
  PRIMARY_DARK = '#45120F', // usually used for headers
  DEFAULT = '#F6EFEF',
  DEFAULT_DARK = '#B18B8B', // usually used for subheaders
  COMPLEMENTARY = '#F39C1F',
  COMPLEMENTARY_LIGHT = '#FFEACC',
  COMPLEMENTARY2 = '#DA5926', // ORANGE
  DANGER = '#EF5681',
  DANGER_LIGHT = '#FFD9E3',
  SUCCESS = '#70AD47',
  SUCCESS_LIGHT = '#DDF2CE',
  DIVIDERS = '#F8E4E4',
  BORDERS = '#FBDDDD',
  INPUT_BACKGROUND = '#FFFAFA', // input backgrounds such as dropdowns
  BACKGROUND = '#FAF5F5', // 2 purposes: backgrounds and some default buttons.
}

export enum COLOR_CODE {
  PRIMARY = '#1976F7',
  ESPECTRO_PRIMARY = '#6C63FF',
  SECONDARY = '#9DAECB',
  TERTIARY = '#0D2A59',
  WARNING = '#e27111',
  DANGER = '#F15382',
  SUCCESS = '#5CDFE6',
  DARK = 'black',
  LIGHT = '#AFB8C5',
  WHITE = 'white',
  LIGHT_BLUE_BG = '#F7FAFF',
  LIGHT_ALMOND_BG = '#CEF2F3',
  LIGHT_PURPLE = '#98AEFC',
  DARK_SUCCESS = '#30C1C8',
  MUTED = '#98A5B4',
  PLACEHOLDER = '#8A99AA',
  INVALID_INPUT_BORDER = '#E0EBFE',
  VALID_INPUT_BORDER = '#ECF3FE',
  UNTOUCHED_INPUT_BG = '#FAFCFF',
  RED = '#E92B36',
}

export enum MAIN_LOCATION {
  INSIDE_DC,
  OUTSIDE_DC,
}

export enum FONT_SIZES {
  LARGE_HEADER = 38,
  MEDIUM_HEADER = 32,
  SMALL_HEADER = 28,
  LARGE_TEXT = 24,
  MEDIUM_TEXT = 16,
  SMALL_TEXT = 13,
  SMALLEST_TEXT = 10,
}

export enum TICKET_TYPE {
  ACT = 'ACT',
  CONDITION = 'CONDITION',
  ENVIRONMENT = 'ENVIRONMENT',
}

export enum SECURITY_STATUS {
  SECURE = 'SECURE',
  INSECURE = 'INSECURE',
}

export enum RISK_LEVEL {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum PRIORITY {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum PAGES {
  LOCATION = 'LOCATION',
  DETAILS = 'DETAILS',
  REVIEW = 'REVIEW',
}

export enum USERNAME_TYPE {
  EMAIL,
  PIN,
}

export enum FILTER_OPTIONS {
  lastDay = 'LAST_DAY',
  lastWeek = 'LAST_WEEK',
  lastMonth = 'LAST_MONTH',
  lastHour = 'LAST_HOUR',
  customPeriod = 'CUSTOM_PERIOD',
}

export enum TimeFilterOption {
  LAST_HOUR,
  LAST_DAY,
  LAST_WEEK,
  LAST_MONTH,
  CUSTOM_PERIOD,
}

export enum USER_TYPES {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export enum UserTypes {
  CorporateSupply = 'CorporateSupply',
  Corporate = 'Corporate',
  DistributionCenterManager = 'DistributionCenterManager',
  DistributionCenterManagerSupply = 'DistributionCenterManagerSupply',
  DistributionCenterManagerDPO = 'DistributionCenterManagerDPO',
  SubzoneGeneralManager = 'SubzoneGeneralManager',
  SubzoneGeneralManagerDPO = 'SubzoneGeneralManagerDPO',
  SubzoneGeneralManagerSupply = 'SubzoneGeneralManagerSupply',
  CorporateDPO = 'CorporateDPO',
  ReporterOnly = 'ReporterOnly',
  ReporterOnlySupply = 'ReporterOnlySupply',
  ReporterOnlyDPO = 'ReporterOnlyDPO',
  CertificatesOnly = 'CertificatesOnly',
  LocationSecurity = 'LocationSecurity',
  LocationManager = 'LocationManager',
  LocationSecurityDoctor = 'LocationSecurityDoctor',
  BackendEdit = 'BackendEdit',
  BreweryViewer = 'BreweryViewer',
  BreweryPlanner = 'BreweryPlanner',
  BreweryViewerManager = 'BreweryViewerManager',
  Doctor = 'Doctor',
  DoctorReporterOnly = 'DoctorReporterOnly',
  DoctorReporterOnlySupply = 'DoctorReporterOnlySupply',
  EspectroViewOnly = 'EspectroViewOnly',
}

// slugs for userRole
export enum USER_ROLE {
  EJECUTOR = 'ejecutor',
  ASSIGNER = 'assigner',
  SAFETY_MANAGER = 'safety-manager',
  LINE_MANAGER = 'line-manager',
  REPORTER_ONLY = 'reporter-only',
  KIOSK = 'kiosk-user',
  EJECUTOR_SAFETY = 'ejecutor-safety',
  ASSIGNER_SAFETY = 'assigner-safety',
  SAFETY_MANAGER_SAFETY = 'safety-manager-safety',
  LINE_MANAGER_SAFETY = 'line-manager-safety',
  EJECUTOR_ENVIRONMENT = 'ejecutor-environment',
  ASSIGNER_ENVIRONMENT = 'assigner-environment',
  ENVIRONMENT_MANAGER = 'environment-manager',
  LINE_MANAGER_ENVIRONMENT = 'line-manager-environment',
  WEB_ONLY = 'web-only',
  PLANT_MANAGER = 'plant-manager',
}

export enum USER_ROLES_NAME {
  SAFETY_MANAGER = 'Safety Manager',
  LINE_MANAGER = 'Line Manager (Safety-Environment)',
  ASSIGNER = 'Assigner (Safety-Environment)',
  EXECUTOR = 'Ejecutor (Safety-Environment)',
  REPORTER_ONLY = 'Reporter Only',
  KIOSK = 'Kiosk',
  LINE_MANAGER_SAFETY = 'Line Manager Safety',
  ASSIGNER_SAFETY = 'Assigner Safety',
  EXECUTOR_SAFETY = 'Ejecutor Safety',
  ENVIRONMENT_MANAGER = 'Environment Manager',
  LINE_MANAGER_ENVIRONMENT = 'Line Manager Environment',
  ASSIGNER_ENVIRONMENT = 'Assigner Environment',
  EXECUTOR_ENVIRONMENT = 'Ejecutor Environment',
  REPORTER_ONLY_ENVIRONMENT = 'Reporter Only Environment',
  KIOSK_ENVIRONMENT = 'Kiosk Environment',
  PLANT_MANAGER = 'Plant Manager',
  EJECUTOR_CONTRATISTA_PDTS = 'Ejecutor contratista (PDTs)',
  EJECUTOR = 'Ejecutor',
  LINE_MANAGER_ROLE = 'Line Manager',
  ASSIGNER_ROLE = 'Assigner',
  KEY_USER = 'Key User',
}

export const SUPPLY_USER_ROLES_NAME = [
  USER_ROLES_NAME.EXECUTOR_SAFETY,
  USER_ROLES_NAME.LINE_MANAGER_SAFETY,
  USER_ROLES_NAME.SAFETY_MANAGER,
  USER_ROLES_NAME.ASSIGNER_SAFETY,
  USER_ROLES_NAME.LINE_MANAGER_ENVIRONMENT,
  USER_ROLES_NAME.ASSIGNER_ENVIRONMENT,
  USER_ROLES_NAME.EXECUTOR_ENVIRONMENT,
  USER_ROLES_NAME.LINE_MANAGER,
  USER_ROLES_NAME.ASSIGNER,
  USER_ROLES_NAME.EXECUTOR,
  USER_ROLES_NAME.ENVIRONMENT_MANAGER,
  USER_ROLES_NAME.PLANT_MANAGER,
  USER_ROLES_NAME.EJECUTOR_CONTRATISTA_PDTS,
  USER_ROLES_NAME.ASSIGNER_ROLE,
  USER_ROLES_NAME.EJECUTOR,
  USER_ROLES_NAME.LINE_MANAGER_ROLE,
  USER_ROLES_NAME.KEY_USER,
];

/** DUPLICATED b/w src/ server/ */
export enum USER_FEATURES {
  CLOSE_SIOS = 'close-sios',
  RETURN_SIOS = 'return-sios',
  ASSIGN_SIOS = 'assign-sios',
  EDIT_SIOS = 'edit-sios',
  REPORT_SIOS = 'report-sios',
  WEB_REPORT_SIOS = 'web-report-sios',
  SAFETY_WEB_ACCESS = 'safety-web-access',
  ONLY_REPORTS_WEB_SIOS = 'sio-only-reports-web',
  CERTIFICATES_FEATURE = 'certificates',
  SCAN_OPERATIONS = 'scan-operations',
  ROUTINES_FEATURE = 'routines',
}

export enum BLOCKED_REASONS {
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
  MANUAL = 'MANUAL',
  VISIT_DOCTOR = 'VISIT_DOCTOR',
}

export enum TRANSLATIONS {
  /** an _ABB suffix added to a word indicates an abbreviation */
  WRITE = 'WRITE',
  ADD_ASSETS='ADD_ASSET',
  ASSET_TYPE='ASSET TYPE',
  CREATE_NEW_TYPE='CREATE_NEW_TYPE',
  INSPECT_NAME='INSPECT NAME',
  UNIQUE_SUBTITLE='UNIQUE_SUBTITLE',
  UNIQUE_NAME='UNIQUE NAME',
  SHARE = 'SHARE',
  WORKS_AT = 'Works at',
  APPLY_OWDS = 'Apply OWDs',
  LOADING_FILE = 'Loading file',
  OWD = 'OWDs',
  PDT = 'PDT',
  OVER_WRITE_FILE = 'Overwrite File',
  LAST_LOAD = 'LAST_LOAD',
  TEMPLATE = 'TEMPLATE',
  DOWNLOAD_TEMPLATE = 'Download Template',
  DOWNLOAD_FILE = 'Download File',
  ARCHIVO = 'Archivo',
  TYPE_OF_COLLABORATOR = 'Type of Collaborator',
  SELECT_DISTRIBUTION_PARAGRAPH = 'In order to access the configuration of the OWDs it is necessary to select a Distribution Center using the top bar',
  WITHIN_24_HOURS = 'Dentro de 24 horas',
  MORE_24_HOURS = 'Fuera de 24 horas',
  week1 = '1er sem',
  week2 = '2da sem',
  week3 = '3ra sem',
  week4 = '4ta sem',
  week5 = '5ta sem',
  week6 = '6ta sem',
  week7 = '7ma sem',
  week8 = '8ma sem',
  week9 = '9ma sem',
  week10 = '10ma sem',
  week11 = 'sem 11',
  week12 = 'sem 12',
  week13 = 'sem 13',
  week14 = 'sem 14',
  week15 = 'sem 15',
  week16 = 'sem 16',
  week17 = 'sem 17',
  week18 = 'sem 18',
  week19 = 'sem 19',
  week20 = 'sem 20',
  week21 = 'sem 21',
  week22 = 'sem 22',
  week23 = 'sem 23',
  week24 = 'sem 24',
  week25 = 'sem 25',
  week26 = 'sem 26',
  week27 = 'sem 27',
  week28 = 'sem 28',
  week29 = 'sem 29',
  week30 = 'sem 30',
  week31 = 'sem 31',
  week32 = 'sem 32',
  week33 = 'sem 33',
  week34 = 'sem 34',
  week35 = 'sem 35',
  week36 = 'sem 36',
  week37 = 'sem 37',
  week38 = 'sem 38',
  week39 = 'sem 39',
  week40 = 'sem 40',
  week41 = 'sem 41',
  week42 = 'sem 42',
  week43 = 'sem 43',
  week44 = 'sem 44',
  week45 = 'sem 45',
  week46 = 'sem 46',
  week47 = 'sem 47',
  week48 = 'sem 48',
  week49 = 'sem 49',
  week50 = 'sem 50',
  week51 = 'sem 51',
  week52 = 'sem 52',
  week53 = 'sem 53',
  week54 = 'sem 54',
  SELECT_ANSWER = 'SELECT_ANSWER',
  INFORMATION_NOT_AVAILABLE = 'INFORMATION_NOT_AVAILABLE',
  SELECT_DISTRIBUTION_CENTER = 'Select a Distribution Center',
  GENERAL = 'General',
  CHOOSE = 'CHOOSE',
  REFERNCIA = 'REFERNCIA',
  WELCOME_TO = 'WELCOME_TO',
  DATE_INCIDENT = 'DATE_INCIDENT',
  RECORD_INCIDENT = 'RECORD_INCIDENT',
  VALIDITY = 'VALIDITY',
  CAUSE = 'CAUSE',
  SUB_CAUSE = 'SUB_CAUSE',
  NATURE = 'NATURE',
  CREATE_YOUR_PASSWORD_TO_ACCESS = 'CREATE_YOUR_PASSWORD_TO_ACCESS',
  FORGOT_YOUR_PASSWORD = 'FORGOT_YOUR_PASSWORD',
  I_REMEMBER_MY_PASSWORD = 'I_REMEMBER_MY_PASSWORD',
  RECOVER_IT_HERE = 'RECOVER_IT_HERE',
  CREATE_AN_ACCOUNT = 'CREATE_AN_ACCOUNT',
  DONT_HAVE_AN_ACCOUNT = 'DONT_HAVE_AN_ACCOUNT',
  ALREADY_REGISTERED = 'ALREADY_REGISTERED',
  REGISTER_ON = 'REGISTER_ON',
  REGISTER = 'REGISTER',
  LETS_FIND_YOUR_ACC = 'LETS_FIND_YOUR_ACC',
  TYPE_YOUR_EMAIL = 'TYPE_YOUR_EMAIL',
  SEND_ME_THE_LINK = 'SEND_ME_THE_LINK',
  EMAIL_SENT = 'EMAIL_SENT',
  YOU_WILL_RECEIVE_AN_EMAIL = 'YOU_WILL_RECEIVE_AN_EMAIL',
  TO_RESET_PASSWORD = 'TO_RESET_PASSWORD',
  PASSWORD = 'PASSWORD',
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  ALREADY_HAVE_AN_ACCOUNT = 'ALREADY_HAVE_AN_ACCOUNT',
  LOGIN = 'LOGIN',
  GO_TO_LOGIN = 'GO_TO_LOGIN',
  EMAIL = 'EMAIL',
  LOADING = 'LOADING',
  YOUR_PASSWORD_WAS_SUCCESSFULLY_CREATED = 'YOUR_PASSWORD_WAS_SUCCESSFULLY_CREATED',
  YOU_CAN_START_USING_THE_APP = 'YOU_CAN_START_USING_THE_APP',
  OPEN_APP = 'OPEN_APP',
  CONTACT = 'CONTACT',
  THIS_URL_HAS_EXPIRED = 'THIS_URL_HAS_EXPIRED',
  LOGIN_TO_ACCESS_SAFETY_DASHBOARD = 'LOGIN_TO_ACCESS_SAFETY_DASHBOARD',
  LOGIN_TOP_ESPECTRO_DASHBOARD = 'LOGIN_TOP_ESPECTRO_DASHBOARD',
  LOGGING_IN = 'LOGGING_IN',
  NAME = 'NAME',
  FIRST_SURNAME = 'FIRST_SURNAME',
  SECOND_SURNAME = 'SECOND_SURNAME',
  NAME_OF_THE_COMPANY = 'NAME_OF_THE_COMPANY',
  DESIGNATION = 'DESIGNATION',
  OPTIONAL = 'OPTIONAL',
  BLOCKED_REASON = 'BLOCKED_REASON',
  SEARCH = 'SEARCH',
  BLOCK = 'BLOCK',
  UNBLOCK = 'UNBLOCK',
  CD_SETTINGS = 'CD_SETTINGS',
  BACK_TO = 'BACK_TO',
  SETTINGS = 'SETTINGS',
  EXIT = 'EXIT',
  WELCOME = 'WELCOME',
  YOU_ARE_VIEWING = 'YOU_ARE_VIEWING',
  REPORTS = 'REPORTS',
  TOTAL_REPORTS = 'TOTAL_REPORTS',
  UNIQUE = 'UNIQUE',
  UNIQUE_USERS = 'UNIQUE_USERS',
  AVERAGE_PER_USER = 'AVERAGE_PER_USER',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  BLOCK_UNBLOCK = 'BLOCK_UNBLOCK',
  AVERAGE = 'AVERAGE',
  PRECIDING_PERIOD = 'PRECIDING_PERIOD',
  REPORT_HISTORY = 'REPORT_HISTORY',
  AVERAGE_DAILY_PER_USER = 'AVERAGE_DAILY_PER_USER',
  WHERE_ARE_YOU = 'WHERE_ARE_YOU',
  ZONE = 'ZONE',
  ROUTINE_SAVED = 'ROUTINE_SAVED',
  AGENCIA_DC = 'AGENCIA_DC',
  CONTINUE = 'CONTINUE',
  NEW_REPORT = 'NEW_REPORT',
  REPORT_DATA = 'REPORT_DATA',
  WHO_IS_REPORTING_IT = 'WHO_IS_REPORTING_IT',
  WHO_COMMITTED_THE_ACT = 'WHO_COMMITTED_THE_ACT',
  IT_WAS_NOT_POSSIBLE_TO_IDENTIFY = 'IT_WAS_NOT_POSSIBLE_TO_IDENTIFY',
  IT_IS_GOING_TO_BE_AN_ANONYMOUS_REPORT = 'IT_IS_GOING_TO_BE_AN_ANONYMOUS_REPORT',
  IT_IS_AN_ANONYMOUS_REPORT = 'IT_IS_AN_ANONYMOUS_REPORT',
  I_COULDNT_IDENTIFY_THE_PERSON = 'I_COULDNT_IDENTIFY_THE_PERSON',
  SEARCH_THE_LIST_AGAIN = 'SEARCH_THE_LIST_AGAIN',
  WHERE_DID_YOU_SEE = 'WHERE_DID_YOU_SEE',
  CD_AREA = 'CD_AREA',
  IN_WHICH_AREA_OF_DISTRIBUTION_CENTER = 'IN_WHICH_AREA_OF_DISTRIBUTION_CENTER',
  SPECIFY_THE_PLACE_IN_THE_SUBAREA = 'SPECIFY_THE_PLACE_IN_THE_SUBAREA',
  TYPE_OF_RISK = 'TYPE_OF_RISK',
  RISK_GROUP = 'RISK_GROUP',
  WHAT_RISK_TYPE_WAS_PREVENTED = 'WHAT_RISK_TYPE_WAS_PREVENTED',
  WHAT_RISK_TYPE_WAS_PREVENTED_WITH_ACT = 'WHAT_RISK_TYPE_WAS_PREVENTED_WITH_ACT',
  WHAT_RISK_TYPE_WAS_PREVENTED_WITH_CONDITION = 'WHAT_RISK_TYPE_WAS_PREVENTED_WITH_CONDITION',
  BEHAVIOR = 'BEHAVIOR',
  WHO_HAD_THIS_BEHAVIOR = 'WHO_HAD_THIS_BEHAVIOR',
  WHO_HAD_THIS_CONDITION = 'WHO_HAD_THIS_CONDITION',
  WHO_SUBMITTED_THIS_BEHAVIOR = 'WHO_SUBMITTED_THIS_BEHAVIOR',
  WHO_SUBMITTED_THIS_CONDITION = 'WHO_SUBMITTED_THIS_CONDITION',
  WHO_SUBMITTED_THIS_ENVIRONMENTAL_RISK = 'WHO_SUBMITTED_THIS_ENVIRONMENTAL_RISK',
  BACK_TO_SEARCH_BAR = 'BACK_TO_SEARCH_BAR',
  SELECT_RISK_TYPE = 'SELECT_RISK_TYPE',
  SELECT_RISK_GROUP = 'SELECT_RISK_GROUP',
  DESCRIBE_THE_BEHAVIOR = 'DESCRIBE_THE_BEHAVIOR',
  PIN_CREATED_SUCCESSFULLY = 'PIN_CREATED_SUCCESSFULLY',
  DESCRIPTION = 'DESCRIPTION',
  OF_THE_ACT = 'OF_THE_ACT',
  OF_THE_CONDITION = 'OF_THE_CONDITION',
  RISK_LEVEL_TRANSLATION = 'RISK_LEVEL_TRANSLATION',
  CORRECTIVE_ACTION = 'CORRECTIVE_ACTION',
  UPLOAD_REGISTRATION = 'UPLOAD_REGISTRATION',
  UPLOAD_EVIDENCE = 'UPLOAD_EVIDENCE',
  ACI_OVERVIEW = 'ACI_OVERVIEW',
  LEVEL = 'LEVEL',
  WITH_YOUR_HELP_WE_CAN_PREVENT_ACCIDENTS_IN_FUTURE = 'WITH_YOUR_HELP_WE_CAN_PREVENT_ACCIDENTS_IN_FUTURE',
  FINISH_AND_SEND = 'FINISH_AND_SEND',
  THANK_YOU = 'THANK_YOU',
  SENT_SUCCESSFULLY = 'SENT_SUCCESSFULLY',
  SIO_SUCCESSFULLY_SENT = 'SIO_SUCCESSFULLY_SENT',
  THANKS_TO_YOU_WE_CAN_IMPROVE_SECURITY_AMONG_OUR_COLLABORATORS = 'THANKS_TO_YOU_WE_CAN_IMPROVE_SECURITY_AMONG_OUR_COLLABORATORS',
  SUCCESSFUL_REGISTRATION = 'SUCCESSFUL_REGISTRATION',
  YOUR_REGISTRATION_WAS_SENT_SUCCESSFULLY = 'YOUR_REGISTRATION_WAS_SENT_SUCCESSFULLY',
  CERTIFICATES = 'CERTIFICATES',
  CERTIFICATES_ONLY = 'CERTIFICATES_ONLY',
  CLOSE = 'CLOSE',
  REPORT = 'REPORT',
  ASSIGN = 'ASSIGN',
  BOTH = 'BOTH',
  DOWNLOAD = 'DOWNLOAD',
  DOWNLOAD_ALL = 'DOWNLOAD_ALL',
  INCIDENTS = 'INCIDENTS',
  INCIDENTS_PER_POSITION = 'INCIDENTS_PER_POSITION',
  ALL_ZONES = 'ALL_ZONES',
  DRVS = 'DRVS',
  DISTRIBUTION_CENTERS = 'DISTRIBUTION_CENTERS',
  DECEMBER = 'DECEMBER',
  NOVEMBER = 'NOVEMBER',
  OCTOBER = 'OCTOBOER',
  SEPTEMBER = 'SEPTEMBER',
  AUGUST = 'AUGUST',
  JULY = 'JULY',
  JUNE = 'JUNE',
  MAY = 'MAY',
  APRIL = 'APRIL',
  MARCH = 'MARCH',
  FEBRUARY = 'FEBRUARY',
  JANUARY = 'JANUARY',
  ALL_MONTHLY_OPTIONS = 'ALL_MONTHLY_OPTIONS',
  // external users module - start
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
  SEARCH_USERS = 'SEARCH_USERS',
  ADD_USER_TO_DC = 'ADD_USER_TO_DC',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  DOES_USER_ACCOUNT = 'DOES_USER_ACCOUNT',
  YES_HE_HAS = 'YES_HE_HAS',
  ENTER_NAME = 'ENTER_NAME',
  ENTER_EMAIL = 'ENTER_EMAIL',
  NO_CREATE_SCRATCH = 'NO_CREATE_SCRATCH',
  POSITION = 'POSITION',
  JOB_TITLE = 'Job Title',
  PERMISSIONS = 'PERMISSIONS',
  ACIS = 'ACIS', // careful, reports is also there
  ROUTINES = 'ROUTINES',
  TRAININGS = 'TRAININGS',
  ACCIDENTABILITY = 'ACCIDENTABILITY',
  ANALYTICS = 'ANALYTICS',
  CANCEL = 'CANCEL',
  ADD = 'ADD',
  ACCESS = 'ACCESS',
  EDIT_INFORMATION = 'EDIT_INFORMATION',
  DELETE_USER = 'DELETE_USER',
  DELETE_USER_OPTION_MENU = 'DELETE_USER_OPTION_MENU',
  LOGOUT = 'LOGOUT',
  GO_TO_DASHBOARD = 'GO_TO_DASHBOARD',
  CONFIRM = 'CONFIRM',
  YES = 'YES',
  NO = 'NO',
  USERS = 'USERS',
  CHECK_YOUR_EMAIL = 'CHECK_YOUR_EMAIL',
  ASK_USER_WHICH_EMAIL = 'ASK_USER_WHICH_EMAIL',
  SOMETHING_WENT_WRONG_TRY_LATER = 'SOMETHING_WENT_WRONG_TRY_LATER',
  USER_NOT_EXIST_CREATE_NEW = 'USER_NOT_EXIST_CREATE_NEW',
  DELIVERY_MAN = 'DELIVERY_MAN',
  SUPPLIER = 'SUPPLIER',
  WAREHOUSE_ASSISTANT = 'WAREHOUSE_ASSISTANT',
  PEOPLE_AND_MANAGEMENT = 'PEOPLE_AND_MANAGEMENT',
  FORKLIFT_DRIVER = 'FORKLIFT_DRIVER',
  // external users module - end
  WEEKLY_REPORTS = 'WEEKLY_REPORTS',
  BOXES = 'BOXES',
  WHO_COMMITTED = 'WHO_COMMITTED',
  DATE_REPORTED = 'DATE_REPORTED',
  REPORTS_LIST = 'REPORTS_LIST',
  ALL = 'ALL',
  BEHAVIORS = 'BEHAVIORS',
  CONDITIONS = 'CONDITIONS',
  DOWNLOAD_LIST = 'DOWNLOAD_LIST',
  PLACE = 'PLACE',
  TOTAL = 'TOTAL',
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
  ASSIGNED = 'ASSIGNED',
  INSIDE_DC = 'INSIDE_DC',
  OUTSIDE_DC = 'OUTSIDE_DC',
  CONDITION = 'CONDITION',
  ENVIRONMENTAL_RISK = 'ENVIRONMENTAL_RISK',
  SEARCH_CHECKLIST = 'SEARCH_CHECKLIST',
  NUMBERS = 'NUMBERS',
  DECIMAL = 'DECIMAL',
  GO_BACK = 'GO_BACK',
  ADD_USER = 'ADD_USER',
  NEW_USER = 'NEW_USER',
  SEND_INVITATION = 'SEND_INVITATION',
  INVITE_USER_MAIL = 'INVITE_USER_MAIL',
  QUESTIONS = 'QUESTIONS',
  LOGICAL_QUESTION = 'LOGICAL_QUESTION',
  CHECKLIST_OPTIONS = 'CHECKLIST_OPTIONS',
  REPLACE_FILE = 'REPLACE_FILE',
  ACT = 'ACT',
  WHERE_DID_YOU_SEE_IN_DC_AREA = 'WHERE_DID_YOU_SEE_IN_DC_AREA',
  DESCRIPTION_OF_THE_ACT = 'DESCRIPTION_OF_THE_ACT',
  DESCRIPTION_OF_THE_CONDITION = 'DESCRIPTION_OF_THE_CONDITION',
  CONFIRM_USER_DELETION = 'CONFIRM_USER_DELETION',
  REGION = 'REGION',
  BUSINESS_UNIT = 'BUSINESS_UNIT',
  SELECT_REGION = 'SELECT_REGION',
  SELECT_DC = 'SELECT_DC',
  SELECT_SUBLOCATION = 'SELECT_SUBLOCATION',
  SELECT = 'SELECT',
  SELECT_POSITION = 'SELECT_POSITION',
  LOCATION = 'LOCATION',
  THE_REPORT_HAS_BEEN_SUCCESSFULLY_SENT = 'THE_REPORT_HAS_BEEN_SUCCESSFULLY_SENT',
  WRITE_YOUR_8_DIGIT_SAP_NUMBER = 'WRITE_YOUR_8_DIGIT_SAP_NUMBER',
  WRITE_AN_8_DIGIT_SAP_NUMBER = 'WRITE_AN_8_DIGIT_SAP_NUMBER',
  SAP_SUCCESSFULLY_VALIDATED = 'SAP_SUCCESSFULLY_VALIDATED',
  TO_KEEP_A_TRACK_RECORD_OF_YOUR_REPORTS_TYPE_IN_YOUR_SAP_NUMBER = 'TO_KEEP_A_TRACK_RECORD_OF_YOUR_REPORTS_TYPE_IN_YOUR_SAP_NUMBER',
  PLEASE_MAKE_SURE_ITS_AN_8_DIGIT_NUMBER = 'PLEASE_MAKE_SURE_ITS_AN_8_DIGIT_NUMBER',
  IF_YOU_LEAVE_IT_EMPTY_OR_INCORRECT_THIS_WILL_COUNT_AS_ANONYMOUS_REPORT = 'IF_YOU_LEAVE_IT_EMPTY_OR_INCORRECT_THIS_WILL_COUNT_AS_ANONYMOUS_REPORT',
  CLEAR = 'CLEAR',
  OVERVIEW = 'OVERVIEW',
  DIST_CENTERS = 'DIST_CENTERS',
  HOME = 'HOME',
  BLOCK_UNBLOCK_SHORT = 'BLOCK_UNBLOCK_SHORT',
  PRECEDING_PERIOD = 'PRECEDING_PERIOD',
  PARTICIPATION = 'PARTICIPATION',
  INCIDENTS_NATIONAL = 'INCIDENTS_NATIONAL',
  DAILY_AVG_PER_USER = 'DAILY_AVG_PER_USER',
  ALL_THE_BUS = 'ALL_THE_BUS',
  All = 'ALL',
  BU = 'BU',
  BY_BU = 'BY_BU',
  BY_POSITION = 'BY_POSITION',
  BY_ROUTINES = 'BY_ROUTINES',
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
  PASSWORD_NO_MATCH = 'PASSWORD_NO_MATCH',
  DO_NOT_USE_YOUR_OLD_PASSWORD = 'DO_NOT_USE_YOUR_OLD_PASSWORD',
  ROUTINES_FOLLOWUP = 'ROUTINES_FOLLOWUP',
  WEEK = 'WEEK',
  TRAININGS_PERCENTAGE = 'TRAININGS_PERCENTAGE',
  SIF_ROUTE_PERCENTAGE = 'SIF ROUTE %',
  TOTAL_SIF_ROUTES = 'TOTAL',
  COURSES = 'COURSES',
  HC_TO_BE_TRAINED = 'HC_TO_BE_TRAINED',
  TRAINED = 'TRAINED',
  IMAGE = 'IMAGE',
  USER_ACCESS_DISCLAIMER = 'USER_ACCESS_DISCLAIMER',
  USER_MISSING_DISCLAIMER = 'USER_MISSING_DISCLAIMER',
  SECURE_CONDITION_DISCLAIMER = 'SECURE_CONDITION_DISCLAIMER',
  YOU_DONT_HAVE_ANY_LOCATION_ASSIGNED_PLEASE_CONTACT_SUPPORT = 'YOU_DONT_HAVE_ANY_LOCATION_ASSIGNED_PLEASE_CONTACT_SUPPORT',
  INCIDENTS_PER_AREA = 'INCIDENTS_PER_AREA',
  RESET = 'RESET',
  SAFE = 'SAFE',
  PLANT = 'CD/Plant',
  ID = 'ID',
  SHARP = 'Sharp',
  STATUS = 'Status',
  REASON = 'Reason to block',
  ACTIONS = 'Actions',
  BLOCKED = 'Blocked',
  MEDIACAL_EXAMINATION = 'Medical examination',
  NO_DATA = 'NO_DATA',
  NO_DATA_FOUND_SO_FAR = 'NO_DATA_FOUND_SO_FAR',
  CURRENT_PROGRESS = 'CURRENT_PROGRESS',
  TOTAL_PROGRESS = 'TOTAL_PROGRESS',
  LAST = 'LAST',
  DOWNLOAD_ROUTINES_DB = 'DOWNLOAD_ROUTINES_DB',
  SEARCH_LOCATION = 'SEARCH_LOCATION',
  NO_MATCHES_FOUND = 'NO_MATCHES_FOUND',
  CONSEQUENCES = 'CONSEQUENCES',
  SELECT_SUBZONE_OR_DISTRIBUTION_CENTER = 'SELECT_SUBZONE_OR_DISTRIBUTION_CENTER',
  FILE_UPLOAD = 'FILE_UPLOAD',
  CRITICAL_CLIENTS = 'CRITICAL_CLIENTS',
  AT_HOME = 'AT_HOME',
  HOSPITAL = 'HOSPITAL',
  STABLE = 'STABLE',
  STABLE_WITH_OXYGEN = 'STABLE_WITH_OXYGEN',
  SERIOUS_WITH_OXYGEN = 'SERIOUS_WITH_OXYGEN',
  SERIOUS_WITHOUT_OXYGEN = 'SERIOUS_WITHOUT_OXYGEN',
  LAST_UPDATED_ON_MONTH_DATE_DAY = 'LAST_UPDATED_ON_MONTH_DATE_DAY',
  LAST_UPDATED_TODAY = 'LAST_UPDATED_TODAY',
  UPDATE_USER_STATUS_TODAY_MONTH_DATE = 'UPDATE_USER_STATUS_TODAY_MONTH_DATE',
  WHERE_IS_USER = 'WHERE_IS_USER',
  REMEMBER_THAT_SENDING_THIS_INFORMATION_IS_VERY_IMPORTANT_FOR_US_TO_GIVE_YOU_THE_BEST_POSSIBLE_SUPPORT_THROUGH_THE_QUARANTINE_PROCESS = 'REMEMBER_THAT_SENDING_THIS_INFORMATION_IS_VERY_IMPORTANT_FOR_US_TO_GIVE_YOU_THE_BEST_POSSIBLE_SUPPORT_THROUGH_THE_QUARANTINE_PROCESS',
  // ACTION LOG MEETINGS START
  DIARIA_LOGÍSTICA = 'DIARIA_LOGÍSTICA',
  RUTINA_MATUTINA_DE_REPARTO = 'RUTINA_MATUTINA_DE_REPARTO',
  RUTINA_DE_CAMBIO_DE_TURNO = 'RUTINA_DE_CAMBIO_DE_TURNO',
  RUTINA_SEMANAL_DE_ALMACÉN = 'RUTINA_SEMANAL_DE_ALMACÉN',
  RUTINA_SEMANAL_DE_DIST = 'RUTINA_SEMANAL_DE_DIST',
  RUTINA_SEMANAL_DE_PEOPLE = 'RUTINA_SEMANAL_DE_PEOPLE',
  RUTINA_SEMANAL_DE_FLOTA = 'RUTINA_SEMANAL_DE_FLOTA',
  RUTINA_SEMANAL_DE_SAFETY = 'RUTINA_SEMANAL_DE_SAFETY',
  RUTINA_SEMANAL_DE_PLANNING = 'RUTINA_SEMANAL_DE_PLANNING',
  RUTINE_SEMANAM_DE_GESTION = 'RUTINE_SEMANAM_DE_GESTION',
  // ACTION LOG MEETINGS END
  SAVE = 'SAVE',
  NESTED_CHECKLIST_ERROR = 'NESTED_CHECKLIST_ERROR',
  ADD_COMMENT = 'ADD_COMMENT',
  COMMENT_CREATED_AT = 'COMMENT_CREATED_AT',
  DELETE_COMMENT = 'DELETE_COMMENT',
  SHAREABLE_COMMENT = 'SHAREABLE_COMMENT',
  DELETE_FILTER = 'DELETE_FILTER',
  PLACEHOLDER_TEXT = 'PLACEHOLDER_TEXT]',
  COMMENT_SHOULD_BE_UNDER_N_CHARACTER = 'COMMENT_SHOULD_BE_UNDER_N_CHARACTER',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  DELETING = 'DELETING',
  PARTICIPANT = 'PARTICIPANT',
  OWNER = 'OWNER',
  IF_NOT_NECESSARY_RETURN_TO_UPDATE_TOMORROW = 'IF_NOT_NECESSARY_RETURN_TO_UPDATE_TOMORROW',
  STATUS_UPDATED_AS_OF_TODAY_DATE_MONTH = 'STATUS_UPDATED_AS_OF_TODAY_DATE_MONTH',
  UPDATED_TODAY_AT_TIME = 'UPDATED_TODAY_AT_TIME',
  YOUR_QUARANTINE_HAS = 'YOUR_QUARANTINE_HAS',
  CONCLUDED = 'CONCLUDED',
  PHYSICAL_STATE = 'PHYSICAL_STATE',
  PILLAR = 'PILLAR',
  // PILLAR START
  SAFETY = 'SAFETY',
  MANAGEMENT = 'MANAGEMENT',
  PLANNING = 'PLANNING',
  DISTRIBUTION = 'DISTRIBUTION',
  FLEET = 'FLEET',
  STOCK = 'STOCK',
  PEOPLE = 'PEOPLE',
  WAREHOUSE = 'WAREHOUSE',
  // PILLAR END
  NOTE = 'NOTE',
  SEARCH_KPI = 'SEARCH_KPI',
  NO_RESULTS_FOUND = 'NO_RESULTS_FOUND',
  SEARCH_TO_ADD_USERS = 'SEARCH_TO_ADD_USERS',
  ACTION_LOG_TABLE_FOR_LOCATION_NAME = 'ACTION_LOG_TABLE_FOR_LOCATION_NAME',
  DELETE_FILTERS = 'DELETE_FILTERS',
  ALL_ROUTINES = 'ALL_ROUTINES',
  REQUIRED_FIELDS = 'REQUIRED_FIELDS',
  ALL_KPIS = 'ALL_KPIS',
  ALL_USERS = 'ALL_USERS',
  SELECT_THE_ROUTINE_YOU_WANT_TO_SEE = 'SELECT_THE_ROUTINE_YOU_WANT_TO_SEE',
  SELECT_THE_KPIS_YOU_WANT_TO_SEE = 'SELECT_THE_KPIS_YOU_WANT_TO_SEE',
  SELECT_THE_USERS_YOU_WANT_TO_SEE = 'SELECT_THE_USERS_YOU_WANT_TO_SEE',
  NO_DUE_DATE_SELECTED = 'NO_DUE_DATE_SELECTED',
  // File-upload
  CLICK_HERE_DROP_TO_UPLOAD = 'CLICK_HERE_DROP_TO_UPLOAD',
  DROP_IT_HERE = 'DROP_IT_HERE',
  FILE_TYPE_NOT_ACCEPTED_SORRY = 'FILE_TYPE_NOT_ACCEPTED_SORRY',
  MAXIMUM_ALLOWED_FILE_SIZE = 'MAXIMUM_ALLOWED_FILE_SIZE',
  REMOVE_FILE = 'REMOVE_FILE',
  UPLOADING = 'UPLOADING',
  UPLOAD = 'UPLOAD',
  ERROR = 'ERROR',
  RETRY = 'RETRY',
  FILE_IS_TOO_LARGE = 'FILE_IS_TOO_LARGE',
  SERVER_ERROR = 'SERVER_ERROR',
  PROCESSING_YOUR_FILE = 'PROCESSING_YOUR_FILE',
  PROCESSING_FAILED_WITH_MESSAGE = 'PROCESSING_FAILED_WITH_MESSAGE',
  CLICK_TO_VISIT = 'CLICK_TO_VISIT',
  REUPLOAD = 'REUPLOAD',
  FINISHED_FILE_PROCESSING = 'FINISHED_FILE_PROCESSING',
  DONE = 'DONE',
  PLEASE_UPLOAD_A_FILE = 'PLEASE_UPLOAD_A_FILE',
  LAST_UPDATED_ON = 'LAST_UPDATED_ON',
  // multi-select
  ALL_ITEMS_ARE_SELECTED = 'ALL_ITEMS_ARE_SELECTED',
  CLEAR_SEARCH = 'CLEAR_SEARCH',
  NO_OPTIONS = 'NO_OPTIONS',
  SELECT_ALL = 'SELECT_ALL',
  SELECT_ALL_FILTERED = 'SELECT_ALL_FILTERED',
  VIOLENCE_PREVENTION = 'VIOLENCE_PREVENTION',
  ROUTINE_COMPLIANCE_HEADING = 'ROUTINE_COMPLIANCE',
  ROUTINE_COMPLIANCE_COMPLETED_PERCENTAGE = 'ROUTINE_%',
  ROUTINE_COMPLIANCE_EXECUTED = 'EXECUTED',
  ROUTINE_COMPLIANCE_PENDING = 'PENDING',
  ROUTINE_COMPLIANCE_SEARCH = 'SEARCH_BY_GRO',
  ROUTINE_COMPLIANCE_REGION = 'COUNTRY_/_REGION',
  ROUTINE_COMPLIANCE_PLANNED = 'PLANNED',
  ROUTINE_COMPLIANCE_REAL = 'REAL',
  ROUTINE_COMPLIANCE_COMPLIANCE = 'COMPLIANCE',
  ROUTINE_COMPLIANCE_DOWNLOAD_DATA = 'DOWNLOAD_DATA',
  ROUTINE_COMPLIANCE_BU = 'PER_BU',
  ROUTINE_COMPLIANCE_RANKING = 'DC_RANKING_FOR_MAZ',
  ROUTINE_COMPLIANCE_CD = 'DC',
  TOOLKIT_HEADING = 'TOOLKIT_HEADING',
  TOOLKIT_CURRENT_MONTH = 'CURRENT_MONTH',
  TOOLKIT_PREVIOUS_MONTH = 'PREVIOUS_MONTH',
  META = 'GOAL',
  NOTES = 'NOTES_FOR',
  COMMENT_BY = 'COMMENT_BY',
  LATEST_EDIT = 'LATEST_EDIT',
  SAVE_NOTE = 'SAVE_NOTE',
  SIF_STATISTICS = 'SIF_STATISTICS',
  USER_TYPE = 'USER_TYPE',
  USER_ROLE_TRANSLATION = 'USER_ROLE_TRANSLATION',
  //  pass recovery
  UPDATE = 'UPDATE',
  PASSWORD_RECOVERY = 'PASSWORD_RECOVERY',
  UPDATE_YOUR_PASSWORD_TO_ACCESS = 'UPDATE_YOUR_PASSWORD_TO_ACCESS',
  YOUR_PASSWORD_WAS_SUCCESSFULLY_RESTORED = 'YOUR_PASSWORD_WAS_SUCCESSFULLY_RESTORED',
  YOU_CAN_USE_THE_APP_AGAIN = 'YOU_CAN_USE_THE_APP_AGAIN',
  REDIRECT_TIMEOUT = 'REDIRECT_TIMEOUT',
  HERE = 'HERE',
  TELEMETRY = 'TELEMETRY',
  CURRENT_MONTH = 'CURRENT_MONTH',
  BY_CATEGORY = 'BY_CATEGORY',
  TELEMETRY_BY_BU = 'TELEMETRY_BY_BU',
  CRITICAL_SPEEDING = 'CRITICAL_SPEEDING',
  REGULAR_SPEEDING = 'REGULAR_SPEEDING',
  CURVE_SPEEDING = 'CURVE_SPEEDING',
  HARSH_TURNING = 'HARSH_TURNING',
  SEATBELT = 'SEATBELT',
  VEHICLE = 'VEHICLE',
  CLICK_HERE = 'CLICK_HERE',
  PREVENTION_CAMPAIGNS = 'PREVENTION_CAMPAIGNS',
  BEST_PRACTICES = 'BEST_PRACTICES',
  MONTHLY_TOTAL = 'MONTHLY_TOTAL',
  BOARDS = 'BOARDS',
  SAFETY_NEWS = 'SAFETY_NEWS',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  SELECT_MONTH = 'SELECT_MONTH',
  PYRAMID = 'PYRAMID',
  COUNTRY = 'COUNTRY',
  ACTION_LOGS = 'ACTION_LOGS',
  AMBIENTAL_RISKS = 'AMBIENTAL_RISKS',
  PRODUCTION_PERFORMANCE_TRACKING = 'PRODUCTION_PERFORMANCE_TRACKING',
  BY = 'BY',
  QR_CODE = 'QR_CODE',
  SEARCH_ROUTINE = 'SEARCH_ROUTINE',
  MANAGEMENT_TOOLS = 'MANAGEMENT_TOOLS',
  SIF_ROUTE = 'SIF_ROUTE',
  SIF_COMPLANICE = 'SIF_COMPLAINCE',
  SAFETY_TRAINING = 'SAFETY_TRAINING',
  INVALID_CREDENTIALS_TRY_AGAIN = 'INVALID_CREDENTIALS_TRY_AGAIN',
  THERE_IS_NO_NOTE_ADDED_FOR_THIS_ACTION_LOG = 'THERE_IS_NO_NOTE_ADDED_FOR_THIS_ACTION_LOG',
  THERE_ARE_NO_ACTION_LOGS_IN_THIS_COLUMN_YET = 'THERE_ARE_NO_ACTION_LOGS_IN_THIS_COLUMN_YET',
  ADD_ACTION_LOG = 'ADD_ACTION_LOG',
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW',
  COMPLETED = 'COMPLETED',
  WRITE_ACTION_HERE = 'WRITE_ACTION_HERE',
  SUCCESS_CRITERIA = 'SUCCESS_CRITERIA',
  WRITE_SUCCESS_CRITERIA_HERE = 'WRITE_SUCCESS_CRITERIA_HERE',
  REVIEW_IN = 'REVIEW_IN',
  SAVE_ACTION_LOG = 'SAVE_ACTION_LOG',
  CREATE_ACTION_LOG = 'CREATE_ACTION_LOG',
  SHOULD_BE_CLOSED_TODAY = 'SHOULD_BE_CLOSED_TODAY',
  SHOULD_HAVE_BEEN_CLOSED = 'SHOULD_HAVE_BEEN_CLOSED',
  DAY = 'DAY',
  DAYS = 'DAYS',
  TO_BE_CLOSED = 'TO_BE_CLOSED',
  THERE_ARE_NO_COMMENTS_YET = 'THERE_ARE_NO_COMMENTS_YET',
  CREATED = 'CREATED',
  DUE_DATE = 'DUE_DATE',
  SINCE = 'SINCE',
  IN = 'IN',
  WRITE_A_NOTE = 'WRITE_A_NOTE',
  WAIT_A_MOMENT = 'WAIT_A_MOMENT',
  IF_YOU_LEAVE_IT_WITHOUT_SAVING_YOUR_CHANGES_WILL_LOST_WANT_TO_STAY_HERE = 'IF_YOU_LEAVE_IT_WITHOUT_SAVING_YOUR_CHANGES_WILL_LOST_WANT_TO_STAY_HERE',
  LEAVE_WITHOUT_SAVE = 'LEAVE_WITHOUT_SAVE',
  DELETE_CARD = 'DELETE_CARD',
  NOT_ENOUGH_PERMISSIONS_TO_ACCESS = 'NOT_ENOUGH_PERMISSIONS_TO_ACCESS',
  INDICATORS = 'INDICATORS',
  DVR_CENTRAL_BAJIO = 'DVR_CENTRAL_BAJIO',
  METROPOLITAN_SBU = 'METROPOLITAN_SBU',
  METROPOLITAN = 'METROPOLITAN',
  CD_SAN_PABLO = 'CD_SAN_PABLO',
  WEB_ACCESS = 'WEB_ACCESS',
  WAREHOUSE_HELPERS = 'WAREHOUSE_HELPERS',
  FORKLIFTERS = 'FORKLIFTERS',
  SKAP = 'SKAP',
  SORRY_THERE_WAS_AN_ERROR = 'SORRY_THERE_WAS_AN_ERROR',
  RELOAD_PAGE = 'RELOAD_PAGE',
  IMAPACTED_HC = 'IMAPACTED_HC',
  FUNCTIONAL_INDUCTION = 'FUNCTIONAL_INDUCTION',
  TECHNICAL_SKILLS = 'TECHNICAL_SKILLS',
  AUTONOMOUS_SKILLS = 'AUTONOMOUS_SKILLS',
  FUNCTIONAL = 'Funcional',
  TECHNICAL = 'Technical',
  AUTONOMOUS = 'Autonomous',
  AUTONOMY_LEVEL = 'AUTONOMY_LEVEL',
  HEAD_COUNT = 'HEAD_COUNT',
  RAMP_UP = 'RAMP_UP',
  SKAP_DISTRIBUTION = 'SKAP_DISTRIBUTION',
  AREA = 'AREA',
  HC_TOTAL = 'HC_TOTAL',
  DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL',
  ALL_THE_TEAM = 'ALL_THE_TEAM',
  NEW_COLLABORATORS = 'NEW_COLLABORATORS',
  OLD_COLLABORATORS = 'OLD_COLLABORATORS',
  DELAY_TASK = 'DELAY_TASK',
  IN_WAREHOUSE = 'IN_WAREHOUSE',
  IN_FACTORY = 'IN_FACTORY',
  CONDITION_WAS_CLOSED_IMMEDIATELY = 'CONDITION_WAS_CLOSED_IMMEDIATELY',
  COULD_IT_BE_SIF = 'COULD_IT_BE_SIF',
  SIF_PRECURSOR = 'SIF_PRECURSOR',
  SIF_POTENTIAL = 'SIF_POTENTIAL',
  DEPARTMENT = 'DEPARTMENT',
  CHECKLISTS = 'CHECKLISTS',
  LAST_WEEK = 'LAST_WEEK',
  NEW_CHECKLIST = 'NEW_CHECKLIST',
  CREATE_NEW_CHECKLIST = 'CREATE_NEW_CHECKLIST',
  TITLE = 'TITLE',
  ENTER_TITLE = 'ENTER_TITLE',
  FREQUENCY = 'FREQUENCY',
  REQUIRED = 'REQUIRED',
  DAILY = 'DAILY',
  ADD_SECTION = 'ADD_SECTION',
  ENTER_DESCRIPTION = 'ENTER_DESCRIPTION',
  ADD_ITEM = 'ADD_ITEM',
  ADD_INSRUCTION = 'ADD_INSTRUCTION',
  ITEM_DELETED = 'ITEM_DELETED',
  SECTION_DELETED = 'SECTION_DELETED',
  BIT_DELETED = 'BIT_DELETED',
  ADD_BIT = 'ADD_BIT',
  TYPE_OF_BIT = 'TYPE_OF_BIT',
  CONFIGURE = 'CONFIGURE',
  IS_EXPANDED_DEFAULT = 'IS_EXPANDED_DEFAULT',
  IS_EXPANDED_DETAILS = 'IS_EXPANDED_DETAILS',
  SAP_SHARP = 'SAP_SHARP',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS = 'YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS',
  YOU_MUST_ENTER_YOUR_OPERATION_TYPE = 'YOU_MUST_ENTER_YOUR_OPERATION_TYPE',
  FOLLOW_THE_INSTRUCTIONS_RECEIVED = 'FOLLOW_THE_INSTRUCTIONS_RECEIVED',
  TOO_SHORT = 'TOO_SHORT',
  ENTER_A_CORRECT_EMAIL_FORMAT = 'ENTER_A_CORRECT_EMAIL_FORMAT',
  OPERATION = 'OPERATION',
  CATEGORY = 'CATEGORY',
  PROCEDURE = 'PROCEDURE',
  MEDIUM = 'MEDIUM',
  PRIORITY_T = 'PRIORITY_T',
  LOGISTIC = 'LOGISTIC',
  DISTRIBUTION_CENTER = 'DISTRIBUTION_CENTER',
  FACTORY = 'FACTORY',
  OPTIONS = 'OPTIONS',
  TITLE_CHECKLIST = 'TITLE_CHECKLIST',
  SECTION_OF_TOTAL = 'SECTION_OF_TOTAL',
  TITLE_SECTION = 'TITLE_SECTION',
  SOMETHING_SHORT = 'SOMETHING_SHORT',
  QUESTION_OF_TOTAL = 'QUESTION_OF_TOTAL',
  ANSWER_OF_TOTAL = 'ANSWER_OF_TOTAL',
  PLACEHOLDER_INFO = 'PLACEHOLDER_INFO',
  ENTER_OPTION_HIT_ENTER = 'ENTER_OPTION_HIT_ENTER',
  SWITCH_TO_DROPDOWN = 'SWITCH_TO_DROPDOWN',
  SWITCH_TO_VERTICAL = 'SWITCH_TO_VERTICAL',
  CHECKLIST_NAME = 'CHECKLIST_NAME',
  LAST_ACTIVITY = 'LAST_ACTIVITY',
  CREATION_DATE = 'CREATION_DATE',
  FIRST_CHECKLIST = 'FIRST_CHECKLIST',
  START_FIRST_CHECKLIST = 'START_FIRST_CHECKLIST',
  ADD_LOGIC = 'ADD_LOGIC',
  QUIT = 'QUIT',
  LOGIC = 'LOGIC',
  OUTLINES = 'OUTLINES',
  SHOW_IF = 'SHOW_IF',
  CHOOSE_QUESTION = 'CHOOSE_QUESTION',
  SECTION = 'SECTION',
  ANSWER = 'ANSWER',
  EQUALS = 'EQUALS',
  ANSWERED = 'ANSWERED',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  TYPE_OF_NUMBER = 'TYPE_OF_NUMBER',
  EXIT_WITHOUT_SAVING = 'EXIT_WITHOUT_SAVING',
  BACK = 'BACK',
  SHOW_QUESTION = 'SHOW_QUESTION',
  SEND_EMAIL = 'SEND_EMAIL',
  THEN = 'THEN',
  AN_ERROR_HAS_OCCURRED = 'AN_ERROR_HAS_OCCURRED',
  FILE_UPLOADED_SUCCESSFULLY = 'FILE_UPLOADED_SUCCESSFULLY',
  COPY_QR_CODE = 'COPY_QR_CODE',
  SUBAREA = 'SUBAREA',
  NOTE_UPLOADED_FILES_WILL_NOT_BE_REFLECTED_IMMEDIATELY_ONCE_YOU_UPLOAD_ALL_YOUR_FILES_PLEASE_REFRESH_THE_PAGE = 'NOTE_UPLOADED_FILES_WILL_NOT_BE_REFLECTED_IMMEDIATELY_ONCE_YOU_UPLOAD_ALL_YOUR_FILES_PLEASE_REFRESH_THE_PAGE',
  YOUR_FILE_HAS_STARTED_TO_LOAD_IT_MAY_TAKE_A_FEW_MINUTES = 'YOUR_FILE_HAS_STARTED_TO_LOAD_IT_MAY_TAKE_A_FEW_MINUTES',
  USER_UPDATED_SUCCESSFULLY = 'USER_UPDATED_SUCCESSFULLY',
  UPDATED_USER_NOW_DOES_NOT_HAVE_ANY_ROLE = 'UPDATED_USER_NOW_DOES_NOT_HAVE_ANY_ROLE',
  SEARCH_THE_USER_BY_QRCODE_TO_CONFIGURE = 'SEARCH_THE_USER_BY_QRCODE_TO_CONFIGURE',
  SELECT_LOCATION = 'SELECT_LOCATION',
  OPERATIONAL = 'OPERATIONAL',
  CORPORATE = 'CORPORATE',
  DOWNLOAD_DATA = 'DOWNLOAD_DATA',
  THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED = 'THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED',
  SELECT_DOCUMENT = 'SELECT_DOCUMENT',
  SELECT_THE_DOCUMENT_YOU_WANT_TO_DOWNLOAD = 'SELECT_THE_DOCUMENT_YOU_WANT_TO_DOWNLOAD',
  DOWNLOADING_DATA = 'DOWNLOADING_DATA',
  CHOOSE_HERE = 'CHOOSE_HERE',
  WITHOUT_POSITION = 'WITHOUT_POSITION',
  RETRY_AGAIN = 'RETRY_AGAIN',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE = 'DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE',
  THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE = 'THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE',
  OTHERS = 'OTHERS',
  SEND_EMAIL_WHEN_FINISHED = 'SEND_EMAIL_WHEN_FINISHED',
  TASK_DESCRIPTION = 'TASK_DESCRIPTION',
  USERS_ESP = 'USUARIOS',
  SEARCH_QUESTION_TYPE = 'SEARCH_QUESTION_TYPE',
  ATLEAST_TWO_OPTIONS = 'ATLEAST_TWO_OPTIONSS',
  DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
  INVITATION_SENT = 'INVITATION_SENT',
  SELECT_OPTION = 'SELECT_OPTION',
  LITE = 'LITE',
  BUSINESS = 'BUSINESS',
  TOTAL_PAGES = 'TOTAL_PAGES',
  PREVIOUS = 'PREVIOUS',
  NEXT = 'NEXT',
  TRIGGER_ACTION_LOG = 'TRIGGER_ACTION_LOG',
  CONTESTAR_CHECKLIST = 'CONTESTAR_CHECKLIST',
  ADD_ANOTHER = 'ADD_ANOTHER',
  TOTAL_CHECKLIST_REPORTS = 'TOTAL_CHECKLIST_REPORTS',
  ADD_SECTION_DESCRIPTION = 'ADD_SECTION_DESCRIPTION',
  ENABLE_REPEATITION = 'ENABLE_REPEATITION',
  ADJUSTMENTS = 'ADJUSTMENTS',
  ASSETS = 'ASSETS',
  LOGICAL_CONDITION_HIGHLIGHT = 'LOGICAL_CONDITION_HIGHLIGHT',
  LOGICAL_CONDITION_TEXT_WHEN_ANSWERED = 'LOGICAL_CONDITION_TEXT_WHEN_ANSWERED',
  WORK_ORDERS = 'WORK_ORDERS',
  NEW_WORK_ORDER = 'NEW_WORK_ORDER',
  JOB_NAME = 'JOB_NAME',
  INSPECTIONS = 'INSPECTIONS',
  STEPS = 'STEPS',
  THERE_ARE_NO_JOBS_YET = 'THERE_ARE_NO_JOBS_YET',
  PRESS_PLUS_NEW_JOB_TO_START = 'PRESS_PLUS_NEW_JOB_TO_START',
  DELETE_WORK = 'DELETE_WORK',
  THIS_WORK_WILL_BE_DELETED_EVERYWHERE_THIS_ACTION_CANNOT_BE_UNDONE = 'THIS_WORK_WILL_BE_DELETED_EVERYWHERE_THIS_ACTION_CANNOT_BE_UNDONE',
  DELETE_PERMANENTLY = 'DELETE_PERMANENTLY',
  DRILLS_CHECKLIST = 'DRILLS_CHECKLIST',
  YOU_WILL_NOT_BE_ABLE_TO_REVERSE_THE_ACTION_DO_YOU_WANT_TO_DELETE_THE_CHECKLIST = 'YOU_WILL_NOT_BE_ABLE_TO_REVERSE_THE_ACTION_DO_YOU_WANT_TO_DELETE_THE_CHECKLIST',
  YES_DELETE = 'YES_DELETE',
  DELETE_INSTANCE = 'DELETE_INSTANCE',
  REPORT_SUBMITTED_SUCCESSFULLY = 'REPORT_SUBMITTED_SUCCESSFULLY',
  YOU_CAN_NOW_CLOSE_THIS_TAB = 'YOU_CAN_NOW_CLOSE_THIS_TAB',
  ENABLE_PARAGRAPH = 'ENABLE_PARAGRAPH',
  ENABLE_DECIMALS = 'ENABLE_DECIMALS',
  MY_ASSETS = 'MY_ASSETS',
  ARCHIVED = 'ARCHIVED',
  ASSET_TYPES = 'ASSET_TYPES',
  ADD_ASSET = 'ADD_ASSET',
  FIND_ACTIVE = 'FIND_ACTIVE',
  ACTIVE_NAME = 'ACTIVE_NAME',
  GUY = 'GUY',
  LAST_INSPECTION_DATE = 'LAST_INSPECTION_DATE',
  OPEN_ACTIONS = 'OPEN_ACTIONS',
  THERE_ARE_NO_ASSETS_YET = 'THERE_ARE_NO_ASSETS_YET',
  PRESS_ADD_ASSET_TO_GET_STARTED = 'PRESS_ADD_ASSET_TO_GET_STARTED',
  ADD_ASSET_TYPE = 'ADD_ASSET_TYPE',
  CHANGES_ARE_SAVED_AUTOMATICALLY = 'CHANGES_ARE_SAVED_AUTOMATICALLY',
  ENTER_ASSET_TYPE = ' ENTER_ASSET_TYPE',
  NEW_FIELD_DESCRIPTION = 'NEW_FIELD_DESCRIPTION',
  DELETE_FIELD_DESCRIPTION = 'DELETE_FIELD_DESCRIPTION',
  ANONYMOUS = 'ANONYMOUS',
  NAME_OF_INSPECTION = 'NAME_OF_INSPECTION',
  NAME_INSPECTION = 'NAME_INSPECTION',
  TYPE = 'TYPE',
  DETAILS = 'DETAILS',
  ADD_RIGHT_PANEL_FIELDS = 'ADD_RIGHT_PANEL_FIELDS',
  CREATE_NEW_FIELD = 'CREATE_NEW_FIELD',
  NEW_FIELD = 'NEW_FIELD',
  CREATE = 'CREATE',
  RENAME_FIELD = 'RENAME_FIELD',
  RENAME_DESCRIPTION = 'RENAME_DESCRIPTION',
  DELETE_FIELD = 'DELETE_FIELD',
  INVITE_USERS = 'INVITE_USERS',
  SHARE_PDF = 'SHARE_PDF',
  SEND_PDF_WHEN_FINALIZED = 'SEND_PDF_WHEN_FINALIZED',
  CHECKLIST_SELECTION = 'CHECKLIST_SELECTION',
  SELECT_WHICH_USERS_WILL_BE_ABLE_TO_PARTICIPATE_IN_THIS_WORK = 'SELECT_WHICH_USERS_WILL_BE_ABLE_TO_PARTICIPATE_IN_THIS_WORK',
  ACTIVE = 'ACTIVE',
  FINALIZED = 'FINALIZED',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  INVITING_YOU_TO_ESPECTRO = 'INVITING_YOU_TO_ESPECTRO',
  THERE_ARE_NO_COMPLETED_JOBS_YET = 'THERE_ARE_NO_COMPLETED_JOBS_YET',
  WAIT_FOR_THE_ASSIGNED_USERS_TO_FINISH_TO_VIEW_THEM_HERE = 'WAIT_FOR_THE_ASSIGNED_USERS_TO_FINISH_TO_VIEW_THEM_HERE',
  CANCEL_JOB = 'CANCEL_JOB',
  TOTAL_QUESSTIONS = 'TOTAL_QUESSTIONS',
  PICK_A_DATE = 'PICK_A_DATE',
  CANCEL_WORK_ORDER = 'CANCEL_WORK_ORDER',
  CANCELLED = 'CANCELLED',
  REASON_FOR_CANCELLATION = 'REASON_FOR_CANCELLATION',
  TYPE_EMAIL_AND_PRESS_ENTER = 'TYPE_EMAIL_AND_PRESS_ENTER',
  SEND_PDF_VIA_EMAIL = 'SEND_PDF_VIA_EMAIL',
  ADD_EMAILS_TO_SEND = 'ADD_EMAILS_TO_SEND',
  SIGN_OFF = 'SIGN_OFF',
  DUPLICATE = 'DUPLICATE',
  DELETE_CHECKLIST = 'DELETE_CHECKLIST',
  WILL_BE_DELETED_THIS_ACTION_CANNOT_BE_UNDONE = 'WILL_BE_DELETED_THIS_ACTION_CANNOT_BE_UNDONE',
  IT_WILL_GET_DELETED_AS_SOON_AS_THE_FOLLOWING_WORK_ORDERS_ARE_FNISHED = 'IT_WILL_GET_DELETED_AS_SOON_AS_THE_FOLLOWING_WORK_ORDERS_ARE_FNISHED',
  YOU_WILL_STILL_SEE_THE_PDF_REPORTS_AFTER_DELETION = 'YOU_WILL_STILL_SEE_THE_PDF_REPORTS_AFTER_DELETION',
  NO_JOBS_CANCELED_YET = 'NO_JOBS_CANCELED_YET',
  WAIT_FOR_WORK_ORDERS_TO_BE_CANCELED_TO_VIEW_THEM_HERE = 'WAIT_FOR_WORK_ORDERS_TO_BE_CANCELED_TO_VIEW_THEM_HERE',
  ENTER_PLACEHOLDER_TEXT = 'ENTER_PLACEHOLDER_TEXT',
  UPLOAD_MEDIA_REFERENCE = 'UPLOAD_MEDIA_REFERENCE',
  REPLACE_MEDIA_REFERENCE = 'REPLACE_MEDIA_REFERENCE',
  USER_MANUAL = 'USER_MANUAL',
  LOOK_FOR_MANUAL = 'LOOK_FOR_MANUAL',
  HOW_TO_MAKE_AN_INSPECTION_CHECKLIST = 'HOW_TO_MAKE_AN_INSPECTION_CHECKLIST',
  HOW_TO_CREATE_A_WORK_ORDER = 'HOW_TO_CREATE_A_WORK_ORDER',
  KIND_OF_QUESTIONS = 'KIND_OF_QUESTIONS',
  IS_SIGNED = 'IS_SIGNED',
  IS_NOT_SIGNED = 'IS_NOT_SIGNED',
  IS_UPLOADED = 'IS_UPLOADED',
  IS_IMAGE_UPLOADED = 'IS_IMAGE_UPLOADED',
  IS_NOT_UPLOADED = 'IS_UPLOADED',
  NO_WO_NAME = 'NO_WO_NAME',
  ADD_AN_IMAGE_TO_SAVE = 'ADD_AN_IMAGE_TO_SAVE',
  ADDED_REASON_FOR_CANCELLATION = 'ADDED_REASON_FOR_CANCELLATION',
  ENABLE_CAMERA_CAPTURE = 'ENABLE_CAMERA_CAPTURE',
  CREATE_WORK_ORDER = 'CREATE_WORK_ORDER',
  EDIT_WORK_ORDER = 'EDIT_WORK_ORDER',
  CHECKLIST = 'CHECKLIST',
  AVAILABLE = 'AVAILABLE',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  PLEASE_SELECT_VALIDITY_FOR_WORK_ORDER = 'PLEASE_SELECT_VALIDITY_FOR_WORK_ORDER',
  SEARCHED_USERS = 'SEARCHED_USERS',
  UNAVAILABLE = 'UNAVAILABLE',
  SEARCH_OR_PRESS_ENTER_AFTER_TYPING_EMAIL = 'SEARCH_OR_PRESS_ENTER_AFTER_TYPING_EMAIL',
  SUPERVISORS = 'SUPERVISORS',
  PROCESSING = 'PROCESSING',
  AUTO_REMOVE_SIGNATURE = 'AUTO_REMOVE_SIGNATURE',
  AUTO_HIDE_SIGNATURE = 'AUTO_HIDE_SIGNATURE',
  SURNAME = 'SURNAME',
  THIS_WILL_BE_PREFILLED_AS_DEFAULT_ANSWER = 'THIS_WILL_BE_PREFILLED_AS_DEFAULT_ANSWER',
  SELECT_A_DEFAULT_OPTION = 'SELECT_A_DEFAULT_OPTION',
  SET_MINIMUM_AND_MAXIMUM = 'SET_MINIMUM_AND_MAXIMUM',
  MINIMUM_VALUE = 'MINIMUM_VALUE',
  MAXIMUM_VALUE = 'MAXIMUM_VALUE',
  MAXIMUM_VALUE_SHOULD_BE_GREATER_THAN_MINIMUM_VALUE = 'MAXIMUM_VALUE_SHOULD_BE_GREATER_THAN_MINIMUM_VALUE',
  MINIMUM_VALUE_SHOULD_BE_LESSER_THAN_MAXIMUM_VALUE = 'MINIMUM_VALUE_SHOULD_BE_LESSER_THAN_MAXIMUM_VALUE',
  SHOW_INPUT_FOR_SIGNEE_NAME = 'SHOW_INPUT_FOR_SIGNEE_NAME',
  REQUIRES_SIGNATURE = 'REQUIRES_SIGNATURE',
  SHOW_OTHER_AS_OPTION = 'SHOW_OTHER_AS_OPTION',
  END_DATE_SHOULD_BE_A_LATER_DATE = 'END_DATE_SHOULD_BE_A_LATER_DATE',
  VALUE_OUT_OF_RANGE = 'VALUE_OUT_OF_RANGE',
  VALUE_OUT_OF_RANGE_WISH_TO_CONTINUE = 'VALUE_OUT_OF_RANGE_WISH_TO_CONTINUE',
  MONTH = 'MONTH',
  TODAY = 'TODAY',
  REINVITE = 'REINVITE',
  FETCHING_REPORT = 'FETCHING_REPORT',
  SELECTED_CHECKLISTS = 'SELECTED_CHECKLISTS',
  NO_MORE_RECORDS = 'NO_MORE_RECORDS'
}

export enum SKAP_TAB_TYPES {
  WAREHOUSE_HELPERS = 'WAREHOUSE_HELPERS',
  FORKLIFTERS = 'FORKLIFTERS',
  DISTRIBUTION = 'DISTRIBUTION',
}
export enum FILTER_ACCESS_LEVELS {
  DISTRIBUTION_CENTER = 'distributionCenter',
  SUBZONE = 'subzone',
  ZONE = 'zone',
  BUSINESS_UNIT = 'bu',
  CLUSTER = 'cluster'
}

export enum POSTS_TYPES {
  NEWS = 'NEWS',
  BEST_PRACTICES = 'BEST_PRACTICES',
  PREVENTION_CAMPAIGNS = 'PREVENTION_CAMPAIGNS',
}

export enum ATTACHMENT_TYPES {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  PDF = 'PDF',
}

export enum PLACE_OF_QUARANTINE {
  HOME = 'HOME',
  HOSPITAL = 'HOSPITAL',
}

export enum GLOBAL_SEARCH_TYPES {
  LOCATION = 'LOCATION',
}

export enum ACTION_LOG_STATUS {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  FOR_REVISION = 'FOR_REVISION',
  COMPLETED = 'COMPLETED',
}

export enum ACTION_LOG_MEETINGS {
  DIARIA_LOGÍSTICA = 'DIARIA_LOGÍSTICA',
  RUTINA_MATUTINA_DE_REPARTO = 'RUTINA_MATUTINA_DE_REPARTO',
  RUTINA_DE_CAMBIO_DE_TURNO = 'RUTINA_DE_CAMBIO_DE_TURNO',
  RUTINA_SEMANAL_DE_ALMACÉN = 'RUTINA_SEMANAL_DE_ALMACÉN',
  RUTINA_SEMANAL_DE_DIST = 'RUTINA_SEMANAL_DE_DIST',
  RUTINA_SEMANAL_DE_PEOPLE = 'RUTINA_SEMANAL_DE_PEOPLE',
  RUTINA_SEMANAL_DE_FLOTA = 'RUTINA_SEMANAL_DE_FLOTA',
  RUTINA_SEMANAL_DE_PLANNING = 'RUTINA_SEMANAL_DE_PLANNING',
  RUTINA_SEMANAL_DE_SAFETY = 'SEMANAL_DE_SAFETY_RUTINA_SEMANAL_DE_SAFETY',
  RUTINE_SEMANAM_DE_GESTION = 'RUTINE_SEMANAM_DE_GESTION',
}

export enum ACTION_LOG_SUMMARY_MODAL_STATUS {
  EDIT = 'EDIT',
  CREATE = 'CREATE',
  SUMMARY = 'SUMMARY',
}
export enum ROUTINE_COMPLIANCE {
  HEADING = 'Routine compliance',
  COMPLETED_PERCENTAGE = 'Routine %',
  EXECUTED = 'Executed',
  PENDING = 'Pending',
  SEARCH = 'Search By GRO',
  REGION = 'Country/ Region',
  PLANNED = 'Planned',
  REAL = 'Real',
  COMPLIANCE = 'Compliance',
  DOWNLOAD_DATA = 'Download Data',
  BU = 'Per BU',
  RANKING = 'DC Ranking for MAZ',
  CD = 'DC',
}
export enum BU {
  Colombia = 'CO',
  Mexico = 'MEX',
  Ecuador = 'EC',
  Perú = 'PE',
  CAC = 'CAC',
}
export enum COMMENT_CREATE_TYPE {
  ACTION_LOG = 'Action Log',
  CAPEX = 'Capex'
}
export enum YES_NO_OPTIONS {
  YES = 'YES',
  NO = 'NO'
}

export enum SIF_TYPES {
  PRECURSOR = 'PRECURSOR',
  POTENTIAL = 'POTENTIAL'
}

export enum LocalLogicActionType {
  BASIC_INIT = 'BASIC_INIT',
  ADD_NEW_LOGIC= 'ADD_NEW_LOGIC',
  REMOVE_LOGIC= 'REMOVE_LOGIC',
  INITIALIZE_WITH_PAYLOAD = 'INITIALIZE_WITH_PAYLOAD',
  UPDATE_LOGIC_QUESTION = 'UPDATE_LOGIC_QUESTION',
  UPDATE_LOGIC_OPERATOR = 'UPDATE_LOGIC_OPERATOR',
  ADD_LOGIC_SELECT = 'ADD_LOGIC_SELECT',
  CLEAR = 'CLEAR',
  ADD_MULTIPLE_SOURCE_ITEM_TRIGGER = 'ADD_MULTIPLE_SOURCE_ITEM_TRIGGER',
  DELETE_SOURCE_ITEM_TRIGGER = 'DELETE_SOURCE_ITEM_TRIGGER'
}

export enum LocalLogicTriggerActionType {
  BASIC_INIT = 'BASIC_INIT',
  INITIALIZE_WITH_PAYLOAD = 'INITIALIZE_WITH_PAYLOAD',
  UPDATE_TRIGGER= 'UPDATE_TRIGGER',
  REMOVE_TRIGGER= 'REMOVE_TRIGGER',
  ADD_NEW_LOGIC_TRIGGER = 'ADD_NEW_LOGIC_TRIGGER',
  ADD_RECIPIENTS = 'ADD_RECIPIENTS',
  ADD_TRIGGER_QUESTION = 'ADD_TRIGGER_QUESTION',
}

export enum WORK_ORDER_USER_ROLE {
  EXECUTOR = 'EXECUTOR',
  SUPERVISOR = 'SUPERVISOR'
}

export enum VIEW_MODE {
  LIST = 'LIST',
  CALENDAR = 'CALENDAR',
}
