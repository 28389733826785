import { WorkOrder, WorkOrderStatus } from 'generated/graphql';
import { LocalLogicActionType, LocalLogicTriggerActionType } from 'types/enums';

/**
 * TODO: Figure out a way to use all these types directly from avolution storybooks codebase
 */
export enum ChecklistBitTypes {
  CUSTOM = 'CUSTOM',
  INPUT = 'INPUT',
  DROPDOWN = 'DROPDOWN',
  CHECKLIST = 'NESTED_CHECKLIST',
  HORIZONTAL_CHECKLIST = 'HORIZONTAL_CHECKLIST',
  VERTICAL_SINGLE_OPTION = 'VERTICAL_SINGLE_OPTION',
  HORIZONTAL_SINGLE_OPTION = 'HORIZONTAL_SINGLE_OPTION',
  CAROUSEL = 'CAROUSEL',
  COUNTER = 'COUNTER',
  TEXT = 'TEXT',
  SECTION = 'SECTION',
  DATE = 'DATE',
  LIST_OF_ITEMS = 'LIST_OF_ITEMS',
  BODY_BOLD = 'BODY_BOLD',
  BODY20 = 'BODY20',
  H2 = 'H2',
  IMAGE_PICKER ='IMAGE_PICKER',
  SIGNATURE ='SIGNATURE',
  DRIBBLE ='DRIBBLE',
  TIME = 'TIME',
  VERTICAL_MULTIPLE_OPTION ='VERTICAL_MULTIPLE_OPTION',
  NESTED_CHECKLIST = 'NESTED_CHECKLIST',
  LIST_ITEM = 'LIST_ITEM',
  ACK ='ACK',
}
export enum SIZE {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}
export enum ACK_TYPE {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}
export type ChecklistBit = {
  type?: ChecklistBitTypes;
  key?: string;
  /** default = true. If it should count towards progress indicator */
  countsTowardProgress?: boolean;
  /**
   * Indirect props not for use
   */
  shouldBitProgressConsidered?: boolean,
  isRendered?: boolean;
  additionalFeatures?: {
    allowsNote: boolean,
    note: string,
    triggerActionLog?: {
      operator: showIfOperators,
      value: string,
      values?: showIf[]
    }
  };
  /** */
  props: {
    checklistId?: string | number;
    placeholder?: string;
    keyboardType?: 'numeric' | 'default';
    numericType?: 'integer' | 'decimal';
    enableParagraphInput?: boolean;
    autoRemoveWhenSectionTampered?: boolean;
    hideQuestionUntilSectionAnswered?: boolean;
    showInputForSigneeName?: boolean;
    capturedSigneeName?: string;
    enableCameraCapture?: boolean;
    showOthersOption?: boolean;
    boundaryValues?: {
      min: number;
      max: number;
    };
    text?: string;
    content?: any;
    title?: string;
    instructionType?: ACK_TYPE;
    instructionImage?: string;
    selectedAssetType?: {
      id: number | string;
      shortSlug: string;
      name: string;
    };
    description?: string;
    subTitle?: string;
    /**
     * Counter/Stepper props
     */
    counterSize?: SIZE;
    step?: number;
    minimumValue?: number;
    toggleKeyboardOnPress?: boolean;
    onSubmitEditing?: (val: number) => any;
    onIncrementPress?: (val: number) => any;
    options?: {
      title: string,
      description?: string,
    }[];
    /**
     * Carousel Props
     */
    images?: {
      url: string
    }[];
    autoplay?: boolean;
    /**
     * List_OF_ITEMS props
     */
    head?: string;
    listItems?: {
      iconUrl: string;
      text: string;
    }[]
    /**
     * dateTimeProps
     */
    dateTimeProps?: any;
    minimumDate?: any;
    maximumDate?: any;
    /**
     * operational props
     */
    isNAMaster?: boolean;
    parentKey?: string;
    blueprint?: string;
  }
  answer?: any;
  defaultAnswer?: any;
  showIf?: showIf;
};

export type dividerOptions = 'off' | 'medium';

export type ChecklistItem = {
  canBeRepeated?: boolean;
  title: string;
  key: string;
  description: string;
  bits: ChecklistBit[];
  divider?: dividerOptions;
  showIf?: showIf;
  communication?: communication
  required?: boolean;
};

export type ChecklistSection = {
  title: string;
  description: string;
  isExpandedDefault?: boolean;
  items: ChecklistItem[];
  key: string;
  recipients?: string[];
  captureSignatureBeforeSubmit?: boolean;
  authorisedSignature?: {
    signeeName: string;
    signaturePath: string;
  }[];
  isExpanded?: boolean;
  styles?: {
    sectionHeaderActive?: string,
    sectionHeaderInActive?: string,
    progressActive?: string,
    progressInActive?: string,
  }
};

export enum showIfAccesors {
  checklist = '$checklist',
  items = '$items',
  previousItem = '$previousItem',
  bits = '$bits',
  previousBit = '$previousBit',
}

export enum showIfOperators {
  equals = 'equals',
  and = 'and',
  or = 'or',
  notEmpty = 'notEmpty',
  empty = 'empty',
  greaterThan = 'greaterThan',
  lessThan = 'lessThan',
}

export type showIf = {
  operator: keyof typeof showIfOperators;
  key: string;
  /** Prefix: $checklist[0], $items, $bits, $previousBit */
  value?: any;
  values?: showIf[];
};

export type communication = {
  operator: keyof typeof showIfOperators;
  key: string;
  value?: any;
  recipient: string[]
  values?: showIf[]
};

export enum Frequency {
  DAILY = '0 0 * * *',
  ANYTIME = '* * * * *',
  WEEKLY = '0 0 * * 0',
  HOURLY = '0 0/1 * * *',
  ONCE_EVERY_SHIFT = '0 8,12,16 * * *'
}
export type ChecklistState = {
  title: string | undefined;
  frequency: Frequency;
  description?: string;
  shareableToken?: string | null;
  versionNumber?: number;
};
export enum Actions {
  SEND_EMAIL = 'SEND_EMAIL',
  SHOW_ANSWER = 'SHOW_ANSWER',
  TRIGGER_ACTION_LOG = 'TRIGGER_ACTION_LOG'
}

export type LocalLogicTrigger = {
  trigger: Actions;
  sectionIndex: number;
  itemIndex: number;
  bitIndex: number;
  recipient: string[];
};

export type LocalLogic = {
  id: string;
  sectionIndex: number;
  itemIndex: number;
  operation: showIfOperators;
  value: any;
  triggers?: LocalLogicTrigger[];
  globalRecipients?: string[]
};

export type LocalLogicAction = | {
  type: LocalLogicActionType.BASIC_INIT,
  payload?: null,
} | {
  type: LocalLogicActionType.ADD_NEW_LOGIC,
  payload: {
    localLogicMapKey: number;
  },
} | {
  type: LocalLogicActionType.REMOVE_LOGIC;
  payload: {
    localLogicMapKey: number;
  },
} | {
  type: LocalLogicActionType.INITIALIZE_WITH_PAYLOAD;
  payload: { [key: number]: LocalLogic[] },
} | {
  type: LocalLogicActionType.UPDATE_LOGIC_QUESTION;
  payload: {
    itemIndex: number;
    logicIndex: number;
    sectionIndex: number;
    localLogicMapKey: number;
  },
} | {
  type: LocalLogicActionType.UPDATE_LOGIC_OPERATOR;
  payload: {
    logicIndex: number;
    operator: showIfOperators;
    localLogicMapKey: number;
  },
} | {
  type: LocalLogicActionType.ADD_LOGIC_SELECT;
  payload: {
    logicIndex: number;
    value: any;
    localLogicMapKey: number;
  },
} | {
  type: LocalLogicActionType.CLEAR,
  payload: {
    localLogicMapKey: number;
  },
} | {
  type: LocalLogicActionType.ADD_MULTIPLE_SOURCE_ITEM_TRIGGER,
  payload: {
    localLogicMapKey: number;
  },
} | {
  type: LocalLogicActionType.DELETE_SOURCE_ITEM_TRIGGER,
  payload: {
    localLogicMapKey: number;
    localLogicIndex: number;
  },
};

export type LocalLogicTriggerAction = | {
  type: LocalLogicTriggerActionType.BASIC_INIT,
  payload?: null,
} | {
  type: LocalLogicTriggerActionType.INITIALIZE_WITH_PAYLOAD;
  payload: LocalLogicTrigger[],
} | {
  type: LocalLogicTriggerActionType.UPDATE_TRIGGER;
  payload: {
    trigger: Actions;
    itemIndex: number;
    logicIndex: number;
    sectionIndex: number;
    triggerIndex?: number;
  },
} | {
  type: LocalLogicTriggerActionType.REMOVE_TRIGGER;
  payload: { logicIndexToDelete: number; },
} | {
  type: LocalLogicTriggerActionType.ADD_NEW_LOGIC_TRIGGER,
  payload?: null,
} | {
  type: LocalLogicTriggerActionType.ADD_RECIPIENTS;
  payload: {
    logicIndex: number;
    recipient: string[];
  },
} | {
  type: LocalLogicTriggerActionType.ADD_TRIGGER_QUESTION;
  payload: {
    itemIndex: number;
    logicIndex: number;
    sectionIndex: number;
  },
};

export type WorkOrdersTabDataType = {
  data: WorkOrder[];
  page: number;
  loading: boolean;
  message: string;
  // type: WorkOrderStatus;
  firstLoadingEvent: boolean;
};
