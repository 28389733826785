import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from 'components/Header';
import NavigationDrawerWithSideMenu
  from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import defaultRoutes from 'utils/defaultRoutes';
import { selectUser, selectUserLocation, selectUserType } from 'store/selectors/user.selectors';
import './NewWorkOrder.scss';
import { TRANSLATIONS, WORK_ORDER_USER_ROLE } from 'types/enums';
import BasicButton from 'components/Button/BasicButton';
import { ToastOptions, toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useCreateWorkOrderMutation,
  useEditWorkOrderMutation,
  useGetRoutinesAvailableForWorkOrdersLazyQuery,
  useGetWorkOrderDetailsLazyQuery,
  useListUsersForWorkOrdersLazyQuery,
} from 'generated/graphql';
import InputWithLabel from 'components/Input/InputWithLabel/InputWithLabel';
import moment from 'moment';
import 'moment/locale/es';
import ImageLinks from 'utils/ImageLinks';
import LoadingSpinner from 'components/LoadingSpinner';
import { DragDropContext, Droppable, DropResult, Draggable } from 'react-beautiful-dnd';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import { EMAIL_REGEX } from '../../../constants';
import checklistSearch from '../../../assets/icons/input-search.svg';
import backArrow from '../../../assets/icons/back-arrow.svg';
import WorkOrderValiditySelector from '../components/WorkOrderValiditySelector/WorkOrderValiditySelector';
import UserAssignmentForChecklist from '../components/UserAssignmentForChecklist/UserAssignmentForChecklist';
import UserSearchAutocomplete from '../components/UserSearchAutocomplete/UserSearchAutocomplete';

enum DndCards {
  CARD_1 = 'CARD_1',
  CARD_2 = 'CARD_2',
}

export interface StateType {
  checklistData: any;
  isEdit: boolean;
  selectStartDate: Date;
  selectEndDate: Date;
  isDuplicateAction?: boolean;
}

const NewWorkOrder = () => {
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  const { state: routerState } = useLocation<StateType>();
  const { t } = useTranslation();
  const history = useHistory();
  moment.locale('es');

  const { isDuplicateAction = false } = routerState;

  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [dndChecklistList, setDndChecklistList] = useState<any>({
    [DndCards.CARD_1]: [],
    [DndCards.CARD_2]: [],
  });

  const [currentFormStep, setCurrentFormStep] = useState<0 | 1 | 2>(0);
  const [receivers, setReceivers] = useState<string[]>([]);
  const [showNoTitleAlert, setNoTitleAlert] = useState<boolean>(false);
  const [routineWithVersionUpgraded, setRoutinesWithVersionUpgraded] = useState<number[]>([]);
  const [
    isWorkOrderValiditySelectorOpen,
    toggleWorkOrderValiditySelector,
  ] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Date>(routerState?.selectStartDate || new Date());
  const [endDate, setEndDate] = useState<Date>(routerState?.selectEndDate || new Date());
  const [draggableCards, setDraggableCards] = useState<any>({
    availableUsers: [],
    assignedUsers: {},
  });
  const [supervisorsList, setSupervisorsList] = useState<any[]>([]);
  const [
    isComputingDataForUserSelection,
    setIsComputingDataForUserSelection,
  ] = useState<boolean>(false);
  const [
    initialRoutinesAvailableList,
    setInitialRoutinesAvailableList,
  ] = useState<any[]>([]);

  const toastConfig = {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  } as ToastOptions;

  const inputEl = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const goBack = () => {
    if (currentFormStep === 0) {
      history.goBack();
    } else setCurrentFormStep(currentFormStep - 1 as (0 | 1 | 2));
  };

  const [
    queryUsersForWorkOrder, {
      refetch: refetchCompanyUsers,
      loading: isQueryUsersLoading,
    },
  ] = useListUsersForWorkOrdersLazyQuery({
    fetchPolicy: 'no-cache',
    onError: e => toast.error(e.message),
    onCompleted: d => {
      const usersFetched = d.listUsersForWorkOrders;
      setDraggableCards((prev: any) => ({
        ...prev,
        availableUsers: usersFetched,
      }));
    },
  });

  const [
    getWorkOrderDetailsQuery, {
      loading: getWorkOrderDetailsLoading,
    },
  ] = useGetWorkOrderDetailsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: async d => {
      const { getWorkOrderDetails } = d;
      const routineExecutors = getWorkOrderDetails?.workOrderRoutineExecutors;
      const usersAssignedWithRoles = getWorkOrderDetails?.userWorkOrders;
      setStartDate(new Date(getWorkOrderDetails?.startDate));
      setEndDate(new Date(getWorkOrderDetails?.endDate));
      const fetchedSupervisorsList = (usersAssignedWithRoles as any[])
        .filter(user => user.roleType === WORK_ORDER_USER_ROLE.SUPERVISOR).map(sup => ({
          ...sup.user,
          registeredCompany: sup.user?.info?.company,
        }));
      setSupervisorsList(fetchedSupervisorsList);

      const routineNames = Array.from(new Set((routineExecutors as any[])
        ?.map(executor => `${executor.routineAvailable.id}: ${executor.routineAvailable.name}`)));

      const assignedUsers = routineNames?.reduce((acc: any, name: string) => {
        acc[name] = [];
        return acc;
      }, {});

      (routineExecutors as any[]).forEach(executor => {
        const name = `${executor.routineAvailable.id}: ${executor.routineAvailable.name}`;
        const userInfo = {
          ...executor.user,
          registeredCompany: executor.user?.info?.company,
        };
        assignedUsers[name].push(userInfo);
      });

      setDraggableCards({
        assignedUsers,
        availableUsers: [],
      });

      queryUsersForWorkOrder({
        variables: {
          startDate: new Date(getWorkOrderDetails?.startDate),
          endDate: new Date(getWorkOrderDetails?.endDate),
          workOrderId: routerState?.checklistData?.id,
        },
      });
    },
    onError: err => {
      console.log('getWorkOrderDetails query error', err);
    },
  });

  const [callGetRoutinesAvailableLazyQuery,
    { loading, data }] = useGetRoutinesAvailableForWorkOrdersLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      if (res?.getRoutinesAvailable) {
        let unlinkedRoutines: any = res?.getRoutinesAvailable;
        const linkedRoutines: any = [];
        if (routerState?.checklistData?.routines?.length) {
          routerState?.checklistData?.routines?.forEach((i: any) => {
            res?.getRoutinesAvailable?.forEach(j => {
              if (i?.routineInfo?.id === j?.id) {
                linkedRoutines?.push(i);
              }
            });
          });
          const routines = (routerState?.checklistData?.routines || [])
            .map((r: any) => r.routineInfo.id);
          unlinkedRoutines = res?.getRoutinesAvailable?.filter(
            item1 => !routines?.some(
              (item2: any) => item1?.id === item2)
          );
        }
        const list = {
          [DndCards.CARD_1]: unlinkedRoutines,
          [DndCards.CARD_2]: linkedRoutines,
        };
        setInitialRoutinesAvailableList(unlinkedRoutines);
        setDndChecklistList(list);
        if (routerState?.isEdit) {
          getWorkOrderDetailsQuery({
            variables: {
              workOrderId: String(routerState?.checklistData?.id),
            },
          });
        } else {
          queryUsersForWorkOrder({
            variables: {
              startDate,
              endDate,
              workOrderId: routerState?.checklistData?.id,
            },
          });
        }
      }
    },
  });

  const [
    createWorkOrderMutation, { loading: createWorkOrderLoading },
  ] = useCreateWorkOrderMutation({
    onCompleted() {
      history.push('/espectro/work-orders');
    },
    onError(e) {
      console.log('error', e.message);
      toast.error(e?.message, toastConfig);
    },
  });

  const [
    editWorkOrderMutation, { loading: editWorkOrderLoading },
  ] = useEditWorkOrderMutation({
    onCompleted() {
      // history.goBack();history.go(1);
      history.push('/espectro/work-orders');
    },
    onError(e) {
      console.log('error', e.message);
      toast.error(e?.message, toastConfig);
    },
  });

  useEffect(() => {
    callGetRoutinesAvailableLazyQuery({
      variables: {
        excludeNestedChecklist: false,
        includeDeletedRoutines: true,
      },
    });
    setTitle(
      routerState?.checklistData
        ? isDuplicateAction
          ? `${routerState?.checklistData?.name} | copy`
          : routerState?.checklistData?.name
        : t(TRANSLATIONS.JOB_NAME),
    );
    setDescription(
      routerState?.checklistData ? routerState?.checklistData?.description : '',
    );
    setReceivers(routerState?.checklistData ? routerState?.checklistData?.receivers : []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editTitle && inputEl?.current) {
      inputEl.current.focus();
    }
  }, [editTitle]);

  const extractAllAssignedUsers = () => {
    const allAssignedUsers: any[] = [];

    Object.keys(draggableCards.assignedUsers).forEach(checklist => {
      const usersList = draggableCards.assignedUsers[checklist].map((u: any) => ({
        userId: u.id,
        role: WORK_ORDER_USER_ROLE.EXECUTOR,
        routineAvailableId: checklist.split(': ')[0], // TODO later on can pass RA id as well, currently appending to title
      }));
      allAssignedUsers.push(...usersList);
    });

    if (supervisorsList.length) {
      const supervisors = supervisorsList.map(s => ({
        userId: s.id,
        role: WORK_ORDER_USER_ROLE.SUPERVISOR,
      }));
      allAssignedUsers.push(...supervisors);
    }

    return allAssignedUsers;
  };

  const onClickSaveWorkOrder = () => {
    if (!title || title === t(TRANSLATIONS.JOB_NAME)) {
      setNoTitleAlert(true);
      return;
    }

    const checklistIds = dndChecklistList[DndCards.CARD_2]
      ?.map((item: any) => {
        return {
          routineId: item?.routineInfo?.id,
          versionId: routerState?.isEdit && routineWithVersionUpgraded
            .includes(item?.routineInfo?.id)
            ? item?.routineInfo?.routineVersions?.id
            : item?.versionAdded?.id,
        };
      });

    // Extract users and roles
    const assignedUsers = extractAllAssignedUsers();

    if (routerState?.isEdit && !isDuplicateAction) {
      editWorkOrderMutation({
        variables: {
          id: routerState?.checklistData?.id,
          name: title,
          description,
          receivers,
          routinesWithVersions: checklistIds,
          validityEndDate: endDate,
          validityStartDate: startDate,
          assignedUsersWithRoles: assignedUsers,
        },
      });
      return;
    }
    createWorkOrderMutation({
      variables: {
        name: title,
        description,
        routinesWithVersions: checklistIds,
        receivers,
        validityEndDate: endDate,
        validityStartDate: startDate,
        assignedUsersWithRoles: assignedUsers,
      },
    });
  };

  const routinesAvailable = data?.getRoutinesAvailable;

  const searchChecklists = useCallback(() => {
    // if (
    //   !dndChecklistList[DndCards.CARD_1] || dndChecklistList[DndCards.CARD_1]?.length === 0
    // ) return;

    const availableRoutinesForDragAndDrop = initialRoutinesAvailableList?.filter(
      item1 => !dndChecklistList[DndCards.CARD_2]
      ?.some((item2: any) => item1?.id === item2?.routineInfo?.id)
    );

    const tempRoutines = availableRoutinesForDragAndDrop?.filter((routine: any) => routine
      ?.name?.toLowerCase().includes(search?.toLocaleLowerCase()));

    if (tempRoutines?.length! > 0) {
      const list = {
        ...dndChecklistList,
        [DndCards.CARD_1]: [...tempRoutines],
      };
      setDndChecklistList(list);
    } else {
      const list = {
        [DndCards.CARD_1]: [],
        [DndCards.CARD_2]: [...dndChecklistList[DndCards.CARD_2]],
      };

      setDndChecklistList(list);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (search) {
      searchChecklists();
    } else {
      const arr = routinesAvailable?.filter(
        item1 => !dndChecklistList[DndCards.CARD_2]
          ?.some((item2: any) => item1?.id === item2?.routineInfo?.id)
      );

      const list = {
        ...dndChecklistList,
        [DndCards.CARD_1]: arr,
      };
      setDndChecklistList(list);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, searchChecklists]);

  const handleSearch = async (event: any) => {
    setSearch(event.target.value);
  };

  const onChangeTitle = (e: any) => {
    setTitle(e.target.value);
  };

  const onChangeDescription = (e: any) => {
    setDescription(e.target.value);
  };

  const toggleEditTitle = () => setEditTitle(prev => !prev);

  async function onDragEnd(result: DropResult) {
    const { source, destination } = result;

    if (!destination) return;
    const sourceColumn = source?.droppableId as DndCards;
    const destinationColumn = destination?.droppableId as DndCards;

    if (sourceColumn === destinationColumn) {
      const items = Array.from(dndChecklistList?.[sourceColumn]);
      const [removed] = items?.splice(source?.index, 1);
      items.splice(destination?.index, 0, removed);

      setDndChecklistList((prevState: any) => ({
        ...prevState,
        [sourceColumn]: items,
      }));
    } else {
      const movingCard = dndChecklistList?.[sourceColumn]?.find(
        (_: any, index: number) => index === source?.index);

      if (destinationColumn === 'CARD_2') {
        const movedRoutineExistInRouterState = routerState?.checklistData?.routines
          ?.find((routine: any) => routine?.routineInfo?.id === movingCard?.id);
        const checkRouterStateForRoutineMeta = movedRoutineExistInRouterState
          && !routineWithVersionUpgraded.includes(Number(movingCard.id));
        const updatedData = {
          routineInfo: movingCard,
          versionAdded: {
            id: checkRouterStateForRoutineMeta
              ? movedRoutineExistInRouterState?.versionAdded?.id : movingCard?.routineVersions?.id,
            versionNumber: checkRouterStateForRoutineMeta
              ? movedRoutineExistInRouterState?.versionAdded?.versionNumber
              : movingCard?.routineVersions?.versionNumber,
          },
        };
        const list = {
          [DndCards.CARD_1]: dndChecklistList?.['CARD_1']?.filter?.(
            (_: any, index: number) => index !== source?.index,
          ),
          [DndCards.CARD_2]: [
            ...dndChecklistList?.['CARD_2']?.slice?.(0, destination?.index),
            { ...updatedData },
            ...dndChecklistList?.['CARD_2']?.slice?.(destination?.index),
          ],
        };
        setDndChecklistList(list);
      } else {
        const movedRoutineExistInRouterState = routinesAvailable?.find(
          (routine: any) => routine?.id === movingCard?.routineInfo?.id,
        );
        const checkRouterStateForRoutineMeta = movedRoutineExistInRouterState
          && routineWithVersionUpgraded.includes(movingCard?.routineInfo?.id);

        const updatedData = {
          ...movingCard?.routineInfo,
          versionId: checkRouterStateForRoutineMeta
            ? movedRoutineExistInRouterState?.versionId
            : movingCard?.versionAdded?.id,
          versionNumber: checkRouterStateForRoutineMeta
            ? movedRoutineExistInRouterState?.versionNumber
            : movingCard?.versionAdded?.versionNumber,
        };
        const list = {
          [DndCards.CARD_1]: [
            ...dndChecklistList?.['CARD_1']?.slice?.(0, destination?.index),
            { ...updatedData },
            ...dndChecklistList?.['CARD_1']?.slice?.(destination?.index),
          ],
          [DndCards.CARD_2]: dndChecklistList?.['CARD_2']?.filter?.(
            (_: any, index: number) => index !== source?.index,
          ),
        };
        setDndChecklistList(list);
      }
    }
  }

  const onClickSelectUsers = async () => {
    if (currentFormStep === 0) {
      toggleWorkOrderValiditySelector(true);
    } else {
      const selectedUsersEmails: string[] = [...receivers];
      Object.keys(draggableCards.assignedUsers).forEach((key: string) => {
        const usersAdded = (draggableCards.assignedUsers[key] as any[])
          ?.map((u: any) => u?.email).filter(e => !!e);
        usersAdded.forEach(email => {
          if (!selectedUsersEmails.includes(email) && EMAIL_REGEX.test(email)) {
            selectedUsersEmails.push(email);
          }
        });
      });
      supervisorsList.forEach((supervisor: any) => {
        const supervisorEmail = supervisor?.email;
        if (EMAIL_REGEX.test(supervisorEmail)) {
          selectedUsersEmails.push(supervisorEmail);
        }
      });
      setReceivers(Array.from(new Set(selectedUsersEmails)));
      setCurrentFormStep(prev => {
        if (prev < 2) {
          return prev + 1 as (0 | 1 | 2);
        }
        return 2;
      });
    }
  };

  const onPressSaveFromWorkOrderValidityModal = async () => {
    const cards = draggableCards;
    const assignedUsers: any = {};
    setIsComputingDataForUserSelection(true);

    const fetchUsersForCompanyData = await refetchCompanyUsers?.({
      startDate,
      endDate,
      workOrderId: !routerState?.checklistData?.id ? undefined : routerState?.checklistData?.id,
    });

    dndChecklistList[DndCards.CARD_2].forEach((checklist: any) => {
      const checklistUniqueName = `${checklist.routineInfo.id!}: ${checklist.routineInfo.name!}`;
      if (!Object.keys(assignedUsers).includes(checklistUniqueName)) {
        const assignedUsersForChecklist: any = [];
        if (cards.assignedUsers[checklistUniqueName]) {
          cards.assignedUsers[checklistUniqueName]?.forEach((user: any) => {
            if (fetchUsersForCompanyData) {
              const isUserEnabled = fetchUsersForCompanyData?.data.listUsersForWorkOrders
                ?.find((fetchedUser: any) => Number(fetchedUser?.id) === Number(user.id))
                  ?.isEnabled;
              if (isUserEnabled) {
                assignedUsersForChecklist.push(user);
              }
            } else {
              assignedUsersForChecklist.push(user);
            }
          });
        }
        assignedUsers[checklistUniqueName] = assignedUsersForChecklist;
      }
    });
    cards.assignedUsers = assignedUsers;
    cards.availableUsers = fetchUsersForCompanyData?.data.listUsersForWorkOrders;

    setDraggableCards(cards);

    setCurrentFormStep(prev => {
      if (prev < 2) {
        return prev + 1 as (0 | 1 | 2);
      }
      return 2;
    });
    toggleWorkOrderValiditySelector(false);
    setIsComputingDataForUserSelection(false);
  };

  const focusOnTitle = (): void => {
    toggleEditTitle();
    setNoTitleAlert(false);
    setTimeout(() => {
      if (inputEl && inputEl.current) {
        inputEl.current.focus();
      }
    }, 100);
  };

  const onPressRefreshRoutineVersion = (
    event: React.MouseEvent<SVGTextElement, MouseEvent>,
    cardData: any,
  ) => {
    event.preventDefault();
    setRoutinesWithVersionUpgraded([...routineWithVersionUpgraded, cardData.routineInfo.id]);
  };

  const getIsNextClickDisabled = () => {
    if (currentFormStep === 0) {
      return dndChecklistList[DndCards.CARD_2]?.length === 0;
    }
    if (currentFormStep === 1) {
      if (supervisorsList.length === 0) return true;
      const selectedChecklistsFromDraggableCards = Object.keys(draggableCards.assignedUsers);
      const hasAssignedUser = selectedChecklistsFromDraggableCards
        ?.every((checklistTitle: string) => {
          return draggableCards.assignedUsers[checklistTitle].length > 0;
        });
      return !hasAssignedUser;
    }
  };

  const userDetails = useSelector(selectUser);

  const renderVersionRefreshHandle = (cardData: any) => {
    return (
      <>
        <text className="wo_version-refresh-info">
          Version
          {` ${cardData?.routineInfo?.routineVersions?.versionNumber!.toFixed(2)} `}
          {t(TRANSLATIONS.AVAILABLE)}
          &nbsp;
        </text>
        <text
          onClick={
            (e: React.MouseEvent<SVGTextElement, MouseEvent>) => onPressRefreshRoutineVersion(
              e,
              cardData,
            )
          }
          className="wo_version-refresh-action-text"
        >
          {t(TRANSLATIONS.UPDATE)}
        </text>
      </>
    );
  };

  return (
    <div className="new_wo_page-container">
      <Header />
      <NavigationDrawerWithSideMenu
        {...{
          routes: defaultRoutes(t, userType, location, userDetails),
          openDrawer: true,
        }}
      />
      <div className="new-workorder__containerv1">
        {(!loading && !createWorkOrderLoading && !editWorkOrderLoading
        && !getWorkOrderDetailsLoading && !isQueryUsersLoading) ? (
          <>
            <div className="new-workorder__flex-row">
              <div className="new-workorder__header-main">
                <div role="button" onClick={goBack} className="new-workorder__backArrow">
                  <img src={backArrow} alt="backArrow" className="new_wo_back-btn" />
                </div>
                <div className="new-workorder__headerContainer">
                  <div className="checklistHeader__tab-edit">
                    {editTitle ? (
                      <div className="checklistHeader__edit-container">
                        <input
                          ref={inputEl}
                          className="checklistHeader_input"
                          type="text"
                          placeholder={t(TRANSLATIONS.JOB_NAME)}
                          value={title}
                          onChange={onChangeTitle}
                          onBlur={title !== t(TRANSLATIONS.JOB_NAME) ? toggleEditTitle : () => {}}
                          maxLength={100}
                        />
                      </div>
                    ) : (
                      <div className="new-workorder__header-edit">
                        <p className="new-workorder__header-title" onClick={toggleEditTitle}>
                          {title}
                        </p>
                        <img onClick={toggleEditTitle} height={16} width={16} src={ImageLinks.edit} alt="edit" />
                      </div>
                    )}
                    {routerState?.isEdit && !isDuplicateAction ? (
                      <text className="checklistHeader_id">{`ID ${routerState?.checklistData ? routerState?.checklistData?.id : ''}`}</text>
                    ) : null}
                    <input
                      type="text"
                      placeholder={t(TRANSLATIONS.ENTER_DESCRIPTION)}
                      name="description"
                      value={description}
                      maxLength={1500}
                      onChange={onChangeDescription}
                      className="new_wo_header-description"
                    />
                  </div>
                </div>
              </div>
              {currentFormStep === 2 && (
                <BasicButton
                  onClick={onClickSaveWorkOrder}
                  className="new-workorder__upload_button"
                  text={routerState?.isEdit && !isDuplicateAction
                    ? t(TRANSLATIONS.SAVE)
                    : t(TRANSLATIONS.CREATE_WORK_ORDER)}
                  background="#6C63FF"
                  disabled={dndChecklistList[DndCards.CARD_2]?.length === 0}
                />
              )}
              {currentFormStep < 2 && (
                <BasicButton
                  onClick={onClickSelectUsers}
                  className="new-workorder__upload_button"
                  text={t(TRANSLATIONS.NEXT)}
                  background="#6C63FF"
                  disabled={getIsNextClickDisabled()}
                />
              )}
            </div>
            <p className={`${currentFormStep <= 2 ? 'new-workorder__checklistSelection-select-users-title' : 'new-workorder__checklistSelection-title'}`}>
              {currentFormStep === 0 && `${t(TRANSLATIONS.CHECKLIST_SELECTION)} (1/3)`}
              {currentFormStep === 1 && `${t(TRANSLATIONS.INVITE_USERS)} (2/3)`}
              {currentFormStep === 2 && `${t(TRANSLATIONS.SHARE_PDF)} (3/3)`}
            </p>
            {currentFormStep === 2 && (
              <div style={{ marginTop: '12px' }}>
                <p style={{ fontSize: 16, fontWeight: 400, color: '#878787' }}>{t(TRANSLATIONS.SEND_PDF_WHEN_FINALIZED)}</p>
                <UserSearchAutocomplete
                  existingReceivers={receivers}
                  setReceivers={setReceivers}
                />
              </div>
            )}

            {currentFormStep === 1 && (
              <div className="new_workorder-users-selection-container">
                <UserAssignmentForChecklist
                  draggableCards={draggableCards}
                  setDraggableCards={setDraggableCards}
                  supervisorsList={supervisorsList}
                  setSupervisorsList={setSupervisorsList}
                />
              </div>
            ) }

            {currentFormStep === 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <div className="new_wo_row">
                  {Object.keys(dndChecklistList)?.map((column: any, index: number) => {
                    const isLeftPanel = column === DndCards.CARD_1;
                    return (
                      <div className="new-workorder__checklist_cards">
                        {isLeftPanel ? (
                          <InputWithLabel
                            inputRef={inputRef}
                            inputImg={checklistSearch}
                            onChange={handleSearch}
                            placeholder={t(TRANSLATIONS.SEARCH)}
                            value={search}
                            labelClassName="search-header"
                            type="search"
                            maxLength={1000}
                            inputClassName="search-input"
                            containerClassName="new-workorder__searchv1"
                          />
                        ) : null}
                        <Droppable droppableId={column}>
                          {provided => (
                            <div ref={provided.innerRef} className="new_workOrder-full-height">
                              {(dndChecklistList?.[column]?.length) ? (
                                <div className="new-workorder__checklist_card_containerv1">
                                  {index === 1 ? <text className="new-workorder__checklist_card_add_title">Arrastra inspecciones para agregarlas a este trabajo</text> : null}
                                  {dndChecklistList?.[column]?.map(
                                    (cardData: any, index1: number) => {
                                      const renderRefreshAvailableText = (!isLeftPanel
                                        && !(cardData?.routineInfo?.routineVersions
                                          ?.versionNumber === cardData?.versionAdded?.versionNumber)
                                        && !routineWithVersionUpgraded
                                          .includes(cardData?.routineInfo?.id));
                                      return (
                                        <Draggable
                                          key={isLeftPanel
                                            ? cardData?.id : cardData?.routineInfo?.id}
                                          draggableId={`${isLeftPanel ? cardData?.id : cardData?.routineInfo?.id}`}
                                          index={index1}
                                        >
                                          {providedV1 => (
                                            <div
                                              className="new-workorder__checklist_card-v1"
                                              role="presentation"
                                              ref={providedV1.innerRef}
                                              {...providedV1.draggableProps}
                                              {...providedV1.dragHandleProps}
                                            >
                                              <text className={`new-workorder__checklist_card_title-v1 ${renderRefreshAvailableText && 'wo_restrict-width-for-routine-text'}`}>
                                                {isLeftPanel
                                                  ? cardData.name
                                                  : cardData?.routineInfo?.name}
                                              </text>
                                              <text className="workorder__checklist_card_version_title-v1">
                                                {renderRefreshAvailableText
                                                  ? renderVersionRefreshHandle(cardData)
                                                  : null}
                                                &nbsp;
                                                v{
                                                isLeftPanel
                                                  ? cardData?.versionNumber?.toFixed(2)
                                                  : (
                                                    routineWithVersionUpgraded
                                                      .includes(cardData?.routineInfo?.id)
                                                      ? cardData?.routineInfo?.routineVersions
                                                        ?.versionNumber?.toFixed(2)
                                                      : cardData?.versionAdded
                                                        ?.versionNumber?.toFixed(2)
                                                  )
                                                }
                                              </text>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                </div>
                              ) : (
                                <div className="new-workorder__checklist_card_add_title_wrap">
                                  <text className="new-workorder__checklist_card_add_title">Arrastra inspecciones para agregarlas a este trabajo</text>
                                </div>
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    );
                  })}
                </div>
              </DragDropContext>
            )}
          </>
          ) : (
            <div>
              <LoadingSpinner text={`${t(TRANSLATIONS.LOADING)}...`} />
            </div>
          )}
      </div>
      {showNoTitleAlert && (
        <ConfirmationModal
          title={t(TRANSLATIONS.WAIT_A_MOMENT)}
          description={`${t(TRANSLATIONS.NO_WO_NAME)}`}
          isOpen={showNoTitleAlert}
          onOk={focusOnTitle}
          okButtonText={`${(t(TRANSLATIONS.CONTINUE).toLowerCase())}`}
          cancelButtonText={t(TRANSLATIONS.CANCEL)}
          onCancel={focusOnTitle}
          okButtonStyle={{ backgroundColor: '#56cd9d', color: '#fff' }}
        />
      )}
      {isWorkOrderValiditySelectorOpen && (
        <WorkOrderValiditySelector
          isOpen={isWorkOrderValiditySelectorOpen}
          startDate={startDate}
          endDate={endDate}
          setEndDateCallback={setEndDate}
          isEditting={routerState?.isEdit}
          setStartDateCallback={setStartDate}
          isProcessingNextStepData={isComputingDataForUserSelection}
          isOpenToggle={onPressSaveFromWorkOrderValidityModal}
        />
      )}
    </div>
  );
};

export default NewWorkOrder;
