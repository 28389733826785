import React from 'react';
import './OverlayLoader.scss';

const OverlayLoader = () => {
  return (
    <div className="overlay">
      <div className="loader" />
    </div>
  );
};

export default OverlayLoader;
