import React, { useMemo, useState } from 'react';
import moment from 'moment';
import 'react-big-calendar/lib/sass/styles.scss';
import {
  Calendar,
  Event,
  NavigateAction,
  SlotInfo,
  ToolbarProps,
  View,
  momentLocalizer,
} from 'react-big-calendar';
import './WorkOrdersCalendarView.scss';
import { differenceInCalendarDays, endOfDay, getMonth } from 'date-fns';
import i18next from 'i18next';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';

type Props = {
  workOrderList: any;
  fetchWorkOrderRecords: any;
  onPressWorkOrderEvent: (id: string | number) => void;
  onSelectSlotForNewWorkOrder: (slotInfo: SlotInfo) => void;
};

const ColoredDateCellWrapper = ({ children }: any) => React
  .cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: '#f5f6fa',
    },
  });

const CustomToolbar = (toolbar: ToolbarProps) => {
  const { t } = useTranslation();

  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = () => {
    toolbar.onNavigate('TODAY');
  };

  const label = () => {
    const date = moment(toolbar.date);
    const { view } = toolbar;

    if (view === 'month') {
      return (
        <span>
          <span>{date.format('MMMM')}</span>
          <span> {date.format('YYYY')}</span>
        </span>
      );
    } if (view === 'week') {
      const startOfWeek = moment(toolbar.date).startOf('week');
      const endOfWeek = moment(toolbar.date).endOf('week');
      return (
        <span>
          <span>{startOfWeek.format('MMMM D')}</span>
          <span> - {endOfWeek.format('MMMM D')}</span>
        </span>
      );
    } if (view === 'day') {
      return (
        <span>
          <span>{date.format('dddd')}</span>
          <span>, {date.format('MMMM D, YYYY')}</span>
        </span>
      );
    }
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={goToBack}>
          {t(TRANSLATIONS.PREVIOUS)}
        </button>
        <button type="button" onClick={goToCurrent}>
          {t(TRANSLATIONS.TODAY)}
        </button>
        <button type="button" onClick={goToNext}>
          {t(TRANSLATIONS.NEXT)}
        </button>
      </span>
      <span className="rbc-toolbar-label">{label()}</span>
      <span className="rbc-btn-group">
        <button
          type="button"
          style={{
            backgroundColor: toolbar.view === 'month' ? '#D4D4D4' : 'white',
          }}
          onClick={() => toolbar.onView('month')}
        >
          {t(TRANSLATIONS.MONTH)}
        </button>
        <button
          type="button"
          style={{
            backgroundColor: toolbar.view === 'week' ? '#D4D4D4' : 'white',
          }}
          onClick={() => toolbar.onView('week')}
        >
          {t(TRANSLATIONS.WEEK)}
        </button>
        <button
          type="button"
          style={{
            backgroundColor: toolbar.view === 'day' ? '#D4D4D4' : 'white',
          }}
          onClick={() => toolbar.onView('day')}
        >
          {t(TRANSLATIONS.DAY).substring(0, 1).toUpperCase()}{t(TRANSLATIONS.DAY).substring(1)}
        </button>
      </span>
    </div>
  );
};

const WorkOrdersCalendarView = ({
  workOrderList,
  onPressWorkOrderEvent,
  // fetchWorkOrderRecords,
  onSelectSlotForNewWorkOrder,
}: Props) => {
  moment.locale(i18next.language);
  const [currentDate, setDate] = useState<Date>();

  const mLocalizer = momentLocalizer(moment);

  const { components, defaultDate, max, views } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
        toolbar: CustomToolbar,
      },
      defaultDate: new Date(),
      max: endOfDay(new Date()),
      views: {
        day: true,
        week: true,
        month: true,
      },
    }),
    [],
  );

  const eventPropGetter = (event: Event | any) => {
    const today = new Date();
    let backgroundColor = '';
    let color = '#FFFFFF';

    if (event?.isFinalized) {
      backgroundColor = '#70AD47';
    } else if (new Date(event.endDate) < today || event?.isCancelled) {
      backgroundColor = '#FF0000';
    } else if (event.totalQuestionsAnswered > 0 && new Date(event.endDate) > today) {
      backgroundColor = '#FDC959';
      color = '#000000';
    }

    return {
      style: {
        color,
        backgroundColor,
      },
    };
  };

  const eventsList: Event[] = workOrderList!.map((wo: any) => ({
    ...wo,
    start: new Date(wo!.start),
    end: new Date(wo!.end),
    allDay: differenceInCalendarDays(new Date(wo!.start), new Date(wo.end)) > 1,
  }));

  return (
    <div className="workOrder-calendar-container">
      <Calendar
        max={max}
        step={30}
        selectable
        views={views}
        events={eventsList}
        localizer={mLocalizer}
        components={components}
        defaultDate={defaultDate}
        eventPropGetter={eventPropGetter}
        onSelectSlot={onSelectSlotForNewWorkOrder}
        onSelectEvent={(event: any) => onPressWorkOrderEvent(event.id)}
        // onNavigate={(newDate: Date, view: View, action: NavigateAction) => {
        //   if (currentDate && getMonth(currentDate) !== getMonth(newDate)) {
            // console.log({ newDate, view, action });
            // fetchWorkOrderRecords(true);
        //   }
        //   setDate(newDate);
        // }}
      />
    </div>
  );
};

export default WorkOrdersCalendarView;
